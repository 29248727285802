// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import TextInput from '../../../../common/TextInput';
import TextAreaInput from '../../../../common/TextAreaInput';
import { Button, Label } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import Attachment from '../../../../common/Attachment';
import ReactModal from "react-modal";

const ScribbleIdeaMobile = ({ privateIdeaData, errorClassIdeaTitle, handleOnSelectFile, uploadFilesList, handleRemoveFile,
    showFileDesc, handleSavePrivateIdea, cancel, handleChangeField, filesToShow, expanded, showAllFiles ,discardValue}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const isDisabled = false;

    return (
        <div className="scribble-idea">
            
            <ReactModal isOpen={showModal} style={{ content: { padding: "0" } }} className={"ReactModal__Content_Mobilepopup capture-idea-mobile"}>
                {/*<div className="pop-my-preference">My Preferences</div>*/}
                <div className="scribble-idea-header-mobile">
                    <div className="icon">
                        <span className="icon scribble-idea-bulb">
                            <svg xmlns="" id="Objects_01_Light_lightbulb-on" data-name="Objects/01 Light/lightbulb-on"
                                width="32" height="24" viewBox="0 0 32 24">
                                <path id="Objects_01_Light_lightbulb-on-2" data-name="Objects/01 Light/lightbulb-on"
                                    d="M17.971,24H14.028a.812.812,0,0,1-.665-.335l-1.225-1.727A.708.708,0,0,1,12,21.525L12,18a12.452,12.452,0,0,0-2.622-4.323A7.909,7.909,0,0,1,7.2,8.261,8.1,8.1,0,0,1,9.792,2.383,9.008,9.008,0,0,1,16,0h.006a8.863,8.863,0,0,1,8.009,4.838,7.861,7.861,0,0,1-1.392,8.84A12.52,12.52,0,0,0,20,18l0,3.523a.729.729,0,0,1-.135.414l-1.225,1.728A.814.814,0,0,1,17.971,24ZM13.6,21v.3l.853,1.2h3.086l.855-1.2V21ZM13.6,18l0,1.5h4.8V18ZM16,1.5a7.388,7.388,0,0,0-5.1,1.952,6.6,6.6,0,0,0-2.1,4.8,6.484,6.484,0,0,0,1.778,4.438,15.777,15.777,0,0,1,2.53,3.81h5.781a15.953,15.953,0,0,1,2.529-3.81A6.5,6.5,0,0,0,23.2,8.25,6.994,6.994,0,0,0,16,1.5Zm13.6,15a.835.835,0,0,1-.357-.08l-3.2-1.5a.762.762,0,0,1-.4-.434.7.7,0,0,1,.044-.573A.8.8,0,0,1,26.4,13.5a.834.834,0,0,1,.357.08l3.2,1.5a.73.73,0,0,1,.358,1.006A.8.8,0,0,1,29.6,16.5Zm-27.2,0a.838.838,0,0,1-.441-.124.738.738,0,0,1-.358-.671.753.753,0,0,1,.441-.626l3.2-1.5A.859.859,0,0,1,5.6,13.5a.79.79,0,0,1,.78.578.734.734,0,0,1-.422.844l-3.2,1.5A.849.849,0,0,1,2.4,16.5ZM31.2,9H28a.752.752,0,1,1,0-1.5h3.2a.752.752,0,1,1,0,1.5Zm-20,0a.777.777,0,0,1-.8-.75A5.45,5.45,0,0,1,16,3a.777.777,0,0,1,.8.75.777.777,0,0,1-.8.75,3.891,3.891,0,0,0-4,3.75A.777.777,0,0,1,11.2,9ZM4,9H.8a.752.752,0,1,1,0-1.5H4A.752.752,0,1,1,4,9ZM26.4,3a.789.789,0,0,1-.779-.578.733.733,0,0,1,.42-.843l3.2-1.5A.836.836,0,0,1,29.6,0a.8.8,0,0,1,.716.414.7.7,0,0,1,.043.573.761.761,0,0,1-.4.434l-3.2,1.5A.865.865,0,0,1,26.4,3ZM5.6,3a.859.859,0,0,1-.358-.08l-3.2-1.5a.76.76,0,0,1-.4-.433A.7.7,0,0,1,1.685.414.8.8,0,0,1,2.4,0a.836.836,0,0,1,.358.08l3.2,1.5a.732.732,0,0,1,.42.843A.788.788,0,0,1,5.6,3Z"
                                    fill="#a100ff" />
                            </svg>
                        </span>
                    </div>
                    <h5 className="title">Capture your private idea</h5>
                    <div className="icon-write">
                        <div className="cross-button" onClick={() => setShowModal(false)}>X</div>
                    </div>
                </div>
                {/*<div className="cross-button" onClick={()=> setShowModal(false)}>X</div>*/}
                <div className="input-fields">
                    
                    <TextInput keyVal="Idea Title" label="" onChangeField={handleChangeField}
                        name='privateIdeaTitle' valueTextArea={privateIdeaData['privateIdeaTitle'] ? privateIdeaData['privateIdeaTitle'] : ''}
                        size='medium' placeholder='Title' className={errorClassIdeaTitle}
                        maxLength={100} disabled={isDisabled} discardValue={discardValue}/>                    
                    

                    <div>
                        <TextAreaInput onChangeField={handleChangeField} content="" placeholder='Description'
                            size='medium' name='privateIdeaDescription'
                            valueTextArea={privateIdeaData['privateIdeaDescription'] ? privateIdeaData['privateIdeaDescription'] : ''}
                            maxLength={2000} className="textarea" disabled={isDisabled} discardValue={discardValue} />
                       
                    </div>
                    <div className="action-btn-wrapper">
                        <div className="attachment-wraper">
                            <div className="attachment-section">
                                <span className="label-attachments" >Attachments</span>
                                <input id="file" type="file" style={{ display: 'none' }} onChange={handleOnSelectFile} />
                                <label htmlFor="file">
                                    <span className="icon scribble-attach float-left">
                                        <svg xmlns="" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                                            <g fill="none">
                                                <path d="M3.257 8.707l4.597-4.596a2.5 2.5 0 0 1 3.535 3.536L6.086 12.95a1 1 0 0 1-1.414-1.414l5.303-5.304a.5.5 0 0 0-.707-.707L3.964 10.83a2 2 0 1 0 2.829 2.828l5.303-5.303a3.5 3.5 0 0 0-4.95-4.95L2.55 8a.5.5 0 1 0 .707.707z" fill="#626262" />
                                            </g>
                                            <rect x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
                                        </svg>
                                    </span>
                                </label>
                            </div>

                            <div className="attached-file">
                                {
                                    uploadFilesList && uploadFilesList.slice(0, filesToShow).map((file, index) => {
                                        return (
                                            <div className="wrapper" key={index}>
                                                <Attachment
                                                    header={file.name}
                                                    action='remove'
                                                    clickAction={() => handleRemoveFile(file.name)}
                                                    description={showFileDesc(file.fileCreatedOn, file.size)}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    (uploadFilesList && uploadFilesList.length > 3 && !expanded) ?
                                        <div className='additional-files' onClick={showAllFiles}>+{uploadFilesList.length - 3}</div> : ''
                                }
                            </div>
                            
                        </div>

                        <div className="full-width divider">
                            <hr className="margin-0" />
                        </div>

                        <div className="attachment-filesize-label">
                            <Label className="scribble-attachment" content="File size: Max 10 MB" />
                        </div>

                        <div className="scribbleidea-btn-container-mobile">
                            
                            {((privateIdeaData && (privateIdeaData['privateIdeaDescription'] !== '' && privateIdeaData['privateIdeaDescription'] !== undefined &&
                                privateIdeaData['privateIdeaDescription'] !== null) || (privateIdeaData['privateIdeaTitle'] !== '' && privateIdeaData['privateIdeaTitle'] !== undefined &&
                                    privateIdeaData['privateIdeaTitle'] !== null)) || (uploadFilesList && uploadFilesList.length > 0)) ?
                                <Button secondary className="btn-secondary" onClick={() => cancel()}>Discard</Button>
                                : null}
                            <Button primary className="btn-primary" onClick={() => { handleSavePrivateIdea(false); setShowModal(false) }}>Submit</Button>
                        </div>
                        <div className="scribbleidea-message-mobile">
                            <span>*This idea will be visible only to you.</span>
                        </div>
                    </div>
                </div>
            </ReactModal>

            <div className="header">
                <div className="icon">
                    <span className="icon scribble-idea-bulb">
                        <svg xmlns="" id="Objects_01_Light_lightbulb-on" data-name="Objects/01 Light/lightbulb-on"
                            width="32" height="24" viewBox="0 0 32 24">
                            <path id="Objects_01_Light_lightbulb-on-2" data-name="Objects/01 Light/lightbulb-on"
                                d="M17.971,24H14.028a.812.812,0,0,1-.665-.335l-1.225-1.727A.708.708,0,0,1,12,21.525L12,18a12.452,12.452,0,0,0-2.622-4.323A7.909,7.909,0,0,1,7.2,8.261,8.1,8.1,0,0,1,9.792,2.383,9.008,9.008,0,0,1,16,0h.006a8.863,8.863,0,0,1,8.009,4.838,7.861,7.861,0,0,1-1.392,8.84A12.52,12.52,0,0,0,20,18l0,3.523a.729.729,0,0,1-.135.414l-1.225,1.728A.814.814,0,0,1,17.971,24ZM13.6,21v.3l.853,1.2h3.086l.855-1.2V21ZM13.6,18l0,1.5h4.8V18ZM16,1.5a7.388,7.388,0,0,0-5.1,1.952,6.6,6.6,0,0,0-2.1,4.8,6.484,6.484,0,0,0,1.778,4.438,15.777,15.777,0,0,1,2.53,3.81h5.781a15.953,15.953,0,0,1,2.529-3.81A6.5,6.5,0,0,0,23.2,8.25,6.994,6.994,0,0,0,16,1.5Zm13.6,15a.835.835,0,0,1-.357-.08l-3.2-1.5a.762.762,0,0,1-.4-.434.7.7,0,0,1,.044-.573A.8.8,0,0,1,26.4,13.5a.834.834,0,0,1,.357.08l3.2,1.5a.73.73,0,0,1,.358,1.006A.8.8,0,0,1,29.6,16.5Zm-27.2,0a.838.838,0,0,1-.441-.124.738.738,0,0,1-.358-.671.753.753,0,0,1,.441-.626l3.2-1.5A.859.859,0,0,1,5.6,13.5a.79.79,0,0,1,.78.578.734.734,0,0,1-.422.844l-3.2,1.5A.849.849,0,0,1,2.4,16.5ZM31.2,9H28a.752.752,0,1,1,0-1.5h3.2a.752.752,0,1,1,0,1.5Zm-20,0a.777.777,0,0,1-.8-.75A5.45,5.45,0,0,1,16,3a.777.777,0,0,1,.8.75.777.777,0,0,1-.8.75,3.891,3.891,0,0,0-4,3.75A.777.777,0,0,1,11.2,9ZM4,9H.8a.752.752,0,1,1,0-1.5H4A.752.752,0,1,1,4,9ZM26.4,3a.789.789,0,0,1-.779-.578.733.733,0,0,1,.42-.843l3.2-1.5A.836.836,0,0,1,29.6,0a.8.8,0,0,1,.716.414.7.7,0,0,1,.043.573.761.761,0,0,1-.4.434l-3.2,1.5A.865.865,0,0,1,26.4,3ZM5.6,3a.859.859,0,0,1-.358-.08l-3.2-1.5a.76.76,0,0,1-.4-.433A.7.7,0,0,1,1.685.414.8.8,0,0,1,2.4,0a.836.836,0,0,1,.358.08l3.2,1.5a.732.732,0,0,1,.42.843A.788.788,0,0,1,5.6,3Z"
                                fill="#a100ff" />
                        </svg>
                    </span>
                </div>
                <h5 className="title">Capture your private idea</h5>
                <div className="icon-write">
                    <span className="icon scribble-idea-write" onClick={() => setShowModal(true)}>
                        <svg aria-hidden="true" focusable="false" width="1em" height="1em"
                            style={{ msTransform: 'rotate(360deg)', WebkitTransform: 'rotate(360deg)', transform: 'rotate(360deg)' }}
                            preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                            <g fill="none">
                                <path d="M10.27 2a2.621 2.621 0 1 1 3.713 3.702l-7.47 7.491a1.5 1.5 0 0 1-.558.354l-3.969 1.416a.75.75 0 0 1-.957-.96l1.425-3.963a1.5 1.5 0 0 1 .35-.55L10.27 2zm3.003.704a1.621 1.621 0 0 0-2.295.002l-.781.784l2.293 2.293l.784-.787a1.621 1.621 0 0 0-.001-2.292zm-1.49 3.787L9.492 4.198l-5.98 5.997a.5.5 0 0 0-.116.184L2.15 13.843l3.47-1.238a.5.5 0 0 0 .186-.118l5.979-5.996z" fill="#626262" />
                                <path d="M8.858 2H1.5a.5.5 0 0 0 0 1h6.36l.998-1z" fill="#626262" />
                                <path d="M6.864 4H1.5a.5.5 0 0 0 0 1h4.367l.997-1z" fill="#626262" />
                                <path d="M4.87 6H1.5a.5.5 0 0 0 0 1h2.373l.997-1z" fill="#626262" />
                            </g>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ScribbleIdeaMobile;