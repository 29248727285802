import React, { useEffect, useState, useRef } from "react";
import ReactModal from "react-modal";
import './my-preferences.scss';
import { Multiselect } from 'multiselect-react-dropdown';
import { addUserPreference } from '../../api/IdeaApi';
import { GenericMessageBar } from '../../common/MessageBar';
import { PageLoader } from "../../common/PageLoader";
import { useDispatch } from "react-redux";
import { fetchMyPreference, setMyPreference } from '../../redux-sharedframework/actions/fetch-my-expertise';
import { Spinner, Tooltip } from "@fluentui/react-components";
var uniqid = require('uniqid');
import { FontIcon } from "@fluentui/react/lib/Icon";
import { mergeStyles, mergeStyleSets } from "@fluentui/react/lib/Styling";
import MyPreferenceMobile from "../mobile/landing/left-panel/MyPreferenceMobile";
import { useSelector, RootStateOrAny } from "react-redux";

const iconClass = mergeStyles({
    fontSize: 25,
    height: 16,
    width: 16,
});

const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: "#A124FF" }, iconClass],
    greenYellow: [{ color: "greenyellow" }, iconClass],
    salmon: [{ color: "salmon" }, iconClass],
});

const MyPreferences = ({ userPreferenceData, technologyData, industry, userPreferenceCompleteData }) => {
    const dispatch = useDispatch();
    const [userTechnologyData, setUserTechnologyData] = useState<any>([]);
    const [userIndustryData, setUserIndustryData] = useState<any>([]);
    const [userTechnologyDataOnLoad, setUserTechnologyDataOnLoad] = useState<any>([]);
    const [userIndustryDataOnLoad, setUserIndustryDataOnLoad] = useState<any>([]);
    const [technologyOptionsArray, setTechnologyOptionArray] = useState<any>([]);
    const [industryOptionsArray, setIndustryOptionsArray] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [choice, setChoice] = useState("");
    const [showLoader, setLoader] = useState(false);
    const [clickedInd, setClickedInd] = useState<boolean>(false);
    const [clickedTech, setClickedTech] = useState<boolean>(false);
    const [clickedIndSelectAll, setClickedIndSelectAll] = useState<boolean>(false);
    const [clickedTechSelectAll, setClickedTechSelectAll] = useState<boolean>(false);
    const indBox = useRef(null);
    const techBox = useRef(null);
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    useEffect(() => {
        if (userPreferenceData != undefined && userPreferenceData != null && userPreferenceData.length > 0) {
            let techData = [];
            let industryData = [];
            userPreferenceData.map((item) => {
                if (item.PreferenceKey == "Industry") {
                    item.PereferenceValue.map((data) => {
                        industryData.push({ CategoryName: data.CategoryName })
                    })
                }
                if (item.PreferenceKey == "Technology") {
                    item.PereferenceValue.map((data) => {
                        techData.push({ CategoryId: data.CategoryId, CategoryName: data.CategoryName })
                    })
                }
            });
            setUserTechnologyData(techData);
            setUserIndustryData(industryData);
            setUserTechnologyDataOnLoad(techData);
            setUserIndustryDataOnLoad(industryData);
        }
    }, [userPreferenceData]);

    useEffect(() => {
        let industryArray = industry.map(item => ({ CategoryName: item }))
        setIndustryOptionsArray(industryArray);
    }, [industry]);

    useEffect(() => {
        let technologyArray = technologyData.map(item => ({ CategoryId: item.CategoryId, CategoryName: item.CategoryName }));
        setTechnologyOptionArray(technologyArray);
    }, [technologyData]);



    //console.log("event.target", clickedInd);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, false);
        return () => {
            document.removeEventListener("click", handleOutsideClick, false);
        };
    }, [])

    const handleOutsideClick = (event) => {

        if (indBox.current && indBox.current?.contains(event.target)) {
            // console.log("event.target", event.target, indBox.current, clickedInd);
            //clickedInd ? setClickedInd(prevClicked => !prevClicked) : clickedTech ? setClickedTech(prevClicked => !prevClicked) : null;
            setClickedInd(true);
            setClickedTech(false);
        }
        else if (techBox.current && techBox.current?.contains(event.target)) {
            // console.log("event.target", event.target, techBox.current, clickedInd);
            //clickedInd ? setClickedInd(prevClicked => !prevClicked) : clickedTech ? setClickedTech(prevClicked => !prevClicked) : null;
            setClickedTech(true);
            setClickedInd(false);
        }
        else {
            setClickedInd(false);
            setClickedTech(false);
        }
    }

    const resetchoice = () => {
        setChoice(undefined);
    }

    const handleOpenModal = () => {
        setShowModal(true);
        if (userIndustryData.length !== userIndustryDataOnLoad.length) setUserIndustryData(userIndustryDataOnLoad);
        if (userTechnologyData.length !== userTechnologyDataOnLoad.length) setUserTechnologyData(userTechnologyDataOnLoad);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setChoice(undefined);
    };

    const industrySelectHandler = (selectedList, selectedItem) => {
        // console.log("industrySelectHandler", selectedItem);
        if (selectedItem.CategoryName === "Select All") {
            setUserIndustryData(industryOptionsArray);
            setClickedIndSelectAll(true);
        }
        else {
            setUserIndustryData([...userIndustryData, { CategoryName: selectedItem.CategoryName }]);
            setClickedIndSelectAll(false);
        }
    }

    const industryRemoveHandler = (selectedList, selectedItem) => {
        // console.log("industrySelectHandler", selectedItem);
        if (selectedList.length > 0) {
            if (selectedItem.CategoryName === "Select All") {
                setUserIndustryData([]);
                setClickedIndSelectAll(false);
            }
            else {
                setUserIndustryData(userIndustryData.filter(industry => industry.CategoryName !== selectedItem.CategoryName));
                setClickedIndSelectAll(false);
            }
        }
        else {
            setUserIndustryData([]);
            setClickedIndSelectAll(false);
        }
    }

    const technologySelectHandler = (selectedList, selectedItem) => {
        // console.log("industrySelectHandler", selectedItem);
        if (selectedItem.CategoryName === "Select All") {
            setUserTechnologyData(technologyOptionsArray);
            setClickedTechSelectAll(true);
        }
        else {
            setUserTechnologyData([...userTechnologyData, { CategoryId: selectedItem.CategoryId, CategoryName: selectedItem.CategoryName }]);
            setClickedTechSelectAll(false);
        }
    }

    const technologyRemoveHandler = (selectedList, selectedItem) => {
        // console.log("industrySelectHandler", selectedItem);
        if (selectedList.length > 0) {
            if (selectedItem.CategoryName === "Select All") {
                setUserTechnologyData([]);
                setClickedTechSelectAll(false);
            }
            else {
                setUserTechnologyData(userTechnologyData.filter(technology => technology.CategoryName !== selectedItem.CategoryName));
                setClickedTechSelectAll(false);
            }
        }
        else {
            setUserTechnologyData([]);
            setClickedTechSelectAll(false);
        }
    }

    const saveHandler = () => {
        setLoader(true);
        const myPreferenceObjToSend = {
            UserPreferenceDetail: [{ "PreferenceKey": "Industry", "PereferenceValue": [...userIndustryData] },
            { "PreferenceKey": "Technology", "PereferenceValue": [...userTechnologyData] }],
            CreatedBy: userPreferenceCompleteData.CreatedBy,
            CreatedDtm: userPreferenceCompleteData.CreatedDtm,
            EnterpriseId: userPreferenceCompleteData.EnterpriseId,
            ModifiedBy: userPreferenceCompleteData.ModifiedBy,
            ModifiedDtm: userPreferenceCompleteData.ModifiedDtm,
            UserPreferenceId: userPreferenceCompleteData.UserPreferenceId
        }
        addUserPreference(myPreferenceObjToSend).then((res) => {
            // console.log("myPreferenceObjToSend", myPreferenceObjToSend, res);
            if (res != undefined && res != null) {
                //setShowMsg(true);
                setChoice("success");
                setLoader(false);
                setShowModal(false);
                setChoice(undefined);
                dispatch(fetchMyPreference());
                //setTimeout(() => {
                //    setShowModal(false);
                //    setChoice(undefined);
                //    // window.location.reload();
                //    dispatch(fetchMyPreference());
                //}, 1500);
                setTimeout(() => {
                    dispatch(setMyPreference());
                }, 1000);


                if (userTechnologyData.length === 0) setUserTechnologyDataOnLoad([]);
                if (userIndustryData.length === 0) setUserIndustryDataOnLoad([]);
            }
        });
    }

    return (
        isMobile ?
            (
                <MyPreferenceMobile showModal={showModal} showLoader={showLoader} showMsg={showMsg} choice={choice} resetchoice={resetchoice}
                    handleCloseModal={handleCloseModal} clickedInd={clickedInd} clickedIndSelectAll={clickedIndSelectAll}
                    industryOptionsArray={industryOptionsArray} industrySelectHandler={industrySelectHandler}
                    industryRemoveHandler={industryRemoveHandler} userIndustryData={userIndustryData} indBox={indBox}
                    clickedTech={clickedTech} clickedTechSelectAll={clickedTechSelectAll} techBox={techBox}
                    technologyOptionsArray={technologyOptionsArray} technologySelectHandler={technologySelectHandler}
                    technologyRemoveHandler={technologyRemoveHandler} userTechnologyData={userTechnologyData} saveHandler={saveHandler}
                    handleOpenModal={handleOpenModal} userPreferenceData={userPreferenceData} classNames={classNames} />
            ) :
            (
                <div className="left-side">
                    <>
                        <ReactModal
                            isOpen={showModal}
                        >
                            {showLoader ? <PageLoader hidden={false} /> : <PageLoader hidden={true} />}
                            {showMsg ? <div className="message-bar"><GenericMessageBar choice={choice} msg={"Record added successfully"} resetMsg={resetchoice} /></div> : ""}
                            <div className="preference-modal-popup">
                                <div className="pop-my-preference">My Preferences</div>
                                <div className="cross-button" onClick={handleCloseModal}>X</div>
                            </div>
                            <div className="pop-up-select-industries">Select Industry</div>
                            <div className={`${clickedInd ? "multiSelectIndustryClicked" : "multiSelectIndustry"} ${(clickedIndSelectAll || (userIndustryData.length && industryOptionsArray.length && userIndustryData.length === industryOptionsArray.length)) ? "multiSelectIndustrySelectAllClicked" : ""}`} ref={indBox}>
                                <Multiselect
                                    showArrow
                                    options={[{ CategoryName: "Select All" }, ...industryOptionsArray]}
                                    displayValue="CategoryName"
                                    showCheckbox={true}
                                    onSelect={industrySelectHandler}
                                    onRemove={industryRemoveHandler}
                                    selectedValues={userIndustryData.length === industryOptionsArray.length ? [{ CategoryName: "Select All" }, ...userIndustryData] : userIndustryData}
                                    closeOnSelect={false}
                                    style={{
                                        option: { display: 'flex', alignItems: 'center' },
                                        searchBox: { maxHeight: '4.396vw', overflowY: 'auto' }
                                    }}

                                />
                            </div>
                            <div className="pop-up-select-technology">Select Technology</div>
                            <div className={` ${clickedTech ? "multiSelectTechnologyClicked" : "multiSelectTechnology"} ${(clickedTechSelectAll || (userTechnologyData.length && technologyOptionsArray.length && userTechnologyData.length === technologyOptionsArray.length)) ? "multiSelectTechnologySelectAllClicked" : ""}`} ref={techBox}>
                                <Multiselect
                                    showArrow
                                    options={[{ CategoryId: "Technology", CategoryName: "Select All" }, ...technologyOptionsArray]}
                                    displayValue="CategoryName"
                                    showCheckbox={true}
                                    onSelect={technologySelectHandler}
                                    onRemove={technologyRemoveHandler}
                                    selectedValues={userTechnologyData.length === technologyOptionsArray.length ? [{ CategoryId: "Technology", CategoryName: "Select All" }, ...userTechnologyData] : userTechnologyData}
                                    closeOnSelect={false}
                                    style={{
                                        option: { display: 'flex', alignItems: 'center' },
                                        searchBox: { maxHeight: '4.396vw', overflowY: 'auto' }
                                    }}
                                />
                            </div>

                            <div className="save-cancel-button">
                                <button className="cancel-button" onClick={handleCloseModal}>cancel</button>
                                <button className="save-button" onClick={saveHandler}>save</button>
                            </div>

                        </ReactModal>
                        <div className="header-div">
                            <p className="patent-header">My Preferences</p>
                        </div>
                        <div className="edit-icon" onClick={handleOpenModal}>
                            {/*<i className="ms-Icon ms-Icon--EditNote" aria-hidden="true"></i>*/}
                            <span className="icon Icon--EditNote">
                                <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                    <g fill="none"><path d="M13.245 2.817a2.783 2.783 0 0 1 4.066 3.796l-.13.14l-9.606 9.606a2.001 2.001 0 0 1-.723.462l-.165.053l-4.055 1.106a.5.5 0 0 1-.63-.535l.016-.08l1.106-4.054c.076-.28.212-.54.398-.76l.117-.128l9.606-9.606zm-.86 2.275L4.346 13.13a1 1 0 0 0-.215.321l-.042.123l-.877 3.21l3.212-.875a1 1 0 0 0 .239-.1l.107-.072l.098-.085l8.038-8.04l-2.521-2.52zm4.089-1.568a1.783 1.783 0 0 0-2.402-.11l-.12.11l-.86.86l2.52 2.522l.862-.86a1.783 1.783 0 0 0 .11-2.402l-.11-.12z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                </svg>
                            </span>
                        </div>
                        {/*<div className="contents">*/}
                        {/*    {userPreferenceData?.length > 0 && (userPreferenceData[0].PereferenceValue.length!=0 || userPreferenceData[1].PereferenceValue.length!=0)?*/}
                        {/*        userPreferenceData != undefined && userPreferenceData != null && userPreferenceData.map((preference) => {*/}
                        {/*            if (preference['PreferenceKey'] != null && preference['PereferenceValue'].length>0) {*/}
                        {/*               // console.log("preference-->",preference);*/}
                        {/*                return (*/}
                        {/*                    <div className="content" key={uniqid()}>*/}
                        {/*                        <div className="content-header">*/}
                        {/*                            */}{/*<i className="ms-Icon ms-Icon--Settings" aria-hidden="true"></i>*/}
                        {/*                            <span className="icon Icon--Settings">*/}
                        {/*                                <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >*/}
                        {/*                                    <g fill="none"><path d="M1.91 7.383a8.491 8.491 0 0 1 1.781-3.08a.5.5 0 0 1 .54-.135l1.918.686a1 1 0 0 0 1.32-.762l.365-2.006a.5.5 0 0 1 .388-.4a8.532 8.532 0 0 1 3.555 0a.5.5 0 0 1 .388.4l.366 2.006a1 1 0 0 0 1.32.762l1.919-.686a.5.5 0 0 1 .539.136a8.491 8.491 0 0 1 1.78 3.079a.5.5 0 0 1-.152.535l-1.555 1.32a1 1 0 0 0 0 1.524l1.555 1.32a.5.5 0 0 1 .152.535a8.491 8.491 0 0 1-1.78 3.08a.5.5 0 0 1-.54.135l-1.918-.686a1 1 0 0 0-1.32.762l-.367 2.007a.5.5 0 0 1-.387.399a8.53 8.53 0 0 1-3.555 0a.5.5 0 0 1-.388-.4l-.365-2.006a1 1 0 0 0-1.32-.762l-1.919.686a.5.5 0 0 1-.539-.136a8.49 8.49 0 0 1-1.78-3.079a.5.5 0 0 1 .152-.535l1.555-1.32a1 1 0 0 0 0-1.524l-1.555-1.32a.5.5 0 0 1-.152-.535zm1.061-.006l1.294 1.098a2 2 0 0 1 0 3.05l-1.294 1.098c.292.782.714 1.51 1.245 2.152l1.596-.57a2 2 0 0 1 2.64 1.525l.305 1.668a7.556 7.556 0 0 0 2.485 0l.305-1.67a2 2 0 0 1 2.64-1.524l1.597.571a7.491 7.491 0 0 0 1.245-2.152l-1.294-1.098a1.998 1.998 0 0 1 0-3.05l1.294-1.098a7.49 7.49 0 0 0-1.245-2.152l-1.596.57a2 2 0 0 1-2.64-1.524l-.306-1.669a7.555 7.555 0 0 0-2.485 0l-.304 1.669a2 2 0 0 1-2.64 1.525l-1.597-.571a7.491 7.491 0 0 0-1.245 2.152zM7.5 10a2.5 2.5 0 1 1 5 0a2.5 2.5 0 0 1-5 0zm1 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 0 0-3 0z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />*/}
                        {/*                                </svg>*/}
                        {/*                            </span>*/}
                        {/*                            <span>{preference.PreferenceKey}</span>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="preference-tag">*/}
                        {/*                            */}{/*{preference.PereferenceValue.map((tag) => {*/}
                        {/*                            */}{/*    return <div className="skill-tags fs-13">{tag.CategoryName}</div>;*/}
                        {/*                            */}{/*})}*/}

                        {/*                            {preference.PereferenceValue.filter((tag, i) => i <= 1).map((tag) => {*/}
                        {/*                                return <div className="skill-tags fs-13" key={uniqid()}>{tag.CategoryName}</div>;*/}
                        {/*                            })}*/}
                        {/*                            {preference.PereferenceValue.length > 2 ?*/}
                        {/*                                <Tooltip*/}
                        {/*                                    content={preference.PereferenceValue.slice(*/}
                        {/*                                        2,*/}
                        {/*                                        preference.PereferenceValue.length*/}
                        {/*                                    ).map((tag) => (*/}
                        {/*                                        <div className="skill-tags fs-13" key={uniqid()}>*/}
                        {/*                                            {tag.CategoryName}*/}
                        {/*                                        </div>*/}
                        {/*                                    ))}*/}
                        {/*                                    position="below"*/}
                        {/*                                    trigger={*/}
                        {/*                                        <span className="more">{`+${preference.PereferenceValue.length - 2} more`}</span>*/}
                        {/*                                    }*/}
                        {/*                                />*/}
                        {/*                                : null}*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                );*/}
                        {/*            }*/}
                        {/*        }) : userPreferenceData != undefined && userPreferenceData?.length !== 0 && (userPreferenceData[0].PereferenceValue.length!=0 && userPreferenceData[1].PereferenceValue.length!=0)? <Loader  label="" labelPosition="end" size="medium" /> : <div className="skill-tags-nodata fs-13">No data available</div>}*/}
                        {/*</div>*/}
                        <div className="contents">
                            {
                                userPreferenceData && ((userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry") &&
                                    userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue &&
                                    userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue.length > 0) ||
                                    (userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology") &&
                                        userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue &&
                                        userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue.length > 0))
                                    ?
                                    <>
                                        {
                                            (userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry") &&
                                                userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue &&
                                                userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue.length > 0) ?
                                                <>
                                                    <div className="content-header">
                                                        <FontIcon iconName="CityNext2" className={classNames.deepSkyBlue} />
                                                        <span>{userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PreferenceKey}</span>
                                                    </div>
                                                    <div className="content" key={uniqid()}>
                                                        <div className="preference-tag">
                                                            {
                                                                userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue
                                                                .sort((a, b) => -b.CategoryName?.toUpperCase().localeCompare(a.CategoryName?.toUpperCase()))
                                                                    .filter((tag, i) => i <= 1).map((tag) => {
                                                                        return <div className="skill-tags fs-13" key={uniqid()}>{tag.CategoryName}</div>;
                                                                    })
                                                            }
                                                            {userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue?.length > 2 ?
                                                                <Tooltip
                                                                    relationship="description"
                                                                    appearance="inverted"
                                                                    content={userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue?.slice(
                                                                        2,
                                                                        userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue?.length
                                                                    ).map((tag) => (
                                                                        <div className="skill-tags fs-13" key={uniqid()}>
                                                                            {tag.CategoryName}
                                                                        </div>
                                                                    ))}
                                                                ><span className="more">
                                                                    {`+${userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "industry")?.PereferenceValue?.length - 2} more`}
                                                                    </span>
                                                                </Tooltip>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </> : ""
                                        }
                                        {
                                            (userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology") &&
                                                userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue &&
                                                userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue.length > 0) ?
                                                <>
                                                    <div className="content-header">
                                                        <span className="icon Icon--Settings">
                                                            <svg width="26.624" height="26.624" viewBox="0 0 26.624 26.624">
                                                                <path id="Path_44875" data-name="Path 44875" d="M19.638,2a1,1,0,0,1,.989.863L20.637,3v3.1a5,5,0,0,1,3.893,3.894h3.1a1,1,0,0,1,.136,1.988l-.136.009h-3v2.33h3a1,1,0,0,1,.989.863l.009.136a1,1,0,0,1-.863.989l-.136.009h-3v2.33h3a1,1,0,0,1,.989.863l.009.136a1,1,0,0,1-.863.989l-.136.009h-3.1a5,5,0,0,1-3.893,3.893v3.1a1,1,0,0,1-1.988.136l-.009-.136v-3H16.309v3a1,1,0,0,1-.863.989l-.136.009a1,1,0,0,1-.989-.863l-.009-.136v-3H11.984v3a1,1,0,0,1-.863.989l-.135.009A1,1,0,0,1,10,27.761l-.009-.136V24.531a5,5,0,0,1-3.894-3.894H3a1,1,0,0,1-.135-1.988L3,18.64h3v-2.33H3a1,1,0,0,1-.989-.863L2,15.312a1,1,0,0,1,.863-.989L3,14.314h3v-2.33H3a1,1,0,0,1-.989-.863L2,10.986A1,1,0,0,1,2.863,10L3,9.987h3.1A5,5,0,0,1,9.988,6.093V3a1,1,0,0,1,1.988-.135L11.984,3V5.992h2.328V3a1,1,0,0,1,.863-.989L15.312,2a1,1,0,0,1,.989.863L16.31,3V5.992H18.64V3a1,1,0,0,1,.733-.963l.13-.027Zm0,5.99H10.986a3,3,0,0,0-3,3v8.653a3,3,0,0,0,3,3h8.653a3,3,0,0,0,3-3V10.986A3,3,0,0,0,19.638,7.99Zm-4.32,3.335a3.994,3.994,0,1,1-3.994,3.994A3.994,3.994,0,0,1,15.319,11.325Zm0,2a2,2,0,1,0,2,2A2,2,0,0,0,15.319,13.322Z" transform="translate(-2 -2)" fill="#a341ed" />
                                                            </svg>
                                                        </span>
                                                        <span style={{ paddingLeft: '0.26vw' }}>
                                                            {userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PreferenceKey}
                                                        </span>
                                                    </div>
                                                    <div className="content" key={uniqid()}>
                                                        <div className="preference-tag">
                                                            {
                                                                userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue
                                                                .sort((a, b) => -b.CategoryName?.toUpperCase().localeCompare(a.CategoryName?.toUpperCase()))
                                                                    .filter((tag, i) => i <= 1).map((tag) => {
                                                                        return <div className="skill-tags fs-13" key={uniqid()}>{tag.CategoryName}</div>;
                                                                    })
                                                            }
                                                            {userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue?.length > 2 ?
                                                                <Tooltip
                                                                    relationship="description"
                                                                    appearance="inverted"
                                                                    content={userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue?.slice(
                                                                        2,
                                                                        userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue?.length
                                                                    ).map((tag) => (
                                                                        <div className="skill-tags fs-13" key={uniqid()}>
                                                                            {tag.CategoryName}
                                                                        </div>
                                                                    ))}
                                                                    positioning="below"
                                                                ><span className="more">
                                                                    {`+${userPreferenceData.find(x => x.PreferenceKey.toLowerCase() === "technology")?.PereferenceValue?.length - 2} more`}
                                                                    </span>
                                                                </Tooltip>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </> : ""}
                                    </>
                                    : <div className="skill-tags-nodata fs-13">No data available</div>}
                        </div>
                    </>
                </div>
            )
    );



};

export default MyPreferences;