import React, { useState } from 'react';
import { Button, Avatar } from '@fluentui/react-components';
import { useHistory } from 'react-router-dom';
import * as microsoftTeams from "@microsoft/teams-js";
import moment from 'moment';
import { updateNudgeReadFlag } from "./../../../api/nudgesApi";


const SharePrivateIdeaForNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    let commentData = (getData(data, '##Comment##') != "" ? getData(data, '##Comment##') : null);
    let idInitial = getData(data, '##ActionFromId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#E6DCFF' }}>COLLABORATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##ActionFromId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##ActionFromId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" has shared his/her "{getData(data, '##IdeaTitle##')}" with you. Provide your feedback now!
                    </span>
                        <div className="user-comments"><span> {commentData ? `"${commentData}"` : ""} </span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "PRIVATEIDEADETAILS", payload)}>PROVIDE COMMENT</Button>
                </div>
            </div>
        </div >
    )
};
const setNudgeFlag = (data) => {
    if (data.IsNudgeRead == false) {
        data.IsNudgeRead = true;
        let nudgeId = data.NudgeId;
        let resp = updateNudgeReadFlag(nudgeId, data);
        return resp;
    }

}
const setNudgeFlagOnButtonAction = (data) => {
    /* if (data.IsNudgeRead == false) {*/
    data.IsNudgeRead = true;
    let nudgeId = data.NudgeId;
    let resp = updateNudgeReadFlag(nudgeId, data);
    return resp;
    //}
}
const ShareIdeaForNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    let commentData = (getData(data, '##Comment##') != "" ? getData(data, '##Comment##') : null);
    let idInitial = getData(data, '##ActionFromId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#E6DCFF' }}>COLLABORATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##ActionFromId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##ActionFromId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" has shared his/her "{getData(data, '##IdeaTitle##')}" with you. Let's see if you want to promote it!
                    </span>
                        <div className="user-comments"><span> {commentData ? `"${commentData}"` : ""} </span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILS", payload)}>LET's LOOK AT IT</Button>
                </div>
            </div>
        </div>
    )
};

const CommentAddedIdeaForNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    const idInitial = getData(data, '##ActionFromId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#E6DCFF' }}>COLLABORATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##ActionFromId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##ActionFromId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" has provided feedback about your idea "{getData(data, '##IdeaTitle##')}"
                    </span>
                        <div className="user-comments"><span>Comment: </span><span> {getData(data, '##IdeaComment##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "PRIVATEIDEADETAILS", payload)}>VIEW COMMENT</Button>
                </div>
            </div>
        </div>
    )
};

const SocialCommentAddedIdeaForNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    const idInitial = getData(data, '##ActionFromId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#E6DCFF' }}>COLLABORATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##ActionFromId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##ActionFromId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" has shared some views about your idea "{getData(data, '##IdeaTitle##')}"
                    </span>
                        <div className="user-comments"><span>Comment: </span><span> {getData(data, '##IdeaComment##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILS", payload)}>VIEW COMMENT</Button>
                </div>
            </div>
        </div>
    )
};

const LikeCountIdeaForNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#E6DCFF' }}>COLLABORATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    <span>Congratulations</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">You've got {getData(data, '##LikeCount##')} or more likes on your ideas.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILS", payload)}>VIEW LIKES</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaDeferredByMentorNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }}>IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="sorryEmoji">
                    {/* <span>We're Sorry</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">We're Sorry! Your idea is deferred by "{getData(data, '##ActionFromId##')}" till "{getData(data, '##TillDate##')}".
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>VIEW MENTOR COMMENTS</Button>
                </div>
            </div>
        </div>
    )
};

const CollaborationIdeaForNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    const idInitial = getData(data, '##ActionFromId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#E6DCFF' }}>COLLABORATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##ActionFromId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##ActionFromId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" wants to collaborate with your idea. Collaborate to transform your great ideas into greater ones.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILS", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const SimilarIdeaForNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    const idInitial = getData(data, '##ActionFromId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#E6DCFF' }}>COLLABORATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##ActionFromId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##ActionFromId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Great minds think alike.. "{getData(data, '##ActionFromId##')}" has submitted an idea similar to yours, would you like to connect with him/her?
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILS", payload)}>COLLABORATE NOW</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaAcceptedForContestNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }}>IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    {/*<span>Congratulations</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Congratulations! Your Idea is selected for the "{getData(data, '##ContestName##')}". Stay tuned for more updates.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaQualifiedForNextStageNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }} >IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    <div className="icon-wrapper">
                    </div>
                    {/*<span>Congratulations</span>*/}
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content"> Congratulations! Your Idea is qualified for the stage "{getData(data, '##StageName##')}" in the "{getData(data, '##ContestName##')}". Stay tuned for more updates.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaImplementorBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaRejectedForNextStageNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }}>IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="sorryEmoji">
                    {/* <span>We're Sorry</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">We're Sorry! Your Idea couldn't qualify for the next stage in the "{getData(data, '##ContestName##')}". But, innovation shouldn't stop here. Keep Innovating.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaImplementorBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaRejectDuplicacyNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }}>IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="sorryEmoji">
                    {/* <span>We're Sorry</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">We're Sorry! Your Idea couldn't qualify for the "{getData(data, '##ContestName##')}" as a similar idea already exists.  But, innovation shouldn't stop here. Keep Innovating.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaApprovedForPatentabilityCheck = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#CFDBFC' }}>PATENTABILITY CHECK</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    {/*<span>Congratulations</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Congratulations! Your Idea is qualified to be filed for Patent. We' re proud of you! Keep Innovating.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "PATENT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaRejectedForPatentabilityCheck = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#CFDBFC' }}>PATENTABILITY CHECK</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="sorryEmoji">
                    {/* <span>We're Sorry</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">We're Sorry! Wish we'd better news for you. Your idea doesn't qualify the patentability criteria. But, innovation shouldn't stop here. Keep Innovating.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "PATENT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const MentorAssignedPatentibilityCheckNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    const idInitial = getData(data, '##MentorId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#CFDBFC' }}>PATENTABILITY CHECK</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##MentorId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##MentorId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <div className="patentabilityCheckDiv">
                            <span>Congratulations!</span>
                        </div>
                        <span className="content">"{getData(data, '##MentorId##')}" is your mentor for the patentability pre-check.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => openChat(data, payload)}>CONNECT WITH MENTOR</Button>
                </div>
            </div>
        </div>
    )
};

const NeedMoreInformationPatentibilityCheckNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#CFDBFC' }}>PATENTABILITY CHECK</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="needInfoEmoji">
                    {/*  <span>Need more details</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">You're required to provide more information about your idea for the patentability pre-check. Please check the comments section for details.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "PATENT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaOnHoldForPatentabilityCheck = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#CFDBFC' }}>PATENTABILITY CHECK</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="needInfoEmoji">
                    {/*  <span>Need more details</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Your Idea is put 'On-hold'. But, innovation shouldn't stop here. Keep Innovating.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "PATENT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};



const IdeaRejectedForContestNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }}>IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="sorryEmoji">
                    {/*<span>We're Sorry</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">We're Sorry! Your idea couldn't be selected for the "{getData(data, '##ContestName##')}". But, innovation shouldn't stop here. Keep Innovating.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const MentorAssignedForIdeaNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    const idInitial = getData(data, '##MentorId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }}>IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##MentorId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##MentorId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Congratulations! "{getData(data, '##MentorId##')}" is your mentor for the "{getData(data, '##ContestName##')}".
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => openChat(data, payload)}>CONNECT WITH MENTOR</Button>
                </div>
            </div>
        </div>
    )
};
const MentorAssignedForIdeaNudgeForMentor = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    const idInitial = getData(data, '##ActionFromId##');
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }}>IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="left-side avatar-wrapper">
                    <Avatar name={getData(data, '##ActionFromId##')} size={24} initials={`${idInitial.charAt(0).toUpperCase()}${idInitial.split('.').pop().charAt(0).toUpperCase()}`} />
                    <span>{getData(data, '##ActionFromId##')}</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Idea has been assigned for your approval!
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span>  <span style={{ color: 'black' }} className="content"> has been assigned for your approval. Please review the idea and approve/reject.
                    </span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaMentorApprovalBtn(data, "MENTORAPPROVAL", payload)}>TAKE ACTION</Button>
                </div>
            </div>
        </div>
    )
};

const ImplementerAddedIdeaNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">TEAM CREATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    {/*  <span>Congratulations</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Congratulations! "{getData(data, '##ActionFromId##')}" has requested you to become an implementer for his/her innovation. Innovate now!
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaImplementorBtn(data, "IMPLEMENTORIDEADETAILS", payload)}>TAKE ACTION</Button>
                </div>
            </div>
        </div>
    )
};

const ImplementerAcceptedIdeaNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">TEAM CREATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    {/*  <span>Congratulations</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Congratulations! "{getData(data, '##ActionFromId##')}" has joined your team as an implementer. Collaborate now!
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const NeedMoreInformationIdeaNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type" style={{ backgroundColor: '#FFD8EA' }}>IDEA ASSESSMENT</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="needInfoEmoji">
                    {/*<span>Need more details</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">You're required to provide more information about your idea. Please check the comments section for details.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};
const ImplementerRejectedIdeaNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">TEAM CREATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="sorryEmoji">
                    {/* <span>We're Sorry</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">We're Sorry! "{getData(data, '##ActionFromId##')}" doesn't want to be the part of your innovation team.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const NewContestAnnouncedNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">CONTEST RELATED GENERIC ANNOUNCEMENTS</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    <span>New Contest</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">1."{getData(data, '##ContestName##')}" is coming soon! Watch this space for more information!
                </span>
                        <div className="user-comments"><span>2.</span><span>{getData(data, '##ContestName##')} is live now!</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>INNOVATE NOW</Button>
                </div>
            </div>
        </div>
    )
};

const IdeaSubmitReminderContestNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">CONTEST RELATED GENERIC ANNOUNCEMENTS</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    <span>Reminder</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Hurry up! "{getData(data, '##TimeLeft##')}" days remaining! Submit your idea in "{getData(data, '##ContestName##')}" now!
                </span>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>SUBMIT IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const AwaitingMentorApprovalIdeaNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">MENTOR</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    <span>Awaiting approval</span>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Innovations are awaiting your approval! Approve now.
                </span>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILS", payload)}>APPROVE NOW</Button>
                </div>
            </div>
        </div>
    )
};

const MentorInitiatedBqSignOffIdeaNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">BENEFITS QUANTIFICATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                {/*<div className="left-side avatar-wrapper">*/}
                {/*    <Avatar name={getData(data, '##MentorId##')} size={24} getInitials={() => handleInitials(getData(data, '##MentorId##'))} />*/}
                {/*    <span>{getData(data, '##MentorId##')}</span>*/}
                {/*</div>*/}
                <div className="bqEmoji">
                    {/*  <span>Need more details</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>

                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##MentorId##')}" has initiated the BQ signoff request for the idea. Take an action now!
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSFORM", payload)}>TAKE AN ACTION NOW</Button>
                </div>
            </div>
        </div>
    )
};

const BqApprovesSignOffIdeaNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">BENEFITS QUANTIFICATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                <div className="congrats">
                    {/*<span>Congratulations</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">Congratulations! "{getData(data, '##ActionFromId##')}" has approved the BQ signoff request for your idea.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSFORM", payload)}>VIEW DETAILS</Button>
                </div>
            </div>
        </div>
    )
};

//const BqRejectsSignOffIdeaNudge = ({ data, createdDate }) => (
//    <div className="nudges-card-wrapper">
//        <div className="adaptiveCard-header">
//            <div className="created-date">
//                <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
//            </div>
//            <div className="skill-tags fs-13 nudge-type">BENEFITS QUANTIFICATION</div>
//        </div>
//        <hr />
//        <div className="adaptivecard-container">
//            <div className="right-side">
//                <div className="content-wrapper">
//                    <span className="content">Oh No!! {getData(data, '##ActionFromId##')} has Rejected the BQ signoff request for your idea.
//                    </span>
//                    <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
//                </div>
//                <div className="button-wrapper">
//                    <Button className="btn-primary adaptiveCard-btn" content="VIEW DETAILS" type="button"
//                        primary onClick={() => viewIdeaBtn(data, "IDEADETAILSFORM")} />
//                </div>
//            </div>
//        </div>
//    </div>
//);

const MentorInitiatedBqSignOffIdeaMentorNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">BENEFITS QUANTIFICATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                {/*<div className="left-side avatar-wrapper">*/}
                {/*    <Avatar name={getData(data, '##ActionFromId##')} size={24} getInitials={() => handleInitials(getData(data, '##ActionFromId##'))} />*/}
                {/*    <span>{getData(data, '##ActionFromId##')}</span>*/}
                {/*</div>*/}
                <div className="bqEmoji">
                    {/*  <span>Need more details</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" has initiated the BQ signoff request for the idea. Take an action now!
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSFORM", payload)}>TAKE AN ACTION NOW</Button>
                </div>
            </div>
        </div>
    )
};


const MentorInitiatedBqSignOffIdeaInnovatorNudge = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">BENEFITS QUANTIFICATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                {/*<div className="left-side avatar-wrapper">*/}
                {/*    <Avatar name={getData(data, '##ActionFromId##')} size={24} getInitials={() => handleInitials(getData(data, '##ActionFromId##'))} />*/}
                {/*    <span>{getData(data, '##ActionFromId##')}</span>*/}
                {/*</div>*/}
                <div className="bqEmoji">
                    {/*  <span>Need more details</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" has initiated the BQ signoff request for the idea.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSFORM", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};

const BqMentorInitiateSignOffForIdeaNudgeApproval = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">BENEFITS QUANTIFICATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                {/*<div className="left-side avatar-wrapper">*/}
                {/*    <Avatar name={getData(data, '##ActionFromId##')} size={24} getInitials={() => handleInitials(getData(data, '##ActionFromId##'))} />*/}
                {/*    <span>{getData(data, '##ActionFromId##')}</span>*/}
                {/*</div>*/}
                <div className="bqEmoji">
                    {/*  <span>Need more details</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" has initiated the BQ signoff request for the idea. Take an action now!.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>TAKE AN ACTION NOW!</Button>
                </div>
            </div>
        </div>
    )
};

const BqMentorInitiateSignOffForIdeaNudgeInnovator = ({ data, createdDate, payload }) => {
    const [showReadNudge, setsReadNudge] = useState<boolean>(false);
    return (
        <div className={`nudges-card-wrapper ${payload.IsNudgeRead ? `clsRead` : showReadNudge ? `clsRead` : `clsUnread`}`} onClick={() => { setNudgeFlag(payload); setsReadNudge(true); }}>
            <div className="adaptiveCard-header">
                <div className="created-date">
                    <span>{formatDate(createdDate, 'DD/MM/YYYY hh:mm A')}</span>
                </div>
                <div className="skill-tags fs-13 nudge-type">BENEFITS QUANTIFICATION</div>
            </div>
            <hr />
            <div className="adaptivecard-container">
                {/*<div className="left-side avatar-wrapper">*/}
                {/*    <Avatar name={getData(data, '##ActionFromId##')} size={24} getInitials={() => handleInitials(getData(data, '##ActionFromId##'))} />*/}
                {/*    <span>{getData(data, '##ActionFromId##')}</span>*/}
                {/*</div>*/}
                <div className="bqEmoji">
                    {/*  <span>Need more details</span>*/}
                    <div className="icon-wrapper">
                    </div>
                </div>
                <div className="right-side">
                    <div className="content-wrapper">
                        <span className="content">"{getData(data, '##ActionFromId##')}" has initiated the BQ signoff request for the idea.
                    </span>
                        <div className="user-comments"><span>Idea Title: </span><span> {getData(data, '##IdeaTitle##')}</span></div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button className="btn-primary adaptiveCard-btn" type="button" onClick={() => viewIdeaBtn(data, "IDEADETAILSEDIT", payload)}>VIEW IDEA</Button>
                </div>
            </div>
        </div>
    )
};
const getData = (data, fieldName) => {
    if (data.find(x => x['Key'] === fieldName)) {
        return (data.find(x => x['Key'] === fieldName)['Value']);
    }
    else {
        return "";
    }
}

const handleInitials = (CreatedBy) => {
    var res = CreatedBy.split(".");
    let nameInitials = (res[res.length - 1].charAt(0) + res[0].charAt(0)).toUpperCase();
    return nameInitials;
}

const viewIdeaBtn = (data, nudgeType, payload) => {
    setNudgeFlagOnButtonAction(payload).then(response => {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            const entityID = process.env.REACT_APP_ENTITY_ID;
            const tab = process.env.REACT_APP_TABNAME;
            const ideaId = getData(data, '##IdeaId##');
            let ideaNumber;
            let patentId;
            let patentInitiationStatus;

            if (nudgeType == "PATENT") {
                ideaNumber = getData(data, '##IdeaNumber##');
                patentId = getData(data, '##PatentId##');
                patentInitiationStatus = getData(data, '##PatentInitiationStatus##');
            }
            let url = `https://teams.microsoft.com/l/entity/${entityID}/${tab}?webUrl=https://tasklist.example.com/123/456&label=Task456&context={\"subEntityId\": \"${nudgeType}:${ideaId}:${ideaNumber}:${patentId}:${patentInitiationStatus}\"}`
            microsoftTeams.executeDeepLink(url);
        });
    });
}

const viewIdeaMentorApprovalBtn = (data, nudgeType, payload) => {
    setNudgeFlagOnButtonAction(payload).then(response => {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            const entityID = process.env.REACT_APP_ENTITY_ID;
            const tab = process.env.REACT_APP_TABNAME;
            const ideaId = getData(data, '##IdeaId##');
            let url = `https://teams.microsoft.com/l/entity/${entityID}/${tab}?webUrl=https://tasklist.example.com/123/456&label=Task456&context={\"subEntityId\": \"${nudgeType}:${ideaId}\"}`
            microsoftTeams.executeDeepLink(url);
        });
    });
}
const viewIdeaImplementorBtn = (data, nudgeType, payload) => {
    setNudgeFlagOnButtonAction(payload).then(response => {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            setNudgeFlag(payload);
            const entityID = process.env.REACT_APP_ENTITY_ID;
            const tab = process.env.REACT_APP_TABNAME;
            const ideaId = getData(data, '##IdeaId##');
            let enterpriseId;
            let role;
            enterpriseId = getData(data, '##ActionToId##');
            if (nudgeType == "IDEADETAILSEDIT") {
                role = getData(data, '##Role##');
                if (role == 'implementer') {
                    nudgeType = "IMPLEMENTORIDEADETAILS";
                    let url = `https://teams.microsoft.com/l/entity/${entityID}/${tab}?webUrl=https://tasklist.example.com/123/456&label=Task456&context={\"subEntityId\": \"${nudgeType}:${ideaId}:${enterpriseId}:${role}\"}`
                    microsoftTeams.executeDeepLink(url);
                }
                else {
                    nudgeType = "IDEADETAILSEDIT"
                    let url = `https://teams.microsoft.com/l/entity/${entityID}/${tab}?webUrl=https://tasklist.example.com/123/456&label=Task456&context={\"subEntityId\": \"${nudgeType}:${ideaId}\"}`
                    microsoftTeams.executeDeepLink(url);
                }

            }
            else {
                role = 'implementer';
                let url = `https://teams.microsoft.com/l/entity/${entityID}/${tab}?webUrl=https://tasklist.example.com/123/456&label=Task456&context={\"subEntityId\": \"${nudgeType}:${ideaId}:${enterpriseId}:${role}\"}`
                microsoftTeams.executeDeepLink(url);
            }
        });
    });
}

const formatDate = (date, format) => {
    return moment(date).format(format);
}

const openChat = (data, payload) => {
    setNudgeFlagOnButtonAction(payload).then(response => {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
            const domain = process.env.REACT_APP_DOMAIN;
            const entityID = process.env.REACT_APP_ENTITY_ID;
            const tab = process.env.REACT_APP_TABNAME;
            const ideaId = getData(data, '##IdeaId##');
            const ideaTitle = getData(data, '##IdeaTitle##');
            const mentorId = getData(data, '##MentorId##');

            let url = "https://teams.microsoft.com/l/chat/0/0?users=" + mentorId + "@" + domain + "&topicName=Mentor%20assigned%20for%20idea%20" + ideaTitle;
            microsoftTeams.executeDeepLink(url);
        });
    });
}


export default function AdaptiveCardMobile({ payload }) {
    const [showDetails, setshowDetails] = useState<boolean>(false);
    const history = useHistory();
    const showDetailsClick = () => {
        setshowDetails(prevCheck => !prevCheck);
    }



    return (
        <>
            {(payload['NudgeType'] === 'SharePrivateIdeaForNudge') &&
                <SharePrivateIdeaForNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'ShareIdeaForNudge') &&
                <ShareIdeaForNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'CommentAddedIdeaForNudge' || payload['NudgeType'] === 'SharePrivateCommentAddedIdeaForNudge') &&
                <CommentAddedIdeaForNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'SocialCommentAddedIdeaForNudge') &&
                <SocialCommentAddedIdeaForNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'LikeCountIdeaForNudge') &&
                <LikeCountIdeaForNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaDeferredByMentorNudge') &&
                <IdeaDeferredByMentorNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'CollaborationIdeaForNudge') &&
                <CollaborationIdeaForNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'SimilarIdeaForNudge') &&
                <SimilarIdeaForNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaAcceptedForContestNudge') &&
                <IdeaAcceptedForContestNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaQualifiedForNextStageNudge') &&
                <IdeaQualifiedForNextStageNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaRejectedForNextStageNudge') &&
                <IdeaRejectedForNextStageNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaRejectDuplicacyNudge') &&
                <IdeaRejectDuplicacyNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaApprovedForPatentabilityCheck') &&
                <IdeaApprovedForPatentabilityCheck data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaRejectedForPatentabilityCheck') &&
                <IdeaRejectedForPatentabilityCheck data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'MentorAssignedPatentibilityCheckNudge') &&
                <MentorAssignedPatentibilityCheckNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'NeedMoreInformationPatentibilityCheckNudge') &&
                <NeedMoreInformationPatentibilityCheckNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaOnHoldForPatentabilityCheck') &&
                <IdeaOnHoldForPatentabilityCheck data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaRejectedForContestNudge') &&
                <IdeaRejectedForContestNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'MentorAssignedForIdeaNudge') &&
                <MentorAssignedForIdeaNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'MentorAssignedForIdeaNudgeForMentor') &&
                <MentorAssignedForIdeaNudgeForMentor data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'ImplementerAddedIdeaNudge') &&
                <ImplementerAddedIdeaNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'ImplementerAcceptedIdeaNudge') &&
                <ImplementerAcceptedIdeaNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'NeedMoreInformationIdeaNudge') &&
                <NeedMoreInformationIdeaNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'ImplementerRejectedIdeaNudge') &&
                <ImplementerRejectedIdeaNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'NewContestAnnouncedNudge') &&
                <NewContestAnnouncedNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'IdeaSubmitReminderContestNudge') &&
                <IdeaSubmitReminderContestNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'AwaitingMentorApprovalIdeaNudge') &&
                <AwaitingMentorApprovalIdeaNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'MentorInitiatedBqSignOffIdeaNudge') &&
                <MentorInitiatedBqSignOffIdeaNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'BqApprovesSignOffIdeaNudge') &&
                <BqApprovesSignOffIdeaNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'MentorInitiatedBqSignOffIdeaMentorNudge') &&
                <MentorInitiatedBqSignOffIdeaMentorNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'MentorInitiatedBqSignOffIdeaInnovatorNudge') &&
                <MentorInitiatedBqSignOffIdeaInnovatorNudge data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'BqMentorInitiateSignOffForIdeaNudgeApproval') &&
                <BqMentorInitiateSignOffForIdeaNudgeApproval data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
            {(payload['NudgeType'] === 'BqMentorInitiateSignOffForIdeaNudgeInnovator') &&
                <BqMentorInitiateSignOffForIdeaNudgeInnovator data={payload['MessageDetails']} createdDate={payload.CreatedDtm} payload={payload} />}
        </>
    )
}