import React from 'react';
import "./NudgesMobile.scss";
import InfiniteScrollNudges from '../../Nudges/InfiniteScrollNudges';

const NudgesMobile = ({ nudgesData }) => {
    return (
        <div className="nudges-container-mobile">
            <InfiniteScrollNudges nudges={nudgesData} />
        </div>
    )
}

export default NudgesMobile;