import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
} from "@fluentui/react/lib/Dialog";
import {
  PrimaryButton,
  DefaultButton,
} from "@fluentui/react/lib/Button";
import {
  hiddenContentStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { ContextualMenu } from "@fluentui/react/lib/ContextualMenu";
import { useId, useBoolean } from "@fluentui/react-hooks";
import { RootStateOrAny, useSelector } from "react-redux";

const dialogStyles = { main: { maxWidth: 200 } };
const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};

const dialogContentProps = {
  type: DialogType.normal,
  closeButtonAriaLabel: "Close",
  subText: "Are you sure you want to delete this idea?",
};

interface IDeletePopUp {
  closeModal: any;
  deleteIdea: any;
  openModal: any;
  contestName?: any;
}

export const DeletePopUp = ({
  closeModal,
  deleteIdea,
  openModal,
  contestName
}: IDeletePopUp) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const labelId: string = useId("dialogLabel");
    const subTextId: string = useId("subTextLabel");
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId]
  );

  useEffect(() => {
    toggleHideDialog();
  }, [openModal]);

  const onCancel = () => {
    toggleHideDialog();
    closeModal();
  };

  const onDeleteBtn = () => {
    deleteIdea();
    toggleHideDialog();
  };
 
  return (
    <>
      <Dialog
              hidden={hideDialog}
              onDismiss={toggleHideDialog}
              dialogContentProps={contestName && contestName == 'v360' ? {
                  type: DialogType.normal,
                  closeButtonAriaLabel: "Close",
                  subText: "Are you sure you want to delete this story?",
              } : dialogContentProps}
              modalProps={modalProps}
              className={isMobile ? "popup-delete-message-mobile" :  "popup-delete-message"}
      >
        <button
          className="topButton-149 cross-icon-submit-dialog close"
          onClick={onCancel}
        />

        <DialogFooter>
          <DefaultButton onClick={onCancel} text="No" />
          <PrimaryButton onClick={onDeleteBtn} text="Yes" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
