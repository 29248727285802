// @ts-nocheck
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { Stack } from "@fluentui/react";
import { Spinner,  } from '@fluentui/react-components';
import { FluentProvider, Button, Divider, teamsLightTheme } from "@fluentui/react-components";
import { useTeams } from "@microsoft/teamsfx-react";
import { ArrowLeftRegular } from '@fluentui/react-icons';
import { Link, useHistory } from 'react-router-dom';
import { getNudgePreference, saveNudgePreference } from '../../api/IdeaApi';
import { PageLoader } from '../../common/PageLoader';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
var uniqid = require('uniqid');
import "./../../NudgeOptInOptOut.scss";

export const NudgeOptInOptOut = (props) => {
    const [showSpinner, setSpinnerData] = useState(false);
    const [{ inTeams, theme, context }] = useTeams();
    const history = useHistory();
    const [isChecked, setIsChecked] = useState<Boolean>(true);
    const [nudgeItem, setNudgeItem] = useState([]);
    const [nudgeDetail, setNudgeDetail] = useState({});
    const [isAllNudgeOptOut, setIsAllNudgeOptOut] = useState<Boolean>(true);
    const enterpriseId = useSelector((state: RootStateOrAny) => state.entityReducer.entityId);

    useEffect(() => {
        getNudgePreferenceAll();
    }, []);

    const getNudgePreferenceAll = () => {
        setSpinnerData(true);
        getNudgePreference().then((res) => {
            if (res !== null && res !== undefined) {
                const data = res;
                setNudgeDetail(res);
                // data['NudgeCategoryPreference'].map((item) => {
                //     item.isChecked = true
                // });
                setIsAllNudgeOptOut(data['IsAllNudgeOptOut']);
                // console.log(data['NudgeCategoryPreference']);
                setNudgeItem(data['NudgeCategoryPreference']);
                setSpinnerData(false);
            }
        })
    }

    const handleOnChange = (e, item) => {
        let arr = [];
        let count  = nudgeItem?.length;
        nudgeItem.forEach((data) => {
            if (data.CategoryKey === item.CategoryKey) {
                data.IsDisabled = !data.IsDisabled;
                if(data.IsDisabled === false){
                    setIsAllNudgeOptOut(false);
                } 
            }
            arr.push(data);
        })
         arr.forEach(data1=>{
            if (data1.IsDisabled === true){
                count-- ;
            }
            if(count === 0) {
                setIsAllNudgeOptOut(true);
            }
        })
        setNudgeItem(arr);
    }

    const selectAll = (event) => {
        let arr = [];
        setIsAllNudgeOptOut(!isAllNudgeOptOut);
        if (event.target.checked) {

            nudgeItem.map((item) => {

                item.IsDisabled = false;
                arr.push(item)
            });
            setNudgeItem(arr);
        } else {
            nudgeItem.map((item) => {
                item.IsDisabled = true;
                arr.push(item)
            });
            setNudgeItem(arr);
        }

    }
    const handleCancel = (event) => {
        onBackClick(event);
    }

    const onBackClick = (event) => {
        event.preventDefault();

        // if (navigatedFromActivity) {
        //     dispatch(setNavigatedFromActivity(false));
        //     histeeory.push({
        //         pathname: '/landing'
        //     });
        // }
        // else {
        history.push({ pathname: '/'});
        // }
    } 

    const handleSaveNudge = (event) => {
        const obj = generateRequest();
        saveNudgePreference(obj).then((res)=>{
            if(res !== null && res !==undefined){
                onBackClick(event);
            } else {
                console.log(`api is failing please check network calls`);
            }
        })
    }

    const generateRequest = () => {
        const obj = {
            'EnterpriseId': enterpriseId,
            'NudgeCategoryPreference' :nudgeItem,
            'IsAllNudgeOptOut' : isAllNudgeOptOut,
            "IsActive": nudgeDetail['IsActive'],
            "CreatedDtm": nudgeDetail['CreatedDtm'],
            "ModifiedDtm": nudgeDetail['ModifiedDtm']
        
        }
        return obj;
    }

    return (

        <>
            <FluentProvider theme={teamsLightTheme}>
                {showSpinner ? <PageLoader hidden={false} /> : ""}
                <div className="submit-idea nudge-preferences-wrapper">
                    <Stack horizontal>
                            <div style={{ cursor: "pointer",width: "2vw" }} onClick={(event) => onBackClick(event)}>
                                <ArrowLeftRegular fontSize={20}/>
                            </div>
                            <div className="submitIdeaTitle">Nudge Preferences</div>    
                    </Stack>
                    <Stack className="nudge-content-wrapper" column>
                    <Stack className="page-title heading-section">
                            These settings will reflect in your 'Living Innovation Channel' MS Teams App's activity feed and banners.
                    </Stack>
                    <Stack className="preferences-section" column>
                        <ul className="preferences-list">
                            <li className="list-item">
                                <label className="page-title">Opt-in all nudges   </label>
                                <Toggle
                                    checked = {!isAllNudgeOptOut}
                                    className='custom-classname'
                                    icons={false}
                                    onChange={($event) => selectAll($event)} />
                            </li>
                            <Divider />
                            {
                                nudgeItem && nudgeItem?.length > 0 && nudgeItem.map((item, index) => {
                                    return (
                                        <li key={uniqid()} className="list-item" >
                                            <label className="page-title">{item.CategoryName}</label>
                                            <Toggle
                                                checked={!item.IsDisabled}
                                                className='custom-classname'
                                                icons={false}
                                                onChange={($event) => handleOnChange($event, item, index)} />
                                        </li>
                                    )
                                })
                            }

                        </ul>
                        <div className="float-right action-btn-wrapper">
                            <Button className="btn-secondary" onClick={(event) => handleCancel(event)}>Cancel</Button>
                            <Button className="btn-primary" onClick={(e) => handleSaveNudge(e)}>Save</Button>
                        </div>
                    </Stack>
                    </Stack>
                </div>
            </FluentProvider>
        </>
    )
}



export default connect()(NudgeOptInOptOut)
