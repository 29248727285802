import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSerialInnovator } from "../../api/SerialInnovatorApi";
import { getFilterDetail } from "../../api/IdeaApi";
import { getData, getPeopleName } from "../../api/PeopleApi";
import SerialInnovatorDefault from "../serial-innovator/serial-innovator-default";
import { Spinner } from "@fluentui/react-components";
import { RootStateOrAny, useSelector } from "react-redux";

const SerialInnovatorView = ({ serialInnovatorsProp, enterpriseId }) => {
    const [serialInnovators, setSerialInnovators] = useState<any>([]);
    const [itemsToShow, setitemsToShow] = useState(3);
    const history = useHistory();
    const token = useSelector((state: RootStateOrAny) => state.fetchTokenReducer.data);

    useEffect(() => {
        if (serialInnovatorsProp && serialInnovatorsProp.length > 0) {
            setSerialInnovators(serialInnovatorsProp);
        }

      //if (filters && Object.keys(filters).length !== 0 && token != '' && Object.keys(token).length !== 0) {
          
      //      getSerailInnovatorsDetails();
      //  }
    }, [serialInnovatorsProp]);

  //const getSerailInnovatorsDetails = () => {
  //  const industry = filters["IndustryDetails"].map(
  //    (industrydetail) => industrydetail.CategoryName
  //  );
  //  const category = filters["Category"].map(
  //    (categorydetail) => categorydetail.CategoryId
  //  );
  //  const technology = filters["Technology"].map(
  //    (technologydetail) => technologydetail.CategoryId
  //  );

  //    if (industry.length && technology.length && category.length) {
  //        getSerialInnovator([], [], [], enterpriseId)
  //            .then(response => {
  //                if (response) {
  //                    setSerialInnovators(response["value"]);
  //                }
  //            });
  //    }
  //};

  const showInnovators = () => {
    history.push({
      pathname: "/view-serial-innovators"
  });
}
  return (
    <>
      <p className="patent-header">Meet our serial innovators</p>
      {serialInnovators?.length > 0 ? (
        <div>
          {serialInnovators
              .slice(0, itemsToShow)
              .map((innovator) => (
                <SerialInnovatorDefault
                  key={innovator.EnterpriseId}
                  innovator={innovator}
                />
              ))}
          <a onClick={showInnovators} className="show-all-link">
            <span>Show All</span>
          </a>
        </div>
      ) : serialInnovators != undefined ? (
        <Spinner label="" labelPosition="after" size="medium" />
      ) : (
        "No data Available"
      )}
    </>
  );
};

export default SerialInnovatorView;
