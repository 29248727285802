import * as React from 'react';
import {
    Link,
    MessageBar,
    MessageBarType
} from '@fluentui/react';

interface IExampleProps {
    resetChoice?: () => void;
    msg: any;
  }

const DefaultExample = () => (
    <MessageBar>
        Info/Default MessageBar.
        <Link href="www.bing.com" target="_blank" underline>
            Visit our website.
      </Link>
    </MessageBar>
);


const SuccessExample = (p: IExampleProps, msg) => (
    <MessageBar
        messageBarType={MessageBarType.success}
        isMultiline={false}
        actions={
            <div>
                <button style={{ border: '0' }} onClick={p.resetChoice}>
                    <span className="iconify" data-icon="fluent:dismiss-16-filled" data-inline="false"></span>
                </button>
              {/* <CloseIcon onClick={p.resetChoice} /> */}
            </div>
          }>
        {p.msg}
    </MessageBar>
);

const WarningExample = (p: IExampleProps, msg) => (
    <MessageBar
        messageBarType={MessageBarType.warning}
        isMultiline={false}
        actions={
            <div>
                <button style={{ border: '0' }} onClick={p.resetChoice}>
                    <span className="iconify" data-icon="fluent:dismiss-16-filled" data-inline="false"></span>
                </button>
            </div>
          }>
        {p.msg}
    </MessageBar>
);


// const ErrorExample = (p: IExampleProps, msg) => (
    const ErrorExample = (p: IExampleProps, msg) => (
    <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
        actions={
            <div>
                <button style={{ border: '0' }} onClick={p.resetChoice}>
                    <span className="iconify" data-icon="fluent:dismiss-16-filled" data-inline="false"></span>
                </button>
            </div>
          }>
        {p.msg}
    </MessageBar>
);

export const GenericMessageBar  = ({ choice, msg, resetMsg }) => {
    const resetChoice = () => {
        resetMsg();
    }

    return (
        <>
            {(choice === 'default' || null) && <DefaultExample />}

            {(choice === 'error' || null) && <ErrorExample resetChoice={resetChoice} msg={msg}/>}

            {(choice === 'success' || null) && <SuccessExample resetChoice={resetChoice} msg={msg} />}

            {(choice === 'warning' || null) && <WarningExample resetChoice={resetChoice} msg={msg}/>}
            {(choice === undefined) && null }
        </>
    );
};