import React, { useEffect, useState } from 'react';
// import TrendingCategory from './trending-category';
import { useHistory } from 'react-router-dom';
import { Spinner } from "@fluentui/react-components";
var uniqid = require('uniqid');

const RSTrendingCategories = ({ trendingTopics, contestIds, challengeIds, filters }) => {

    const [itemsToShow, setitemsToShow] = useState(6);
    const [expanded, setexpanded] = useState(false);
    const history = useHistory();

    const ExploreAllClick = (e) => {
        e.preventDefault();
        //const categoryIds = trendingTopics.map((topic) => { return topic.CategoryIds });
        const categoryIds = filters["Category"].map(
            (categorydetail) => categorydetail.CategoryId
        );
        //console.log('on click', categoryIds, contestIds, challengeIds);
        history.push({
            pathname: '/exploreideas',
            state: { categoryIds: categoryIds, contestIds: contestIds, challengeIds: challengeIds, landingPage: true}
        });
    }

    useEffect(() => {
        //console.log('trendingTopics', trendingTopics);
    }, [trendingTopics]);

        return (
            <div className="right-side">
                {/*<p className="patent-header">Trending Categories</p>*/}
                {/*{trendingTopics?.length > 0 && Object.keys(filters).length > 0?*/}
                {/*    <>*/}
                {/*        {trendingTopics && trendingTopics.length > 0 ? (trendingTopics.slice(0, itemsToShow).map((topic) => {*/}
                {/*            return (*/}
                {/*                <TrendingCategory category={topic} key={uniqid()} allCategory={trendingTopics} contestIds={contestIds} challengeIds={challengeIds} filters={filters}/>*/}
                {/*            )*/}
                {/*        })) : "No data Available"}*/}
                {/*        {*/}
                {/*            trendingTopics && trendingTopics.length > 0 ? (<div className="float-left">*/}
                {/*                <a onClick={(event) => ExploreAllClick(event)} className="show-more-link">*/}
                {/*                    <span>Explore All</span>*/}
                {/*                </a>*/}
                {/*            </div>) : null*/}
                {/*        }*/}
                {/*    </> : trendingTopics != undefined ? <Loader  label="" labelPosition="end" size="medium" /> : "No data Available" }*/}
                <div className="float-left">
                    <a onClick={(event) => ExploreAllClick(event)} className="show-more-link">
                        <span >Explore All Ideas</span>
                    </a>
                </div>
            </div>
        )
   
}

export default React.memo(RSTrendingCategories);