import { types } from "../actions/types";

const initialState = {
  expertiseComLoaded: false,
  preferenceComLoaded: false,
};

const expertiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_EXPERTISE_DATA_REQUEST:
      // console.log(action, "=", state);
      return {
        ...state,
          expertiseComLoaded: !state.expertiseComLoaded,
      };
    case types.SET_EXPERTISE_DATA_REQUEST:
      return {
        ...state,
        expertiseComLoaded: false,
      };
    case types.SEND_PREFERENCE_DATA_REQUEST:
      // console.log(action, "=", state);
      return {
        ...state,
          preferenceComLoaded: !state.preferenceComLoaded,
      };
    case types.SET_PREFERENCE_DATA_REQUEST:
      return {
        ...state,
        preferenceComLoaded: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default expertiseReducer;
