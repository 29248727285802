
import { HttpClient, HttpBackend, HttpRequest, HttpContext, HttpContextToken, HttpHeaders, HttpParams} from '@ngify/http';
import {map} from 'rxjs'
import axios from 'axios';

let httpHandler:  HttpBackend
const http = new HttpClient(httpHandler);

export const uploadToAWSS3=async (fileurl,file)=>{
    if(fileurl.includes(".s3.amazonaws.com") && fileurl.includes(".msg?AWSAccessKeyId")){
        const headers = new HttpHeaders({"Content-Type": "application/vnd.ms-outlook"});
        return await http.put(fileurl, file,  { headers: headers }).subscribe(res => {return res});
    }else{
        return await http.put(fileurl, file,  { }).subscribe(res => {return res});

    }
    
}

// export const downloadAWSS3=async (fileurl)=>{
//     return await http.get(fileurl).subscribe(res => {return res});
// }

export const downloadAWSS3=async(fileurl) =>{
     return http.get(fileurl).pipe(map((response: any) => {return response}) ) 
    }

export const downloadAWSS31=async(fileurl) =>{
    axios.get(fileurl)
    .then(res => {
     return  res
    })
};

