import { types } from '../actions/types';

const initialState = {
    entityId: '',
    navigatedFromActivity: false,
    isMobile: false,
}

const entityReducer = (state = initialState, action) => {
    // console.log('reducer action Type', action.type);
    switch (action.type) {
        case types.SET_ENITITY_ID:
            //console.log(action,"=",state);
            return {
                ...state,
                entityId: action.payload
            }
        case types.SET_NAVIGATED_FROM_ACTIVITY:
            //console.log(action, "=", state);
            //console.log('reducer subEntityId', action.payload);
            return {
                ...state,
                navigatedFromActivity: action.payload
            }
        case types.SET_IS_MOBILE:
            //console.log(action, "=", state);
            //console.log('reducer subEntityId', action.payload);
            return {
                ...state,
                isMobile: action.payload
            }
        default:
            return {
                ...state
            }
    }


}

export default entityReducer;