import {types} from '../actions/types';

const initialState={
   activeTab:'',
   setActiveTab:''
}

const fetchActiveTabReducer=(state=initialState, action)=>{
    switch(action.type){
        case types.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab:'appliedPatent'
            }
        case types.SET_ACTIVE_TAB_PARENT:
            return {
                ...state,
                setActiveTab:action.payload,
            }
            default:
                return{
                    ...state
                }
    }
    

}
export default fetchActiveTabReducer;