import React, { useEffect, useState } from "react";
import { Avatar, Tooltip } from "@fluentui/react-components";
import { ChatRegular } from "@fluentui/react-icons";
import { getData } from "../../api/PeopleApi";
import { app } from "@microsoft/teams-js";
var uniqid = require('uniqid');
import { FontIcon } from "@fluentui/react/lib/Icon";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useTeams } from "@microsoft/teamsfx-react";

const iconClass = mergeStyles({
    fontSize: 25,
    height: 16,
    width: 16,
});

const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: "#A124FF" }, iconClass],
    greenYellow: [{ color: "greenyellow" }, iconClass],
    salmon: [{ color: "salmon" }, iconClass],
});

const SerialInnovatorDefault = ({ innovator }) => {    

    const [{inTeams}] = useTeams();
    const [serialInnovatorName, setSerialInnovatorName] = useState<string>(`${innovator.ForeignFirstName} ${innovator.ForeignLastName}`);
    const [innovatorTechnology, setInnovatorTechnology] = useState(innovator.TechnologyDetails);

    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

     
    const [itemsToShowIndustry, setItemsToShowIndustry] = useState(1);
    const [expandedIndustry, setIndustryExpanded] = useState(false);

    const [itemsToShowTechnology, setItemsToShowTechnology] = useState(1);
    const [expandedTechnology, setTechnologyExpanded] = useState(false);

    const showMoreIndustry = () => {
        itemsToShowIndustry === 1 ? (
            setItemsToShowIndustry(innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.length),
            setIndustryExpanded(true)
        ) : (
                setItemsToShowIndustry(1),
                setIndustryExpanded(false)
            )
    }

    const showMoreTechnology = () => {
        itemsToShowTechnology === 1 ? (
            setItemsToShowTechnology(innovatorTechnology.filter(x => x.CategoryId !== null)?.length ),
            setTechnologyExpanded(true)
        ) : (
            setItemsToShowTechnology(1),
            setTechnologyExpanded(false)
            )
    }

    const onClickExecute = () => {    
        const url = `https://teams.microsoft.com/l/chat/0/0?users=${innovator.EnterpriseId}@accenture.com`;
        //If run outside Teams open the link in new tab
        if (inTeams !== undefined && inTeams === true) {
            app.openLink(url);
        } else {
            window.open(url, "_blank");
        }
    };    

    return (
        <div className="innovator-serial" key={serialInnovatorName}>
            <div className="innovator-image-name">
                <Tooltip relationship="label" appearance="inverted" content={serialInnovatorName}>
                    <Avatar size={48} name={serialInnovatorName} />
                </Tooltip>
                <div className="innovator-name">
                    <div className="innovator-person-name">{serialInnovatorName}</div>
                    <div className="innovator-title-location">
                        <span> {innovator?.StandardJobDescription}</span>
                    </div>
                    {innovator.TechnologyDetails && ((innovator.TechnologyDetails.filter((tag, i) => i <= 1 && tag.CategoryId === null) &&
                        innovator.TechnologyDetails.filter((tag, i) => i <= 1 && tag.CategoryId === null).length > 0) ||
                        (innovatorTechnology && innovatorTechnology.filter((tag, i) => tag.CategoryId !== null) &&
                        innovatorTechnology.filter((tag, i) => tag.CategoryId !== null).length > 0)) ? (
                            <>
                                {
                                    (innovator.TechnologyDetails.filter((tag, i) => i <= 1 && tag.CategoryId === null) &&
                                        innovator.TechnologyDetails.filter((tag, i) => i <= 1 && tag.CategoryId === null).length > 0) ?
                                        <div>
                                            <div className="content-header" style={{ marginBottom: '0px' }}>
                                                <FontIcon iconName="CityNext2" className={classNames.deepSkyBlue} />
                                                <span>Industry</span>
                                            </div>
                                            {innovator.TechnologyDetails.filter((tag, i) => i <= 1 && tag.CategoryId === null)?.slice(0,1).map((tag) => {
                                                return <div className="skill-tags" key={uniqid()}>{tag.CategoryName}</div>;
                                            })}

                                        {innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.length > 1 && isMobile ?
                                                     
                                                    // changes here
                                                   <>
                                                        <a onClick={showMoreIndustry} className="show-more-link-mobile">
                                                            {!expandedIndustry ? <span>+{innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.length - 1} more</span> : null}
                                                        </a>

                                                       {expandedIndustry ?
                                                         innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.slice(
                                                            1,
                                                            innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.length
                                                        ).map((tag) => (
                                                            <div className="skill-tags fs-13" key={uniqid()} >
                                                                {tag.CategoryName}
                                                            </div>
                                                        ))
                                                        : null}

                                                        <a onClick={showMoreIndustry} className="show-more-link-mobile show-less-link-mobile">
                                                        {expandedIndustry ? <span>Show less</span> : null}
                                                        </a>
                                                    </>
                                                    // changes ends
                                                            :
                                             <>               
                                            { innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.length >1 ?
                                            <Tooltip
                                                relationship="label"
                                                appearance="inverted"
                                                content={innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.slice(
                                                    1,
                                                    innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.length
                                                ).map((tag) => (
                                                    <div className="skill-tags fs-13" key={uniqid()} >
                                                        {tag.CategoryName}
                                                    </div>
                                                ))}
                                                positioning="below"
                                            >
                                                <span>{`+${innovator.TechnologyDetails.filter(x => x.CategoryId === null)?.length - 1} more`}</span>
                                            </Tooltip> : null}
                                            </>

                                            }

                                        </div> : ""
                                }
                                {
                                    (innovatorTechnology && innovatorTechnology.filter((tag, i) => tag.CategoryId !== null) &&
                                        innovatorTechnology.filter((tag, i) => tag.CategoryId !== null).length > 0) ?
                                        <div>
                                            <div className="content-header" style={{ marginBottom: '0px' }}>
                                                <span className="icon Icon--Settings">
                                                    <svg width="26.624" height="26.624" viewBox="0 0 26.624 26.624">
                                                        <path id="Path_44875" data-name="Path 44875" d="M19.638,2a1,1,0,0,1,.989.863L20.637,3v3.1a5,5,0,0,1,3.893,3.894h3.1a1,1,0,0,1,.136,1.988l-.136.009h-3v2.33h3a1,1,0,0,1,.989.863l.009.136a1,1,0,0,1-.863.989l-.136.009h-3v2.33h3a1,1,0,0,1,.989.863l.009.136a1,1,0,0,1-.863.989l-.136.009h-3.1a5,5,0,0,1-3.893,3.893v3.1a1,1,0,0,1-1.988.136l-.009-.136v-3H16.309v3a1,1,0,0,1-.863.989l-.136.009a1,1,0,0,1-.989-.863l-.009-.136v-3H11.984v3a1,1,0,0,1-.863.989l-.135.009A1,1,0,0,1,10,27.761l-.009-.136V24.531a5,5,0,0,1-3.894-3.894H3a1,1,0,0,1-.135-1.988L3,18.64h3v-2.33H3a1,1,0,0,1-.989-.863L2,15.312a1,1,0,0,1,.863-.989L3,14.314h3v-2.33H3a1,1,0,0,1-.989-.863L2,10.986A1,1,0,0,1,2.863,10L3,9.987h3.1A5,5,0,0,1,9.988,6.093V3a1,1,0,0,1,1.988-.135L11.984,3V5.992h2.328V3a1,1,0,0,1,.863-.989L15.312,2a1,1,0,0,1,.989.863L16.31,3V5.992H18.64V3a1,1,0,0,1,.733-.963l.13-.027Zm0,5.99H10.986a3,3,0,0,0-3,3v8.653a3,3,0,0,0,3,3h8.653a3,3,0,0,0,3-3V10.986A3,3,0,0,0,19.638,7.99Zm-4.32,3.335a3.994,3.994,0,1,1-3.994,3.994A3.994,3.994,0,0,1,15.319,11.325Zm0,2a2,2,0,1,0,2,2A2,2,0,0,0,15.319,13.322Z" transform="translate(-2 -2)" fill="#a341ed" />
                                                    </svg>
                                                </span>
                                                <span>Technology</span>
                                            </div>
                                            {innovatorTechnology.filter((tag, i) => tag.CategoryId !== null)?.slice(0, 1).map((tag) => {
                                                return <div className="skill-tags" key={uniqid()}>{tag.CategoryName}</div>;
                                            })}

                                                {
                                                     innovatorTechnology.filter(x => x.CategoryId !== null)?.length > 1 && isMobile ?
                                                     
                                                    // changes here
                                                   <>
                                                        <a onClick={showMoreTechnology} className="show-more-link-mobile">
                                                            {!expandedTechnology ? <span>+{ innovatorTechnology.filter(x => x.CategoryId !== null)?.length - 1} more</span> : null}
                                                        </a>

                                                       {expandedTechnology ?
                                                         innovatorTechnology.filter(x => x.CategoryId !== null)?.slice(
                                                            1,
                                                            innovatorTechnology.filter(x => x.CategoryId !== null)?.length
                                                        ).map((tag) => (
                                                            <div className="skill-tags fs-13" key={uniqid()} >
                                                                {tag.CategoryName}
                                                            </div>
                                                        ))
                                                        : null}

                                                        <a onClick={showMoreTechnology} className="show-more-link-mobile show-less-link-mobile">
                                                        {expandedTechnology ? <span>Show less</span> : null}
                                                        </a>
                                                    </>
                                                    // changes ends
                                            :

                                            <>

                                            { innovatorTechnology.filter(x => x.CategoryId !== null)?.length > 1 ?
                                            <Tooltip
                                                relationship="label"
                                                appearance="inverted"
                                                content={innovatorTechnology.filter(x => x.CategoryId !== null)?.slice(
                                                    1,
                                                    innovatorTechnology.filter(x => x.CategoryId !== null)?.length
                                                ).map((tag) => (
                                                    <div className="skill-tags fs-13" key={uniqid()} >
                                                        {tag.CategoryName}
                                                    </div>
                                                ))}
                                                positioning="below"
                                            >
                                                <span>{`+${innovatorTechnology.filter(x => x.CategoryId !== null)?.length - 1} more`}</span>
                                            </Tooltip> : null }
                                        </>
                                        }

                                        </div> : ""
                                }
                            </>
                        ) : (
                            <span> </span>
                        )}
                </div>
                <ChatRegular
                    fontSize={24}
                    className="innovator-chat-icon"
                    onClick={onClickExecute}
                />
            </div>
        </div>
    );
};

export default SerialInnovatorDefault;
