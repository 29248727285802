import React, { useEffect, useState } from 'react';
import { Avatar } from '@fluentui/react-components';

const LeftSideMenu = ({ ideaCount, patentIdeaCount, enterprise, approvalCount, profileImage, ideaCountPerRole, patentAwaitingCountInnovator }) => {
    // let p1 = ideaCountPerRole && ideaCountPerRole.CoInnovatorCount ? ideaCountPerRole.CoInnovatorCount : 0;
    // let p2 = ideaCountPerRole && ideaCountPerRole.InnovatorCount ? ideaCountPerRole.InnovatorCount : 0;
    let count =ideaCountPerRole ? ideaCountPerRole: 0;
    return (
        <div className="left-side">
            <div className="avatar-div">
                <Avatar name={enterprise} size={64} image={profileImage}/>
                <div>
                    <span className="welcome-div">Welcome {enterprise}!</span>
                </div>
            </div>
            <div className="count-div">
                <span className="text-span">Ideas submitted </span><span className="count">{count}</span>
            </div>
            <div className="count-div">
                <span className="text-span">Ideas awaiting approval </span><span className="count">{patentAwaitingCountInnovator? patentAwaitingCountInnovator: 0}</span>
            </div>
            <div className="count-div">
                <span className="text-span">Ideas submitted for Patentability </span><span className="count">{patentIdeaCount}</span>
            </div>
        </div>
    )
}

export default React.memo(LeftSideMenu);