import React, { Component } from 'react'
import './filter-ideas.scss'
import { FilterRegular } from '@fluentui/react-icons';
import FilterPanel from './FilterPanel';
import { connect } from "react-redux";
import { filterPrivateIdeas, filterPinnedIdeas, filterContestIdeas, filterSharedIdeas, AllIdeas} from '../../../redux-sharedframework/actions/fetch-filter-action';

interface FilterIdeasState{
    showFilterPanel: any | boolean;
    AllIdeasButton?:string;
    privateFilterButton?:string;
    contestFilterButton?:string;
    pinnedFilterButton?:string;
    sharedIdeaFilterButton?: string;
    isMobile?: any | boolean;
}

interface FilterIdeaProps{
    AllIdeas?(): any;
    filterPrivateIdeas?():any;
    filterPinnedIdeas?(): any;
    filters?: any;
    filterContestIdeas?():any;
    filterSharedIdeas?(): any;
    allContestFilters?(): any;
    allIdeasState?: any;
    privateIdeasState?: any;
    contestDataState?: any;
    sharedDataState?: any;
    pinnedDataState?: any;
    hallofFameDataState?: any;
    popularDataState?: any;
    allFilterDataState?: any;
}

class FilterIdeas extends Component<FilterIdeaProps, FilterIdeasState>{
    private box: React.RefObject<HTMLDivElement>;
    constructor(props){
        super(props);
        this.state={
            showFilterPanel: false,
            AllIdeasButton: 'filter-buttons-active',
            privateFilterButton:'filter-buttons',
            contestFilterButton:'filter-buttons',
            pinnedFilterButton:'filter-buttons',
            sharedIdeaFilterButton: 'filter-buttons',
            isMobile: false,
        }
        this.box = React.createRef();
       
    }
    

    componentDidUpdate(prevProps, prevState) {
        const { privateIdeasState, contestDataState, sharedDataState, pinnedDataState, hallofFameDataState, popularDataState, allIdeasState, allFilterDataState } = this.props;
        //console.log("state", hallofFameDataState, popularDataState, allIdeasState, allFilterDataState);
        if (!(privateIdeasState || contestDataState || sharedDataState || pinnedDataState || hallofFameDataState || popularDataState || allFilterDataState.allFilters.length)) {
            this.state.AllIdeasButton == "filter-buttons" ? this.setState({ AllIdeasButton: 'filter-buttons-active' }) : null;
            this.state.privateFilterButton == "filter-buttons-active" ? this.setState({ privateFilterButton: 'filter-buttons' }) : null;
            this.state.contestFilterButton == "filter-buttons-active" ? this.setState({ contestFilterButton: 'filter-buttons' }) : null;
            this.state.pinnedFilterButton == "filter-buttons-active" ? this.setState({ pinnedFilterButton: 'filter-buttons' }) : null;
            this.state.sharedIdeaFilterButton == "filter-buttons-active" ? this.setState({ sharedIdeaFilterButton: 'filter-buttons' }) : null;
            allIdeasState ? null : this.props.AllIdeas();
        }
        if (hallofFameDataState || popularDataState || allFilterDataState.allFilters.length) {
            this.state.AllIdeasButton == "filter-buttons-active" ? this.setState({ AllIdeasButton: 'filter-buttons' }) : null;
            this.state.privateFilterButton == "filter-buttons-active" ? this.setState({ privateFilterButton: 'filter-buttons' }) : null;
            this.state.contestFilterButton == "filter-buttons-active" ? this.setState({ contestFilterButton: 'filter-buttons' }) : null;
            this.state.pinnedFilterButton == "filter-buttons-active" ? this.setState({ pinnedFilterButton: 'filter-buttons' }) : null;
            this.state.sharedIdeaFilterButton == "filter-buttons-active" ? this.setState({ sharedIdeaFilterButton: 'filter-buttons' }) : null;
        }        
    }

    componentDidMount() {
        // Adding a click event listener
        document.addEventListener('click', this.handleOutsideClick);
        const { privateIdeasState, contestDataState, sharedDataState, pinnedDataState, hallofFameDataState, popularDataState, allIdeasState } = this.props;
        allIdeasState && !(privateIdeasState || contestDataState || sharedDataState || pinnedDataState) ?
            this.setState({ AllIdeasButton: 'filter-buttons-active' }) : this.setState({ AllIdeasButton: 'filter-buttons' });
        privateIdeasState ? this.setState({ privateFilterButton: 'filter-buttons-active' }) : null;
        contestDataState ? this.setState({ contestFilterButton: 'filter-buttons-active' }) : null;
        sharedDataState ? this.setState({ sharedIdeaFilterButton: 'filter-buttons-active' }) : null;
        pinnedDataState ? this.setState({ pinnedFilterButton: 'filter-buttons-active' }) : null;

        window.addEventListener('resize', this.updateSize);
        this.updateSize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSize);
    }

    updateSize = () => {
        if (navigator.userAgent.match(/iPhone/i) !== null) {
            this.setState({ isMobile: true });
        } else if (
            navigator.userAgent.match(/Android/i) !== null &&
            (parseInt(window.innerWidth.toString()) < 768 ||
                parseInt(window.innerHeight.toString()) < 768)
        ) {
            this.setState({ isMobile: true });
        }
        else {
            this.setState({ isMobile: false });
        }
    }

    handleOutsideClick = (event) => {
        if (this.box && event.target!==null && !this.box.current?.contains(event.target)) {
            this.state.showFilterPanel ? this.setState({ showFilterPanel: !this.state.showFilterPanel }) : null;
           
        }
    }

    filterColorHandler=(e)=>{
        // e.preventDefault();
        //console.log("target:", e.target.tagName);
        //console.log("list clicked",e.target.value)
        if (e.target.value == "AllIdeas" && this.state.AllIdeasButton == "filter-buttons") {
            this.props.AllIdeas();
            this.setState({
                AllIdeasButton: 'filter-buttons-active',
                privateFilterButton: 'filter-buttons',
                contestFilterButton: "filter-buttons",
                pinnedFilterButton: 'filter-buttons',
                sharedIdeaFilterButton: 'filter-buttons',
                showFilterPanel: false
            });
        }
        if(e.target.value=="Private" && this.state.privateFilterButton=="filter-buttons")
        this.setState({
            privateFilterButton:'filter-buttons-active',
            contestFilterButton:"filter-buttons",
            pinnedFilterButton:'filter-buttons',
            sharedIdeaFilterButton: 'filter-buttons',
            AllIdeasButton: 'filter-buttons',
            showFilterPanel: false
             });
        if(e.target.value=="Contest" && this.state.contestFilterButton=="filter-buttons")
        this.setState({
            contestFilterButton:'filter-buttons-active',
            privateFilterButton:'filter-buttons',
            pinnedFilterButton:'filter-buttons',
            sharedIdeaFilterButton: 'filter-buttons',
            AllIdeasButton: 'filter-buttons',
            showFilterPanel: false

              });
        if(e.target.value=="Pinned" && this.state.pinnedFilterButton=="filter-buttons")
        this.setState({
            pinnedFilterButton:'filter-buttons-active',
            privateFilterButton:'filter-buttons',
            contestFilterButton:'filter-buttons',
            sharedIdeaFilterButton: 'filter-buttons',
            AllIdeasButton: 'filter-buttons',
            showFilterPanel: false
        });
        if(e.target.value=="Shared with me" && this.state.sharedIdeaFilterButton=="filter-buttons")
        this.setState({
            sharedIdeaFilterButton:'filter-buttons-active',
            privateFilterButton:'filter-buttons',
            contestFilterButton:'filter-buttons',
            pinnedFilterButton: 'filter-buttons',
            AllIdeasButton: 'filter-buttons',
            showFilterPanel: false        
        });
        //if (e.target.tagName == "svg" || e.target.tagName == "DIV")
        //    this.setState({
        //        sharedIdeaFilterButton: 'filter-buttons',
        //        privateFilterButton: 'filter-buttons',
        //        contestFilterButton: 'filter-buttons',
        //        pinnedFilterButton: 'filter-buttons',
        //        AllIdeasButton: 'filter-buttons',
        //    });

        //if (e.target.value == "AllIdeas" && this.state.AllIdeasButton == "filter-buttons-active")
        //this.setState({ AllIdeasButton: 'filter-buttons' });
        if(e.target.value=="Private" && this.state.privateFilterButton=="filter-buttons-active")
        this.setState({privateFilterButton:'filter-buttons'});
        if(e.target.value=="Contest" && this.state.contestFilterButton=="filter-buttons-active")
        this.setState({contestFilterButton:'filter-buttons'});
        if(e.target.value=="Pinned" && this.state.pinnedFilterButton=="filter-buttons-active")
        this.setState({pinnedFilterButton:'filter-buttons'});
        if(e.target.value=="Shared with me" && this.state.sharedIdeaFilterButton=="filter-buttons-active")
        this.setState({ sharedIdeaFilterButton: 'filter-buttons' });  
    }


    render() {
        const {AllIdeasButton,privateFilterButton,contestFilterButton,pinnedFilterButton,sharedIdeaFilterButton}= this.state;

        if (this.state.isMobile) {
            return (
                <div className="filter-ideas" ref={this.box}>                       
                            <div className="filter-inner">
                                <span className={AllIdeasButton}><button onClick={(e) => { this.filterColorHandler(e) }} value="AllIdeas">All</button></span>
                                <span className={privateFilterButton}><button onClick={(e) => { this.props.filterPrivateIdeas(), this.filterColorHandler(e) }} value="Private">Private</button></span>
                                <span className={contestFilterButton}><button onClick={(e) => { this.props.filterContestIdeas(), this.filterColorHandler(e) }} value="Contest">My Contest Ideas</button></span>                          
                                <span className={pinnedFilterButton}><button onClick={(e) => { this.props.filterPinnedIdeas(), this.filterColorHandler(e) }} value="Pinned">Pinned</button></span>
                                <span className={sharedIdeaFilterButton}><button onClick={(e) => { this.props.filterSharedIdeas(), this.filterColorHandler(e) }} value="Shared with me">Shared with me</button></span>
                             </div>          
                        {/*<div className="filter-text" onClick={(e) => { e.preventDefault(); this.filterColorHandler(e), this.setState({ showFilterPanel: !this.state.showFilterPanel }) }} ><FilterRegular /></div>*/}
                        {this.state.showFilterPanel ? <div> <FilterPanel filters={this.props.filters} allContestFilters={this.props.allContestFilters} /></div> : ""}
                </div>
            )
        }
        else {
            return (
                <div className="filter-ideas" ref={this.box}>
                    <span className={AllIdeasButton}><button onClick={(e) => { this.filterColorHandler(e) }} value="AllIdeas">All</button></span>
                    <span className={privateFilterButton}><button onClick={(e) => { this.props.filterPrivateIdeas(), this.filterColorHandler(e) }} value="Private">Private</button></span>
                    <span className={contestFilterButton}><button onClick={(e) => { this.props.filterContestIdeas(), this.filterColorHandler(e) }} value="Contest">My Contest Ideas</button></span>
                    <span className={pinnedFilterButton}><button onClick={(e) => { this.props.filterPinnedIdeas(), this.filterColorHandler(e) }} value="Pinned">Pinned</button></span>
                    <span className={sharedIdeaFilterButton}><button onClick={(e) => { this.props.filterSharedIdeas(), this.filterColorHandler(e) }} value="Shared with me">Shared with me</button></span>
                    {/*<div className="filter-text" onClick={(e) => { e.preventDefault(); this.filterColorHandler(e), this.setState({ showFilterPanel: !this.state.showFilterPanel }) }} ><FilterRegular />Filter</div>*/}
                    {this.state.showFilterPanel ? <div> <FilterPanel filters={this.props.filters} allContestFilters={this.props.allContestFilters} /></div> : ""}
                </div>
            )
        }        
    }
}


const mapStateToProps = (state) => {
    return {
        entityId: state.entityReducer.entityId,
        token: state.fetchTokenReducer.data,
        allIdeasState: state.fetchFilterDataReducer.allIdeas,
        privateIdeasState: state.fetchFilterDataReducer.privateData,
        contestDataState: state.fetchFilterDataReducer.contestData,
        sharedDataState: state.fetchFilterDataReducer.sharedData,
        pinnedDataState: state.fetchFilterDataReducer.pinnedData,
        popularDataState: state.fetchFilterDataReducer.popularData,
        hallofFameDataState: state.fetchFilterDataReducer.hallofFame,
        allFilterDataState: state.fetchFilterDataReducer.filterData,
    }
}

const mapDispatchToProps = (dispatch) => {
    //console.log("dispatching")
    return ({
        AllIdeas: () => dispatch(AllIdeas()),
        filterPrivateIdeas:() => dispatch(filterPrivateIdeas()),
        filterPinnedIdeas:() => dispatch(filterPinnedIdeas()),
        filterContestIdeas:()=>dispatch(filterContestIdeas()),
        filterSharedIdeas:()=>dispatch(filterSharedIdeas())
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterIdeas);

