// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Dialog, DialogType } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import { useHistory } from 'react-router-dom';
import { useSelector, RootStateOrAny } from "react-redux";

const modalProps = {
    isBlocking: false,
    styles: { main: { width: '36.364vw', height:'30.303vw' } },
    showCloseButton: true,
};

const dialogContentProps = {
    type: DialogType.close,
    title: '',
};

export const StartImplementationPopup = ({ openModal, closeModal, text1, text2, imageClassName }) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const history = useHistory();
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    useEffect(() => {
        if (openModal) {
            toggleHideDialog();
        }
    }, [openModal]);

    const closePopup = () => {
        history.push('/');
        toggleHideDialog();
        closeModal();
    }

    return (
        <>
            <Dialog hidden={hideDialog} onDismiss={closePopup}
                dialogContentProps={dialogContentProps}
                modalProps = {{ ...modalProps, className: isMobile? "modal-width-small-mobile" : "modal-width-small" }}>
                <div className="image-wrapper">
                    <div className={imageClassName}>
                    </div>
                </div>
                <div className="content-wrapper" style={{ height: '3vw'}}>
                    <div>
                        <span className="content-bold">{text1}</span>
                    </div>
                    <div>
                        <span className="content-normal">{text2}</span>
                    </div>
                </div>
            </Dialog>
        </>
    );
};