// @ts-nocheck
import { takeEvery, call, put } from 'redux-saga/effects';
import {types} from '../actions/types';
import axios from 'axios';
import * as Constants from '../../api/constants';
import {fetchTokenSuccess, fetchTokenFailure} from '../actions/token-action';

const qs = require("qs");


/**  Case of API lagacy AD **/
let data = qs.stringify({
    grant_type: process.env.GRANT_TYPE,
    client_id: process.env.CLIENT_ID,
    client_secret: process.env.CLIENT_SECRET,
    scope: process.env.SCOPE,
});

let config = {
    method: "post",
    url: Constants.TOKENURL,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
};


/**  Case of API Azure AD **/
//let data = qs.stringify({
//    grant_type: "client_credentials",
//    client_id: "76816367-1182-4c71-8ff2-952a31ecd908",
//    client_secret: "RHVLKmFweHhHYUwyTkVJIw==",
//    resource: "https://myinnovation-user-webapi-uat.ciotest.accenture.com",
//});

//let config = {
//    method: "post",
//    url: "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/token",
//    headers: {
//        "Content-Type": "application/x-www-form-urlencoded",
//    },
//    data: data,
//};







function* asyncFetchTokenRequest(action){
    try{
            const response = yield call(()=> axios(config));
            const access_token = response.data.access_token;
            yield put(fetchTokenSuccess(access_token));
          
        }
    catch(error){
        // console.log(error);
        yield put (fetchTokenFailure("error"))
        
    }
}

export function* watchTokenDataSaga(){
    yield takeEvery(types.SEND_TOKEN_REQUEST, asyncFetchTokenRequest)
}