import * as React from "react";
import { Stack } from "@fluentui/react";
import { Text } from "@fluentui/react-components";
import { useState, useEffect } from "react";
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { useTeams } from "@microsoft/teamsfx-react";
import * as microsoftTeams from "@microsoft/teams-js";

export const RemoveTab = () => {

    const [{ inTeams, theme }] = useTeams();

    useEffect(() => {
        if (inTeams === true) {
            microsoftTeams.appInitialization.notifySuccess();
        }
    }, [inTeams]);

    return (
        <FluentProvider theme={teamsLightTheme}>
            <Stack>
                <div className="remove-tab">
                    <Text as='h1'>You're about to remove the LIving Innovation Channel App...</Text>
                        {/*<Text content="You can just add stuff here if you want to clean up when removing the tab. For instance, if you have stored data in an external repository, you can delete or archive it here. If you don't need this remove page you can remove it." />*/}
                </div>
            </Stack>
        </FluentProvider>
    );
};
