import React, { useEffect, useState, useCallback, Suspense, lazy } from "react";
import LeftSideMenu from "./left-side-menu";
import RSActiveContests from "./rs-active-contests";
import RSTrendingCategories from "./rs-trending-categories";
import MyPreferences from "./my-preference-updated";
import MyExpertise from "./my-expertise-updated";
import ScribbleIdea from "./scribble-idea";
import { useTeams } from "@microsoft/teamsfx-react";
import HelpComponentMobile from '../mobile/landing/right-panel/HelpComponentMobile';
// import ContestChallengeView from "./ContestChallengeView";
import { PageLoader } from "../../common/PageLoader";
//import TourButton from "../TourButton";
import {
    getData,
    getPeopleHierarchyUpload,
    getConfigurationList,
    getChallengeNContestByDemographicFromTeams,
    getChallengeNContestByDemographicFromTeamsContractor,
    getUserDetail
} from "../../api/PeopleApi";
import { SaveUniqueUser } from "../../api/UserApi";
import {
    getInnovatorIdeaCount,
    getIdeaStatus,
    getInnovatorIdeas,
    getChallAndContestByRole,
    getIdeaStatusMentor,
    getTrendingTopics,
    popularRequests,
    getPatentIdeaCount,
    getPrivateIdeaDetails,
    getIdeaStatusPatent,
    getEmployeePhoto,
    userPreference,
    getPromotionalFeed,
    userExpertise,
    technologyDetails,
    getPinnedData,
    getPrivatePinnedData,
    getFilterDetail,
    getSharedIdeasFromPrivateIdeas,
    getSharedIdeasFromPublicIdeas,
    getIdeaCountAsPerRole,
    getPromotionalPinnedFeed,
    getFiltersRoleWise,
    getInnovatorIdeasWithFilters,
    getIdeasForImplementer,
    getPromotionalFeedBinaryData,
    addIdeaShareUserDetails,
    addPrivateIdeaShareUserDetails,
    getIdeasByIdeaId,
    getIdeasByIdeaIdWithIsValidUser,
    getChallengeTypeAndOrgUnitByChallengeId,
    getChallengeByContestId
} from "../../api/IdeaApi";

import {getContestByContestId} from "../../api/contestApi";

import { innovatorFilterData } from "../../redux-sharedframework/actions/fetch-filter-action";
import {
    fetchIdeaFeedLastGetIdeaTillDates,
    setPromotionalMediaFiles,
    setSerialInnovatorData,
    setSerialInnoDemograpghyType, setSerialInnovatorIdeaFilter
} from "../../redux-sharedframework/actions/fetch-data-actions";
import {
    setOrgUnits,
    setPeopleKey,
    setAllIdeaFeedData,
    storeUserExpertiseData,
    storeUserPreferenceData,
    setContestChallengeData,
    setLoginName,
    setUserCountryName,
    setProfilePic,
    setFilterData,
    setTrendingCategoriesData,
    setIndustryData,
    setTechnologyData,
    IdeaCreationData,
    promotionalContestData,
    setPeopleDetails,
    storeUserPreferenceCompleteData,
    storeUserExpertiseCompleteData,
    setInnovatorsData,
    setIdeaCountAsPerRoleState,
    setPendingIdeaCount,
    setPendingPatentIdeaCount,
    setIsContractorLoggedin,
    setActiveContestDetails,
    setV360ContestId,
    setV360ContestIdFy23,
    setV360ContestIdFy24,
} from "../../redux-sharedframework/actions/fetch-demography-actions";
import { RootStateOrAny, useSelector, useDispatch, shallowEqual } from "react-redux";
import { connect } from "react-redux";
import { Spinner, Skeleton, Tooltip, SkeletonItem, FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import PatentabilityCheck from "./PatentabilityCheck";
import SerialInnovatorView from "./serial-innovator-view";
import moment from "moment";
import { GenericMessageBar } from "../../common/MessageBar";
// import InfiniteScrollFeed from "./../infinite-scroll/InfiniteScroll";
import FilterIdeas from "./filters/FilterIdeas";
import { useHistory } from "react-router-dom";
import { app, authentication } from "@microsoft/teams-js";
import NudgePreference from "./NudgePreference";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from "./../appInsight/AppInsight";
import StickyBox from "react-sticky-box";
import AnalyticsDashboardView from "./AnalyticsDashboardView";
//import Tour from 'reactour';

var graph = require("@microsoft/microsoft-graph-client");
import "./../../dashboard.scss";
// import "./my-preferences.scss";
import "./view-idea.scss";
import '../../common/test.scss';
import { initializeIcons } from "@fluentui/font-icons-mdl2";
// initializeIcons();
import { getSerialInnovatorForMaxIdeaCount } from "../../api/SerialInnovatorApi";
// import Tab from "@mui/material/Tab";
// import Tabs from "@mui/material/Tabs";
import "../mobile/Mobile.scss";
import { Tabs, Tab } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick'
// import "../mobile/Mobile.scss";
// import MentorApproval from "./MentorApproval";
// import HelpComponent from "./HelpComponent";
//import LeftPanel from "../mobile/landing/left-panel/LeftPanel";
import MiddlePanel from "../mobile/landing/middle-panel/MiddlePanel";
//import RightPanel from "../mobile/landing/right-panel/RightPanel";
import { Announcements } from "./Announcements";
// import TourCarousel from "../mobile/tour-carousel/TourCarousel";
import {setActiveContestsAction} from "../../redux-sharedframework/actions/fetch-active-contest";
import { GetContestQuickLinks } from "../../api/stagesApi";
import {CommonPopup} from '../../common/common-popup/CommonPopup';
import {CommonPopupMobile} from '../../common/common-popup/CommonPopupMobile';
import InfiniteScrollFeed from "../infinite-scroll/InfiniteScroll";
const MentorApproval = lazy(() => import("./MentorApproval"));
const HelpComponent = lazy(() => import("./HelpComponent"));
const LeftPanel = lazy(() => import("../mobile/landing/left-panel/LeftPanel"));
//const MiddlePanel = lazy(() => import("../mobile/landing/middle-panel/MiddlePanel"));
const RightPanel = lazy(() => import("../mobile/landing/right-panel/RightPanel"));
//import DynamicFormBuilder from '../dynamic-form/DynamicFormBuilder';

interface IdeaCreatorHierarchy {
    OrgUnitId: string;
    OrgUnitDescription: string;
    OrgUnitLevel: string;
}

const NewViewIdea = ({ location }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        loginNameState,
        profilePicState,
        orgUnitsState,
        peopleKeyState,
        peopleDetails,
        allIdeaFeedState,
        userPreferenceState,
        userExpertiseState,
        contestsState,
        contestsStateWithoutFilter,
        challengesState,
        filterDetailsState,
        trendingCategoriesState,
        technologyState,
        industryState,
        tourStatus,
        activeContestDetailsArray,
        v360ContestId,
    } = useSelector((state: RootStateOrAny) => (state.fetchDemographyReducer), shallowEqual);

    const [ideaCreationData, setIdeaCreationData] = useState<any>([]);
    const [contestIds, setContestIds] = useState<any>(contestsState?.length ? contestsState.map((contest) => contest.ContestId) : []);
    const [contestIdsWithoutFilter, setContestIdsWithoutFilter]= useState<any>(contestsStateWithoutFilter?.length ? contestsStateWithoutFilter.map((contest) => contest.ContestId) : []);
    const [challengeIds, setChallengeIds] = useState<any>([]);
    const [ideaStatus, setIdeaStatus] = useState<any>([]);
    const [ideas, setIdeas] = useState<any>([]);
    const [privateIdeas, setPrivateIdeas] = useState<any>([]);
    const token = useSelector(
        (state: RootStateOrAny) => state.fetchTokenReducer.data, shallowEqual
    );
    const [{ inTeams, theme, context, themeString }] = useTeams();
    const [showSpinner, setSpinnerData] = useState(true);
    const [trendingCategories, setTrendingCategories] = useState<any>(
        trendingCategoriesState?.length ? trendingCategoriesState : []
    );
    const [activeContests, setActiveContests] = useState<any>([]);
    const [innovatorIdeaCount, setInnovatorIdeaCount] = useState<number>(0);
    const [patentCount, setPatentCount] = useState<number>(0);
    const [approvalIdeaCount, setApprovalIdeaCount] = useState<number>(0);
    const [name, setName] = useState<string>(
        loginNameState !== "" ? loginNameState : ""
    );
    const [profileImageUrl, setprofileImageUrl] = useState<string>(
        profilePicState !== "" ? profilePicState : ""
    );
    const [editPrivateIdeaCheck, setEditPrivateIdeaCheck] = useState<any>(false);
    const enterpriseId = useSelector(
        (state: RootStateOrAny) => state.entityReducer.entityId, shallowEqual
    );
    const [userCountryNameStateAll, setUserCountryNameStateAll] = useState("");
    const [choice, setChoice] = useState<string>("");
    const [msg, setMsg] = useState<string>("");
    const [userPreferenceData, setuserPreferenceData] = useState<any>(
        userPreferenceState?.length ? userPreferenceState : []
    );
    const [userPreferenceCompleteData, setUserPreferenceCompleteData] =
        useState<any>([]);

    const [userExpertiseData, setUserExpertiseData] = useState<any>(
        userExpertiseState?.length ? userExpertiseState : []
    );
    const [userExpertiseCompleteData, setUserExpertiseCompleteData] =
        useState<any>([]);
    const [technologyData, setTechnologiesData] = useState<any>(
        technologyState?.length ? technologyState : []
    );
    const [industry, setIndustry] = useState<any>(
        industryState?.length ? industryState : []
    );

    const [editPrivateIde, setEditPrivateIdea] = useState<any>([]);
    const filterData = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.filterData, shallowEqual
    );
    const popularData = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.popularData, shallowEqual
    );
    const allIdeasState = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.allIdeas, shallowEqual
    );
    const privateIdeasState = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.privateData, shallowEqual
    );
    const contestDataState = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.contestData, shallowEqual
    );
    const sharedDataState = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.sharedData, shallowEqual
    );
    const pinnedDataState = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.pinnedData, shallowEqual
    );
    const initialInnovatorContestData = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.innovatorFilterData, shallowEqual
    );
    const expertiseDataStore = useSelector(
        (state: RootStateOrAny) => state.expertiseReducer.expertiseComLoaded, shallowEqual
    );
    const preferenceDataStore = useSelector(
        (state: RootStateOrAny) => state.expertiseReducer.preferenceComLoaded, shallowEqual
    );
    const promotionalDataState = useSelector(
        (state: RootStateOrAny) => state.fetchFilterDataReducer.hallofFame, shallowEqual
    );

    const [allPinnedData, setAllPinnedData] = useState<any>([]);

    const [allPrivateData, setAllPrivateData] = useState<any>([]);
    const [allContestData, setAllContestData] = useState<any>([]);
    const [allSharedData, setAllSharedData] = useState<any>([]);
    const [allpromotionalData, setAllpromotionalData] = useState<any>([]);
    const [allContestFilters, setAllContestFilters] = useState<any>([]);
    const [allPopularIdeas, setAllPopulaIdeas] = useState<any>([]);
    //const [filters, setFilters] = useState<any>(filterDetailsState !== '' ? filterDetailsState : []);
    const [filters, setFilters] = useState<any>([]);
    const [ideaCountAsPerRole, setIdeaCountAsPerRole] = useState<any | undefined>();
    const [patentAwaitingCountInnovator, setPatentAwaitingCountInnovator]=  useState<any | undefined>();
    const [filteredDataGot, setFilteredDataGot] = useState<any>([]);
    const [mentorPendingCount, setMentorPendingCount] = useState<number>(0);
    const [patentPendingCount, setPatentPendingCount] = useState<number>(0);
    const [dataLoader, setDataLoader] = useState(false);

    const [tempPromotionalData, setTempPromotionalData] = useState<any>([]);
    const [firstFeedData, setFirstFeedData] = useState<any>([]);
    const [challenges, setChallenges] = useState<any | undefined>([]);

    const navigatedFromActivity = useSelector(
        (state: RootStateOrAny) => state.entityReducer.navigatedFromActivity, shallowEqual
    );

    const refreshNeeded = location.state ? location.state.refreshNeeded : true;
    window.history.replaceState({}, document.title);
    const [serialInnovators, setSerialInnovators] = useState<any>([]);
    const isMobile = useSelector(
        (state: RootStateOrAny) => state.entityReducer.isMobile, shallowEqual
    );
    const { userCountryNameState } = useSelector((state: RootStateOrAny) => state.fetchDemographyReducer);
    const [selectedTab, setSelectedTab] = React.useState("Tab 2");
    const [showAnalytics, setShowAnalytics] = useState<boolean>(false);
    const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);
    const [itemsToShow, setItemsToShow] = useState(3);
    const [contest, setContest] = useState<any | undefined>(undefined);
    const [GTICContractorContests,setGTICContractorContests] = useState<any | boolean>(false);
    const [contractorContestPop, setContractorContestPop] = useState<any | boolean>(false);

    const [showSpinnerOnScreen, setSpinnerDataOnScreen] = useState<any | boolean>(false);
    const [contractorCancel, setContractorCancel] = useState<any | boolean>(false);
    let sustainabilityContestID = process.env.REACT_APP_SUSTAINABILITY2023_CONTEST_ID;
    let IOTYContestID = process.env.REACT_APP_IOTY_CONTEST_ID;
    let solutioningHeroesID= process.env.REACT_APP_SOLUTIONING_DELIVERY_HEROES_CONTEST_ID;

    const showMore = () => {
        itemsToShow === 3 ? (
            setItemsToShow(activeContests.length)
           // setExpanded(true)
        ) : (
            setItemsToShow(3)
           // setExpanded(false)
        )
        history.push({
            pathname: "/view-all-active-contests",
            state: { contests: activeContests },
        });
    }
    const initializeTeam = async() =>  {

        app.initialize();
        authentication.getAuthToken().then((result) => {
                localStorage.setItem("tokenTeams", result);
            }).catch((error) => {
                alert("Failure: " + error);
            });
        // authentication.getAuthToken(authTokenRequest);
        app.getContext()
            .then(context => {
            // let prefixValues = context.page.subPageId.split(":");
           
            if (navigatedFromActivity) {
                //Navigating to idea details beased on id which we get it from activity feed.
                let prefixValues = context.page.subPageId.split(":");
                
                if( context.page.subPageId.length > 0 &&
                    prefixValues[0] == "CAMPAINGPAGE"){
                   // console.log("contest data", prefixValues[1]);
                    getContestByContestId(prefixValues[1].trim()).then(responseContest => {
                        if (responseContest) {
                            if (responseContest.ContestNm && responseContest.ContestNm?.toLowerCase().includes("v360")) {
                                //history.push({
                                //    pathname: `/view-contest-homepage-v360/${prefixValues[1]}`,
                                //    state: { nominationId: prefixValues[1].trim() },
                                //});
                                history.push({
                                    pathname: `/v360-idea-submission-form/${prefixValues[1]}`,
                                    state: { nominationId: prefixValues[2].trim() },
                                });
                            }
                            else{
                                history.push({
                                    pathname: `/view-contest-homepage/${prefixValues[1]}`

                                });
                            }
                        }
                        else {
                            history.push({
                                pathname: `/view-contest-homepage/${prefixValues[1]}`

                            });
                        }
                    })
                        
                        
                    }

                if(context.page.subPageId && context.page.subPageId !="" && context.page.subPageId.length > 0 &&
                    context.page.subPageId == "GLC"){
                            history.push({
                                pathname: `/view-contest-homepage/${process.env.REACT_APP_GLC2022_CONTEST_ID}`              
                            });
                    
                }

                if (context.page.subPageId && context.page.subPageId != "" && context.page.subPageId.length > 0 &&
                    (context.page.subPageId == "GTIC" || context.page.subPageId == "GTIC6")) {
                    setSpinnerDataOnScreen(true);
                    if (enterpriseId) {
                        getData(enterpriseId).then((res) => {
                            if (res) {
                                if (res && res["ResponseCode"]) {
                                    setSpinnerDataOnScreen(false);
                                    if (res["ResponseCode"] === "107" || res["ResponseCode"] === "109") {
                                        history.push("/unautherised-user");
                                    }
                                }
                                else if (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() == "" || (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() !== "" && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] !== null && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] == "92149")) {
                                    dispatch(setIsContractorLoggedin(true));
                                    setContractorCancel(true);
                                    // getContestAndChallenge(token, peopleKeyState, userCountryNameState, orgUnitsState, true);

                                    getChallengeNContestByDemographicFromTeamsContractor(
                                        token,
                                        res["value"][0].PeopleKey,
                                        res["value"][0]["CountryName"],
                                        [],
                                        false,
                                        false,
                                        true
                                    ).then((response) => {
                                        if (response) {

                                            //   console.log("contractor response-->",response);
                                            let filteredGTICContestsForContractor = [];
                                            if(context.page.subPageId == "GTIC6"){
                                                filteredGTICContestsForContractor = response['Contests']?.filter((contestsData) => contestsData.ContestNm.toLowerCase().includes("global tech innovation contest season 6"))
                                            }
                                            else if(context.page.subPageId == "GTIC"){
                                                filteredGTICContestsForContractor = response['Contests']?.filter((contestsData) => contestsData.ContestNm.toLowerCase().includes("global tech innovation contest season 5"))
                                            }
                                            //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                            if (!filteredGTICContestsForContractor || filteredGTICContestsForContractor?.length == 0) {
                                                setSpinnerDataOnScreen(false);
                                                history.push("/unautherised-user");
                                            }
                                            if (filteredGTICContestsForContractor?.length == 1) {
                                                setSpinnerDataOnScreen(false);
                                                history.push(`/view-contest-homepage-gtic/${filteredGTICContestsForContractor[0].ContestId}`);

                                            } else if (filteredGTICContestsForContractor?.length > 1) {
                                                setContractorContestPop(true);
                                                setGTICContractorContests(filteredGTICContestsForContractor.map((data) => {
                                                    return (
                                                        { value: data.ContestId, label: data.ContestNm }
                                                    )
                                                }))
                                            }
                                            else {
                                                setSpinnerDataOnScreen(false);
                                            }
                                        }
                                    })
                                }
                                else {
                                    setContractorCancel(false);

                                    getPeopleHierarchyUpload(
                                        token,
                                        res["value"][0]["OrgnisationsDetail"]
                                    ).then((res1) => {
                                        if (res1["value"]) {
                                            const orgUnits = getOrgUnit(res1["value"]);

                                            getChallengeNContestByDemographicFromTeams(
                                                "",
                                                res["value"][0].PeopleKey,
                                                res["value"][0]["CountryName"],
                                                orgUnits,
                                                false,
                                                false,
                                            ).then((response) => {
                                                if (response) {
                                                       console.log('>>theme line 454 ', theme); 
                                                    //   console.log("contractor response-->",response);
                                                    //let filteredGTICContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestNm.toLowerCase().includes("global tech innovation contest season 5"))
                                                    //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                                    let filteredGTICContestsForContractor = [];
                                                    if(context.page.subPageId == "GTIC6"){
                                                        filteredGTICContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestNm.toLowerCase().includes("global tech innovation contest season 6"))
                                                    }
                                                    else if(context.page.subPageId == "GTIC"){
                                                        filteredGTICContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestNm.toLowerCase().includes("global tech innovation contest season 5"))
                                                    }
                                                    if (!filteredGTICContestsForContractor || filteredGTICContestsForContractor?.length == 0) {
                                                        setSpinnerDataOnScreen(false);
                                                        history.push("/unautherised-user");
                                                    }
                                                    if (filteredGTICContestsForContractor?.length == 1) {
                                                        setSpinnerDataOnScreen(false);
                                                        history.push(`/view-contest-homepage-gtic/${filteredGTICContestsForContractor[0].ContestId}`);

                                                    } else if (filteredGTICContestsForContractor?.length > 1) {
                                                        setContractorContestPop(true)
                                                        setGTICContractorContests(filteredGTICContestsForContractor.map((data) => {
                                                            return (
                                                                { value: data.ContestId, label: data.ContestNm }
                                                            )
                                                        }))
                                                    }
                                                    else {
                                                        setSpinnerDataOnScreen(false);
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            setSpinnerDataOnScreen(false);
                                        }
                                    })
                                }                               
                            }
                        });
                    }
                }

                if (context.page.subPageId && context.page.subPageId != "" && context.page.subPageId.length > 0 &&
                    context.page.subPageId == "sustainability") {
                    setSpinnerDataOnScreen(true);
                    if (enterpriseId) {
                        getData(enterpriseId).then((res) => {
                            if (res) {
                                if (res && res["ResponseCode"]) {
                                    setSpinnerDataOnScreen(false);
                                    if (res["ResponseCode"] === "107" || res["ResponseCode"] === "109") {
                                        history.push("/unautherised-user");
                                    }
                                }
                                else if (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() == "" || (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() !== "" && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] !== null && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] == "92149")) {
                                    dispatch(setIsContractorLoggedin(true));
                                    setContractorCancel(true);
                                    history.push(`/view-contest-homepage-sustainability/${sustainabilityContestID}`);
                                    // getContestAndChallenge(token, peopleKeyState, userCountryNameState, orgUnitsState, true);

                                    //getChallengeNContestByDemographicFromTeamsContractor(
                                    //    token,
                                    //    res["value"][0].PeopleKey,
                                    //    res["value"][0]["CountryName"],
                                    //    [],
                                    //    false,
                                    //    false,
                                    //    true
                                    //).then((response) => {
                                    //    if (response) {

                                    //        //   console.log("contractor response-->",response);
                                    //        let filteredSustainabilityContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === sustainabilityContestID);
                                    //        //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                    //        if (!filteredSustainabilityContestsForContractor || filteredSustainabilityContestsForContractor?.length == 0) {
                                    //            setSpinnerDataOnScreen(false);
                                    //            history.push("/unautherised-user");
                                    //        }
                                    //        if (filteredSustainabilityContestsForContractor?.length == 1) {
                                    //            setSpinnerDataOnScreen(false);
                                    //            history.push(`/view-contest-homepage-sustainability/${filteredSustainabilityContestsForContractor[0].ContestId}`);
                                    //        } 
                                    //        else {
                                    //            setSpinnerDataOnScreen(false);
                                    //        }
                                    //    }
                                    //})
                                }
                                else {
                                    setContractorCancel(false);

                                    getPeopleHierarchyUpload(
                                        token,
                                        res["value"][0]["OrgnisationsDetail"]
                                    ).then((res1) => {
                                        if (res1["value"]) {
                                            const orgUnits = getOrgUnit(res1["value"]);

                                            getChallengeNContestByDemographicFromTeams(
                                                "",
                                                res["value"][0].PeopleKey,
                                                res["value"][0]["CountryName"],
                                                orgUnits,
                                                false,
                                                false,
                                            ).then((response) => {
                                                if (response) {

                                                    //   console.log("contractor response-->",response);
                                                    let filteredSustainabilityContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === sustainabilityContestID)
                                                    //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                                    if (!filteredSustainabilityContestsForContractor || filteredSustainabilityContestsForContractor?.length == 0) {
                                                        setSpinnerDataOnScreen(false);
                                                        history.push("/unautherised-user");
                                                    }
                                                    if (filteredSustainabilityContestsForContractor?.length == 1) {
                                                        setSpinnerDataOnScreen(false);
                                                        history.push(`/view-contest-homepage-sustainability/${filteredSustainabilityContestsForContractor[0].ContestId}`);
                                                    }
                                                    else {
                                                        setSpinnerDataOnScreen(false);
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            setSpinnerDataOnScreen(false);
                                        }
                                    })
                                }
                            }
                        });
                    }
                }
                if (context.page.subPageId && context.page.subPageId != "" && context.page.subPageId?.length > 0 &&
                context.page.subPageId == "solutioning") {
                setSpinnerDataOnScreen(true);
                if (enterpriseId) {
                    getData(enterpriseId).then((res) => {
                        if (res) {
                            if (res && res["ResponseCode"]) {
                                setSpinnerDataOnScreen(false);
                                if (res["ResponseCode"] === "107" || res["ResponseCode"] === "109") {
                                    history.push("/unautherised-user");
                                }
                            }
                            else if (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() == "" || (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() !== "" && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] !== null && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] == "92149")) {
                                setSpinnerDataOnScreen(false);
                                history.push("/unautherised-user");

                                // dispatch(setIsContractorLoggedin(true));
                                // setContractorCancel(true);
                                // history.push(`/view-contest-homepage-sustainability/${solutioningHeroesID}`);
                                // // getContestAndChallenge(token, peopleKeyState, userCountryNameState, orgUnitsState, true);


                                //getChallengeNContestByDemographicFromTeamsContractor(
                                //    token,
                                //    res["value"][0].PeopleKey,
                                //    res["value"][0]["CountryName"],
                                //    [],
                                //    false,
                                //    false,
                                //    true
                                //).then((response) => {
                                //    if (response) {

                                //        //   console.log("contractor response-->",response);
                                //        let filteredSustainabilityContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === solutioningHeroesID);
                                //        //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                //        if (!filteredSustainabilityContestsForContractor || filteredSustainabilityContestsForContractor?.length == 0) {
                                //            setSpinnerDataOnScreen(false);
                                //            history.push("/unautherised-user");
                                //        }
                                //        if (filteredSustainabilityContestsForContractor?.length == 1) {
                                //            setSpinnerDataOnScreen(false);
                                //            history.push(`/view-contest-homepage-sustainability/${filteredSustainabilityContestsForContractor[0].ContestId}`);
                                //        } 
                                //        else {
                                //            setSpinnerDataOnScreen(false);
                                //        }
                                //    }
                                //})
                            }
                            else {
                                setContractorCancel(false);

                                getPeopleHierarchyUpload(
                                    token,
                                    res["value"][0]["OrgnisationsDetail"]
                                ).then((res1) => {
                                    if (res1["value"]) {
                                        const orgUnits = getOrgUnit(res1["value"]);

                                        getChallengeNContestByDemographicFromTeams(
                                            "",
                                            res["value"][0].PeopleKey,
                                            res["value"][0]["CountryName"],
                                            orgUnits,
                                            false,
                                            false,
                                        ).then((response) => {
                                            if (response) {

                                                //   console.log("contractor response-->",response);
                                                let filteredSolutioningContest = response['Contests'].filter((contestsData) => contestsData.ContestId === solutioningHeroesID)
                                                //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                                if (!filteredSolutioningContest || filteredSolutioningContest?.length == 0) {
                                                    setSpinnerDataOnScreen(false);
                                                    history.push("/unautherised-user");
                                                }
                                                if (filteredSolutioningContest?.length == 1) {
                                                    setSpinnerDataOnScreen(false);
                                                    history.push(`/view-contest-homepage-solutioning/${filteredSolutioningContest[0].ContestId}`);
                                                }
                                                else {
                                                    setSpinnerDataOnScreen(false);
                                                }
                                            }
                                        })
                                    }
                                    else {
                                        setSpinnerDataOnScreen(false);
                                    }
                                })
                            }
                        }
                    });
                }
            }

                if (context.page.subPageId && context.page.subPageId != "" && context.page.subPageId?.length > 0 &&
                    context.page.subPageId == "ioty") {
                    setSpinnerDataOnScreen(true);
                    if (enterpriseId) {
                        getData(enterpriseId).then((res) => {
                            if (res) {
                                if (res && res["ResponseCode"]) {
                                    setSpinnerDataOnScreen(false);
                                    if (res["ResponseCode"] === "107" || res["ResponseCode"] === "109") {
                                        history.push("/unautherised-user");
                                    }
                                }
                                else if (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() == "" || (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() !== "" && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] !== null && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] == "92149")) {
                                    dispatch(setIsContractorLoggedin(true));
                                    setContractorCancel(true);
                                    //history.push("/unautherised-user");
                                    //history.push(`/view-contest-homepage-ioty/${sustainabilityContestID}`);
                                    getChallengeNContestByDemographicFromTeamsContractor(
                                        token,
                                        res["value"][0].PeopleKey,
                                        res["value"][0]["CountryName"],
                                        [],
                                        false,
                                        false,
                                        true
                                    ).then((response) => {
                                        if (response) {

                                            //   console.log("contractor response-->",response);
                                            let filteredIOTYForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === IOTYContestID);
                                            //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                            if (!filteredIOTYForContractor || filteredIOTYForContractor?.length == 0) {
                                                setSpinnerDataOnScreen(false);
                                                history.push("/unautherised-user");
                                            }
                                            if (filteredIOTYForContractor?.length == 1) {
                                                setSpinnerDataOnScreen(false);
                                                history.push(`/view-contest-homepage-ioty/${filteredIOTYForContractor[0].ContestId}`);
                                            } 
                                            else {
                                                setSpinnerDataOnScreen(false);
                                            }
                                        }
                                    })
                                }
                                else {
                                    setContractorCancel(false);

                                    getPeopleHierarchyUpload(
                                        token,
                                        res["value"][0]["OrgnisationsDetail"]
                                    ).then((res1) => {
                                        if (res1["value"]) {
                                            const orgUnits = getOrgUnit(res1["value"]);

                                            getChallengeNContestByDemographicFromTeams(
                                                "",
                                                res["value"][0].PeopleKey,
                                                res["value"][0]["CountryName"],
                                                orgUnits,
                                                false,
                                                false,
                                            ).then((response) => {
                                                if (response) {

                                                    //   console.log("contractor response-->",response);
                                                    let filteredIOTYContests = response['Contests'].filter((contestsData) => contestsData.ContestId === IOTYContestID)
                                                    //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                                    if (!filteredIOTYContests || filteredIOTYContests?.length == 0) {
                                                        setSpinnerDataOnScreen(false);
                                                        history.push("/unautherised-user");
                                                    }
                                                    if (filteredIOTYContests?.length == 1) {
                                                        setSpinnerDataOnScreen(false);
                                                        history.push(`/view-contest-homepage-ioty/${filteredIOTYContests[0].ContestId}`);
                                                    }
                                                    else {
                                                        setSpinnerDataOnScreen(false);
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            setSpinnerDataOnScreen(false);
                                        }
                                    })
                                }
                            }
                        });
                    }
                }

                if (context.page.subPageId && context.page.subPageId != "" && context.page.subPageId?.length > 0 &&
                    (context.page.subPageId == "V360" || context.page.subPageId == "V360FY24")) {
                    setSpinnerDataOnScreen(true);
                    if (enterpriseId) {
                        getData(enterpriseId).then((res) => {
                            if (res) {
                                if (res && res["ResponseCode"]) {
                                    setSpinnerDataOnScreen(false);
                                    if (res["ResponseCode"] === "107" || res["ResponseCode"] === "109") {
                                        history.push("/unautherised-user");
                                    }
                                }
                                else if (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() == "" || (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() !== "" && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] !== null && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] == "92149")) {
                                    setSpinnerDataOnScreen(false);
                                    dispatch(setIsContractorLoggedin(true));
                                    getConfigurationList('').then((response) => {
                                        if (response) {
                                            for (const obj of response["value"]) {
                                                for (const Key in obj) {
                                                    if (obj["Key"] === "v360ContestIdFY2023" && context.page.subPageId == "V360") {
                                                        let v360ContestId = obj["Value"];
                                                        dispatch(setV360ContestIdFy23(v360ContestId));
                                                        history.push(`/view-contest-homepage-v360/${v360ContestId}`);                                                        
                                                    }
                                                    if (obj["Key"] === "v360ContestIdFY2024" && context.page.subPageId == "V360FY24") {
                                                        let v360ContestId = obj["Value"];
                                                        dispatch(setV360ContestIdFy24(v360ContestId));
                                                        history.push(`/view-contest-homepage-v360/${v360ContestId}`);                                                        
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                                else {
                                    setContractorCancel(false);
                                    getConfigurationList('').then((response) => {
                                        if (response) {
                                            for (const obj of response["value"]) {
                                                for (const Key in obj) {
                                                    if (obj["Key"] === "v360ContestIdFY2023" && context.page.subPageId == "V360") {
                                                        let v360ContestId = obj["Value"];
                                                        history.push(`/view-contest-homepage-v360/${v360ContestId}`);                                                        
                                                    }
                                                    if (obj["Key"] === "v360ContestIdFY2024" && context.page.subPageId == "V360FY24") {
                                                        let v360ContestId = obj["Value"];
                                                        history.push(`/view-contest-homepage-v360/${v360ContestId}`);                                                        
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }

                
                if(context.page.subPageId && context.page.subPageId !="" && context.page.subPageId?.length > 0 && prefixValues[0] && prefixValues[1] &&
                prefixValues[0] == "DYNAMICIDEASUBMITFORM"){
                    if(prefixValues[1]){
                        history.push({
                            pathname: `/dynamic-form/${prefixValues[1]}`             
                            
                        });
                    }  
                }

                if(context.page.subPageId && context.page.subPageId !="" && context.page.subPageId?.length > 0 && prefixValues[0] && prefixValues[1] &&
                prefixValues[0] == "GLCEXPLOREIDEAS"){
                    if(prefixValues[1]){
                      
                            
                              
                                getContestByContestId(prefixValues[1]).then(responseContest => {
                                    if (responseContest) {
                                        // setContest(response);  
                                    
                                        
                                       getChallengeByContestId(prefixValues[1]).then((response)=>{
                                            if(response && response!=undefined){

                        

                                            if (response && response["value"] && response["value"]?.length > 0) {
                                
                                                let challenges= response["value"].filter(challenge=> challenge.ChallengeStatus.toLowerCase()=="published").map(challenge => ({
                                                        ChallengeId: challenge.ChallengeId,
                                                        ChallengeName: challenge.ChallengeNm,
                                                        ChallengeDescription: challenge.ChallengeDesc,
                                                        ChallengeIdeaCount: challenge.ChallengeIdeaSubmittedCount,
                                                        ChallengeContestName: challenge.ContestNm,
                                                        ChallengeIcon: '',
                                                    }));
    
    
    
                                                    const selectedChallenges = challenges?.map((topic) => { return topic.ChallengeId });
                                                    const challengesWithIdName = challenges?.map((item) => ({ value: item.ChallengeId, label: item.ChallengeName }));
                                                    if(responseContest && responseContest!=undefined && responseContest!="" && selectedChallenges && challengesWithIdName ){
                                                        history.push({
                                                            pathname: '/exploreideas',
                                                            state: { categoryIds: [], contestIds: [prefixValues[1]], challengeIds: selectedChallenges, fromActiveContest: true, challengesWithIdName: challengesWithIdName, contestName: responseContest.ContestNm , fromFlexibleIdeaHomepage: true }           
                                                             
                                                         });
                                                    }

                                                }
                                    
                                    
                                            };
                                        })
                                        
                    
                                        
                                    }
                    
                                })
                            
                       
                    }  
                }

                if(context.page.subPageId && context.page.subPageId !="" && context.page.subPageId?.length > 0 && prefixValues[0] && prefixValues[1] &&
                prefixValues[0] == "FORCE1"){
                    if(prefixValues[1]){
                        GetContestQuickLinks(prefixValues[1], 'FORCE 1: TOTAL ENTERPRISE REINVENTION').then((response) => {
                            if (response) {
                                history.push({
                                    pathname: `/quick-link/${prefixValues[1]}`,
                                    state: { quickLink: response }
                                });
                            }
                        });
                    }  
                }
                if(context.page.subPageId && context.page.subPageId !="" && context.page.subPageId.length > 0 && prefixValues[0] && prefixValues[1] &&
                prefixValues[0] == "FORCE2"){
                    if(prefixValues[1]){
                        GetContestQuickLinks(prefixValues[1], 'FORCE 2: TALENT').then((response) => {
                            if (response) {
                                history.push({
                                    pathname: `/quick-link/${prefixValues[1]}`,
                                    state: { quickLink: response }
                                });
                            }
                        });
                    }  
                }
                if(context.page.subPageId && context.page.subPageId !="" && context.page.subPageId.length > 0 && prefixValues[0] && prefixValues[1] &&
                prefixValues[0] == "FORCE3"){
                    if(prefixValues[1]){
                        GetContestQuickLinks(prefixValues[1], 'FORCE 3: SUSTAINABILITY').then((response) => {
                            if (response) {
                                history.push({
                                    pathname: `/quick-link/${prefixValues[1]}`,
                                    state: { quickLink: response }
                                });
                            }
                        });
                    }  
                }
                if(context.page.subPageId && context.page.subPageId !="" && context.page.subPageId.length > 0 && prefixValues[0] && prefixValues[1] &&
                prefixValues[0] == "FORCE4"){
                    if(prefixValues[1]){
                        GetContestQuickLinks(prefixValues[1], 'FORCE 4: METAVERSE').then((response) => {
                            if (response) {
                                history.push({
                                    pathname: `/quick-link/${prefixValues[1]}`,
                                    state: { quickLink: response }
                                });
                            }
                        });
                    }  
                }
                if(context.page.subPageId && context.page.subPageId !="" && context.page.subPageId.length > 0 && prefixValues[0] && prefixValues[1] &&
                prefixValues[0] == "FORCE5"){
                    if(prefixValues[1]){
                        GetContestQuickLinks(prefixValues[1], 'FORCE 5: ONGOING TECH REVOLUTION').then((response) => {
                            if (response) {
                                history.push({
                                    pathname: `/quick-link/${prefixValues[1]}`,
                                    state: { quickLink: response }
                                });
                            }
                        });
                    }  
                }


                // 
                //navigate to private idea details
                if (
                    context.page.subPageId.length > 0 &&
                    prefixValues[0] == "PRIVATEIDEADETAILS"
                ) {
                    history.push({
                        pathname: "/viewprivateidea",
                        state: { ideaId: prefixValues[1].trim() },
                    });
                }
                if (
                    context.page.subPageId.length > 0 &&
                    prefixValues[0] == "IDEADETAILS"
                ) {
                    getChallengeTypeAndOrgUnitByChallengeId(prefixValues[1].trim(), enterpriseId).then((resp:any)=>{
                        if(resp==true || resp==false){
                            getIdeasByIdeaIdWithIsValidUser(prefixValues[1].trim(), false, false,resp).then((ideaData) => {
                                if (!ideaData["ResponseCode"]) {
                                    if (ideaData['IsDynamicIdea']) {
                                        if (ideaData['ContestNm']?.toLowerCase().includes("v360")) {
                                            history.push({
                                                pathname: '/view-dynamic-idea',
                                                state: { detail: prefixValues[1].trim(), contestNm: ideaData['ContestNm'], contestId: ideaData['ContestId'] }
                                            });
                                        }
                                        else {
                                            console.log("dynamic-idea-details-view from view idea 1", ideaData);
                                            history.push({
                                                pathname: '/dynamic-idea-details-view',
                                                state: { detail: prefixValues[1].trim(), contestNm: ideaData['ContestNm'], contestId: ideaData['ContestId'] }
                                            });
                                        }
                                        //history.push({
                                        //    pathname: "/view-dynamic-idea",
                                        //    state: { detail: prefixValues[1].trim(), contestNm: ideaData['ContestNm'], contestId: ideaData['ContestId'] }
                                        //});
                                    } else {
                                        history.push({
                                            pathname: "/view-idea/" + prefixValues[1].trim(),
                                        });
                                    }
                                }
                            });
                        }
                    })

                   
                }
                if (
                    context.page.subPageId.length > 0 &&
                    prefixValues[0] == "IDEADETAILSEDIT"
                ) {
                    getChallengeTypeAndOrgUnitByChallengeId(prefixValues[1].trim(), enterpriseId).then((resp:any)=>{
                        if(resp==true || resp==false){
                            getIdeasByIdeaIdWithIsValidUser(prefixValues[1].trim(), false, false,resp).then((ideaData) => {
                                if (!ideaData["ResponseCode"]) {
                                    if (ideaData['IsDynamicIdea']) {
                                        console.log("dynamic-idea-details-view from view idea 2", ideaData);
                                        history.push({
                                            pathname: '/dynamic-idea-details-view',
                                            state: { detail: prefixValues[1].trim(), contestNm: ideaData['ContestNm'], contestId: ideaData['ContestId'] }
                                        });
                                    } else {
                                        history.push({
                                            pathname: "/view-idea/" + prefixValues[1].trim(),
                                        });
                                    }
                                }
                            });
                        }
                    })
                    
                  
                    
                }
                if (
                    context.page.subPageId.length > 0 &&
                    prefixValues[0] == "IDEADETAILSFORM"
                ) {
                    getChallengeTypeAndOrgUnitByChallengeId(prefixValues[1].trim(), enterpriseId).then((resp:any)=>{
                        if(resp==true || resp==false){
                            getIdeasByIdeaIdWithIsValidUser(prefixValues[1].trim(), false, false, resp).then((ideaData) => {
                                if (!ideaData["ResponseCode"]) {
                                    if (ideaData['IsDynamicIdea']) {
                                        console.log("dynamic-idea-details-view from view idea 3", ideaData);
                                        history.push({
                                            pathname: '/dynamic-idea-details-view',
                                            state: { detail: prefixValues[1].trim(), contestNm: ideaData['ContestNm'], contestId: ideaData['ContestId'] }
                                        });
                                    } else {
                                        history.push({
                                            pathname: "/view-idea/" + prefixValues[1].trim(),
                                        });
                                    }
                                }
                            });
                        }
                    })
                    
                }
                if (
                    context.page.subPageId.length > 0 &&
                    prefixValues[0] == "IMPLEMENTORIDEADETAILS"
                ) {
                    history.push({
                        pathname: "/view-idea",
                        state: {
                            ideaId: prefixValues[1].trim(),
                            enterprise: prefixValues[2].trim(),
                            role: "implementer",
                            isFromImplementerCard: true,
                        },
                    });
                }
                if (
                    context.page.subPageId.length > 0 &&
                    prefixValues[0] == "MENTORAPPROVAL"
                ) {
                    getChallengeTypeAndOrgUnitByChallengeId(prefixValues[1].trim(), enterpriseId).then((resp:any)=>{
                        if(resp==true || resp==false){
                            getIdeasByIdeaIdWithIsValidUser(prefixValues[1].trim(), false, false, resp).then((ideaData) => {
                                if (!ideaData["ResponseCode"]) {
                                    if (ideaData['IsDynamicIdea']) {      
                                        console.log("dynamic-idea-details-view from view idea 4", ideaData);                          
                                        history.push({
                                            pathname: '/dynamic-idea-details-view',
                                            state: { detail: prefixValues[1].trim(), contestNm: ideaData['ContestNm'], contestId: ideaData['ContestId'], role: 'mentor' }
                                        });
                                    } else {
                                        history.push({
                                            pathname: "/view-idea",
                                            state: { ideaId: prefixValues[1].trim(), role: "mentor" },
                                        });
                                    }
                                }
                            });
                        }
                    })

                    


                   
                }
                if (context.page.subPageId.length > 0 && prefixValues[0] == "PATENT") {
                    let ideaId = prefixValues[1]?.trim();
                    let patentId = prefixValues[3]?.trim();
                    let status = prefixValues[4]?.trim();
                    let ideaNumber = prefixValues[2]?.trim();
                    if (isMobile) {
                        history.push({
                            pathname: "/submitPatentIdeaMobile",
                            state: { ideaId, patentId, status, ideaNumber },
                        });
                    }
                    else {
                        history.push({
                            pathname: "/submitPatentIdea",
                            state: { ideaId, patentId, status, ideaNumber },
                        });
                    }

                }
                if (
                    context.page.subPageId.length > 0 &&
                    prefixValues[0] == "MSGEXTPRIVATEIDEASHAREDETAILS"
                ) {
                    let email = prefixValues[2]?.trim();
                    if (email != null && email != '') {
                        if (email !== enterpriseId) {
                            addPrivateIdeaShareUserDetails(prefixValues[1].trim(), [enterpriseId], '', 'true').then(response => { })
                        }
                    }
                    history.push({
                        pathname: "/viewprivateidea",
                        state: { ideaId: prefixValues[1].trim() },
                    });
                }
                if (context.page.subPageId.length > 0 && prefixValues[0] == "MSGEXTSHAREDETAILS") {
                    let ideaId = prefixValues[1]?.trim();
                    let implentors = prefixValues[2]?.trim();
                    let coInnovators = prefixValues[3]?.trim();
                    let mentor = prefixValues[4]?.trim();
                    let email = prefixValues[5]?.trim();

                    getChallengeTypeAndOrgUnitByChallengeId(prefixValues[1].trim(), enterpriseId).then((resp:any)=>{
                        if(resp==true || resp==false){
                            getIdeasByIdeaIdWithIsValidUser(prefixValues[1]?.trim(), false, false,resp).then((ideaData) => {
                                if (!ideaData["ResponseCode"]) {
                                    if (coInnovators != null && coInnovators != '' && email != null && email != '') {
                                        if (email !== enterpriseId && !coInnovators.includes(email) && !coInnovators.includes(enterpriseId)) {
                                            addIdeaShareUserDetails(prefixValues[1]?.trim(), [enterpriseId], '', "true").then(response => {
                                            });
                                        }
                                    }
                                   else if (email != null && email != '') {
                                        if (email !== enterpriseId) {
                                            addIdeaShareUserDetails(prefixValues[1]?.trim(), [enterpriseId], '', "true").then(response => {
                                            });
                                        }
                                    }
                                }
                            });
                        }
                    })

                   


                    if (mentor && mentor != null && mentor != "" && mentor == enterpriseId) {
                        history.push({
                            pathname: "/view-idea",
                            state: { ideaId: ideaId, role: "mentor" },
                        });
                    }
                    else if (implentors && implentors != null && implentors != "" && implentors.indexOf(enterpriseId) >= 0) {
                        history.push({
                            pathname: "/view-idea",
                            state: {
                                ideaId: ideaId,
                                enterprise: enterpriseId,
                                role: "implementer",
                                isFromImplementerCard: true,
                            },
                        });
                    }
                    else {
                        history.push({
                            pathname: "/view-idea/" + ideaId,
                        });
                    }


                }
                if (context.page.subPageId.length > 0 && prefixValues[0] == "MSGEXTDETAILS") {
                    let ideaId = prefixValues[1]?.trim();
                    let implentors = prefixValues[2]?.trim();
                    let coInnovators = prefixValues[3]?.trim();
                    let mentor = prefixValues[4]?.trim();
                    if (mentor && mentor != null && mentor != "" && mentor == enterpriseId) {
                        history.push({
                            pathname: "/view-idea",
                            state: { ideaId: ideaId, role: "mentor" },
                        });
                    }
                    else if (implentors && implentors != null && implentors != "" && implentors.indexOf(enterpriseId) >= 0) {
                        history.push({
                            pathname: "/view-idea",
                            state: {
                                ideaId: ideaId,
                                enterprise: enterpriseId,
                                role: "implementer",
                                isFromImplementerCard: true,
                            },
                        });
                    }
                    else {
                        history.push({
                            pathname: "/view-idea/" + ideaId,
                        });
                    }


                }
                if (
                    context.page.subPageId.length > 0 &&
                    context.page.subPageId == "details"
                ) {
                    history.push({
                        pathname: "/view-idea/5a7ea19f-9d97-463f-a3ce-564613557238",
                    });
                }
            }
           
        });
    }

    useEffect(() => {
        if (filterData.allFilters.length > 0) {
            getFilteredData(filterData);
        } else {
            setFilteredDataGot([]);
        }
    }, [filterData]);

    useEffect(() => {
        getPinnedDataDetails(orgUnitsState);
    }, [pinnedDataState]);

    useEffect(() => {
        getContestDataDetails();
    }, [contestDataState]);

    useEffect(() => {
        getSharedIdeas();
    }, [sharedDataState]);

    useEffect(() => {
        getPrivateIdeas();
    }, [privateIdeasState]);

    useEffect(() => {
        if (allIdeasState && contestIds.length && challengeIds.length) {
            setDataLoader(true);
            setFirstFeedData([]);
            getAllIdeaData(contestIds, orgUnitsState, [], true);
            //getAllIdeaDataRefresIdeaFeed();
        }
    }, [allIdeasState]);

    //useEffect(() => {
    //    if (token != '' && Object.keys(token).length !== 0 && name) {
    //        getUserExpertise();
    //    }
    //}, [expertiseDataStore, token, name])

    //useEffect(() => {
    //    if (token != '' && Object.keys(token).length !== 0 && name) {
    //        getUserPrefrence();
    //    }
    //}, [preferenceDataStore, token, name])

    useEffect(() => {
        if (expertiseDataStore) {
            getUserExpertise();
        }
    }, [expertiseDataStore]);

    useEffect(() => {
        if (preferenceDataStore) {
            getUserPrefrence();
        }
    }, [preferenceDataStore]);

    useEffect(() => {
        setAllpromotionalData([]);
        getPopularIdeasDetails();
    }, [popularData]);

    useEffect(() => {
        setAllPopulaIdeas([]);
        getPromotionalIdeas(orgUnitsState);
    }, [promotionalDataState]);

    useEffect(() => {
        if (navigatedFromActivity) {
            initializeTeam();
        }
    }, [navigatedFromActivity]);

    useEffect(() => {
        if (token != "" && Object.keys(token).length !== 0) {
            //if (refreshNeeded) {
            if (
                loginNameState == "" &&
                profilePicState == "" &&
                orgUnitsState == "" &&
                peopleKeyState == ""
            ) {

                console.log("theme on view-idea page", theme);
                getData(enterpriseId).then((res) => {
                    if (res) {
                        if (res && res["ResponseCode"]) {
                            if (res["ResponseCode"] === "107" || res["ResponseCode"] === "109") {
                                history.push("/unautherised-user");
                            }
                        }
                        else if (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() == "" || (res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"].trim() !== "" && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] !== null && res["value"][0]["OrgnisationsDetail"][0]["OrgUnitId"] == "92149")) {
                            dispatch(setIsContractorLoggedin(true));
                            setContractorCancel(true);
                            // getContestAndChallenge(token, peopleKeyState, userCountryNameState, orgUnitsState, true);
                            
                            getChallengeNContestByDemographicFromTeamsContractor(
                                token,
                                res["value"][0].PeopleKey,
                                res["value"][0]["CountryName"],
                                [],
                                false,
                                false,
                                true
                            ).then((response) => {
                                if(response){
                                 getConfigurationList('').then((configResponse) => {
                                     if (configResponse) {
                                        let contractorContests = [];
                                        let filteredV360ContestsForContractor=[];
                                        let filteredV360ContestsFY23ForContractor=[];
                                        let filteredV360ContestsFY24ForContractor=[];
                                         for (const obj of configResponse["value"]) {
                                             for (const Key in obj) {
                                                 if (obj["Key"] === "v360contestid") {
                                                    let v360ContestId = obj["Value"];
                                                      dispatch(setV360ContestId(v360ContestId));
                                                      filteredV360ContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === v360ContestId);
                                                 }
                                                 if(obj["Key"] === "v360ContestIdFY2023"){
                                                    let Fy23V360ContestId = obj["Value"]
                                                    dispatch(setV360ContestIdFy23(Fy23V360ContestId));
                                                    filteredV360ContestsFY23ForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === Fy23V360ContestId);
                                                 }
                                                 if(obj["Key"] === "v360ContestIdFY2024"){
                                                    let Fy24V360ContestId = obj["Value"]
                                                    dispatch(setV360ContestIdFy24(Fy24V360ContestId));
                                                    filteredV360ContestsFY24ForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === Fy24V360ContestId);
                                                 }
                                             }
                                         }

                                        //  let filteredV360ContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === v360ContestId);
                                         let filteredGTICContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestNm.toLowerCase().includes("global tech innovation contest season"))
                                         let filteredSustainabilityContestsForContractor = [{ ContestId: sustainabilityContestID, ContestNm: 'Sustainability Innovation Challenge 2023' }];
                                         let filteredIOTYContestsForContractor = response['Contests'].filter((contestsData) => contestsData.ContestId === IOTYContestID);
                                         //   console.log("contractor filtred contests",filteredGTICContestsForContractor)
                                         if ((!filteredGTICContestsForContractor || filteredGTICContestsForContractor?.length == 0) &&
                                             (!filteredV360ContestsForContractor || filteredV360ContestsForContractor?.length == 0) &&
                                             (!filteredV360ContestsFY23ForContractor || filteredV360ContestsFY23ForContractor?.length ==0) &&
                                             (!filteredV360ContestsFY24ForContractor || filteredV360ContestsFY24ForContractor?.length ==0) &&
                                             (!filteredSustainabilityContestsForContractor || filteredSustainabilityContestsForContractor?.length == 0) &&
                                             (!filteredIOTYContestsForContractor || filteredIOTYContestsForContractor?.length == 0)) {
                                             history.push("/unautherised-user");
                                         }
                                         if (filteredGTICContestsForContractor?.length == 1 &&
                                             filteredV360ContestsForContractor?.length == 0 &&
                                             filteredV360ContestsFY23ForContractor?.length==0 &&
                                             filteredV360ContestsFY24ForContractor?.length==0 &&
                                             filteredSustainabilityContestsForContractor?.length == 0 &&
                                             filteredIOTYContestsForContractor?.length == 0) {
                                             history.push(`/view-contest-homepage-gtic/${filteredGTICContestsForContractor[0].ContestId}`);

                                         }
                                         else if (filteredGTICContestsForContractor?.length == 0 &&
                                             ((filteredV360ContestsForContractor?.length == 1 &&
                                                filteredV360ContestsFY23ForContractor?.length == 0 &&                                             
                                                filteredV360ContestsFY24ForContractor?.length==0) ||  
                                                    (filteredV360ContestsFY23ForContractor?.length == 1 && 
                                                        filteredV360ContestsForContractor?.length == 0 &&                                             
                                                        filteredV360ContestsFY24ForContractor?.length==0) ||  
                                                            (filteredV360ContestsFY23ForContractor?.length == 0 && 
                                                                filteredV360ContestsForContractor?.length == 0 &&                                             
                                                                filteredV360ContestsFY24ForContractor?.length==1))&&
                                             filteredSustainabilityContestsForContractor?.length == 0 &&
                                             filteredIOTYContestsForContractor?.length == 0) {
                                                let id = filteredV360ContestsForContractor?.length == 1 ? filteredV360ContestsForContractor[0].ContestId : 
                                                            filteredV360ContestsFY23ForContractor?.length == 1 ? filteredV360ContestsFY23ForContractor[0].ContestId : 
                                                                filteredV360ContestsFY24ForContractor?.length == 1 ? filteredV360ContestsFY24ForContractor[0].ContestId : null;
                                                if(id){
                                                    history.push(`/view-contest-homepage-v360/${id}`);
                                                }           
                                                else{
                                                    history.push("/unautherised-user");
                                                } 
                                         }
                                         else if (filteredGTICContestsForContractor?.length == 0 &&
                                             filteredV360ContestsForContractor?.length == 0 &&
                                             filteredV360ContestsFY23ForContractor?.length==0 &&                                             
                                             filteredV360ContestsFY24ForContractor?.length==0 &&
                                             filteredSustainabilityContestsForContractor?.length == 1 &&
                                             filteredIOTYContestsForContractor?.length == 0) {
                                             history.push(`/view-contest-homepage-sustainability/${filteredSustainabilityContestsForContractor[0].ContestId}`);
                                         }
                                         else if (filteredGTICContestsForContractor?.length == 0 &&
                                             filteredV360ContestsForContractor?.length == 0 &&
                                             filteredV360ContestsFY23ForContractor?.length==0 &&                                             
                                             filteredV360ContestsFY24ForContractor?.length==0 &&
                                             filteredSustainabilityContestsForContractor?.length == 0 &&
                                             filteredIOTYContestsForContractor?.length == 1) {
                                             history.push(`/view-contest-homepage-ioty/${filteredIOTYContestsForContractor[0].ContestId}`);
                                         }
                                         else {                                                
                                             
                                             if (filteredGTICContestsForContractor?.length > 0) {
                                                 filteredGTICContestsForContractor.forEach((data) => {
                                                     contractorContests.push({ value: data.ContestId, label: data.ContestNm })
                                                 });
                                             }
                                             if (filteredV360ContestsForContractor?.length > 0) {
                                                 filteredV360ContestsForContractor.forEach((data) => {
                                                     contractorContests.push({ value: data.ContestId, label: data.ContestNm })
                                                 });
                                             }
                                             if (filteredV360ContestsFY23ForContractor?.length > 0) {
                                                filteredV360ContestsFY23ForContractor.forEach((data) => {
                                                    contractorContests.push({ value: data.ContestId, label: data.ContestNm })
                                                });
                                             }
                                             if (filteredV360ContestsFY24ForContractor?.length > 0) {
                                                filteredV360ContestsFY24ForContractor.forEach((data) => {
                                                    contractorContests.push({ value: data.ContestId, label: data.ContestNm })
                                                });
                                             }
                                             if (filteredSustainabilityContestsForContractor?.length > 0) {
                                                 filteredSustainabilityContestsForContractor.forEach((data) => {
                                                     contractorContests.push({ value: data.ContestId, label: data.ContestNm })
                                                 });
                                             }
                                             if (filteredIOTYContestsForContractor?.length > 0) {
                                                 filteredIOTYContestsForContractor.forEach((data) => {
                                                     contractorContests.push({ value: data.ContestId, label: data.ContestNm })
                                                 });
                                            }
                                                 if (contractorContests?.length > 0) {
                                                    setContractorContestPop(true);
                                                    setGTICContractorContests(contractorContests);
                                                }
                                                else {
                                                    history.push("/unautherised-user");
                                                }

                                             }                                
                                     }
                                     else {
                                         history.push("/unautherised-user");
                                     }
                                 });                                
                                }
                            })
                            // getConfigurationList(token).then((response) => {
                            //     if (response) {
                            //         for (const obj of response["value"]) {
                            //             for (const Key in obj) {
                            //                 if (obj["Key"] === "v360contestid") {
                            //                     let v360ContestId = obj["Value"];
                            //                     history.push(`/view-contest-homepage-v360/${v360ContestId}`);
                            //                 }
                            //             }
                            //         }
                            //     }
                            // });                            
                        }
                        else {
                            const loginName =
                                res["value"][0]["ForeignFirstName"] +
                                " " +
                                res["value"][0]["ForeignLastName"];
                            dispatch(setLoginName(loginName));
                            const userCountry = res["value"][0]["CountryName"];
                            dispatch(setPeopleDetails(res["value"][0]));
                            dispatch(setUserCountryName(res["value"][0]["CountryName"]));
                            setUserCountryNameStateAll(res["value"][0]["CountryName"])
                            setName(loginName);

                            getProfilePic();
                            getUserDetails();

                            getPeopleHierarchyUpload(
                                token,
                                res["value"][0]["OrgnisationsDetail"]
                            ).then((res1) => {                                
                                if (res1 && res1["value"]) {
                                    // start active contest
                                    //getChallengeNContestByDemographicFromTeams(token, res['value'][0].PeopleKey, orgUnits, true).then((response) => {

                                    //    let sortedActiveContests = response['Contests']
                                    //        .filter(contest => contest.ContestType && contest.ContestType.toLowerCase() === 'public'
                                    //            && contest.ContestNm.trim() !== ''
                                    //            && contest['ChallengeCount'] > 0
                                    //            && contest['ContestIsActive']
                                    //            && contest.IsIdeaSubmissionClosed === false)
                                    //        .sort(function (left, right) {
                                    //            return moment.utc(left.EndDate, 'DD/MM/YYYY').diff(moment.utc(right.EndDate, 'DD/MM/YYYY'))
                                    //        });

                                    //    sortedActiveContests.length === 0 ? setActiveContests(["No Active Contest Available"]) : setActiveContests(sortedActiveContests);

                                    //});
                                    // // end active contest
                                    const orgUnits = getOrgUnit(res1["value"]);
                                    dispatch(setOrgUnits(orgUnits));
                                    dispatch(setPeopleKey(res["value"][0].PeopleKey));
                                    const ideaCreationDataAll = [
                                        ...convertIntoIdeaCreatorHierarchy(res1["value"][0]),
                                    ];
                                    dispatch(IdeaCreationData([...ideaCreationDataAll]));
                                    setIdeaCreationData([...ideaCreationDataAll]);
                                    getFirstFeedLoad(orgUnits);
                                    getContestAndChallenge(
                                        token,
                                        res["value"][0].PeopleKey,
                                        res["value"][0]["CountryName"],
                                        orgUnits,
                                        true
                                    );
                                    saveUniqueUserDetails(res["value"][0], res1["value"][0]);
                                }
                                else {
                                    history.push("/unautherised-user");
                                }
                            });
                            //saveUniqueUserDetails(res);              
                        }
                    }
                });
            } else {
                //getProfilePic();
                //getContestAndChallenge(token, peopleKeyState, orgUnitsState,true);
                //getIdeaPendingCountMentor();
                //getPatentPendingCountMentor();
                //saveUniqueUserDetails(res);
                //allIdeaFeedState.length && !refreshNeeded ? setIdeas(allIdeaFeedState) : null;
                if (peopleDetails != '' && orgUnitsState != '' && refreshNeeded) {
                    saveUniqueUserDetails(peopleDetails, orgUnitsState[0]);
                }
                if (refreshNeeded) {
                    getFirstFeedLoad(orgUnitsState);

                    getContestAndChallenge(token, peopleKeyState, userCountryNameState, orgUnitsState, true);
                }
                getUserDetails();
            }
        }
    }, [token, enterpriseId]);

    const saveUniqueUserDetails = (userData: any, peopleHierarchyDetails: any) => {
        const saveUniqueUserPayload = {
            EnterpriseId: enterpriseId,
            PeopleKey: parseInt(userData?.PeopleKey),
            AppNm: "MS Teams",
            UserCountryNm: userData["CountryName"]
                ? userData["CountryName"]
                : "",
            UserCareerLevel: userData["JobCode"] ? userData["JobCode"] : "",
            OrganizationLevel1Id: peopleHierarchyDetails.OrganizationLevel1Id,
            OrganizationLevel2Id: peopleHierarchyDetails.OrganizationLevel2Id,
            OrganizationLevel3Id: peopleHierarchyDetails.OrganizationLevel3Id,
            OrganizationLevel4Id: peopleHierarchyDetails.OrganizationLevel4Id,
            OrganizationLevel5Id: peopleHierarchyDetails.OrganizationLevel5Id,
            OrganizationLevel6Id: peopleHierarchyDetails.OrganizationLevel6Id,
            OrganizationLevel7Id: peopleHierarchyDetails.OrganizationLevel7Id,
            OrganizationLevel8Id: peopleHierarchyDetails.OrganizationLevel8Id,
            OrganizationLevel9Id: peopleHierarchyDetails.OrganizationLevel9Id,
            OrganizationLevel10Id: peopleHierarchyDetails.OrganizationLevel10Id,
            OrganizationLevel11Id: peopleHierarchyDetails.OrganizationLevel11Id,
            OrganizationLevel12Id: peopleHierarchyDetails.OrganizationLevel12Id,
            OrganizationLevel1Description: peopleHierarchyDetails.OrganizationLevel1Description,
            OrganizationLevel2Description: peopleHierarchyDetails.OrganizationLevel2Description,
            OrganizationLevel3Description: peopleHierarchyDetails.OrganizationLevel3Description,
            OrganizationLevel4Description: peopleHierarchyDetails.OrganizationLevel4Description,
            OrganizationLevel5Description: peopleHierarchyDetails.OrganizationLevel5Description,
            OrganizationLevel6Description: peopleHierarchyDetails.OrganizationLevel6Description,
            OrganizationLevel7Description: peopleHierarchyDetails.OrganizationLevel7Description,
            OrganizationLevel8Description: peopleHierarchyDetails.OrganizationLevel8Description,
            OrganizationLevel9Description: peopleHierarchyDetails.OrganizationLevel9Description,
            OrganizationLevel10Description: peopleHierarchyDetails.OrganizationLevel10Description,
            OrganizationLevel11Description: peopleHierarchyDetails.OrganizationLevel11Description,
            OrganizationLevel12Description: peopleHierarchyDetails.OrganizationLevel12Description
        };
        SaveUniqueUser(saveUniqueUserPayload).then((resp) => {
            if (resp) {
            } else {
                //console.log("Error is saving unique user data");
            }
        });
    };

    const getIndustryDetails = () => {
        getConfigurationList(token).then((response) => {
            if (response) {
                for (const obj of response["value"]) {
                    for (const Key in obj) {
                        if (obj["Key"] === "Industry") {
                            const industry = obj["Value"]?.split(",")?.sort();
                            dispatch(setIndustryData(Array.from(industry)));
                            setIndustry(Array.from(industry));
                        }
                        if (obj["Key"] === "v360contestid") {
                            let v360ContestId = obj["Value"];
                            dispatch(setV360ContestId(v360ContestId));
                        }
                        if(obj["Key"] === "v360ContestIdFY2023"){
                            let Fy23V360ContestId = obj["Value"]
                            dispatch(setV360ContestIdFy23(Fy23V360ContestId));
                        }
                        if(obj["Key"] === "v360ContestIdFY2024"){
                            let Fy24V360ContestId = obj["Value"]
                            dispatch(setV360ContestIdFy24(Fy24V360ContestId));
                        }
                    }
                }
            }
        });
    };

    const getTrendingCategories = () => {
        getTrendingTopics("public").then((response) => {
            if (response && response["value"]) {
                dispatch(setTrendingCategoriesData(response["value"]));
                setTrendingCategories(response["value"]);
            }
        });
    };

    const getTechnologyDetails = () => {
        technologyDetails().then((response) => {
            if (response !== undefined) {
                let sortedCateList = response["categoryList"]?.sort((a, b) => a.CategoryName.toLowerCase() >= b.CategoryName.toLowerCase() ? 1 : -1);
                dispatch(setTechnologyData(sortedCateList));
                setTechnologiesData(sortedCateList);
            }
        });
    };

    const getFilterDetails = () => {
        getFilterDetail().then((response) => {
            if (response) {
                dispatch(innovatorFilterData({ filters: response }));
                dispatch(setFilterData(response));
                setFilters(response);
            }
        });
    };

    //Mentor
    const getIdeaPendingCountMentor = () => {
        const obj = {
            ChallengeIds: [],
            ContestIds: [],
            Type: "Public",
        };
        getIdeaStatusMentor("Reviewer", obj).then((res) => {
            if (res !== null && res !== undefined) {
                if (res["value"] && res["value"].length > 0) {
                    const ideaStatusRibbon = res["value"];
                    ideaStatusRibbon.map((item) => {
                        if (item.IdeaStatus === "Awaiting Mentor Approval") {
                            if (item.IdeaCount) {
                                setMentorPendingCount(item.IdeaCount);
                                dispatch(setPendingIdeaCount(item.IdeaCount));
                            } else {
                                setMentorPendingCount(0);
                            }
                        }
                    });
                } else {
                    setMentorPendingCount(0);
                }
            } else {
                setMentorPendingCount(0);
            }
        });
    };

    const getPatentPendingCountMentor = () => {
        const data = {
            ChallengeIds: [],
            IdeaStatus: [""],
            Type: "Public",
        };
        const chlArr = [];
        const contestArr = [];
        const ideaStatusPendingPatent = ["Under Mentor Review"];
        const ideaStatusActionPatent = [
            "Need More Information",
            "On Hold",
            "Approved",
            "Rejected",
        ];
        const statusPatent = [
            ...ideaStatusPendingPatent,
            ...ideaStatusActionPatent,
        ];
        setSpinnerData(true);
        getChallAndContestByRole(1, "Mentor", data).then((res) => {
            if (res !== null && res !== undefined) {
                if (res["value"] !== null && res["value"] !== undefined) {
                    setSpinnerData(false);
                    res["value"]["ChallengeDetails"].forEach((item) => {
                        chlArr.push(item.ChallengeId);
                    });
                    res["value"]["ContestDetails"].forEach((item) => {
                        contestArr.push(item.ContestId);
                    });
                    const objStatusCount = {
                        ChallengeIds: chlArr,
                        ContestIds: contestArr,
                        Type: "Public",
                        Days: "All",
                        Searchkeyword: "",
                        FromDate: null,
                        ToDate: null,
                        IdeaStatus: statusPatent,
                    };

                    getIdeaStatusPatent("Reviewer", objStatusCount).then((res) => {
                        if (res && res != undefined && res != null) {
                            setPatentPendingCount(res["IdeaPendingCount"]);
                            dispatch(setPendingPatentIdeaCount(res["IdeaPendingCount"]));
                        }

                        setSpinnerData(false);
                    });
                }
            }
        });
    };

    const convertIntoIdeaCreatorHierarchy = (
        ideaCreatorHierarchyArray: any,
        ideaCreatorHierarchy?: IdeaCreatorHierarchy
    ) => {
        const arr: IdeaCreatorHierarchy[] = [];
        const currentUserHierarchyObjLength = Object.keys(
            ideaCreatorHierarchyArray
        ).length;
        let hierarchyCounter = 0;

        if (currentUserHierarchyObjLength > 0) {
            hierarchyCounter = currentUserHierarchyObjLength / 3;
        }

        for (let i = 0; i < hierarchyCounter; i++) {
            ideaCreatorHierarchy = {
                OrgUnitId: ideaCreatorHierarchyArray[
                    "OrganizationLevel" + (i + 1) + "Id"
                ]
                    ? ideaCreatorHierarchyArray["OrganizationLevel" + (i + 1) + "Id"]
                    : null,
                OrgUnitDescription: ideaCreatorHierarchyArray[
                    "OrganizationLevel" + (i + 1) + "Description"
                ]
                    ? ideaCreatorHierarchyArray[
                    "OrganizationLevel" + (i + 1) + "Description"
                    ]
                    : null,
                OrgUnitLevel: "OrganizationLevel" + (i + 1) + "Description",
            };

            if (ideaCreatorHierarchy.OrgUnitId) {
                arr.push({ ...ideaCreatorHierarchy });
            }
        }
        return arr;
    };

    useEffect(() => {
        console.log('themeString useTeams view-idea.tsx', themeString);
    }, [themeString]);

    useEffect(() => {
        if (
            token != "" &&
            Object.keys(token).length !== 0 &&
            contestsState.length > 0 &&
            challengesState.length > 0 &&
            activeContestDetailsArray.length && 
            !refreshNeeded
        ) {
            setContestChallengeDetails(contestsState, challengesState);
        }
    }, [token]);

    useEffect(() => {
        if (userCountryNameStateAll != '' || userCountryNameState != '') {
            getserialInnovator();
        }
    }, [userCountryNameStateAll, userCountryNameState]);

    const setContestChallengeDetails = (
        contestsArray: any,
        challengesArray: any
    ) => {
        console.log('>> theme  view idea page line no 1828', theme);
        const contestIdArr = contestsArray.map((contest) => {
            return contest.ContestId;
        });

        const challengeIdArr = challengesArray.map((challenge) => {
            return challenge.ChallengeId;
        });

        setContestIds([...contestIdArr]);
        setChallengeIds([...challengeIdArr]);

        //this should call first
        if (!refreshNeeded && allIdeasState) {
            getAllIdeaData(contestIdArr, orgUnitsState);
        }

        // active contest
        if (!refreshNeeded) {
            let sortedActiveContests = activeContestDetailsArray
                //.filter(
                //    (contest) =>
                //        contest.ContestType &&
                //        contest.ContestType.toLowerCase() === "public" &&
                //        contest.ContestNm.trim() !== "" &&
                //        contest["ChallengeCount"] > 0 &&
                //        contest["ContestIsActive"] &&
                //        ((contest.IsDynamicContest) || (contest.IsDynamicContest === false && contest.IsIdeaSubmissionClosed === false))
                //)
                .sort(function (left, right) {
                    return moment
                        .utc(left.EndDate, "DD/MM/YYYY")
                        .diff(moment.utc(right.EndDate, "DD/MM/YYYY"));
                });

            sortedActiveContests.length === 0
                ? setActiveContests(["No Active Contest Available"])
                : setActiveContests(sortedActiveContests);
        }
        //
        getFilterDetails();
        if (refreshNeeded) {
            getTrendingCategories();
        }
        //console.log("activeContests via local", activeContests);

        // getserialInnovator(); // keeping it outside asit depend on countryname

        //getContestfilters(challengeIdArr);
        getStatus(contestIdArr, challengeIdArr);
        getIdeaCountAsPerEachRole(challengeIdArr);

        if (refreshNeeded) {
            getTechnologyDetails();
            getIndustryDetails();
            getUserPrefrence();
            getUserExpertise();
        }
        patentIdeaCount();
        getIdeaPendingCountMentor();
        getInnovatorIdeaCount().then((response) => {
            if (response && response["value"]) {
                setInnovatorIdeaCount(response["value"]);
            } else {
            }
        });
        getPatentPendingCountMentor();
    };

    const getOrgUnit = (peopleHierarchy) => {
        let _arr = Array;
        let orgUnits: any = [];

        if (_arr.isArray(peopleHierarchy)) {
            for (var p = 0; p < peopleHierarchy.length; p++) {
                for (var i = 1; ; i++) {
                    var key = "OrganizationLevel" + i + "Id";
                    if (typeof peopleHierarchy[p][key] === "undefined") {
                        break;
                    }
                    if (parseInt(peopleHierarchy[p][key]) || 0) {
                        orgUnits.push({
                            OrganizationUnitId: peopleHierarchy[p][key],
                        });
                    }
                }
            }

            return orgUnits;
        }
    };

    const getContestAndChallenge = (
        token,
        peopleKey,
        userCountryName,
        orgUnits,
        isRefreshNeeded
    ) => {
        getChallengeNContestByDemographicFromTeams(
            token,
            peopleKey,
            userCountryName,
            orgUnits,
            false,
            true
        ).then((response) => {
            if (response["Contests"]) {
                dispatch(setActiveContestsAction(response["Contests"]))
                getContestfilters(response["Contests"]);
                // console.log("contest-->",response["Contests"]);



                const contestIdArr = response["Contests"]?.map((contest) => {
                    // console.log("contest-->",contest);
                    return contest.ContestId;
                });

                const IsExplorDisableFilteredContest = response["Contests"]?.filter(contest => !(contest.ContestNm?.toLowerCase().includes("v360")))
                                                            .filter(contest =>
                                                            (contest.IsExplorDisable && !contest.ExplorerVisibleTo && contest.ExplorerUsersValues.includes(enterpriseId)) ||
                                                            (!contest.IsExplorDisable && !contest.ExplorerVisibleTo) ||
                                                            //(contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "selectedusers" && contest.ExplorerUsersValues.includes(enterpriseId)) ||
                                                            //(contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "all"))
                                                            (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "notvisible" && (contest?.CreatedBy == enterpriseId || contest?.CoOwner?.includes(enterpriseId))) ||
                                                            (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "selectedusers" && (contest.ExplorerUsersValues.includes(enterpriseId) || contest?.CreatedBy == enterpriseId || contest?.CoOwner?.includes(enterpriseId))) ||
                                                            (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "all"))                                                            
                                                            .map((contest) => contest.ContestId);

                getAllIdeaData(contestIdArr, orgUnits, [], false, IsExplorDisableFilteredContest);
            }
        });

        getChallengeNContestByDemographicFromTeams(
            token,
            peopleKey,
            userCountryName,
            orgUnits,
            true,
            true
        ).then((response) => {
            if (response["Contests"] && response["Contests"]?.length){
                 dispatch(setActiveContestDetails(response["Contests"]))
                // console.log("contest-->",response["Contests"]);
             }
            let sortedActiveContests = response["Contests"]?.sort(function (
                left,
                right
            ) {
                return moment
                    .utc(left.EndDate, "DD/MM/YYYY")
                    .diff(moment.utc(right.EndDate, "DD/MM/YYYY"));
            });
            sortedActiveContests.length === 0
                ? setActiveContests(["No Active Contest Available"])
                : setActiveContests(sortedActiveContests);
        });

        getChallengeNContestByDemographicFromTeams(
            token,
            peopleKey,
            userCountryName,
            orgUnits,
            false,
            false
        ).then((response) => {
            const challenges: any = [];
            const contests: any = [];
            const allContests: any = [];
            if (response) {
                response["Challenges"].forEach((challenge) => {
                    if (
                        challenge.ChallengeType &&
                        challenge.ChallengeType.toLowerCase() === "public"
                    ) {
                        challenges.push(challenge);
                    }
                });

                response["Contests"].forEach((contest) => {
                    allContests.push({
                        contestId: contest.ContestId,
                        contestName: contest.ContestNm,
                    });

                    if (
                        contest.ContestType &&
                        contest.ContestType.toLowerCase() === "public" &&
                        contest.ContestNm.trim() !== ""
                    ) {
                        contests.push(contest);
                    }
                });

                if (isRefreshNeeded) {
                    setContestChallengeDetails(
                        response["Contests"],
                        response["Challenges"]
                    );
                }
            }

            //to get active 'global technology innovation contest' for promotional feed
            let promotionalContest = response["Contests"].filter(contest => {
                return ((contest['ContestNm'].toLowerCase().includes('global technology innovation contest') || contest['ContestNm'].toLowerCase().includes('global tech innovation contest') && contest['IsIdeaSubmissionClosed'] === false))
            });

            let promoContest = {};
            if (promotionalContest.length > 0) {
                promoContest = promotionalContest[0]
            } else {
                promoContest = {};
            }

            dispatch(promotionalContestData(promoContest));

            dispatch(
                setContestChallengeData({
                    responseContests: response["Contests"],
                    responseChallenges: response["Challenges"],
                })
            );
        });
    };

    const getContestfilters =  (contestIdArray) => {
        // const contestNamesArray = await getFiltersRoleWise(challengeIdArr).then(
        //     (resp) => resp["value"]["ContestDetails"]
        // );
        dispatch(innovatorFilterData({ contestFilters: contestIdArray }));
        setAllContestFilters(contestIdArray);
    };

    const getStatus = (contests, challenges) => {
        getIdeaStatus(token, contests, challenges, "public", "Innovator").then(
            (response) => {
                if (response && response["value"]) {
                    setIdeaStatus([...response["value"]]);
                    const ideaCount =
                        response["value"].filter(
                            (status) => status.IdeaStatus === "Awaiting Mentor Approval"
                        ).length > 0
                            ? response["value"].filter(
                                (status) => status.IdeaStatus === "Awaiting Mentor Approval"
                            )[0].IdeaCount
                            : 0;
                    setApprovalIdeaCount(ideaCount);
                }
            }
        );
    };

    const getIdeaCountAsPerEachRole = (challenges) => {
       const obj = {
            ContestIds: [],
            ChallengeIds: challenges,
            Type: "Public",
        };
        getIdeaStatusMentor("Innovator", obj).then((res) => {
            if (res !== null && res !== undefined) {
                if (res["value"] && res["value"].length > 0) {
                    const ideaStatusRibbon = res["value"];
                    let totalIdeaCount= 0;
                    ideaStatusRibbon.map((item) => {
                        if (item.IdeaStatus != "Draft") {
                            if (item.IdeaCount) {
                                totalIdeaCount+=item.IdeaCount
                            }
                            
                        }
                        if(item.IdeaStatus == "Awaiting Mentor Approval"){
                            if (item.IdeaCount) {
                            setPatentAwaitingCountInnovator(item.IdeaCount);
                            }   
                        }
                    });
                    dispatch(setIdeaCountAsPerRoleState(totalIdeaCount));
                    setIdeaCountAsPerRole(totalIdeaCount);
                } else{
                    setIdeaCountAsPerRole(0);
                }
            }
            else{
                setIdeaCountAsPerRole(0);
            } 
        });

        // getIdeaCountAsPerRole(challenges, "public").then((response) => {
        //     dispatch(setIdeaCountAsPerRoleState({ ...response }));
        //     setIdeaCountAsPerRole({ ...response });
        // });
    };

    const getFirstFeedLoad = (orgUnits: any) => {
        const initialFetchDate = new Date().toJSON();
        let p1 = getPrivateIdeaDetails(enterpriseId, initialFetchDate, 1);
        let p2 = getPromotionalFeed(initialFetchDate, orgUnits);
        Promise.all([p1, p2]).then((values) => {
            let tempArr = [];
            let tempFilteredPrivateArray = [];
            if (values[0] && values[0]["value"] && values[0]["value"].length > 0) {
                values[0]["value"].forEach((element) => {
                    element["isPrivateIdea"] = true;
                });
                tempArr.push(...values[0]["value"].filter((feed, index) => index < 7));
            }
            if (values[1]) {
                let promotionalFeeds = values[1]["value"];
                if (promotionalFeeds && promotionalFeeds.length > 0) {
                    promotionalFeeds.forEach((element) => {
                        element["isPromotionalFeed"] = true;
                        element["IsImplementer"] = false;
                    });
                    tempArr.push(...promotionalFeeds);
                    setTempPromotionalData(promotionalFeeds);
                }
            }
            tempFilteredPrivateArray = tempArr.sort(
                (a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate)
            );
            setFirstFeedData(tempFilteredPrivateArray);
        }).catch(err => console.log(err)) ;
    };

    const getAllIdeaData = async (
        contests,
        orgUnits,
        challenges = [],
        firstLoad = false,
        IsExplorDisableFilteredContest = []
    ) => {
        //console.log("contest state", contestsState);
        const initialFetchDate = new Date().toJSON();
        let p1 = getPrivateIdeaDetails(enterpriseId, initialFetchDate, 1);
        let p2 = getInnovatorIdeas(
            [],
            contests,
            challenges,
            enterpriseId,
            initialFetchDate,
            "desc",
            1
        );
        let p3 = popularRequests(IsExplorDisableFilteredContest.length ? IsExplorDisableFilteredContest :
                                        contestsState.filter(contest => !(contest.ContestNm?.toLowerCase().includes("v360")))
                                            .filter(contest =>
                                            (contest.IsExplorDisable && !contest.ExplorerVisibleTo && contest.ExplorerUsersValues.includes(enterpriseId)) ||
                                            (!contest.IsExplorDisable && !contest.ExplorerVisibleTo) ||
                                            //(contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "selectedusers" && contest.ExplorerUsersValues.includes(enterpriseId)) ||
                                            //(contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "all"))
                                            (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "notvisible" && (contest?.CreatedBy == enterpriseId || contest?.CoOwner?.includes(enterpriseId))) ||
                                            (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "selectedusers" && (contest.ExplorerUsersValues.includes(enterpriseId) || contest?.CreatedBy == enterpriseId || contest?.CoOwner?.includes(enterpriseId))) ||
                                            (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "all"))
                                        .map((contest) => contest.ContestId), challenges, initialFetchDate, 1, "desc", true);
        let p4 = getPromotionalFeed(initialFetchDate, orgUnits);
        let p5 = getSharedIdeasFromPrivateIdeas(enterpriseId, "desc", {
            GetIdeaTillDate: initialFetchDate,
        });
        let p6 = getIdeasForImplementer(
            enterpriseId,
            contests,
            challenges,
            initialFetchDate
        );

        Promise.all([p1, p2, p3, p4, p5, p6]).then((values) => {
            let tempArr = [];

            if (values[0] && values[0]["value"] && values[0]["value"].length > 0) {
                if (!refreshNeeded || firstLoad) {
                    values[0]["value"].forEach((element) => {
                        element["isPrivateIdea"] = true;
                    });
                    tempArr.push(...values[0]["value"]);
                    setPrivateIdeas(values[0]["value"]);
                }
            }

            if (values[1] && values[1]["value"] && values[1]["value"].length > 0) {
                dispatch(setInnovatorsData(values[1]["value"]));
                values[1]["value"].forEach((element) => {
                    element["IsImplementerIdea"] = false;
                    if (element.Implementers && element.Implementers.length > 0) {
                        if (
                            element.Implementers.find(
                                (x) => x.ImplementerId === enterpriseId
                            ) &&
                            element.Implementers.find((x) => x.ImplementerId === enterpriseId)
                                ?.Status === "UnderAcceptance"
                        ) {
                            element["IsImplementer"] = true;
                        }
                    }
                });

                tempArr.push(...values[1]["value"]);
            }

            let popularIdeas = values[2]["value"];
            if (popularIdeas && popularIdeas.length > 0) {
                popularIdeas.forEach((element) => {
                    element["isPopularIdeas"] = element['IsPopularIdea'];
                    element["IsImplementer"] = false;
                });

                // start - code for removing popular data  which is already in myidea
                let indexArr = [];
                tempArr.forEach(myideaelement => {
                    popularIdeas.forEach(popularelement => {
                        if (myideaelement["IdeaId"] === popularelement["IdeaId"]) {
                            //myideaelement['isPopularIdeas'] = false;
                            myideaelement['isPopularIdeas'] = popularelement['IsPopularIdea'];
                            indexArr.push(popularelement["IdeaId"]);
                        }
                    });
                });
                popularIdeas = popularIdeas.filter(item => !indexArr.includes(item["IdeaId"]));
                // end - code for removing popular data  which is already in myidea

                //let tempArr1 = popularIdeas; //.filter(item => item.CreatedBy != enterpriseId);
                let tempArr1 = popularIdeas.filter(
                    (item) => item.CreatedBy != enterpriseId
                );
                tempArr.push(...tempArr1);
            }

            if (values[3]) {
                if (!refreshNeeded || firstLoad) {
                    let promotionalFeeds = values[3]["value"];
                    if (promotionalFeeds && promotionalFeeds.length > 0) {
                        promotionalFeeds.forEach((element) => {
                            element["isPromotionalFeed"] = true;
                            element["IsImplementer"] = false;
                        });
                        tempArr.push(...promotionalFeeds);
                        setTempPromotionalData(promotionalFeeds);
                    }
                }
            }

            if (values[4] && values[4]["value"] && values[4]["value"].length > 0) {
                tempArr.push(...values[4]["value"]);
            }

            if (values[5] && values[5]["value"] && values[5]["value"].length > 0) {
                values[5]["value"].forEach((element) => {
                    element["IsImplementerIdea"] = true;

                    if (element.Implementers && element.Implementers.length > 0) {
                        if (
                            element.Implementers.find(
                                (x) => x.ImplementerId === enterpriseId
                            ) &&
                            element.Implementers.find((x) => x.ImplementerId === enterpriseId)
                                ?.Status === "UnderAcceptance"
                        ) {
                            element["IsImplementer"] = true;
                        }
                    }
                });
                tempArr.push(...values[5]["value"]);
            }

            setIdeas(tempArr);

            dispatch(
                fetchIdeaFeedLastGetIdeaTillDates({
                    privateIdea:
                        values[0] && values[0]["value"] && values[0]["value"].length > 0
                            ? values[0]["value"]
                                .sort(
                                    (a, b) =>
                                        -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)
                                )
                                .slice(-1)[0]["GetIdeaTillDate"]
                            : initialFetchDate,
                    innovatorIdea:
                        values[1] && values[1]["value"] && values[1]["value"].length > 0
                            ? values[1]["value"].slice(-1)[0]["GetIdeaTillDate"]
                            : initialFetchDate,
                    popularIdea:
                        values[2] && values[2]["value"] && values[2]["value"].length > 0
                            ? values[2]["value"].slice(-1)[0]["GetIdeaTillDate"]
                            : initialFetchDate,
                    promotionalIdea:
                        values[3] && values[3]["value"] && values[3]["value"].length > 0
                            ? values[3]["value"]
                                .sort(
                                    (a, b) =>
                                        -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)
                                )
                                .slice(-1)[0]["GetIdeaTillDate"]
                            : initialFetchDate,
                    SharedPrivateIdea:
                        values[4] && values[4]["value"] && values[4]["value"].length > 0
                            ? values[4]["value"]
                                .sort(
                                    (a, b) =>
                                        -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)
                                )
                                .slice(-1)[0]["GetIdeaTillDate"]
                            : initialFetchDate,
                    implementerIdeas:
                        values[5] && values[5]["value"] && values[5]["value"].length > 0
                            ? values[5]["value"]
                                .sort(
                                    (a, b) =>
                                        -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)
                                )
                                .slice(-1)[0]["GetIdeaTillDate"]
                            : initialFetchDate,
                })
            );
            setDataLoader(false);
        });
    };

    useEffect(() => {
        if (tempPromotionalData.length) {
            let promotionalItems = [];
            tempPromotionalData.forEach((element) => {
                if (element["mediafiles"][0]["isfileuploaded"]) {
                    promotionalItems.push(element["promotionalfeedid"]);
                }
            });
            Promise.all(
                promotionalItems.map((item) => getPromotionalFeedBinaryData(item))
            ).then((values) => {
                dispatch(setPromotionalMediaFiles(values));
            });
        }
    }, [tempPromotionalData]);

    const getUserPrefrence = () => {
        userPreference(enterpriseId).then((response) => {
            if (response) {
                setuserPreferenceData(response["UserPreferenceDetail"]);
                setUserPreferenceCompleteData(response);
                dispatch(storeUserPreferenceCompleteData(response));
                dispatch(storeUserPreferenceData(response["UserPreferenceDetail"]));
            }
        });
    };
    const getUserExpertise = () => {
        userExpertise(enterpriseId).then((response) => {
            if (response) {
                setUserExpertiseCompleteData(response);
                setUserExpertiseData(response["UserExpertiseDetail"]);
                dispatch(storeUserExpertiseCompleteData(response));
                dispatch(storeUserExpertiseData(response["UserExpertiseDetail"]));
            }
        });
    };

    const getPrivateIdeas = () => {
        if (privateIdeasState) {
            setDataLoader(true);
            const initialFetchDate = new Date().toJSON();
            let p1 = getPrivateIdeaDetails(enterpriseId, initialFetchDate, 1);

            Promise.all([p1]).then((values) => {
                let tempAllPrivateArray = [];
                let tempFilteredPrivateArray = [];

                if (values[0] && values[0]["value"] && values[0]["value"].length > 0) {
                    tempAllPrivateArray.push(...values[0]["value"]);
                    values[0]["value"].forEach((element) => {
                        element["isPrivateIdea"] = true;
                    });
                }

                tempFilteredPrivateArray = tempAllPrivateArray.sort(
                    (a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate)
                );
                if (tempFilteredPrivateArray.length > 0) {
                    setAllPrivateData([...tempFilteredPrivateArray]);
                    setDataLoader(false);
                } else {
                    setAllPrivateData(["no data available"]);
                    setDataLoader(false);
                }
            });
        } else {
            setAllPrivateData([]);
        }
    };

    const getPinnedDataDetails = (orgUnits: any) => {
        if (pinnedDataState) {
            setDataLoader(true);
            const initialFetchDate = new Date().toJSON();
            let p1 = getPinnedData(initialFetchDate, contestIds);
            let p2 = getPrivatePinnedData(initialFetchDate);
            let p3 = getPromotionalPinnedFeed(initialFetchDate, orgUnits);
            //let p4 = getIdeasForImplementer(enterpriseId, contestIds, challengeIds, initialFetchDate);
            let p4 = getIdeasForImplementer(
                enterpriseId,
                contestIds,
                [],
                initialFetchDate
            );

            Promise.all([p1, p2, p3, p4]).then((values) => {
                let pinnedImplementerIdeas = [];
                let tempPinnedArray = [];

                if (values[2]["value"]) {
                    values[2]["value"].forEach((element) => {
                        element["isPromotionalFeed"] = true;
                    });
                    tempPinnedArray.push(...values[2]["value"]);
                    setTempPromotionalData(values[2]["value"]);
                }

                if (values[1]["value"]) {
                    values[1]["value"].forEach((element) => {
                        element["isPrivateIdea"] = true;
                    });
                    tempPinnedArray.push(...values[1]["value"]);
                }

                if (values[0]["value"]) {
                    values[0]["value"].forEach((element) => {
                        if (element["IsPopularIdea"] === true) {
                            element["isPopularIdeas"] = true;
                        }

                        if (element.Implementers && element.Implementers.length > 0) {
                            if (
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                ) &&
                                element.CreatedBy !== enterpriseId
                            ) {
                                element["IsImplementerIdea"] = true;
                            }

                            if (
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                ) &&
                                element.CreatedBy !== enterpriseId &&
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                )?.Status === "UnderAcceptance"
                            ) {
                                element["IsImplementer"] = true;
                                element["IsImplementerIdea"] = true;
                            }
                        }
                    });
                    tempPinnedArray.push(...values[0]["value"]);
                }

                if (
                    values[0]["value"] &&
                    values[0]["value"].length > 0 &&
                    values[3]["value"] &&
                    values[0]["value"].length > 0
                ) {
                    pinnedImplementerIdeas = values[3]["value"].filter((x) =>
                        values[0]["value"].find((y) => y.IdeaId === x.IdeaId)
                    );
                }

                let filteredImplementerIdeas = [];
                if (pinnedImplementerIdeas && pinnedImplementerIdeas.length > 0) {
                    pinnedImplementerIdeas.forEach((element) => {
                        element["IsImplementerIdea"] = true;

                        if (element.Implementers && element.Implementers.length > 0) {
                            if (
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                ) &&
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                )?.Status === "UnderAcceptance"
                            ) {
                                element["IsImplementer"] = true;
                            }
                        }

                        if (
                            element.CreatedBy === enterpriseId &&
                            element.Implementers &&
                            element.Implementers.find((x) => x.ImplementerId === enterpriseId)
                        ) {
                            filteredImplementerIdeas.push(element);
                        }
                    });
                    tempPinnedArray.push(...filteredImplementerIdeas);
                }

                if (tempPinnedArray.length > 0) {
                    let sortedTempPinnedArray = tempPinnedArray.sort(
                        (a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate)
                    );
                    setAllPinnedData(sortedTempPinnedArray);
                    setDataLoader(false);
                } else {
                    setAllPinnedData(["no data available"]);
                    setDataLoader(false);
                }
            });
        } else {
            setAllPinnedData([]);
        }
    };

    const getContestDataDetails = () => {
        const initialFetchDate = new Date().toJSON();
        if (contestDataState) {
            setDataLoader(true);

            //let p1 = getInnovatorIdeas([], contestIds, challengeIds, enterpriseId, initialFetchDate, 'desc', 1);
            //let p2 = getIdeasForImplementer(enterpriseId, contestIds, challengeIds, initialFetchDate);
            let p1 = getInnovatorIdeas(
                [],
                contestIds,
                [],
                enterpriseId,
                initialFetchDate,
                "desc",
                1
            );
            let p2 = getIdeasForImplementer(
                enterpriseId,
                contestIds,
                [],
                initialFetchDate
            );

            Promise.all([p1, p2]).then((values) => {
                let tempAllContestArray = [];
                let tempFilteredContestArray = [];

                if (values && values[0]["value"] && values[0]["value"].length > 0) {
                    tempAllContestArray.push(...values[0]["value"]);
                    //dispatch(setInnovatorsData(values[0]["value"]));
                }

                if (values[1] && values[1]["value"] && values[1]["value"].length > 0) {
                    values[1]["value"].forEach((element) => {
                        element["IsImplementerIdea"] = true;

                        if (element.Implementers && element.Implementers.length > 0) {
                            if (
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                ) &&
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                )?.Status === "UnderAcceptance"
                            ) {
                                element["IsImplementer"] = true;
                            }
                        }
                    });
                    tempAllContestArray.push(...values[1]["value"]);
                }

                if (tempAllContestArray.length > 0) {
                    tempFilteredContestArray = tempAllContestArray
                        .filter((idea) => {
                            if (
                                idea.CreatedBy === enterpriseId ||
                                (idea.CoInnovators &&
                                    idea.CoInnovators.includes(enterpriseId)) ||
                                (idea.Implementers &&
                                    idea.Implementers.find(
                                        (x) => x.ImplementerId === enterpriseId
                                    ))
                            )
                                return idea;
                        })
                        .sort(
                            (a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate)
                        );
                    setAllContestData([...tempFilteredContestArray]);
                } else {
                    setAllContestData(["no data available"]);
                }
                setDataLoader(false);
            });
        } else {
            setAllContestData([]);
        }
    };

    const getPopularIdeasDetails = () => {
        const initialFetchDate = new Date().toJSON();
        if (popularData) {
            setDataLoader(true);
            //popularRequests(contestIds, challengeIds, initialFetchDate, 1)
            popularRequests(contestsState.filter(contest => !(contest.ContestNm?.toLowerCase().includes("v360")))
                                        .filter(contest =>
                                        (contest.IsExplorDisable && !contest.ExplorerVisibleTo && contest.ExplorerUsersValues.includes(enterpriseId)) ||
                                        (!contest.IsExplorDisable && !contest.ExplorerVisibleTo) ||
                                        //(contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "selectedusers" && contest.ExplorerUsersValues.includes(enterpriseId)) ||
                                        //(contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "all"))
                                        (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "notvisible" && (contest?.CreatedBy == enterpriseId || contest?.CoOwner?.includes(enterpriseId))) ||
                                        (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "selectedusers" && (contest.ExplorerUsersValues.includes(enterpriseId) || contest?.CreatedBy == enterpriseId || contest?.CoOwner?.includes(enterpriseId))) ||
                                        (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "all"))
                                        .map((contest) => contest.ContestId), [], initialFetchDate, 1).then((response) => {
                if (response && response["value"]) {
                    response["value"].forEach((element) => {
                        element["isPopularIdeas"] = true;
                    });

                    let tempArr = response["value"].sort(
                        (a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate)
                    );
                    if (tempArr.length > 0) {
                        setAllPopulaIdeas(tempArr);
                    } else {
                        setAllPopulaIdeas(["no data available"]);
                    }
                } else {
                    setAllPopulaIdeas(["no data available"]);
                    setAllPinnedData([]);
                }
                setDataLoader(false);
            });
        } else {
            setAllPopulaIdeas([]);
        }
    };

    const getSharedIdeas = () => {
        const initialFetchDate = new Date().toJSON();
        const objToSend = {
            GetIdeaTillDate: initialFetchDate,
        };
        if (sharedDataState) {
            setDataLoader(true);
            let p1 = getSharedIdeasFromPrivateIdeas(enterpriseId, "desc", objToSend);
            let p2 = getSharedIdeasFromPublicIdeas(enterpriseId, "desc", {
                GetIdeaTillDate: initialFetchDate,
                ContestIds: contestIds,
            });

            Promise.all([p1, p2]).then((values) => {
                let privateIdeaArray = [];
                let publicDataArray = [];
                if(values[0] && values[0]['ResponseCode'] && values[0]['ResponseCode'] === "107") {
                    privateIdeaArray = [];
                } else {
                    // @ts-ignore
                    privateIdeaArray.push(...values[0]);
                }

                if(values[1] && values[1]['ResponseCode'] && values[1]['ResponseCode'] === "107") {
                    publicDataArray = [];
                } else {
                    publicDataArray.push(...values[1]['value']);
                }
                if (
                    privateIdeaArray && privateIdeaArray.length > 0 &&
                    publicDataArray && publicDataArray?.length > 0
                ) {
                    privateIdeaArray.forEach((element) => {
                        element["isPrivateIdea"] = true;
                    });
                    setAllSharedData(
                        [...privateIdeaArray, ...publicDataArray].sort(
                            (a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)
                        )
                    );
                } else if (
                    privateIdeaArray &&
                    privateIdeaArray.length > 0 &&
                    !(publicDataArray && publicDataArray.length > 0)
                ) {
                    privateIdeaArray.forEach((element) => {
                        element["isPrivateIdea"] = true;
                    });
                    setAllSharedData(
                        [...privateIdeaArray]?.sort(
                            (a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)
                        )
                    );
                } else if (
                    !(privateIdeaArray && privateIdeaArray.length > 0) &&
                    publicDataArray && publicDataArray.length > 0
                ) {
                    setAllSharedData(
                        [...publicDataArray].sort(
                            (a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)
                        )
                    );
                } else if (privateIdeaArray && privateIdeaArray.length == 0 && publicDataArray && publicDataArray.length == 0) {
                    setAllSharedData(["no data available"]);
                }
                setDataLoader(false);
            });
        } else {
            setAllSharedData([]);
        }
    };

    const getPromotionalIdeas = (orgUnits: any) => {
        const initialFetchDate = new Date().toJSON();
        if (promotionalDataState) {
            setDataLoader(true);
            getPromotionalFeed(initialFetchDate, orgUnits).then((promotionalIdeas) => {
                if (promotionalIdeas && promotionalIdeas["ResponseMessage"]) {
                    setAllpromotionalData(["no data available"]);
                }
                else if (
                    promotionalIdeas &&
                    promotionalIdeas["value"] &&
                    promotionalIdeas["value"].length > 0
                ) {
                    let promotionalFeeds = promotionalIdeas["value"];
                    if (promotionalFeeds && promotionalFeeds.length > 0) {
                        promotionalFeeds.forEach((element) => {
                            element["isPromotionalFeed"] = true;
                        });
                        setAllpromotionalData(
                            promotionalFeeds.sort(
                                (a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate)
                            )
                        );
                        setTempPromotionalData(promotionalFeeds);
                    }
                }
                else if (
                    promotionalIdeas &&
                    promotionalIdeas["value"] &&
                    promotionalIdeas["value"].length === 0
                ) {
                    setAllpromotionalData(["no data available"]);
                }

                setDataLoader(false);
            });
        } else {
            setAllpromotionalData([]);
        }
    };

    const getFilteredData = (filterData: any) => {
        if (filterData.allFilters.length !== 0) {
            setDataLoader(true);
            const industryFiltersData = filterData.industryFilters.map(
                (industry) => industry.key
            );
            const contestFiltersData = filterData.contestFilters.map(
                (contest) => contest.key
            );
            const ideaStatusData = filterData.ideaStatus.map((idea) => idea.key);
            const technologyFiltersData = filterData.technologyFilters.map(
                (technology) => technology.key
            );
            const initialContestDataForFilter = initialInnovatorContestData?.contestFilters?.length > 0 ?
                initialInnovatorContestData?.contestFilters.map((contest) => contest.ContestId) : [];
            const initialFetchDate = new Date().toJSON();
            //let p1 = getInnovatorIdeasWithFilters(contestFiltersData, challengeIds, ideaStatusData, industryFiltersData, technologyFiltersData, initialFetchDate, enterpriseId, 'desc', 1);
            //let p2 = getIdeasForImplementer(enterpriseId, contestFiltersData, challengeIds, initialFetchDate, ideaStatusData);
            let p1 = getInnovatorIdeasWithFilters(
                contestFiltersData?.length > 0 ? contestFiltersData : initialContestDataForFilter,
                [],
                ideaStatusData,
                industryFiltersData,
                technologyFiltersData,
                initialFetchDate,
                enterpriseId,
                "desc",
                1
            );
            let p2 = getIdeasForImplementer(
                enterpriseId,
                contestFiltersData?.length > 0 ? contestFiltersData : initialContestDataForFilter,
                [],
                initialFetchDate,
                ideaStatusData
            );

            Promise.all([p1, p2]).then((values) => {
                setSpinnerData(false);
                let tempArr = [];

                if (values && values[0]["value"] && values[0]["value"].length > 0) {
                    tempArr.push(...values[0]["value"]);
                }

                if (values[1] && values[1]["value"] && values[1]["value"].length > 0) {
                    values[1]["value"].forEach((element) => {
                        element["IsImplementerIdea"] = true;

                        if (element.Implementers && element.Implementers.length > 0) {
                            if (
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                ) &&
                                element.Implementers.find(
                                    (x) => x.ImplementerId === enterpriseId
                                )?.Status === "UnderAcceptance"
                            ) {
                                element["IsImplementer"] = true;
                            }
                        }
                    });
                    tempArr.push(...values[1]["value"]);
                }

                let tempFilteredArray = tempArr
                    .filter((idea) => {
                        if (
                            idea.CreatedBy === enterpriseId ||
                            (idea.CoInnovators && idea.CoInnovators.includes(enterpriseId)) ||
                            (idea.Implementers &&
                                idea.Implementers.find((x) => x.ImplementerId === enterpriseId))
                        )
                            return idea;
                    })
                    .sort((a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate));
                if (tempFilteredArray.length > 0) {
                    setFilteredDataGot(tempFilteredArray);
                } else {
                    setFilteredDataGot(["no data available"]);
                }
                setDataLoader(false);
            });
        } else {
            setFilteredDataGot([]);
        }
    };

    const patentIdeaCount = () => {
        getPatentIdeaCount().then((response) => {
            if (response !== undefined && response !== null) {
                let myPatentCount = response["value"].filter(
                    (idea) => idea["PatentInitiationStatus"].toLowerCase() !== "draft"
                ).length;
                setPatentCount(myPatentCount);
            }
        });
    };

    const getProfilePic = () => {
        try {
            getEmployeePhoto(enterpriseId)
                .then((image) => {
                    if (image && image?.data && image?.data?.length > 10) {
                        dispatch(setProfilePic("data:image/jpeg;base64," + image?.data));
                        setprofileImageUrl("data:image/jpeg;base64," + image?.data);
                    }
                    else {
                        dispatch(setProfilePic(""));
                        setprofileImageUrl("");
                        console.log("profile pic error", image);
                    }
                });
        } catch (error) {
            dispatch(setProfilePic(""));
            setprofileImageUrl("");
            console.log("profile pic error", error);
        }
    };

    const handleSavePrivateIdea = useCallback(
        (val) => {
            if (!val) {
                setFirstFeedData([]);
                getAllIdeaData(contestIds, orgUnitsState, [], true);
                setEditPrivateIdeaCheck(false);
                setPrivateIdeas([]);
                setEditPrivateIdea([]);
            }
        }, []
    );
    const handleEditPrivateIdea = (ideaId) => {
        if (privateIdeas.length > 0) {
            const arr = privateIdeas;
            const filterArr = arr.filter((item) => item.PrivateIdeaId === ideaId);
            setEditPrivateIdea(filterArr[0]);
            setEditPrivateIdeaCheck(true);
        }
    };

    const setError = useCallback(
        (errorMsg, type) => {
            setMsg(errorMsg);
            setChoice(type);
        }, []
    );
    const setSuccess = useCallback(
        (successMsg) => {
            setMsg(successMsg);
            setChoice("success");
        }, []
    );
    const resetchoice = () => {
        setChoice(undefined);
    };
    let filteredIdeaData;

    const getAllIdeaDataRefresIdeaFeed = () => {
        if (pinnedDataState) {
            getPinnedDataDetails(orgUnitsState);
        } else if (privateIdeasState) {
            getPrivateIdeas();
        } else if (contestDataState) {
            getContestDataDetails();
        } else if (sharedDataState) {
            getSharedIdeas();
        } else if (popularData) {
            setAllpromotionalData([]);
            getPopularIdeasDetails();
        } else if (promotionalDataState) {
            setAllPopulaIdeas([]);
            getPromotionalIdeas(orgUnitsState);
        }
        else if (filterData.allFilters.length > 0) {
            getFilteredData(filterData);
        }
        else {
            setDataLoader(true);
            setFirstFeedData([]);
            getAllIdeaData(contestIds, orgUnitsState, [], true);
        }
    };

    //const getPromotionalFeedData = async (promotionalfeedid)  => {

    //    try {
    //        const result = await Promise.all([getPromotionalFeedBinaryData(promotionalfeedid)]);
    //        //const result1 = result.then(res => { return res.mediafiles });
    //        return result[0].mediafiles;
    //    } catch (e) { }

    //};

    const getserialInnovator = () => {
        const country = userCountryNameStateAll ? userCountryNameStateAll : userCountryNameState;
        if (country == null) {
            getSerialInnovatorForMaxIdeaCount([], [], [], enterpriseId, 'Global', country).then((response) => {
                if (response['value'] && response['value'].length > 0) {
                    setSerialInnovators(response["value"]);
                    dispatch(setSerialInnovatorIdeaFilter('max_idea_submitted'));
                    dispatch(setSerialInnoDemograpghyType('Global'));
                }
            });
        } else {
            getSerialInnovatorForMaxIdeaCount([], [], [], enterpriseId, 'Local', country).then((response) => {
                if (response) {
                    if (response['value'] && response['value'].length > 0) {
                        dispatch(setSerialInnoDemograpghyType('Local'));
                        dispatch(setSerialInnovatorIdeaFilter('max_idea_submitted'));
                        setSerialInnovators(response["value"]);
                    } else if (response['value'] && response['value'].length === 0) {
                        getSerialInnovatorForMaxIdeaCount([], [], [], enterpriseId, 'Global', country).then((response) => {
                            if (response['value'] && response['value'].length > 0) {
                                setSerialInnovators(response["value"]);
                                dispatch(setSerialInnovatorIdeaFilter('max_idea_submitted'));
                                dispatch(setSerialInnoDemograpghyType('Global'));
                            }
                        });

                    }


                }
            });
        }

    };

    const getUserDetails = () => {
        getUserDetail(enterpriseId).then(response => {
            //console.log(`user detail ${response}`);
            if (response && response.UserType && response.UserType.length > 0) {
                const data = response.UserType;
                if (data.indexOf('teamsadmin') > -1) {
                    setShowAnalytics(true);
                }
            }
        })
    }
    //const goToNext = () => { 
    //    if (currentLevel == 3) {
    //        selectedTab !== "Tab 3" ? setSelectedTab("Tab 3") : null;
    //        setTimeout(() => {
    //            setCurrent(currentLevel + 1);
    //        }, 1000);
    //    }
    //    else if (currentLevel == 7) {
    //        selectedTab !== "Tab 1" ? setSelectedTab("Tab 1") : null;
    //        setTimeout(() => {
    //            setCurrent(current + 1);
    //        }, 1000);
    //    }
    //    else {
    //        setCurrent(current + 1);
    //    }
    //    //setTimeout(() => {
    //    //    setCurrent(currentLevel + 1);
    //    //}, 1000);
    //    //setCurrent(currentLevel + 1);
    //}
    //const goToPrev = () => {
    //    if (currentLevel == 4){
    //        selectedTab !== "Tab 2" ? setSelectedTab("Tab 2") : null;
    //        setTimeout(() => {
    //            setCurrent(currentLevel - 1);
    //        }, 1000);
    //    }
    //    else if (currentLevel == 8) {
    //        selectedTab !== "Tab 3" ? setSelectedTab("Tab 3") : null;     
    //        setTimeout(() => {
    //            setCurrent(currentLevel - 1);
    //        }, 1000);
    //    }
    //    else {
    //        setCurrent(currentLevel - 1);
    //    }

    //} 
    //const getCurrrentStep = (val) => {
    //    setCurrentLevel(val);
    //    console.log("getCurrrentStep--->" + val);
    //}

    //const [currentLevel, setCurrentLevel] = useState(0);
    //const [current, setCurrent] = useState(0);
    //const [isTourOpen, setIsTourOpen] = useState(true);
    //const accentColor = "#5cb7b7";


    const handleTabChange: (selectedTab: {
        label: string;
        key: string | number;
    }) => void = updatedTab => {
        setSelectedTab(updatedTab.label);
    };


    const showAnnouncementPopup = () => {
        setShowAnnouncement(true);
    }

    const closeAnnouncementPopup = () => {
        setShowAnnouncement(false);
    }

    const closeSubmitPopup = () => {
        setSpinnerDataOnScreen(false);
        setContractorContestPop(false);
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: dots => (
            <div>
              <ul style={{ display: "flex", justifyContent: "center" }}> {dots} </ul>
            </div>
          ),
          customPaging: i => (
            <div>
            </div>
          ),
    };

    return (
        <FluentProvider theme={teamsLightTheme}>
            {showSpinnerOnScreen ? <PageLoader hidden={false} /> : ""}

            {isMobile ? (
                <>
                    {contractorContestPop ? <CommonPopupMobile closeModal={closeSubmitPopup} optionsData={GTICContractorContests} contractorCancel={contractorCancel} />: null}
                    <GenericMessageBar choice={choice} msg={msg} resetMsg={resetchoice} />

                    <div className="mobile-tabs-container">
                        {/*{tourStatus ? <TourCarousel /> : null}*/}
                        <>
                            <Slider {...settings} 
                            // value={selectedTab} onChange={handleTabChange}
                            //     blacklistedElement={{ identifierType: "className", identifierName: "filter-idea-wrapper" }}
                            //     styleProps={{ headerTextColor: "transparent", alignHeader: "center", showInkBar: false, activeInkBarColor: "#82589F", size: "medium", tabPosition: "bottom", justifyTabs: "space-evenly" }}
                                >
                                    {/* <Tabs> */}

                                   
                                <div title="Tab 1" key={0}>
                                    {/* <Suspense fallback={<Skeleton animation="wave">
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                    </Skeleton>}> */}
                                        <LeftPanel patentCount={patentCount} ideaCountAsPerRole={ideaCountAsPerRole} patentAwaitingCountInnovator={patentAwaitingCountInnovator}
                                            mentorPendingCount={mentorPendingCount} patentPendingCount={patentPendingCount}
                                            userPreferenceData={userPreferenceData} technologyData={technologyData}
                                            industry={industry} userPreferenceCompleteData={userPreferenceCompleteData}
                                            userExpertiseData={userExpertiseData} userExpertiseCompleteData={userExpertiseCompleteData} />
                                    {/* </Suspense> */}
                                </div>
                                <div title="Tab 2" key={1}>
                                    <MiddlePanel editPrivateIdeaCheck={editPrivateIdeaCheck} ideaData={editPrivateIde}
                                        enterpriseId={enterpriseId} onSavePrivateIdea={handleSavePrivateIdea}
                                        showSuccess={setSuccess} showError={setError} filters={filters} allContestFilters={allContestFilters} dataLoader={dataLoader}
                                        ideas={ideas} name={name} getAllIdeaDataRefresIdeaFeed={getAllIdeaDataRefresIdeaFeed}
                                        handleEditPrivateIdea={handleEditPrivateIdea} setError={setError} contestIds={contestIds}
                                        challengeIds={challengeIds} filterData={filteredDataGot} popularData={allPopularIdeas} privateIdeas={allPrivateData}
                                        pinnedData={allPinnedData} allContestData={allContestData} allSharedData={allSharedData}
                                        allpromotionalData={allpromotionalData} firstFeedData={firstFeedData} />
                                </div>
                                <div title="Tab 3" key={2}>
                                    {/* <Suspense fallback={<Skeleton animation="wave">
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                    </Skeleton>}> */}
                                        <RightPanel activeContests={activeContests} trendingCategories={trendingCategories}
                                            contestIds={contestIds} challengeIds={challengeIds} filters={filters}
                                            serialInnovators={serialInnovators} enterpriseId={enterpriseId} />
                                    {/* </Suspense> */}
                                </div>
                                {/* </Tabs> */}
                            </Slider>

                            <div className="Help-btn-mobile">
                                <HelpComponentMobile enterpriseId={enterpriseId} setSuccess={setSuccess} />
                            </div>
                        </>
                    </div>
                </>
            ) :
                (
                    <>
                        {contractorContestPop ? <CommonPopup closeModal={closeSubmitPopup} optionsData={GTICContractorContests} contractorCancel={contractorCancel}/>: null}
                        {showAnnouncement ? <Announcements closeModal={closeAnnouncementPopup} /> : null}
                        <GenericMessageBar choice={choice} msg={msg} resetMsg={resetchoice} />
                        <div className="submitIdeaTitle"></div>
                        <div className="ms-Grid landing-page" dir="ltr">
                            <div className="ms-Grid-row margin-top-5">
                                <StickyBox
                                    offsetTop={0}
                                    offsetBottom={20}
                                    style={{ float: "left", width: "21%" }}
                                >
                                    <div
                                        className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 left-area"
                                        style={{ width: "100%" }}
                                    >
                                        <div className="child left-side-box avatar-wrapper">
                                            <LeftSideMenu
                                                ideaCount={innovatorIdeaCount}
                                                patentIdeaCount={patentCount}
                                                enterprise={name}
                                                approvalCount={approvalIdeaCount}
                                                profileImage={profileImageUrl}
                                                ideaCountPerRole={ideaCountAsPerRole}
                                                patentAwaitingCountInnovator={patentAwaitingCountInnovator}
                                            />
                                        </div>
                                        {mentorPendingCount === 0 && patentPendingCount === 0 ? null : (
                                            <div className="child left-side-box mt-20">
                                                <Suspense fallback={
                                                    <Skeleton animation="wave">
                                                        <SkeletonItem />
                                                        <SkeletonItem />
                                                        <SkeletonItem />
                                                    </Skeleton>
                                                }>
                                                    <MentorApproval
                                                        mentorPendingCount={mentorPendingCount}
                                                        patentPendingCount={patentPendingCount}
                                                    />
                                                </Suspense>
                                            </div>
                                        )}
                                         <div className="child left-side-box mt-20">
                                            <MyPreferences
                                                userPreferenceData={userPreferenceData}
                                                technologyData={technologyData}
                                                industry={industry}
                                                userPreferenceCompleteData={userPreferenceCompleteData}
                                            />
                                        </div>
                                        <div className="child left-side-box mt-20">
                                            <MyExpertise
                                                userExpertiseData={userExpertiseData}
                                                technologyData={technologyData}
                                                industry={industry}
                                                userExpertiseCompleteData={userExpertiseCompleteData}
                                            />
                                        </div> 
                                        <div className="child left-side-box mt-20">
                                            <NudgePreference />
                                        </div>
                                        {showAnalytics ?
                                            <div className="child left-side-box mt-20">
                                                <AnalyticsDashboardView />
                                            </div> : null}
                                        {/* <div className="child left-side-box mt-20"> */}
                                        {/*    <ContestChallengeView />*/}
                                        {/*</div>*/}
                                        {/*<div className="child left-side-box mt-20">*/}
                                        {/*    <div onClick={() => history.push("/dynamic-idea-form")}>DynamicFormBuilder </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </StickyBox>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 middle-area">
                                    <div className="child padding-top-5">
                                        <ScribbleIdea
                                            editPrivateIdeaCheck={editPrivateIdeaCheck}
                                            ideaData={editPrivateIde}
                                            enterpriseId={enterpriseId}
                                            onSavePrivateIdea={handleSavePrivateIdea}
                                            showSuccess={setSuccess}
                                            showError={setError}
                                        />
                                    </div>
                                    <div className="filter-idea-wrapper">
                                        <span className="announcement" onClick={showAnnouncementPopup} >
                                            <Tooltip relationship='label' withArrow appearance="inverted" content="What's New?" positioning="above">
                                                <span className="iconify" data-icon="fluent:megaphone-loud-20-regular" data-inline="false"></span>
                                            </Tooltip>
                                        </span>
                                        {Object.keys(filters).length > 0 ? (
                                            <FilterIdeas
                                                filters={filters}
                                                allContestFilters={allContestFilters}
                                            />
                                        ) : null}
                                    </div>
                                    {dataLoader ? (
                                        <Spinner label="Loading" appearance="primary" labelPosition="after" size="medium" />
                                    ) : (
                                        <InfiniteScrollFeed
                                            ideas={ideas}
                                            name={name}
                                            getAllIdeaDataRefresIdeaFeed={getAllIdeaDataRefresIdeaFeed}
                                            enterpriseId={enterpriseId}
                                            handleEditPrivateIdea={handleEditPrivateIdea}
                                            setError={setError}
                                            contestIds={contestIds}
                                            challengeIds={challengeIds}
                                            filterData={filteredDataGot}
                                            popularData={allPopularIdeas}
                                            privateIdeas={allPrivateData}
                                            pinnedData={allPinnedData}
                                            allContestData={allContestData}
                                            allSharedData={allSharedData}
                                            allpromotionalData={allpromotionalData}
                                            firstFeedData={firstFeedData}
                                        />
                                    )}
                                </div>
                                <StickyBox
                                    offsetTop={0}
                                    offsetBottom={20}
                                    style={{ float: "left", width: "26%" }}
                                >
                                    <div
                                        className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 right-area"
                                        style={{ width: "100%" }}
                                    >
                                        <div className="child right-side-box padding-0">
                                            <div className="active-contest-header">
                                                <div>
                                                <span>
                                                    <svg width="34.128" height="24.7" viewBox="0 0 34.128 24.7">
                                                        <g
                                                            id="live-stream_1_"
                                                            data-name="live-stream (1)"
                                                            transform="translate(0.35 0.35)"
                                                        >
                                                            <path
                                                                id="Path_45286"
                                                                data-name="Path 45286"
                                                                d="M50,36.615a.606.606,0,0,1-.433-.181.624.624,0,0,1,0-.875,15.607,15.607,0,0,0,0-21.886.624.624,0,0,1,0-.875.606.606,0,0,1,.865,0,16.854,16.854,0,0,1,0,23.637A.608.608,0,0,1,50,36.615Z"
                                                                transform="translate(-21.84 -12.615)"
                                                                fill="#460973"
                                                                stroke="#460973"
                                                                strokeWidth="0.7"
                                                            />
                                                            <path
                                                                id="Path_45287"
                                                                data-name="Path 45287"
                                                                d="M10.271,36.615a.606.606,0,0,1-.433-.181,16.854,16.854,0,0,1,0-23.637.606.606,0,0,1,.865,0,.624.624,0,0,1,0,.875,15.607,15.607,0,0,0,0,21.886.624.624,0,0,1,0,.875.606.606,0,0,1-.433.181Z"
                                                                transform="translate(-5 -12.615)"
                                                                fill="#460973"
                                                                stroke="#460973"
                                                                strokeWidth="0.7"
                                                            />
                                                            <path
                                                                id="Path_45288"
                                                                data-name="Path 45288"
                                                                d="M45.754,35.666a.606.606,0,0,1-.433-.182.627.627,0,0,1,0-.878,11.928,11.928,0,0,0,0-16.689.627.627,0,0,1,0-.878.6.6,0,0,1,.865,0,13.2,13.2,0,0,1,0,18.445.6.6,0,0,1-.433.183Z"
                                                                transform="translate(-20.192 -14.262)"
                                                                fill="#460973"
                                                                stroke="#460973"
                                                                strokeWidth="0.7"
                                                            />
                                                            <path
                                                                id="Path_45289"
                                                                data-name="Path 45289"
                                                                d="M15.2,35.667a.606.606,0,0,1-.433-.182,13.2,13.2,0,0,1,0-18.445.6.6,0,0,1,.865,0,.627.627,0,0,1,0,.878,11.928,11.928,0,0,0,0,16.689.627.627,0,0,1,0,.878.605.605,0,0,1-.433.182Z"
                                                                transform="translate(-7.333 -14.262)"
                                                                fill="#460973"
                                                                stroke="#460973"
                                                                strokeWidth="0.7"
                                                            />
                                                            <path
                                                                id="Path_45290"
                                                                data-name="Path 45290"
                                                                d="M41.511,34.717a.6.6,0,0,1-.433-.183.633.633,0,0,1,0-.883,8.253,8.253,0,0,0,0-11.485.633.633,0,0,1,0-.883.6.6,0,0,1,.865,0,9.521,9.521,0,0,1,0,13.251A.6.6,0,0,1,41.511,34.717Z"
                                                                transform="translate(-18.643 -15.909)"
                                                                fill="#460973"
                                                                stroke="#460973"
                                                                strokeWidth="0.7"
                                                            />
                                                            <path
                                                                id="Path_45291"
                                                                data-name="Path 45291"
                                                                d="M20.124,34.717a.6.6,0,0,1-.433-.183,9.521,9.521,0,0,1,0-13.251.6.6,0,0,1,.865,0,.633.633,0,0,1,0,.883,8.253,8.253,0,0,0,0,11.485.633.633,0,0,1,0,.883.6.6,0,0,1-.433.183Z"
                                                                transform="translate(-9.564 -15.909)"
                                                                fill="#460973"
                                                                stroke="#460973"
                                                                strokeWidth="0.7"
                                                            />
                                                            <path
                                                                id="Path_45292"
                                                                data-name="Path 45292"
                                                                d="M28.506,34.012a5.506,5.506,0,1,1,5.506-5.506A5.58,5.58,0,0,1,28.506,34.012Zm0-9.789a4.283,4.283,0,1,0,4.283,4.283A4.34,4.34,0,0,0,28.506,24.224Z"
                                                                transform="translate(-11.792 -16.506)"
                                                                fill="#460973"
                                                                stroke="#460973"
                                                                strokeWidth="0.7"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span className="patent-header active-contest-text">
                                                    Ongoing Campaigns
                                                </span>
                                            </div>
                                            <div>{activeContests?.length >= 1 && (activeContests && activeContests[0] !== "No Active Contest Available") ?
                                                <a onClick={showMore} className="show-more-link">{
                                                    <span>Show All</span>
                                                    //expanded ?
                                                    //    <span>Show less</span> :
                                                    //    <span>Show more</span>
                                                    }
                                                </a> : null}
                                            </div>
                                            </div>
                                            <RSActiveContests contests={activeContests} />
                                        </div>
                                        <div className="child right-side-box mt-20">
                                            <PatentabilityCheck
                                                trendingTopics={trendingCategories}
                                                contestIds={contestIds}
                                                challengeIds={challengeIds}
                                            />
                                        </div>
                                        {trendingCategories &&
                                            trendingCategories.length > 0 &&
                                            Object.keys(filters).length > 0 ? (
                                            <div className="child right-side-box mt-20">
                                                <RSTrendingCategories
                                                    trendingTopics={trendingCategories}
                                                    contestIds={contestIds}
                                                    challengeIds={challengeIds}
                                                    filters={filters}
                                                />
                                            </div>
                                        ) : null}
                                        {serialInnovators && serialInnovators.length > 0 ? (
                                            <div className="child right-side-box mt-20">
                                                <SerialInnovatorView
                                                    serialInnovatorsProp={serialInnovators}
                                                    enterpriseId={enterpriseId}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </StickyBox>
                            </div>
                        </div>
                        <Suspense fallback={<Spinner label="Loading" labelPosition="after" size="tiny" />}>
                            <HelpComponent enterpriseId={enterpriseId} setSuccess={setSuccess} />
                        </Suspense>
                    </>
                )
            }
        </FluentProvider>
    );
};

export default connect()(
    withAITracking(reactPlugin, NewViewIdea, "Landing Page", "Landing Page")
);
