export const serviceMethod = {
    peopleDetailApis: {
        peopleDetails: 'GetPeopleDetails/?enterpriseId=#enterpriseId',
        userTypeDetails: 'UserMaster/?EnterpriseID=#enterpriseId',
        GetPeopleHierarchyUnfolded: 'GetPeopleHierarchyUnfolded',
        GetpeopleSuggestion: 'GetPeopleSuggestions?keyword=#keywordData'
    },
    contest: {
        //contest services
        getChallengeByContestID: 'GetChallengeByContestId?pageIndex=#pageIndex', 
        getContestByContestId:'GetContestByContestId?contestId=#contestId&isvaliduser=#isvaliduser&isCallFromTeams=true',
        addNewContest: 'SaveContest',
        AddFileInviteCreate: 'AddInviteeFile?ContestInviteeMappingId=#ChallengeInviteeMappingId&ContestId=#ChallengeId',
        addFileContest: 'AddFile?contestId=#contestID',
        deleteFileContest: 'DeleteFile',        
    },
    ioty: {
        getPatentDetailsByIdfNumber: 'GetPatentDetailsByIdfNumber',
    },
    search: {
        isValidCoinnovatorWithIdearole: 'IsValidCoInnovator?idearole=#idearole',
    },
    configurationApis: {
        GetConfigurationList: 'GetConfigurationList?isCallFromTeams=true'
    },
    GetLocationDetails: {
        GetLocationDetails: 'GetLocationDetail?locationId=#locationId&isMarket=#isMarket'
    },
    demographicApi: {
        GetChallengeNContestByDemographic: 'GetChallengeNContestByDemographic?countryName=India',
        GetChallengeNContestByDemographicFromTeams: 'GetChallengeNContestByDemographicFromTeams?countryName=#countryName',
        GetChallengeNContestByDemographicFromTeamsContractor: 'GetChallengeNContestByDemographicFromTeams?countryName=#countryName&IsCallFromContractor=#IsCallFromContractor',
    },
    challenge: {
        getContestChallengeDemographic: 'GetChallengeNContestByDemographic',
        getChallengeByContestId: 'GetChallengeByContestId?pageIndex=#pageIndex',
        SaveContestView: 'SaveContestView',
    },
    contest: {
        getContestByContestId: 'GetContestByContestId?contestId=#contestId&isvaliduser=#isvaliduser&isCallFromTeams=true&isLighterContestRequired=true',
        GetContestApplicableEntityDetail: 'GetContestApplicableEntityDetail?contestId=#contestId',
        addNewContest: 'SaveContest',
        AddFileInviteCreate: 'AddInviteeFile?ContestInviteeMappingId=#ChallengeInviteeMappingId&ContestId=#ChallengeId',
        addFileContest: 'AddFile?contestId=#contestID',
        getUploadPreSignedUrl: 'GetUploadPreSignedUrl?FileName=#FileName&Id=#Id&Type=#Type',
        deleteFileContest: 'DeleteFile',
        getBannerImageByteData: 'GetBannerImageByteData?id=#id&fileName=#fileName',
        getStageImageByteData: 'GetStageImageByteData?id=#id&fileName=#fileName',
        getUserReportAccessDetail: 'GetUserReportAccessDetail?contestId=#contestId'
    },
    download: {
        downloadFile: 'DownloadS3File?FileName=#FileName&Type=#Type&Id=#Id',
        downloadDoc: 'DownloadS3File?FileName=#FileName&Id=#Id'
    },
    presignedUrl:{
        GetUploadPreSignedUrl: "GetUploadPreSignedUrl?FileName=#FileName&Id=#Id&Type=#Type",
        GetDownloadPreSignedUrl: "GetDownloadPreSignedUrl?FileName=#FileName&Id=#Id&Type=#Type",
        UpdatePrivateIdeaDetailsAfterFileUpload:"UpdatePrivateIdeaDetailsAfterFileUpload?privateIdeaId=#ideaId",
        UpdateIdeaDetailsAfterFileUpload: "UpdateIdeaDetailsAfterFileUpload?ideaId=#ideaId",

        // patent
        GetUploadPatentPreSignedUrl: "GetUploadPreSignedUrl?FileName=#FileName&Id=#Id&Type=patentfile",

        
        // download
        GetDownloadPreSignedUrlWithProp: "GetDownloadPreSignedUrl?FileName=#FileName&Id=#Id&Type=#Type&propertyName=#propertyName",

    },
    idea: {
        GetCreateIdeaType: 'GetCreateIdeaType?isSponsorshipRequired=false&challengeType=Public&iIsSavedFromTeams=true',
        PostIdeaMaster: 'PostIdeaMaster?isSponsorshipRequired=false&challengeType=Public&IsSavedFromTeams=true',
        SaveIdeaWorkFlow:'SaveIdeaWorkFlow?ideaId=#ideaId&isSponsorshipRequired=false&challengeType=Public&IsSavedFromTeams=true',

        GetUpdateIdeaType: 'GetUpdateIdeaType?key=#key&isSponsorshipRequired=false&challengeType=Public&IsSavedFromTeams=true',
        UpdatedeaMaster: 'UpdateIdeaMaster?key=#key&isSponsorshipRequired=false&challengeType=Public&IsSavedFromTeams=true',
        UpdateIdeaWorkFlowandAllocation: 'UpdateIdeaWorkFlowandAllocation?ideaId=#ideaId&isSponsorshipRequired=false&challengeType=Public&isMentorChanged=#isMentorChanged&IsSavedFromTeams=true',
        
        GetUNSDGAlignment: 'GetUNSDGAlignment',
        addIdea: 'IdeaMaster?isSponsorshipRequired=' + false + '&challengeType=public&IsSavedFromTeams=true',
        GetReviewer: 'GetReviewer?challengeId=#ChallengeId&categoryId=#subCategoryId',
        getIdeaCountForInnovator: 'GetIdeaCountForInnovator',
        getIdeaStatus: 'GetIdeaStatus?ribbonType=#ribbonType',
        getInnovatorIdeas: 'GetMyIdeas?enterpriseId=#enterpriseId&pageIndex=#pageIndex&sortType=#sortType&isCallFromTeams=true',
        GetIdeasForMentorReview: 'GetIdeasForMentorReview?enterpriseId=#enterpriseId&pageIndex=#pageIndex&sortType=#sortType',
        IsValidCoInnovator: 'IsValidCoInnovator',
        IsValidMentor: 'IsValidMentor?selectedMentorId=#SelectedMentorId',
        GetIdeaDetailsByIdeaId: 'GetIdeaDetailsByIdeaId?IdeaId=#ideaId&isDemographyCheckDisabled=#isDemographyCheckDisabled&IsCallFromTeams=true',
        GetIdeaDetailsByIdeaIdWithIsValidUser: 'GetIdeaDetailsByIdeaId?IdeaId=#ideaId&isDemographyCheckDisabled=#isDemographyCheckDisabled&IsCallFromTeams=true&isValidUser=#isValidUser',
        GetChallengeTypeAndOrgUnitByChallengeId: 'GetChallengeTypeAndOrgUnitByChallengeId?mentorid=null&isideaview=true',
        collaborateSimiliarIdeas: 'CollaborateIdea?IsSavedFromTeams=true',
        GetIdeaCommentsByIdeaId: 'GetIdeaCommentsByIdeaId?IdeaId=#ideaId&pageindex=#pageindex&isParticipant=#isParticipant',
        AddCommentForIdea: 'AddCommentForIdea?isParticipant=#isParticipant&IsSavedFromTeams=true',
        GetdeaWorkFlowDetailsByIdeaId: 'GetdeaWorkFlowDetailsByIdeaId?IdeaId=#ideaId',
        getPrivateIdeaDetails: 'GetPrivateIdeaDetails?enterpriseId=#enterpriseId&pageIndex=#pageIndex',
        getIdeasByCategory: 'GetIdeasByCategory?pageIndex=#pageIndex&sortType=#sortType',
        getPromotionalFeed: 'GetPromotionalFeed',
        getPromotionalFeedBinaryData: 'GetPromotionalFeedData?PromotionFeedId=#promotionalfeedid',
        getPromotionalPinnedFeed: 'GetPromotionalFeed?isPin=true',
        getFilterDetail: 'GetFilterDetail',
        getIdeaCountAsPerRole: 'GetIdeaCountAsPerRole',
        getFiltersRoleWise: 'GetFiltersRoleWise?pageIndex=1&role=Innovator',
        getTechnologyDetails: 'GetTechnologyDetails',
        updateIdeaStatusAsUnderImplementation: 'StartImplementation?ideaId=#ideaId&Status=#status&IsSavedFromTeams=true',
        updateIdeaStatusAsImplementedOrNot: 'ReviewerImplementation?ideaId=#ideaId&Status=#status&IsSavedFromTeams=true',
        needMoreInformation: 'NeedMoreInformation?ideaId=#ideaId&challengeType=#challengeType&IsSavedFromTeams=true',
        updateImplementerAction: 'UpdateImplementerAction?ideaId=#ideaId&actions=#action',
        getIdeasForImplementer: 'GetIdeasForImplementer?enterpriseId=#enterpriseId&pageIndex=#pageIndex&sortType=#sortType&isCallFromTeams=#isCallFromTeams',
        updateIdeaStatusAsClosed: 'ImplementationClosed?ideaId=#ideaId&Status=#status&IsSavedFromTeams=true',
        getMyIdeasFromLICTabManual: 'GetMyIdeas?enterpriseId=#enterpriseId&sortType=desc&IsFromTeamsMessagingExt=true&isCallFromTeams=true&isOnlySubmitted=true',
        getPrivateIdeasFromLICTabManual: 'GetPrivateIdeaDetails?enterpriseId=#enterpriseId&pageIndex=1&IsFromTeamsMessagingExt=true',
        getInnovatorIdeasOnly: 'GetMyIdeas?enterpriseId=#enterpriseId&pageIndex=#pageIndex&sortType=#sortType&isCallFromTeams=true&isOnlySubmitted=true',
        isIDFNumber: 'IsIDFNumber?idfNumber=#IDFNumber&ideaId=#IdeaId',
        checkForDuplicateIdea:"CheckduplicateIdea?isSponsorshipRequired=#isSponsorshipRequired&challengeType=#challengeType",
        getConfigurationMasterStringValue:"GetConfigurationMasterStringValue?configurationmasterkey=#Configurationmasterkey",
    },
    submitIdea: {
        SubmitIdea: '?isSponsorshipRequired=' + false + '&challengeType=public'
    },
    patentIdea: {
        patentCount: 'GetPatentInitiatedIdeaList?role=Innovator&pageIndex=1'
    },
    trendingTopics: {
        trendingTopics: '/GetCategoryDetails?categoryType=public'
    },
    popularIdeas: {
        //popularIdeas: 'GetPopularIdeas?categoryIds=Popular&pageIndex=#pageIndex&sortType=#sortType&isCallFromTeams=true'
        popularIdeas: 'GetPopularIdeasForTeams?categoryIds=Popular&pageIndex=#pageIndex&sortType=#sortType'
    },
    profilePhoto: {
        profilePhoto: 'ProfilePic?EnterpriseId=#EnterpriseId'
    },
    trending: {
        trendingIdeas: 'GetTrendingIdeas?pageIndex=#pageIndex&sortType=#sortType',
        trendingCategories: 'GetCategoryDetails?categoryType=#categoryType&IsCallFromTeams=true'
    },
    likeIdea: {
        like: 'LikeUnlikeIdeaForTeams?ideaId=#ideaId&isLike=#isLike'
    },
    privateIdea: {
        addPrivateIdea: 'AddPrivateIdea?IsSavedFromTeams=true',
        addFile: 'AddFile?ideaId=#ideaId&isPrivateIdea=true',
        deleteFile: 'DeleteFile?isPrivateIdea=true',
        deletePrivateIdeaFile: 'DeletePrivateIdeaFile?isPrivateIdea=true',
        updatePrivateIdea: 'UpdatePrivateIdea?privateIdeaId=#privateIdeaId',
        getPrivateIdeaById: 'GetPrivateIdeaByPrivateIdeaId?privateIdeaId=#privateIdeaId',
        deletePrivateIdea: 'DeletePrivateIdea?privateIdeaId=#privateIdeaId',
        LikeUnlikePrivateIdea: 'LikeUnlikePrivateIdea?privateIdeaId=#privateIdeaId&isLike=#isLike&IsSavedFromTeams=true'
    },
    sharedIdea: {
        getShareIdeaDetails: 'GetShareIdeaDetails?EnterpriseId=#EnterpriseId&pageIndex=#pageIndex', // not in use
        getSharePrivateIdeaDetails: 'GetSharePrivateIdeaDetails?EnterpriseId=#EnterpriseId&pageIndex=#pageIndex', //not in use
        addPrivateIdeaShareUserDetails: 'AddPrivateIdeaShareUserDetails?PrivateIdeaId=#PrivateIdeaId&isCallFromMessageExtension=#isCallFromMessageExtension',
        addIdeaShareUserDetails: 'AddIdeaShareUserDetails?IdeaId=#IdeaId&isCallFromMessageExtension=#isCallFromMessageExtension',
        AddShareIdeaComments: 'AddShareComments?IsSavedFromTeams=true',
        getSharedIdeasFromPrivateIdeas: 'GetSharedIdeasFromPrivateIdeas?enterpriseId=#EnterpriseId&sortType=#sortType',
        getSharedIdeasFromPublicIdeas: 'GetSharedIdeasFromPublicIdeas?enterpriseId=#EnterpriseId&sortType=#sortType',
        GetShareComments: 'GetShareComments?IdeaId=#IdeaId&IsPrivateIdea=true&pageIndex=#pageIndex'
    },
    landingPage: {
        getUserPreferenceData: 'GetUserPreferenceData?EnterpriseId=#EnterpriseId',
        getUserExpertiseData: 'GetUserExpertiseData?EnterpriseId=#EnterpriseId'
    },
    patentability: {
        getPatentInitiatedIdeaList: 'GetPatentInitiatedIdeaList?role=#role&pageIndex=#pageIndex', //not in use
        getAvailablePatentIdeas: 'GetAvailablePatentIdeas',
        getAppliedPatentIdeas: 'GetAppliedPatentIdeas',
        getIdeaDetailsForPatentByIdeaNumber: 'GetIdeaDetailsForPatentByIdeaNumber?ideaNumber=#ideaNumber',
        getPatentDetailsByPatentId: 'GetPatentDetailsByPatentId?PatentId=#patentId',
        savePatentDetails: 'SavePatentDetails',
        addPatentComments: 'AddCommentForPatent',
        getPatentComments: 'GetPatentCommentsByPatentId?patentId=#patentId&pageIndex=#pageIndex',
        addPatentSupportingFiles: 'AddPatentSupportingFiles?PatentId=#PatentId',
        deletePatentFile: 'DeletePatentSupportingFiles',
        deletePatentability: 'DeletePatentByPatentId?PatentId=#PatentId',


    },
    pinIdea: {
        savePinDetailsPrivateIdea: 'SavePinDetailsPrivateIdea?PrivateIdeaId=#PrivateIdeaId', // not in use
        savePinDetailsSubmittedIdea: 'SavePinDetailsSubmittedIdea?IdeaId=#IdeaId', // not in use
        savePinUnPinDetails: 'SavePinUnPinDetails?ideaId=#ideaId&isPrivateIdea=#isPrivateIdea&isPin=#isPin',
        addPinUnpinDetailsPromotionalFeed: 'AddPinUnpinDetailsPromotionalFeed?promtionalFeedId=#promtionalFeedId&isPin=#isPin',
        getPinIdea: 'GetIdeaPinDetails',
        getPinPrivateIdea: 'GetPrivateIdeaPinDetails'
    },
    expertisePrefrences: {
        addUserExpertiseData: 'AddUserExpertiseData?IsSavedFromTeams=true',
        addUserPreferenceData: 'AddUserPreferenceData?IsSavedFromTeams=true'
    },
    serialInnovators: {
        getSerialInnovator: 'GetSerialInnovator?pageIndex=1&enterpriseId=#EnterpriseId',
        searchInnovatorIdeas: 'GetAdvanceSearchIdea?pageIndex=#pageIndex&sortType=desc&isCallFromTeams=true',
        getSerialInnovatorForMaxIdeaCount: 'GetSerialInnovatorForMaxIdeaCount?pageIndex=1&enterpriseId=#EnterpriseId'
    },
    deleteIdea: {
        deleteIdea: 'DeleteIdea?ideaId=#ideaId'
    },
    nudges: {
        getNudgeDetails: 'GetNudgeDetails?enterpriseId=#enterpriseId&pageIndex=#pageIndex&sortType=#sortType',
        getNudgeDetailsByNudgeId: 'GetNudgeDetailsByNudgeId?nudgeId=#nudgeId',// not in use
        updateNudgeByNudgeId: 'UpdateNudgeByNudgeId?nudgeId=#nudgeId',
        getNudgeUserPreference: 'GetNudgeUserPreference',
        saveNudgeUserPreference: 'SaveNudgeUserPreference'

    },
    mentor: {
        addParticipantComments: 'AddCommentForIdea?isParticipant=#isParticipant&IsSavedFromTeams=true',
        mentorSubmit: 'MentorSubmit?Status=#status&ideaId=#ideaIdValue&isSponsorshipRequired=#isSponsorshipRequired&challengeType=#challengeType&IsSavedFromTeams=true',
        getChallengeById: 'InnovationChallengeStore?challengeId=#challengeId&isvaliduser=#isvaliduser',
        getFiltersRoleWise: 'GetFiltersRoleWise?pageIndex=#pageIndex&role=#role',
        getIdeaStatus: 'GetIdeaStatus?ribbonType=#ribbonType',
        getReviewSummary: 'GetIdeasForMentorReview?pageIndex=#pageIndex&sortType=#sortType&isCallFromTeams=true',
        getPatentInitiatedIdeaCount: 'GetPatentInitiatedIdeaCount',
        getPatentInitiatedIdeaListTeams: 'GetPatentInitiatedIdeaListTeams?pageIndex=#pageIndex',
        UpdateMentorRoleStatus: 'UpdateMentorRoleStatus?MentorRoleStatus=#MentorRoleStatus'
    },
    user: {
        saveUserFeedback: 'SaveUserFeedback',
        saveUniqueUser: 'SaveUniqueUser'
    },
    analytics: {
        nudgesSentToUsers: 'NudgesSentToUsers',
        fetchNumberOfDailyVisits: 'FetchNumberOfDailyVisits',
        fetchNumberOfNewVisits: 'FetchNumberOfNewVisits',
        ideasSubmittedByUsers: 'IdeasSubmittedByUsers',
        getPeopleHierarchy: 'GetPeopleHierarchy?parentOrgUnitId=#parentOrgUnitId',
        getContestDetailByDemographic: 'GetContestDetailByDemographic',
        getIdeaLikesData: 'GetIdeaLikesData',
        getIdeaCommentsData: 'GetIdeaCommentsData',
        getIdeaSharedData: 'GetIdeaSharedData',
        getMentorActionData: 'GetMentorActionData',
        getUserIdeaTableData: 'GetUserIdeaTableData ',
        getUniqueUserEnterpriseData: 'GetUniqueUserEnterpriseData',
        getDailyVisitUserEnterpriseData: 'GetDailyVisitUserEnterpriseData',
        getContestPrivateIdeaCount: 'GetContestPrivateIdeaCount',
        getCountryWiseUserData: 'GetCountryWiseUserData',
        getIdeaCountForContest: 'GetIdeaCountForContest',
        getIdeasRejectedByMentor: 'GetIdeasRejectedByMentor',
        getUserPreferenceData: 'GetUserPreferenceData'
    },
    exploreAll:{
        hierarchyFilter: 'GetPeopleHierarchy?parentOrgUnitId=#parentOrgUnit',
        advanceFilter: 'GetAllExploreIdeas?pageIndex=#pageIndex&sortType=#sortType'
    },
    PIB: {
        getNudgeDetailsPIB: 'GetNudgeDetails?enterpriseId=#enterpriseId&pageIndex=#pageIndex&sortType=#sortType&isCallFromPersonalBoard=true',
        getProfilePic: 'ProfilePic?EnterpriseId=#EnterpriseId',
        GetPersonalBoardIdeaCount: 'GetPersonalBoardIdeaCount?enterpriseId=#enterpriseId&pageIndex=#pageIndex&sortType=#sortType&isCallFromTeams=true',
        getCollaboratedIdeas: 'GetCollaboratedIdeas',
        getCollaboratedIdeasCount: 'GetCollaboratedIdeasCount',
        saveCollaborationAction: 'SaveCollaborationAction?ideaNumber=#ideaNumber',
        SaveFollowerDetail: 'SaveFollowerDetail',
        GetFollowerFollowingCount: 'GetFollowerFollowingCount?enterpriseId=#enterpriseId',
        SaveFollowerDetail: 'SaveFollowerDetail',
        GetFollowingPeopleDetails: 'GetFollowingPeopleDetails?pageIndex=#pageIndex',
        getSerialInnovator: 'GetSerialInnovator?pageIndex=#pageIndex&enterpriseId=#EnterpriseId&isCallFromPersonalBoard=#isCallFromPersonalBoard',
        getSerialInnovatorForMaxIdeaCount: 'GetSerialInnovatorForMaxIdeaCount?pageIndex=#pageIndex&enterpriseId=#EnterpriseId&isCallFromPersonalBoard=#isCallFromPersonalBoard',
        reminderNudgeToMentor:'ReminderNudgeToMentor',
        saveCollaborationChatDetail: 'SaveCollaborationChatDetail',
        getCollaborationChatDetail: 'GetCollaborationChatDetail?ideaId=#IdeaId',
    },
    LICTab: {
        contestIdeas: "GetMyIdeas?enterpriseId=#EnterpriseId&sortType=desc&IsFromTeamsMessagingExt=true&isCallFromTeams=true&isOnlySubmitted=true",
        privateIdeas: "GetPrivateIdeaDetails?enterpriseId=#EnterpriseId&pageIndex=1&IsFromTeamsMessagingExt=true",
    },
    adminPanel: {
        getMyContests: 'GetMyContests?pageIndex=#pageIndex',
        isValidContest: 'IsValidContest',
        editContest: 'EditContest?key=#contestID',
        downloadFileContest: 'DownloadS3File?FileName=#fileName&id=#uniqueID',
        GetContestByContestId: 'GetContestByContestId?contestId=#contestId&isvaliduser=#isvaliduser',
        CheckIdeaExistForContest: 'CheckIdeaExistForContest?contestId=#contestId',
        getInviteeByChallengeInviteeMappingId: 'GetInviteeByContestInviteeMappingId?ContestInviteeMappingId=#ChallengeInviteeMappingId',

    },
    stageVoteService: {
        GetStagesByChallengeIdOrContestId: 'GetStagesByChallengeIdOrContestId?Id=#Id&IsChallenge=#IsChallenge&pageIndex=#pageIndex',
        GetContestAnnouncement: 'GetContestAnnouncement?contestId=#contestId',
        GetHallOfFameData: 'GetHallOfFameData?PageIndex=#pageIndex&sortType=desc',
        GetContestInsight: 'GetContestInsight?contestid=#ContestId&NumberOfDays=#NumberOfDays',
        GetIdeaByAwardType: 'GetIdeaByAwardType?contestId=#ContestId&awardType=#AwardType',
        GetContestQuickLinks: 'GetContestQuickLinks',
        GetContestQuickLinkTypes: 'GetContestQuickLinkTypes?contestId=#ContestId',
        AddStageRequestQueue: 'AddStageRequestQueue',
        GetRequestQueueDetails: 'GetRequestQueueDetails',
        AddFile: 'AddFile?StageRequestQueueId=#StageRequestQueueId',
        GetIdeaAllocationQueueStatus: 'GetIdeaAllocationQueueStatus?stageReqQueueId=#StageReqQueueId',
    },
    dynamicFormRender: {
        GetIdeaTemplateMappingByContestId: 'GetIdeaTemplateMappingByContestId?contestId=#contestId',
        GetMarketUnitDetail: 'GetMarketUnitDetail?countryKey=#countryKey&countryname=#countryName&MetroCityCode=#metroCityCode',
        GetMarketWithMarketUnits: 'GetMarketWithMarketUnits',
    },
    V360:{
        V360Contest: "V360 Awards",
        GetV360DiamondClients: "GetV360DiamondClients",
        GetMarketAndMarketUnitDetailsForV360 :"GetMarketAndMarketUnitDetailsForV360?marketId=#marketId&isMarket=#isMarket",
        GetV360ContractorExceptionList: "GetV360ContractorExceptionList",
        IsV360IdeaDuplicate: "IsV360IdeaDuplicate",
        GetIdeaMasterAwardIdeas: "GetIdeaMasterAwardIdeas?contestId=#contestId",
        GetIdeaMasterAwardIdeasByContestId: "GetIdeaMasterAwardIdeasByContestId?ContestId=#ContestId&AwardSequence=#AwardSequence",
        GetIdeaAwardCountDetail: "GetIdeaAwardCountDetail?ContestId=#ContestId",
        GetV360NonDiamondClients: "GetV360NonDiamondClients",
        GetV360CalidsbyClient: "GetV360CalidsbyClient",
    },
    evaluate: {
        GetStageDetailForJudge: "GetStageDetailForJudge",
        GetAllocatedIdeaForJudgeReviewDynamic: "GetAllocatedIdeaForJudgeReviewDynamic?pageIndex=#PageIndex",
        SubmitJudgeRatingsDynamic: "SubmitJudgeRatingsDynamic?status=#status",        
    },
    voting:{
        saveUserVotingDetail:"SaveUserVotingDetail",
        deleteUserVotingDetail:"DeleteUserVotingDetail?ideaId=#ideaId&enterpriseId=#enterpriseId",
        getIdeasForVoting:"GetIdeasForVoting?pageIndex=#pageIndex",
        GetIdeaCountForVoting: "GetIdeaCountForVoting",
        IsValidVote: "IsValidVote",
        getTotalCountForVoting: "GetTotalCountForVoting",
    },
    report: {
        GetReportQueue: 'GetReportQueue?ReportType=#ReportType',
        SaveReportQueue: 'SaveReportQueue?ReportType=#ReportType',
        GetAllocatedIdeaForJudgeReviewDynamicForPdF: 'GetAllocatedIdeaForJudgeReviewDynamicForPdF'
    }
}

