import React, { Component } from 'react'
import { connect } from "react-redux";
import {fetchToken} from '../redux-sharedframework/actions/token-action';
import HttpService  from './../api/http-interceptor-service';

interface AuthProps{
    fetchToken():any;
    data: string;
    entityId? : string;
  }

export class Auth extends Component<AuthProps> {

    componentDidMount(){
       // this.props.fetchToken();
    }
    render() {
        // to be removed after store intergration of token in all files
        //if(Object.keys(this.props.data).length !==0) localStorage.setItem('token', JSON.stringify(this.props.data));
        return (
            <HttpService />
        );
    }
}

const mapStateToProps=(state)=>{
      return{
          data: state.fetchTokenReducer.data,
          error: state.fetchTokenReducer.error,
          entityId : state.entityReducer.entityId
      }
}
const mapDispatchToProps=(dispatch)=>{
    return{
        fetchToken:()=>{dispatch(fetchToken())}
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Auth);
