import axios from 'axios';
import { serviceMethod } from './config-reader';
import { deleteMethod, getMethod, postMethod, putMethod } from './http-interceptor-service';

export const getNudgeDetails = (enterpriseId, pageIndex= 1, sortType='desc') => {
    let method =  serviceMethod.nudges.getNudgeDetails;
    method = method.replace('#enterpriseId', enterpriseId);
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#sortType', sortType);
    const requestJSON = {
        "Days": "",
        "Searchkeyword": "",
        "FromDate": "",
        "ToDate": "",
        "NudgeCategory": [],
        
    };
   
    const URL = process.env.REACT_APP_NUDGESERVICE + method;
    return postMethod(URL, requestJSON);
}

export const updateNudgeReadFlag = (nudgeid, requestJSON) => {
    let method = serviceMethod.nudges.updateNudgeByNudgeId;
    method = method.replace('#nudgeId', nudgeid)
    const URL = process.env.REACT_APP_NUDGESERVICE + method;
    return putMethod(URL, requestJSON);
}

