import axios from 'axios';
import { serviceMethod } from './config-reader';
import { deleteMethod, getMethod, postMethod, putMethod, getTeamstoken } from './http-interceptor-service';
let cancelToken;

export const downloadFile = (token, fileName, type, id, EnterpriseId) => {    
    let method = serviceMethod.download.downloadFile;
    method = method.replace("#FileName", encodeURIComponent(fileName));
    method = method.replace("#Type", type);
    method = method.replace("#Id", id);

    const URL = process.env.REACT_APP_IDEASERVICE + method;

    return getMethod(URL);
}

export const GetCreateIdeaApi=async (data, ideaId)=>{
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    const URL = process.env.REACT_APP_IDEASERVICE + serviceMethod.idea.GetCreateIdeaType;
    return postMethod(URL, data, config);
}

export const GetUpdateIdeaTypeApi= async (data, ideaId)=>{
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    let method= serviceMethod.idea.GetUpdateIdeaType;
    method = method.replace("#key", ideaId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return putMethod(URL, data, config);
}
export const UpdatedeaMasterApi=async (data, ideaId, nominationId?)=>{
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    let method= serviceMethod.idea.UpdatedeaMaster;
    method = method.replace("#key", ideaId);
    if (nominationId && nominationId !== '' && ideaId == '') {
        method=method+ '&isNominationFromEmailLink=true'
     }
  
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return putMethod(URL, data, config);
}
export const  UpdateIdeaWorkFlowandAllocationApi=async(ideaId,isMentorChanged)=>{
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    let method= serviceMethod.idea.UpdateIdeaWorkFlowandAllocation;
    method = method.replace("#ideaId", ideaId);
    method = method.replace("#isMentorChanged", isMentorChanged);
    
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return await postMethod(URL, {}, config);
}
export const PostIdeaMasterApi= async(data, ideaId, nominationId?)=>{
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    let PostIdeaMasterURL= serviceMethod.idea.PostIdeaMaster;
    if (nominationId && nominationId !== '' && ideaId == '') {
        PostIdeaMasterURL = PostIdeaMasterURL + '&isNominationFromEmailLink=true'
        }
    const URL = process.env.REACT_APP_IDEASERVICE + PostIdeaMasterURL;
    return postMethod(URL, data, config);
}

export const SaveIdeaWorkFlowApi=async (ideaId)=>{
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    let method= serviceMethod.idea.SaveIdeaWorkFlow;
    method = method.replace("#ideaId", ideaId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return await postMethod(URL, {}, config);
}

export const saveAndSubmitIdea = async(data, ideaId, nominationId?) => {
    if(ideaId!=''){
        if(data.hasOwnProperty("@odata.context")){
            delete data['@odata.context']
        }
        if(data.hasOwnProperty("IsIndustrySMEInvolved")){
            data['IsIndustrySMEInvolved']= data['IsIndustrySMEInvolved'].toString();
        }
       let getUpdateIdeaTypeData= await GetUpdateIdeaTypeApi(data, ideaId);
       if(getUpdateIdeaTypeData){
        let UpdatedeaMasterObject={
            "ideaMaster":data,
            "msg":getUpdateIdeaTypeData
        }
        let isMentorChanged= getUpdateIdeaTypeData.isMentorChanged;
        let UpdatedeaMasterResponse= await UpdatedeaMasterApi(UpdatedeaMasterObject,ideaId,nominationId='');
        if(UpdatedeaMasterResponse?.Values[0]?.IdeaType==1){
            let response= await UpdateIdeaWorkFlowandAllocationApi(ideaId,isMentorChanged);
            response["Values"]=UpdatedeaMasterResponse?.Values
            return response
        }else{
            return UpdatedeaMasterResponse;
        }

    }
}else{
        let getCreatedIdeaData=  await GetCreateIdeaApi(data, ideaId)
        if(getCreatedIdeaData){
            let PostIdeaMasterObject={
                "ideaMaster":data,
                "msg":getCreatedIdeaData
            }
            let PostIdeaMasterResponse=  await PostIdeaMasterApi(PostIdeaMasterObject,ideaId,nominationId='');
            if(PostIdeaMasterResponse?.Values[0]?.IdeaType==1){
                let response= await SaveIdeaWorkFlowApi(PostIdeaMasterResponse?.Values[0]?.Id);
                response["Values"]=PostIdeaMasterResponse?.Values
                return response
            }else{
                return PostIdeaMasterResponse;
            }
        }
    }

    // let submitIdeaURL = (ideaId !== '') ? `PutIdeaMaster?key=${ideaId}&isSponsorshipRequired=false&challengeType=Public&IsSavedFromTeams=true` : `${serviceMethod.idea.addIdea}`;
    // if (nominationId && nominationId !== '' && ideaId == '') {
    //     submitIdeaURL = submitIdeaURL + '&isNominationFromEmailLink=true'
    // }
    // const config = {
    //     headers: {
    //         IsBatchProcess: true
    //     }
    // };

    // const URL = process.env.REACT_APP_IDEASERVICE + submitIdeaURL;
    // if (ideaId) {
    //     return putMethod(URL, data, config);
    // } else {
    //     return postMethod(URL, data, config);
    // }

}

export const getInnovatorIdeaCount = () => {
    const URL = process.env.REACT_APP_IDEASERVICE + serviceMethod.idea.getIdeaCountForInnovator;
    return getMethod(URL);

}
export const getPatentIdeaCount = () => {
    const URL = process.env.REACT_APP_PATENTDETAILSERVICE + serviceMethod.patentIdea.patentCount;
    return getMethod(URL);
}

export const getTrendingIdeas = (contest, challenge, pageIndex, sortType) => {
    let method = serviceMethod.trendingTopics.trendingTopics
    method = method.replace("#pageIndex", pageIndex);
    method = method.replace("#sortType", sortType);

    const URL = process.env.REACT_APP_IDEASERVICE + method;

    const requestJSON = {
        'ContestIds': contest,
        'ChallengeIds': challenge,

    };
    return postMethod(URL, requestJSON);
}
export const likeService = (challengeId, challengeName, peopleHierarchy, ideaId, isLike) => {
    let method = serviceMethod.likeIdea.like;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#isLike', isLike)
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const requestJSON = {
        "ChallengeId": challengeId,
        "ChallengeName": challengeName,
        "LikeHierarchy": peopleHierarchy
    }
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, requestJSON, config);
}

export const addPrivateIdea = (title, description, sharedUserDetails, mediaFiles, createdBy) => {
    let method = serviceMethod.privateIdea.addPrivateIdea;
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    const requestJSON = {
        "Title": title,
        "Description": description,
        "SharedUserDetails": sharedUserDetails, // array
        "Mediafiles": mediaFiles, // array
        "CreatedBy": createdBy
    }
    return postMethod(URL, requestJSON);
}


export const popularRequests = (contestIds, challengeIds, fetchDate, pageIndex, sortType = 'desc', IsLatestModifiedRequired = false) => {
    let method = serviceMethod.popularIdeas.popularIdeas;
    method = method.replace("#pageIndex", pageIndex);
    method = method.replace("#sortType", sortType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const requestJSON = {
        'ContestIds': contestIds,
        'ChallengeIds': challengeIds,
        "IsLatestModifiedRequired": IsLatestModifiedRequired,
        'GetIdeaTillDate': fetchDate
    };
    return postMethod(URL, requestJSON);
}


export const getEmployeePhoto = (peopleKey) => {
    let method = serviceMethod.profilePhoto.profilePhoto;
    method = method.replace("#EnterpriseId", peopleKey);
    const URL = process.env.REACT_APP_PROFILE_PIC + method;
    let res;
    return getTeamstoken().then((response) => {
        if (response) {
            const token = response;
            if (Object.keys(token).length > 0) {
                res = axios.get(URL, {
                    headers: {
                        'Authorization': "Bearer " + token
                    }
                });
                return res;
            }
        }
        else {
            return res;
        }        
    })
    //return res;
}


export const getPrivateIdeaDetails = (enterpriseId, fetchDate, pageIndex) => {
    let method = serviceMethod.idea.getPrivateIdeaDetails;
    method = method.replace("#enterpriseId", enterpriseId);
    method = method.replace("#pageIndex", pageIndex);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    const requestJSON = {
        'GetIdeaTillDate': fetchDate
    };
    return postMethod(URL, requestJSON);
}

export const getIdeaStatus = (token, contestIds, challengeIds, viewType, ribbonType) => {
    let method = serviceMethod.idea.getIdeaStatus;
    method = method.replace("#ribbonType", ribbonType);
    const requestJSON = {
        'ContestIds': contestIds,
        'ChallengeIds': challengeIds,
        'Type': viewType
    };

    const URL = process.env.REACT_APP_IDEASERVICE + method;

    return postMethod(URL, requestJSON);

}

export const getIdeasByCategory = (categoryIds, challengeIds, contestIds, pageIndex, sortType, technologyIds = [], industryAlignment = []) => {
    let method = serviceMethod.idea.getIdeasByCategory;
    method = method.replace("#pageIndex", pageIndex);
    method = method.replace("#sortType", sortType);

    const requestJSON = {
        'CategoryIds': categoryIds,
        'ChallengeIds': challengeIds,
        'ContestIds': contestIds,
        'TechnologyIds': technologyIds,
        'IndustryAlignment': industryAlignment
    };

    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, requestJSON);
}

export const getInnovatorIdeas = (ideaStatus, contestIds, challengesIds, enterpriseId, fetchDate, sortType, pageIndex) => {
    let method = serviceMethod.idea.getInnovatorIdeas;
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#sortType', sortType);
    method = method.replace('#enterpriseId', enterpriseId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    let data = {
        'ContestIds': contestIds,
        'ChallengeIds': challengesIds,
        'IdeaStatus': ideaStatus,
        'GetIdeaTillDate': fetchDate
    }

    return postMethod(URL, data);
}

export const getInnovatorIdeasWithFilters = (contestIds, challengesIds, ideaStatus, industryAlignment, TechnologyIds, fetchDate, enterpriseId, sortType, pageIndex) => {
    let method = serviceMethod.idea.getInnovatorIdeas;
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#sortType', sortType);
    method = method.replace('#enterpriseId', enterpriseId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    let data = {
        'ContestIds': contestIds,
        'ChallengeIds': challengesIds,
        'IdeaStatus': ideaStatus,
        'IndustryAlignment': industryAlignment,
        'TechnologyIds': TechnologyIds,
        'GetIdeaTillDate': fetchDate
    }

    return postMethod(URL, data);
}

export const popularRequestsWithFilters = (contestIds, challengeIds, industryAlignment, ideaStatus, TechnologyIds, fetchDate, pageIndex, sortType = 'desc') => {
    let method = serviceMethod.popularIdeas.popularIdeas;
    method = method.replace("#pageIndex", pageIndex);
    method = method.replace("#sortType", sortType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const requestJSON = {
        'ContestIds': contestIds,
        'ChallengeIds': challengeIds,
        'IndustryAlignment': industryAlignment,
        'IdeaStatus': ideaStatus,
        'TechnologyIds': TechnologyIds,
        'GetIdeaTillDate': fetchDate
    };
    return postMethod(URL, requestJSON);
}

export const getIdeasForMentorReview = (ideaStatus, contestIds, challengesIds, enterpriseId, fetchDate, sortType, pageIndex) => {
    let method = serviceMethod.idea.GetIdeasForMentorReview;
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#sortType', sortType);
    method = method.replace('#enterpriseId', enterpriseId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    let data = {
        'ContestIds': contestIds,
        'ChallengeIds': challengesIds,
        'IdeaStatus': ideaStatus,
        'GetIdeaTillDate': fetchDate
    }

    return postMethod(URL, data);
}

export const getIdeasByIdeaId = (ideaId, isDemographyCheckDisabled, isStageRole) => {
    console.log('inside idea apijs,', ideaId, isDemographyCheckDisabled, isStageRole);
    console.log('>>token ideaAPijs', getTeamstoken);
    let method = serviceMethod.idea.GetIdeaDetailsByIdeaId;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#isDemographyCheckDisabled', isDemographyCheckDisabled);
    URL = process.env.REACT_APP_IDEASERVICE + method;

    let data = {
        "isStageRole": isStageRole
    }

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const getIdeasByIdeaIdWithIsValidUser = (ideaId, isDemographyCheckDisabled, isStageRole,isValidUser) => {
    let method = serviceMethod.idea.GetIdeaDetailsByIdeaIdWithIsValidUser;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#isDemographyCheckDisabled', isDemographyCheckDisabled);
    method = method.replace('#isValidUser', isValidUser);
    URL = process.env.REACT_APP_IDEASERVICE + method;

    let data = {
        "isStageRole": isStageRole
    }

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}
export const getChallengeTypeAndOrgUnitByChallengeId=(ideaId,enterpriseId)=>{
    let method = serviceMethod.idea.GetChallengeTypeAndOrgUnitByChallengeId;
    URL = process.env.REACT_APP_CHALLENGESERVICE + method;

    let data = {
        "IdeaId":ideaId,
        "UserId":enterpriseId
    }

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const collaborateIdea = (token, collaborateData) => {
    const method = serviceMethod.idea.collaborateSimiliarIdeas;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, collaborateData, config);
}

export const getIdeaCommentsByIdeaId = (ideaId, pageindex, isParticipant) => {
    let method = serviceMethod.idea.GetIdeaCommentsByIdeaId;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#pageindex', pageindex);
    method = method.replace('#isParticipant', isParticipant);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return getMethod(URL);
}

export const AddCommentForIdea = (commentDetails, isParticipant) => {
    let method = serviceMethod.idea.AddCommentForIdea;
    method = method.replace('#isParticipant', isParticipant);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return postMethod(URL, commentDetails, config);
}


export const getdeaWorkFlowDetailsByIdeaId = (ideaId) => {
    let method = serviceMethod.idea.GetdeaWorkFlowDetailsByIdeaId;
    method = method.replace('#ideaId', ideaId);
    const URL = process.env.REACT_APP_IDEAWFLOWSERVICE + method;
    return getMethod(URL);
}

export const getTrendingTopics = (type) => {
    let method = serviceMethod.trending.trendingCategories;
    method = method.replace('#categoryType', type);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return getMethod(URL);
}

export const savePrivateIdea = (obj) => {
    let method = serviceMethod.privateIdea.addPrivateIdea;
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return postMethod(URL, obj);
}

export const updatePrivateIdea = (obj) => {
    let method = serviceMethod.privateIdea.updatePrivateIdea;
    method = method.replace('#privateIdeaId', obj.PrivateIdeaId)
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return putMethod(URL, obj);
}


export const getUploadPreSignedUrl =(fileName,id, type)=>{
    let method = serviceMethod.contest.getUploadPreSignedUrl;
    method = method.replace('#fileName', fileName);
    method = method.replace('#id', id);
    method = method.replace('#type', type);
    const URL = process.env.REACT_APP_DOCUMENTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);

}


export const downloadPreSignedUrl = (file,propertyName, id,type="mediafile") => {
    let method = '';
    if(propertyName && propertyName != null){
        method = serviceMethod.presignedUrl.GetDownloadPreSignedUrlWithProp;
    }
    else{
        method = serviceMethod.presignedUrl.GetDownloadPreSignedUrl;
    }
    method = method.replace('#FileName', file && file?.name ? file?.name : file);
    method = method.replace('#Id', id);
    method = method.replace('#Type', type);
    if(propertyName && propertyName != null){
        let propUpdated = propertyName.split('_').filter((prop,ind) => ind > 0).join('_');
        method = method.replace('#propertyName', propUpdated);
    }
    const URL = process.env.REACT_APP_DOCUMENTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}
export const uploadPreSignedUrlPatent = (file, id) => {
    let method = serviceMethod.presignedUrl.GetUploadPatentPreSignedUrl;
    method = method.replace('#FileName', file.name);
    method = method.replace('#Id', id);
    const URL = process.env.REACT_APP_DOCUMENTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const uploadPreSignedUrl = (file, id, type="mediafile", propertyName?) => {
    let method = serviceMethod.presignedUrl.GetUploadPreSignedUrl;
    method = method.replace('#FileName', file?.name ?  file.name : file?.FileNm ? file.FileNm : file);
    method = method.replace('#Id', id);
    method = method.replace('#Type', type);
    if(propertyName && propertyName != ""){
        method = `${method}&propertyName=${propertyName}`
    }

    const URL = process.env.REACT_APP_DOCUMENTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const UpdatePrivateIdeaDetailsAfterFileUpload = (file, ideaId, fileUrl) => {
    let method = serviceMethod.presignedUrl.UpdatePrivateIdeaDetailsAfterFileUpload;
    method = method.replace('#ideaId', ideaId);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    let payload= {
        "FileNm":file.name,
        "FileSize":file.size,
        "FileURL":fileUrl,
        "FileType": file.type,
        "FileCreatedOn": new Date()
    }
    const config = {
        headers: {
            IsBatchProcess: true,
            // 'content-type': 'multipart/form-data',
        }
    };
    return postMethod(URL, payload, config);
}

export const UpdateIdeaDetailsAfterFileUpload = (file, ideaId, fileUrl, propDetails?) => {
    let method = serviceMethod.presignedUrl.UpdateIdeaDetailsAfterFileUpload;
    method = method.replace('#ideaId', ideaId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    let fileType = `.${file.name.split('.').pop()}`
    let payload= {
        "FileNm":file.name,
        "FileSize":file.size,
        "FileURL":fileUrl,
        "FileType": fileType,
        "FileCreatedOn": new Date(),
    }
    if(propDetails){
        payload = {...payload,
             "IsCustom":propDetails?.IsCustom, 
            "PropertyName":propDetails?.PropertyName
        }
    }
    const config = {
        headers: {
            IsBatchProcess: true,
            // 'content-type': 'multipart/form-data',
        }
    };
    return postMethod(URL, payload, config);
}

export const uploadFile = (file, ideaId) => {
    // https://myinnovation-stage-webapi.accenture.com/DocumentService/GetUploadPreSignedUrl?FileName=8ca4d2ea-7623-4d15-855e-9c5a372af4b5c.csv&Id=2accca42-2863-42c1-a3dd-c8154142f7f7&Type=InviteeFiles
    let method = serviceMethod.privateIdea.addFile;
    method = method.replace('#ideaId', ideaId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const formData = new FormData();
    formData.append('fileName', file);
    const config = {
        headers: {
            IsBatchProcess: true,
            'content-type': 'multipart/form-data',
        }
    };
    return postMethod(URL, formData, config);
}



export const deleteFile = (data) => {
    const method = serviceMethod.privateIdea.deleteFile
    const URL = process.env.REACT_APP_IDEA_STAGESERVICE_LAMBDA + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);

}

export const deleteFilePrivate = (data) => {
    const method = serviceMethod.privateIdea.deletePrivateIdeaFile
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);

}

export const getPrivateIdeaDetailsByIdeaId = (privateIdeaId) => {
    let method = serviceMethod.privateIdea.getPrivateIdeaById;
    method = method.replace('#privateIdeaId', privateIdeaId)
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return getMethod(URL);

}

//get PromotionFeed api call
export const getPromotionalFeed = (fetchDate, orgUnits) => {
    let method = serviceMethod.idea.getPromotionalFeed;
    const URL = process.env.REACT_APP_PROMOTIONFEEDSERVICE + method;
    const requestJSON = {
        'GetIdeaTillDate': fetchDate,
        'PeopleHierarchy': orgUnits,
    };
    return postMethod(URL, requestJSON);
}

export const getPromotionalFeedBinaryData = (promotionalfeedid) => {
    let method = serviceMethod.idea.getPromotionalFeedBinaryData;
    method = method.replace('#promotionalfeedid', promotionalfeedid)
    const URL = process.env.REACT_APP_PROMOTIONFEEDSERVICE + method;
    return getMethod(URL);
}

//sharedIdas
export const getShareIdeaDetails = (EnterpriseId, pageIndex) => {
    let method = serviceMethod.sharedIdea.getShareIdeaDetails;
    method = method.replace('#EnterpriseId', EnterpriseId);
    method = method.replace('#pageindex', pageIndex);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return getMethod(URL);
}

//private share ideas
export const getSharePrivateIdeaDetails = (EnterpriseId, pageIndex) => {
    let method = serviceMethod.sharedIdea.getSharePrivateIdeaDetails;
    method = method.replace('#EnterpriseId', EnterpriseId);
    method = method.replace('#pageindex', pageIndex);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return getMethod(URL);
}

//user preference data
export const userPreference = (EnterpriseId) => {
    let method = serviceMethod.landingPage.getUserPreferenceData;
    method = method.replace('#EnterpriseId', EnterpriseId);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return getMethod(URL);
}

export const savePinDetailsPrivateIdea = (PrivateIdeaId) => {
    let method = serviceMethod.pinIdea.savePinDetailsPrivateIdea;
    method = method.replace('#PrivateIdeaId', PrivateIdeaId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, {});
}

export const savePinDetailsSubmittedIdea = (IdeaId) => {
    let method = serviceMethod.pinIdea.savePinDetailsSubmittedIdea;
    method = method.replace('#IdeaId', IdeaId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, {});
}

export const addPrivateIdeaShareUserDetails = (PrivateIdeaId, peopleIds, message,  isCallFromMessageExtension='false') => {
    let method = serviceMethod.sharedIdea.addPrivateIdeaShareUserDetails;
    method = method.replace('#PrivateIdeaId', PrivateIdeaId);
    method = method.replace('#isCallFromMessageExtension',isCallFromMessageExtension)
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    const requestJSON = 
        { "EnterpriseIdList": peopleIds, "Comment": message };
    return postMethod(URL, requestJSON);
}

export const addIdeaShareUserDetails = (ideaId, peopleIds, message, isCallFromMessageExtension='false') => {
    let method = serviceMethod.sharedIdea.addIdeaShareUserDetails;
    method = method.replace('#IdeaId', ideaId);
    method = method.replace('#isCallFromMessageExtension',isCallFromMessageExtension);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const requestJSON = {
        "EnterpriseIdList": peopleIds,
        "Comment": message
    };
    return postMethod(URL, requestJSON);
}


//applied patent ideas
export const getAppliedPatentData = (data) => {
    let method = serviceMethod.patentability.getAppliedPatentIdeas;
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);

}
export const getAvailablePatentData = (data) => {
    let method = serviceMethod.patentability.getAvailablePatentIdeas;
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);

}

export const getIdeaDetailsForPatentByIdea = (ideaNumber) => {
    let method = serviceMethod.patentability.getIdeaDetailsForPatentByIdeaNumber;
    method = method.replace('#ideaNumber', ideaNumber)
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return getMethod(URL);
}

export const getIdeaDetailsForPatentView = (patentId) => {
    let method = serviceMethod.patentability.getPatentDetailsByPatentId;
    method = method.replace('#patentId', patentId)
    const URL = process.env.REACT_APP_PATENTDETAILSERVICE + method;
    return getMethod(URL);
}

export const deletePatent = (patentId) => {
    let method = serviceMethod.patentability.deletePatentability;
    method = method.replace('#PatentId', patentId)
    const URL = process.env.REACT_APP_PATENTDETAILSERVICE + method;
    return postMethod(URL, {});
}
export const savePatent = (obj) => {
    let method = serviceMethod.patentability.savePatentDetails;
    const URL = process.env.REACT_APP_PATENTDETAILSERVICE + method;
    let updatedObj = {
        "data": obj
    }
    return postMethod(URL, updatedObj);
}
export const addPatentComments = (obj) => {
    let method = serviceMethod.patentability.addPatentComments;
    const URL = process.env.REACT_APP_PATENTDETAILSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true

        }
    };
    return postMethod(URL, obj, config);
}
export const getPatentComments = (pageIndex, patentId) => {
    let method = serviceMethod.patentability.getPatentComments;
    method = method.replace('#patentId', patentId);
    method = method.replace('#pageIndex', pageIndex)
    const URL = process.env.REACT_APP_PATENTDETAILSERVICE + method;
    return getMethod(URL);
}
export const uploadPatentFile = (file, patentId,fileUrl) => {
    let method = serviceMethod.patentability.addPatentSupportingFiles;
    method = method.replace('#PatentId', patentId);
    const URL = process.env.REACT_APP_PATENTDETAILSERVICE + method;
    const formData = new FormData();
    formData.append('fileName', file);

    let payload= {
        "FileNm":file.name,
        "FileSize":file.size,
        "FileUrl":fileUrl,
        "FileType": "."+ file.name?.split('.')[1],
        "FileCreatedOn": ''
    }

    

    return postMethod(URL, payload);
}
export const deletePatentFile = (data) => {
    const method = serviceMethod.patentability.deletePatentFile;
    const URL = process.env.REACT_APP_PATENTDETAILSERVICE + method;

    return postMethod(URL, data);

}

//

export const userExpertise = (EnterpriseId) => {
    let method = serviceMethod.landingPage.getUserExpertiseData;
    method = method.replace('#EnterpriseId', EnterpriseId);
    const URL = process.env.REACT_APP_USERSERVICE + method;
    return getMethod(URL);
}
export const technologyDetails =()=>{
    let method = serviceMethod.idea.getTechnologyDetails;
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return getMethod(URL);

}

export const getFilterDetail = () => {
    let method = serviceMethod.idea.getFilterDetail;
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return getMethod(URL);
}
// Add user expertise
export const addUserExpertise = (data) => {
    let method = serviceMethod.expertisePrefrences.addUserExpertiseData;
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return postMethod(URL, data);
}

// Add user preference
export const addUserPreference = (data) => {
    let method = serviceMethod.expertisePrefrences.addUserPreferenceData;
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return postMethod(URL, data);
}


//unpinned Idea

export const savePinUnPinDetails = (ideaId, isPrivateIdea, isPin) => {
    let method = serviceMethod.pinIdea.savePinUnPinDetails;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#isPrivateIdea', isPrivateIdea);
    method = method.replace('#isPin', isPin);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return postMethod(URL, {});

}

export const AddShareIdeaComments = (obj) => {
    let method = serviceMethod.sharedIdea.AddShareIdeaComments;
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return postMethod(URL, obj);
}

export const GetShareComments = (IdeaId, PageIndex) => {
    let method = serviceMethod.sharedIdea.GetShareComments;
    method = method.replace('#IdeaId', IdeaId);
    method = method.replace('#pageIndex', PageIndex); 
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return getMethod(URL);
}

export const addPinUnpinDetailsPromotionalFeed = (promtionalFeedId, isPin) => {
    let method = serviceMethod.pinIdea.addPinUnpinDetailsPromotionalFeed;
    method = method.replace('#promtionalFeedId', promtionalFeedId);
    method = method.replace('#isPin', isPin);
    const URL = process.env.REACT_APP_PROMOTIONFEEDSERVICE + method;
    return putMethod(URL, {});
}


/** Delete Private Idea */

export const deletePrivateIdea = (privateIdeaId) => {
    let method = serviceMethod.privateIdea.deletePrivateIdea;
    method = method.replace('#privateIdeaId', privateIdeaId);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return putMethod(URL, {});
}


// get pinned data
export const getPinnedData = (fetchDate, contestIds) => {
    let method = serviceMethod.pinIdea.getPinIdea;
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const requestJSON = {
        'GetIdeaTillDate': fetchDate,
        'ContestIds': contestIds
    };
    return postMethod(URL, requestJSON);
}

// get pinned private data
export const getPrivatePinnedData = (fetchDate) => {
    let method = serviceMethod.pinIdea.getPinPrivateIdea;
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    const requestJSON = {
        'GetIdeaTillDate': fetchDate
    };
    return postMethod(URL, requestJSON);
}


/** Delete public idea */

export const deleteIdea = (ideaId) => {
    let method = serviceMethod.deleteIdea.deleteIdea;
    method = method.replace('#ideaId', ideaId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: false
        }
    };
    return deleteMethod(URL, '', config);
}

export const getSharedIdeasFromPrivateIdeas = (enterpriseId, sortType, data) => {
    let method = serviceMethod.sharedIdea.getSharedIdeasFromPrivateIdeas;
    method = method.replace('#EnterpriseId', enterpriseId);
    method = method.replace('#sortType', sortType);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    return postMethod(URL, data);

}
export const getSharedIdeasFromPublicIdeas = (enterpriseId, sortType, data) => {
    let method = serviceMethod.sharedIdea.getSharedIdeasFromPublicIdeas;
    method = method.replace('#EnterpriseId', enterpriseId);
    method = method.replace('#sortType', sortType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);

}

//to get the idea count as per the role


export const getIdeaCountAsPerRole = (challengeIds, viewType) => {
    let method = serviceMethod.idea.getIdeaCountAsPerRole;
    const URL = process.env.REACT_APP_IDEA_STAGESERVICE_LAMBDA + method;
    const requestJSON = {
        'ChallengeIds': challengeIds,
        'Type': viewType
    };

    return postMethod(URL, requestJSON);

}
// Pinned promotional feed
export const getPromotionalPinnedFeed = (fetchDate, orgUnits) => {
    let method = serviceMethod.idea.getPromotionalPinnedFeed;
    const URL = process.env.REACT_APP_PROMOTIONFEEDSERVICE + method;
    const requestJSON = {
        'GetIdeaTillDate': fetchDate,
        'PeopleHierarchy': orgUnits,
    };
    return postMethod(URL, requestJSON);
}



export const getFiltersRoleWise = (challengeIds) => {

    let method = serviceMethod.idea.getFiltersRoleWise;
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const requestJSON = {
        'ChallengeIds': challengeIds,
        'IdeaStatus': [""],
        'Type': 'Public'
    };

    return postMethod(URL, requestJSON);


}



//mentor 

export const addCommentsMentor = (requestJSON, isParticipant) => {

    let method = serviceMethod.mentor.addParticipantComments;
    method = method.replace('#isParticipant', isParticipant);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, requestJSON, config);


}
export const mentorSubmit = (mentorAction, ideaId, isIdeaSponsorshipNeeded, challengeType, requestJSON) => {
    let method = serviceMethod.mentor.mentorSubmit;
    method = method.replace('#status', mentorAction);
    method = method.replace('#ideaIdValue', ideaId);
    method = method.replace('#isSponsorshipRequired', isIdeaSponsorshipNeeded);
    method = method.replace('#challengeType', challengeType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return putMethod(URL, requestJSON, config);

}
export const getChallengeById = (challengeId, isvaliduser) => {
    let method = serviceMethod.mentor.getChallengeById;
    method = method.replace('#challengeId', challengeId);
    method = method.replace('#isvaliduser', isvaliduser);
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    const URL = process.env.REACT_APP_CHALLENGESERVICE + method;
    return getMethod(URL, config);


}
export const getChallAndContestByRole = (pageIndex, role, data) => {
    let method = serviceMethod.mentor.getFiltersRoleWise;
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#role', role);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);


}
export const getIdeaStatusMentor = (ribbonType, data) => {
    let method = serviceMethod.mentor.getIdeaStatus;
    method = method.replace('#ribbonType', ribbonType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);


}
export const getMentorIdea = (pageIndex, sortType,data) => {
    let method = serviceMethod.mentor.getReviewSummary;
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#sortType', sortType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);


}

export const updateIdeaStatusAsUnderImplementation = (ideaId, status) => {
    let method = serviceMethod.idea.updateIdeaStatusAsUnderImplementation;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#status', status);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, {});
}

export const updateIdeaStatusAsImplementedOrNot = (ideaId, status, data = {}) => {
    let method = serviceMethod.idea.updateIdeaStatusAsImplementedOrNot;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#status', status);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);
}

export const needMoreInformation = (ideaId, challengeType, data) => {
    let method = serviceMethod.idea.needMoreInformation;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#challengeType', challengeType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return putMethod(URL, data);
}
export const getIdeaStatusPatent = (ribbonType, data) => {

    let method = serviceMethod.mentor.getPatentInitiatedIdeaCount;
    method = method.replace('#ribbonType', ribbonType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    console.log('>>getIdeaStatusPatent<<', ribbonType, data, URL);
    return postMethod(URL, data);


}
export const getMentorPatentData = (pageIndex, data) => {
    let method = serviceMethod.mentor.getPatentInitiatedIdeaListTeams;
    method = method.replace('#pageIndex', pageIndex);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);


}

//to get challenges by contestId
export const getChallengeByContestId = (contestId, pageIndex = 0) => {
    let method = serviceMethod.challenge.getChallengeByContestId;
    method = method.replace('#pageIndex', pageIndex);
    const data = {
        ChallengeStatus: [],
        ContestId: contestId
    };

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    const URL = process.env.REACT_APP_CHALLENGESERVICE + method;
    return postMethod(URL, data, config);
}

export const updateImplementerAction = (ideaId, action) => {
    let method = serviceMethod.idea.updateImplementerAction;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#action', action);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return putMethod(URL, {});
}

export const getIdeasForImplementer = (enterpriseId, contestIds, challengesIds, fetchDate, ideaStatus = [], pageIndex = 1, sortType = 'desc', isCallFromTeams = true) => {
    let method = serviceMethod.idea.getIdeasForImplementer;
    method = method.replace('#enterpriseId', enterpriseId);
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#sortType', sortType);
    method = method.replace('#isCallFromTeams', isCallFromTeams);
    const URL = process.env.REACT_APP_IDEASERVICE + method;

    let data = {
        'ContestIds': contestIds,
        'ChallengeIds': challengesIds,
        'IdeaStatus': ideaStatus,
        'GetIdeaTillDate': fetchDate
    }
    return postMethod(URL, data);
}

export const updateIdeaStatusAsClosed = (ideaId, status, data = {}) => {
    let method = serviceMethod.idea.updateIdeaStatusAsClosed;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#status', status);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);
}

//nudge preference 
export const getNudgePreference = () => {
    let method = serviceMethod.nudges.getNudgeUserPreference;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    const URL = process.env.REACT_APP_NUDGESERVICE + method;
    return getMethod(URL, config);


}
export const saveNudgePreference = (data) => {
    let method = serviceMethod.nudges.saveNudgeUserPreference;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    const URL = process.env.REACT_APP_NUDGESERVICE + method;
    return postMethod(URL, data, config);
}

//like and unlike private idea

export const LikeUnlikePrivateIdea = (privateIdeaId, isLike, peopleHierarchy) => {
    let method = serviceMethod.privateIdea.LikeUnlikePrivateIdea;
    method = method.replace('#privateIdeaId', privateIdeaId);
    method = method.replace('#isLike', isLike);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    const requestJSON = {
        "LikeHierarchy": peopleHierarchy
    }
    return postMethod(URL, requestJSON);
}

// Advance filter api call

export const advanceFilter = (data,pageIndex, sortType="desc") => {
    let method = serviceMethod.exploreAll.advanceFilter;
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#sortType', sortType);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, data);
}

// collaborate idea chat details 

export const saveCollaborationChatDetail = (collaborationChatDetailObject) => {
    let method = serviceMethod.PIB.saveCollaborationChatDetail;
    const URL = process.env.REACT_APP_CHATBOTSERVICE + method;
    return postMethod(URL, collaborationChatDetailObject);
}

export const getCollaborationChatDetail = (IdeaId) => {
    let method = serviceMethod.PIB.getCollaborationChatDetail;
    method = method.replace('#IdeaId', IdeaId);
    const URL = process.env.REACT_APP_CHATBOTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const getInnovatorIdeasForLICInTab = (ideaStatus, contestIds, challengesIds, enterpriseId, fetchDate) => {
    let method = serviceMethod.LICTab.contestIdeas;
    method = method.replace('#EnterpriseId', enterpriseId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    let data = {
        'ContestIds': contestIds,
        'ChallengeIds': challengesIds,
        'IdeaStatus': ideaStatus,
        'GetIdeaTillDate': fetchDate
    }

    return postMethod(URL, data);
}

export const getPrivateIdeasForLICInTab = (enterpriseId, fetchDate) => {
    let method = serviceMethod.LICTab.privateIdeas;
    method = method.replace("#EnterpriseId", enterpriseId);
    const URL = process.env.REACT_APP_PRIVATEIDEAMASTERSERVICE + method;
    const requestJSON = {
        'GetIdeaTillDate': fetchDate
    };
    return postMethod(URL, requestJSON);
}

export const GetIdeaTemplateMappingByContestId=(contestId)=>{
    let method = serviceMethod.dynamicFormRender.GetIdeaTemplateMappingByContestId;
    method = method.replace('#contestId', contestId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}


export const getDiamondClients = () => {
    let method = serviceMethod.V360.GetV360DiamondClients;
    // method = method.replace("#peopleKey", peopleKey);
    const URL = process.env.REACT_APP_MARKETSERVICE + method;
    return getMethod(URL);
}

export const getMarketAndMarketUnitDetailsForV360=(marketId,isMarket)=>{
    let method = serviceMethod.V360.GetMarketAndMarketUnitDetailsForV360;
    method = method.replace('#marketId', marketId);
    method = method.replace('#isMarket', isMarket);
    const URL = process.env.REACT_APP_MARKETSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const getV360ContractorExceptionList = () => {
    let method = serviceMethod.V360.GetV360ContractorExceptionList;
    // method = method.replace("#peopleKey", peopleKey);
    const URL = process.env.REACT_APP_MARKETSERVICE + method;
    return getMethod(URL);
}


export const IsV360IdeaDuplicate = (contestId, categoryId, clientName, enterpriseId, clientPropertyName, marketUnitName) => {
    let method = serviceMethod.V360.IsV360IdeaDuplicate;
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const requestJSON = {
        "ContestId": contestId,
        "CategoryId": categoryId,
        "Innovator": enterpriseId,
        "ClientName": clientName,
        "ClientPropertyName": clientPropertyName,
        "MarketUnitName": marketUnitName
    };
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, requestJSON, config);
}

export const getInnovatorIdeasOnly = (ideaStatus, contestIds, challengesIds, enterpriseId, fetchDate, sortType, pageIndex, historicContestListSelected?) => {
    let method = serviceMethod.idea.getInnovatorIdeasOnly;
    method = method.replace('#pageIndex', pageIndex);
    method = method.replace('#sortType', sortType);
    method = method.replace('#enterpriseId', enterpriseId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    let data = {
        'ContestIds': contestIds,
        'ChallengeIds': challengesIds,
        'IdeaStatus': ideaStatus,
        'GetIdeaTillDate': fetchDate
    }

    if(historicContestListSelected && historicContestListSelected?.length > 0){
        data = {
            ...data,
            "IsV360HistoricalSubmission": true,
            "V360PreviousFYContestIdList": historicContestListSelected
        }
    }

    return postMethod(URL, data);
}

export const getIdeasForVoting=(contestDetails,pageIndex)=>{
    let method = serviceMethod.voting.getIdeasForVoting;
    method = method.replace('#pageIndex', pageIndex);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, contestDetails);
}

export const getIdeaCountForVoting=(contestDetails)=>{

    let method = serviceMethod.voting.GetIdeaCountForVoting;
   // method = method.replace('#pageIndex', pageIndex);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, contestDetails);

}

export const saveUserVotingDetail = (ideaDetails) => {
    let method = serviceMethod.voting.saveUserVotingDetail;
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, ideaDetails);
}

export const deleteUserVotingDetail = (ideaId, enterpriseId) => {
    let method = serviceMethod.voting.deleteUserVotingDetail;
    method = method.replace('#ideaId', ideaId);
    method = method.replace('#enterpriseId', enterpriseId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL,{},config);
}

export const isValidVote =(ideaDetails)=>{
    let method = serviceMethod.voting.IsValidVote;
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, ideaDetails);
}

export const GetIdeaMasterAwardIdeas =(contestId)=>{
    let method = serviceMethod.V360.GetIdeaMasterAwardIdeas;
    method = method.replace('#contestId', contestId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL,{}, config);
}

export const IsValidMentor = (mentorId) => {
    let method = serviceMethod.idea.IsValidMentor;
    method = method.replace('#SelectedMentorId', mentorId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const GetIdeaMasterAwardIdeasByContestId =(contestId)=>{
    let method = serviceMethod.V360.GetIdeaMasterAwardIdeasByContestId;
    method = method.replace('#ContestId', contestId);
    method = method.replace('#AwardSequence', 2);    
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const GetIdeaAwardCountDetail =(contestId)=>{
    let method = serviceMethod.V360.GetIdeaAwardCountDetail;
    method = method.replace('#ContestId', contestId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const UpdateMentorRoleStatus=(mentorRoleStatus, mentorRoleStatusPayload )=>{
    let method = serviceMethod.mentor.UpdateMentorRoleStatus;
    method = method.replace('#MentorRoleStatus', mentorRoleStatus);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, mentorRoleStatusPayload);
}

export const getV360NonDiamondClients = (clientName) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let method = serviceMethod.V360.GetV360NonDiamondClients;
    const URL = process.env.REACT_APP_MARKETSERVICE + method;
    let data = { "clientName": clientName };
    return postMethod(URL, data, { cancelToken: cancelToken.token });
}

export const getV360CalidsbyClient = (clientName) => {
    let method = serviceMethod.V360.GetV360CalidsbyClient;
    const URL = process.env.REACT_APP_MARKETSERVICE + method;
    let data = { "clientName": clientName }
    return postMethod(URL, data);
}

export const IDFDuplicateIdea = (IDFNumber, IdeaId = '') => {
    let method = serviceMethod.idea.isIDFNumber;
    method = method.replace('#IDFNumber', IDFNumber);
    method = method.replace('#IdeaId', IdeaId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}


export const getTotalCountForVoting = (contestId) => {

    let method = serviceMethod.voting.getTotalCountForVoting;
    // method = method.replace('#pageIndex', pageIndex);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    let payload = {
        "ContestId": contestId,
        "ChallengeIds": [],
        "MarketUnitIds": [],
        "MarketIds": []
    }
    return postMethod(URL, payload);

}

export const checkForDuplicateIdea = (payload) => {
    let method = serviceMethod.idea.checkForDuplicateIdea;
    method = method.replace('#isSponsorshipRequired', 'false'); 
    method = method.replace('#challengeType', 'public'); 
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, payload);
}

export const getConfigurationMasterStringValue = (configurationmasterkey) => {
    let method = serviceMethod.idea.getConfigurationMasterStringValue;
    method = method.replace('#Configurationmasterkey', configurationmasterkey);
    const URL = process.env.REACT_APP_CONFIGURATIONSERVICE + method;
    // const config = {
    //     headers: {
    //         IsBatchProcess: true
    //     }
    // };
    return getMethod(URL);
}






