import React, { Component } from 'react'
import "./page-loader.scss"
import { Spinner, FluentProvider } from '@fluentui/react-components';

interface PageLoaderProps{
    hidden:boolean;
}

export class PageLoader extends Component<PageLoaderProps,{}> {
    render() {
        const hiddenState= this.props.hidden;
        return (
                <div className="loaderInner" hidden={hiddenState} >
                    <Spinner className="loader-icon" size="medium" />
                </div>
        )
    }
}
