// @ts-nocheck
import "bootstrap/dist/css/bootstrap.css";
import "./custom.scss";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import Modal from 'react-modal';
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import { store , persistor } from "./redux-sharedframework/store";
import ErrorBoundary from "./common/ErrorBoundary";
import { PersistGate } from 'redux-persist/integration/react';
import "./assets/iconify/iconify.min.js";
import { initializeIcons } from '@fluentui/react';
initializeIcons();

Modal.setAppElement('#root');

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ErrorBoundary>
    <Provider store={store}>
        <Router history={history}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </Router>
    </Provider>
  </ErrorBoundary>
);

/*registerServiceWorker();*/
