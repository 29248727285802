import React, { useEffect, useState } from 'react';
import { getContestAndChallengeByDemographic } from '../../api/PeopleApi';
import ActiveContest from './active-contest';
import { Spinner } from "@fluentui/react-components";
var uniqid = require('uniqid');
import { useHistory } from "react-router-dom";

interface RSActiveContest {
    contests?: any;
}
let GLCContestID= process.env.REACT_APP_GLC2022_CONTEST_ID;

const RSActiveContests = React.memo(({ contests }: RSActiveContest) => {
    const [itemsToShow, setItemsToShow] = useState(3);
    const [expanded, setExpanded] = useState(false);
    const history = useHistory();

    const showMore = () => {
        itemsToShow === 3 ? (
            setItemsToShow(contests.length),
            setExpanded(true)
        ) : (
            setItemsToShow(3),
            setExpanded(false)
        )
        history.push({
            pathname: "/view-all-active-contests",
            state: { contests: contests },
        });
    }


    return (

        <div className="right-side padding-active-contest">
            <div className="contest-wrapper">
                {contests?.length > 0 ?
                    (contests?.length === 1 && contests[0] === "No Active Contest Available" ?
                        <div className="skill-tags-nodata fs-13 ml-3 mb-2">No ongoing contest available</div> :
                        <>
                        {
                                contests.filter(contest => (contest.ContestId ==GLCContestID || contest.ContestNm.toLowerCase()=="global leadership council 2022") && (contest.IsDynamicContest) || (contest.IsDynamicContest === false && contest.IsIdeaSubmissionClosed === false)).filter(contest => contest?.IsFeatureCampain === true)
                                    .sort((a, b) => -b.CloseDate?.split('/').reverse().join('').localeCompare((a.CloseDate?.split('/').reverse().join(''))))
                                    .map(contest => {
                                        return <ActiveContest contest={contest} key={uniqid()} />
                                    })
                        }
                            {
                                contests.filter(contest => (contest.ContestId !=GLCContestID || contest.ContestNm.toLowerCase()!="global leadership council 2022") && (contest.IsDynamicContest) || (contest.IsDynamicContest === false && contest.IsIdeaSubmissionClosed === false)).filter(contest => contest?.IsFeatureCampain === true)
                                    .sort((a, b) => -b.CloseDate?.split('/').reverse().join('').localeCompare((a.CloseDate?.split('/').reverse().join(''))))
                                    .map(contest => {
                                        return <ActiveContest contest={contest} key={uniqid()} />
                                    })
                            }
                            {
                                contests.filter(contest => (contest.IsDynamicContest) || (contest.IsDynamicContest === false && contest.IsIdeaSubmissionClosed === false)).filter(contest => contest?.IsFeatureCampain === false)
                                    .sort((a, b) => -b.CloseDate?.split('/').reverse().join('').localeCompare((a.CloseDate?.split('/').reverse().join(''))))
                                    .slice(0, itemsToShow)
                                    .map(contest => {
                                        return <ActiveContest contest={contest} key={uniqid()} />
                                    })
                            }

                            {/* {
                                contests.filter(x => x.ContestNm.trim().toLowerCase().includes("global technology innovation contest")
                                    || x.ContestNm.trim().toLowerCase().includes("global tech innovation contest")
                                    || x.ContestNm.trim().toLowerCase().includes("living innovation channel"))
                                    .sort((a, b) => { if (a.ContestNm.trim().toLowerCase() < b.ContestNm.trim().toLowerCase()) return -1; else return 1 })
                                    .map(contest => {
                                        return <ActiveContest contest={contest} key={uniqid()} />
                                    })}

                            {contests.filter(x => !(x.ContestNm.trim().toLowerCase().includes("global technology innovation contest")
                                || x.ContestNm.trim().toLowerCase().includes("global tech innovation contest")
                                || x.ContestNm.trim().toLowerCase().includes("living innovation channel")))
                                .slice(0, itemsToShow)
                                .map(contest => {
                                    return <ActiveContest contest={contest} key={uniqid()} />
                                })}
                            */}
                            {contests?.length >= 1 ?
                                <a onClick={showMore} className="show-more-link">{
                                    <span className="bottom-show-more">Show All</span>
                                    //expanded ?
                                    //    <span>Show less</span> :
                                    //    <span>Show more</span>
                                }</a> : null}
                        </>)
                    : <Spinner label="" labelPosition="after" size="medium" />}
            </div>
        </div>
    )

});

export default RSActiveContests;