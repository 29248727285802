// @ts-nocheck
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactModal from "react-modal";
import { useTeams } from "@microsoft/teamsfx-react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
// initializeIcons();
import { RootStateOrAny, useSelector } from "react-redux";
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

const RaiseIncidentPopup = ({ showModal, handleCloseModal, enterpriseId }) => {
    const [{ theme }] = useTeams();
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    const modelStyles = {
        overlay: {
            backgroundColor: 'rgba(4, 16, 29, 0.7)',
        },
        content: {
            width: '54.26vw',
            maxWidth: '54.26vw',
            border: '1px solid black',
            borderRadius: 'none',
            padding: '1.812vw'
        }
    }

    const overlayClassName = {
        base: "animation-modal-overlay feedback-wrapper-mobile-popup",
        afterOpen: "animation-modal-overlay--after-open",
        beforeClose: "animation-modal-overlay--before-close"
    };

    return (
        <FluentProvider theme={teamsLightTheme}>
            <ReactModal isOpen={showModal} style={modelStyles} id="raiseTicketModal" closeTimeoutMS={500}
                overlayClassName={overlayClassName}
                shouldCloseOnOverlayClick={false} onRequestClose={handleCloseModal}>
                <div className={isMobile? "feedback-wrapper-mobile" :"feedback-wrapper"}>
                    <div className="feedback-mail-header" style={{ marginBottom: '0.606vw', borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                        <div className="header" style={{ width: '98%' }}>
                            <h6>MyInnovation Support</h6>
                        </div>
                        <div className="close-div">
                            <button className="close-button" data-close aria-label="Close modal" type="button" onClick={handleCloseModal}>
                                <span aria-hidden="true"> X </span>
                            </button>
                        </div>
                    </div>
                    <div className="incident-section">
                        <div className="incident-label">
                            <span>Incidents</span>
                        </div>
                        <div className="incident-content">
                            <span>If you are experiencing any issue with the application then you should create an <a href="https://support.accenture.com/support_portal?id=acn_service_catalog_dp&sys_id=c9856641139a6600380ddbf18144b05f&sysparm_load=7220" target="_blank">Incident</a></span>
                        </div>
                    </div>
                    <div className="incident-section" style={{ marginBottom: '0' }}>
                        <div className="incident-label">
                            <span>Service Requests</span>
                        </div>
                        <div className="incident-content">
                            <span>You can contact us through a <a href="https://support.accenture.com/support_portal?id=it_services_order&articleNumber=KB0072225&sys_id=cafd77e3dbab3780b3535eea4b96198d&sysold=7ee09b61131aa600380ddbf18144b032&sysparm_load=7220" target="_blank">Service Request</a> for most inquiries:</span>
                        </div>
                        <div className="incident-items">
                            <ul>
                                <li><span>Access requests</span></li>
                                <li><span>Questions about application functionality</span></li>
                                <li><span>Enhancement requests</span></li>
                                <li><span>General questions</span></li>
                                <li><span>And more e.g. if you see information that is not accurate in your deal, that will be a Service Request.</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ReactModal>
        </FluentProvider>
    )
}

export default connect()(RaiseIncidentPopup);