import {types} from './types';
export const fetchFilterData=(data)=>{
    //console.log("filter action",data);
    return {
        type: types.SEND_FILTER_REQUEST,
        payload:data
    }
}

export const removeFilterItem = (data) => {
    //console.log("filter action", data);
    return {
        type: types.REMOVE_FILTER_ITEM,
        payload: data
    }
}

export const selectAllFilterItems = (data) => {
    return {
        type: types.SELECT_ALL_FILTER_ITEMS,
        payload: data
    }
}

export const unSelectAllFilterItems = (data) => {
    return {
        type: types.UN_SELECT_ALL_FILTER_ITEMS,
        payload: data
    }
}

export const resetFilterItems = () => {
    return {
        type: types.RESET_FILTER_ITEM
    }
}

export const fetchPopularData=()=>{
    //console.log("action of popular data");
    return {
        type: types.SEND_POPULAR_IDEAS_REQUEST
    }
}

export const fetchPromotionalData = () => {
    //console.log("action of popular data");
    return {
        type: types.SEND_PROMOTIONAL_IDEAS_REQUEST
    }
}

export const filterPrivateIdeas=()=>{
    
    return {
        type: types.SEND_PRIVATE_IDEA_REQUEST
    }
}
export const filterPinnedIdeas=()=>{
    return{
        type: types.SEND_PINNED_IDEA_REQUEST

    }
}

export const filterContestIdeas=()=>{
    return{
        type: types.SEND_CONTEST_IDEA_REQUEST
    }
}

export const filterSharedIdeas=()=>{
    return{
        type: types.SEND_SHARED_IDEA_REQUEST
    }
}

export const innovatorFilterData = (data) => {
    // console.log("filter action", data);
    return {
        type: types.INNOVATOR_FILTER_DATA,
        payload: data
    }
}

export const AllIdeas = () => {
    return {
        type: types.SEND_ALL_IDEA_REQUEST
    }
}
