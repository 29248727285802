import 'font-awesome/css/font-awesome.min.css';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Tooltip, Spinner, Button, Text, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles } from '@fluentui/react-components';
import { ArrowRightRegular, EditFilled, EyeRegular, DeleteFilled, MoreVerticalRegular } from '@fluentui/react-icons';
import {
    getIdeaCommentsByIdeaId, AddCommentForIdea, likeService, getPrivateIdeaDetailsByIdeaId, downloadFile,
    savePinDetailsSubmittedIdea, savePinDetailsPrivateIdea, savePinUnPinDetails, addPinUnpinDetailsPromotionalFeed,
    deletePrivateIdea, deleteIdea, AddShareIdeaComments, GetShareComments, getIdeaStatus, updateImplementerAction,
    LikeUnlikePrivateIdea
} from '../../api/IdeaApi';
import IdeaComment from './idea-comment';
import TextAreaInput from '../../common/TextAreaInput';
// import { AttachmentPopup } from './AttachmentPopup';
import { PageLoader } from '../../common/PageLoader';
import Axios from "axios";
import VideoFeed from "../PromotionalFeed/VideoFeed";
import { fetchToken } from '../../redux-sharedframework/actions/token-action';
import { exploreIdeaRefresh } from '../../redux-sharedframework/actions/fetch-explore-idea-filters-action';
import { useDispatch, useSelector, RootStateOrAny, shallowEqual } from "react-redux";
import { ShareIdeaPopup } from "./ShareIdeaPopUp";
import { DeletePopUp } from '../../common/DeletePopUp';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { PromoteToIdea } from "./PromoteToIdea";
import RejectPopup from "../view-idea/RejectPopup";
import { StartImplementationPopup } from "../view-idea/StartImplementationPopup";
var graph = require('@microsoft/microsoft-graph-client');
var uniqid = require('uniqid');
import IdeaFeedMobile from '../mobile/landing/middle-panel/IdeaFeedMobile';
import { CopyIdeaPopup } from './CopyIdeaPopup';
import parse from "html-react-parser";

const options = {
    replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === "remove") {
            return <></>;
        }
    }
};

// part of POC code added. TODO: change as per the requirement.
const triggerMessage = async (jsonData, type) => {
    let teamsToken = localStorage.getItem("tokenTeams");
    jsonData.type = type;
    const messages = [
        { body: jsonData },
    ];
    populateUserProfileInfo(teamsToken, type, jsonData);
};

const populateUserProfileInfo = async (token, flag, dataJson) => {
    const response = await Axios.post("/api/user/GetUserProfile", dataJson, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Flag": flag,
        },
    });
    const data = response.data;
}

interface IdeaFeed {
    onEditPrivateIdea?: any;
    ideaData?: any;
    enterprise?: string;
    enterpriseId?: string;
    isExploreIdea?: boolean;
    showError?: any;
    updateIdeaFeed?: (id: string, type: string) => void;
    getAllIdeaDataRefresh?: any;
    fromSerialInnovator?: boolean;
    landingPage?: boolean;
    setErrorMobile?: any;
    commentBasedFilter?: any;
    fromMyStories?: any;
}

let showList = false;

const IdeaFeed = React.memo(({ ideaData, enterprise, enterpriseId, isExploreIdea, onEditPrivateIdea, showError, updateIdeaFeed, getAllIdeaDataRefresh, fromSerialInnovator, landingPage, setErrorMobile, commentBasedFilter, fromMyStories }: IdeaFeed) => {

    const history = useHistory();
    const isDisabled = false;
    const [addComment, setAddComment] = useState<string>("");
    const [comments, setComments] = useState<any>([]);
    const [likeCount, setLikeCount] = useState<number>(ideaData.LikeCount);
    const [isIdeaLiked, setIsIdeaLiked] = useState<boolean>(ideaData.IsIdeaLiked);
    const [showMessages, setShowMessages] = useState<boolean>(false);
    const [attachmentData, setAttachmentData] = useState<any>([]);
    const [showSpinner, setSpinnerData] = useState(false);
    const [ideaIdAttachment, setIdeaIdAttachment] = useState<any>('');
    // changes here
    const [pinned, setPinned] = useState<string>('icon Icon--Pinned');
    const token = useSelector((state: RootStateOrAny) => state.fetchTokenReducer.data, shallowEqual);
    const [showSharedPopup, setshowSharedPopup] = useState<boolean>(false);
    const [isPinned, setIsPinned] = useState(false);
    const [type, setType] = useState('');
    const [selectedIndex, setSelectedIndex] = useState<any>(-1);
    const [showMoreList, setShowMoreList] = useState(false);
    const [ideaDetails, setideaDetails] = useState<any>({});
    const [showDelete, setShowDelete] = useState(false);

    const [participantComments, setParticipantComments] = useState([]);
    const [socialComments, setSocialComments] = useState([]);
    const [participantCommentsPage, setParticipantCommentsPage] = useState<number>(1);
    const [socialCommentsPage, setSocialCommentsPage] = useState<number>(1);
    const [loadParticipantMoreShow, setLoadParticipantMoreShow] = useState<boolean>(true);
    const [loadSocialMoreShow, setLoadSocialMoreShow] = useState<boolean>(true);
    const [privateIdeaSharedComments, setPrivateIdeaSharedComments] = useState([]);
    const [showCommentBox, setShowCommentBox] = useState<any>();
    const globalSelectedList = [{ key: 'View', content: 'View', media: (<EyeRegular />) }];
    const [selectedList, setSelectedList] = useState(globalSelectedList);
    const [newCommentLoader, setNewCommentLoader] = useState<boolean>(false);
    const [allTags, setAllTags] = useState<any>([]);
    const [showPromoteToIdeaPopup, setShowPromoteToIdeaPopup] = useState<boolean>(false);
    const [showCopyIdeaPopup, setShowCopyIdeaPopup] = useState<boolean>(false);
    const [showCopyIdea, setShowCopyIdea] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [isImplementer, setIsImplementer] = useState<boolean>(false);
    const [showAcceptedPopup, setShowAcceptedPopup] = useState<Boolean>(false);
    const [showRejectedPopup, setShowRejectedPopup] = useState<Boolean>(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<Boolean>(false);
    const [isConfirmationClosed, setIsConfirmationClosed] = useState<Boolean>(false);
    const moreItemsBox = useRef(null);
    const [privateCommentsPage, setPrivateCommentsPage] = useState<number>(1);
    const [loadPrivateMoreShow, setLoadPrivateMoreShow] = useState<boolean>(true);
    const peopleHierarchy = useSelector(
        (state: RootStateOrAny) => state.fetchDemographyReducer.ideaCreationData
    );
    const { promotionalContest } = useSelector(
        (state: RootStateOrAny) => state.fetchDemographyReducer
    );

    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile, shallowEqual);
    const { contestsState } = useSelector((state: RootStateOrAny) => state.fetchDemographyReducer, shallowEqual);

    let IOTYContestID = process.env.REACT_APP_IOTY_CONTEST_ID;
    let sustainabilityContestID = process.env.REACT_APP_SUSTAINABILITY2023_CONTEST_ID;   

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, false);
        return () => {
            document.removeEventListener("click", handleOutsideClick, false);
        };
    }, [])

    const handleOutsideClick = (event) => {

        if (moreItemsBox.current && moreItemsBox.current?.contains(event.target)) {
            // console.log("event.target", event.target);
            //clickedInd ? setClickedInd(prevClicked => !prevClicked) : clickedTech ? setClickedTech(prevClicked => !prevClicked) : null;
            //setShowMoreList(true);
        }
        else {
            setShowMoreList(false);
        }
    }
    // changes here
    useEffect(() => {
        if (ideaData && Object.keys(ideaData).length > 0) {
            if (ideaData.PinDetails && ideaData.PinDetails.length > 0) {
                if (ideaData.PinDetails.find(x => x.PinBy === enterpriseId)) {
                    setPinned('icon Icon--PinnedSolid');
                    setIsPinned(true);
                } else {
                    setIsPinned(false);
                    setPinned('icon Icon--Pinned');
                }
            }

            if (ideaData.SharedUserDetails && ideaData.SharedUserDetails.length > 0 && !ideaData.IsImplementerIdea && !ideaData.isPopularIdeas) {
                if (ideaData.SharedUserDetails.find(x => x.EnterprisId === enterpriseId)) {
                    if (ideaData['PrivateIdeaId']) {
                        ideaData['IdeaStatus'] = 'Shared with Me';
                    } else {
                        ideaData['IdeaWorkFlowStatus'] = 'Shared with Me';
                    }
                }
            }
            ideaData.PrivateIdeaId ? setType('private') : setType('public');
            deleteIconVisible();
            editIconVisible();
            promoteToIdeaIconVisible();
        }
        setideaDetails(ideaData);
    }, [ideaData]);

    useEffect(() => {
        if (ideaData && Object.keys(ideaData).length > 0) {
            //CoInnovators, CreatedBy, SharedUserDetails enterpriseId  EnterprisId
            let showParticipantCommentBox = false;
            let showSocialCommentBox = true;
            if (ideaData.CoInnovators && ideaData.CoInnovators.length > 0) {
                if (ideaData.CoInnovators.includes(enterpriseId)) {
                    showParticipantCommentBox = true;
                    showSocialCommentBox = true;
                    setShowCopyIdea(true);
                }
            }
            if (ideaData.CreatedBy && ideaData.CreatedBy.length > 0) {
                if (ideaData.CreatedBy === enterpriseId) {
                    showParticipantCommentBox = true;
                    showSocialCommentBox = true;
                    setShowCopyIdea(true);
                }
            }
            if (ideaData?.IsImplementer || (ideaData?.IsImplementerIdea && ideaData?.Implementers?.find(x => x.ImplementerId === enterpriseId))) {
                showParticipantCommentBox = true;
                showSocialCommentBox = true;
                setShowCopyIdea(false);

            }

            setShowCommentBox({
                ParticipantCommentBox: showParticipantCommentBox,
                SocialCommentBox: showSocialCommentBox
            });

            setMergedTags();
        }
    }, [ideaData]);

    const handleInitials = (CreatedBy) => {
        if (CreatedBy) {
            var res = CreatedBy.split(".");
            let nameInitials = (res[res.length - 1].charAt(0) + res[0].charAt(0)).toUpperCase();
            return nameInitials;
        }
        else {
            return "";
        }
    }


    const deleteIconVisible = () => {
        if (fromSerialInnovator) {
            if (globalSelectedList.find(list => list.key === 'Delete')) {
                globalSelectedList.filter(list => list.key !== 'Delete');
            }
        }
        else if (ideaData.IsDynamicIdea && ideaData.ContestNm.toLowerCase().includes("global tech innovation contest season 6")) {
            if((ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Submitted') || 
            (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Submitted')){
                if (globalSelectedList.find(list => list.key === 'Delete')) {
                    globalSelectedList.filter(list => list.key !== 'Delete');
                }
            }
            else{
                if (!(globalSelectedList.find(list => list.key === 'Delete'))) {
                    globalSelectedList.push({ key: "Delete", content: 'Delete', media: (<DeleteFilled />) });
                }
            }
        }
        else if (ideaData.IsDynamicIdea && ideaData.ContestNm.toLowerCase().includes("v360") && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId)))) {

            let ideaSubmissionClosed = contestsState?.filter(contest => contest.ContestId === ideaData.ContestId)?.length ? contestsState.filter(contest => contest.ContestId === ideaData.ContestId)[0]["IsIdeaSubmissionClosed"] : true;
            if ((!ideaSubmissionClosed && ideaData?.IsEditEnableDynamic !== 'disable') || ideaData?.IsEditEnableDynamic === 'enable') {
                if (!(globalSelectedList.find(list => list.key === 'Delete'))) {
                    globalSelectedList.push({ key: "Delete", content: 'Delete', media: (<DeleteFilled />) });
                }
            }
        }
        else if (ideaData.IsDynamicIdea && !ideaData.ContestNm.toLowerCase().includes("v360") && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId)))) {
            let ideaSubmissionClosed = contestsState?.filter(contest => contest.ContestId === ideaData.ContestId)?.length ? contestsState.filter(contest => contest.ContestId === ideaData.ContestId)[0]["IsIdeaSubmissionClosed"] : true;
            if (!ideaSubmissionClosed) {
                if ((ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Rejected')
                    || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Rejected')
                    || (ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Cannot Implemented')
                    || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Cannot Implemented')
                    || (ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Closed') ||
                    (ideaData.IdeaWorkFlowStatus && ideaData.IdeaWorkFlowStatus === 'Implementation Rejected')) {
                    if (globalSelectedList.find(list => list.key === 'Delete')) {
                        globalSelectedList.filter(list => list.key !== 'Delete');
                    }
                }
                else {
                    if (!(globalSelectedList.find(list => list.key === 'Delete'))) {
                        globalSelectedList.push({ key: "Delete", content: 'Delete', media: (<DeleteFilled />) });
                    }
                }
            }
            else {
                if (ideaSubmissionClosed && ideaData?.IsEditEnableDynamic === 'enable') {
                    if (!(ideaData.ContestId === sustainabilityContestID)) {
                        if (!(globalSelectedList.find(list => list.key === 'Delete'))) {
                            globalSelectedList.push({ key: "Delete", content: 'Delete', media: (<DeleteFilled />) });
                        }
                    }
                    else {
                        if (globalSelectedList.find(list => list.key === 'Delete')) {
                            globalSelectedList.filter(list => list.key !== 'Delete');
                        }
                    }
                }
                else {
                    if (globalSelectedList.find(list => list.key === 'Delete')) {
                        globalSelectedList.filter(list => list.key !== 'Delete');
                    }
                }
            }
            //setSelectedList(globalSelectedList);
            //return;
        }
        else if ((ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Rejected')
            || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Rejected')
            || (ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Cannot Implemented')
            || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Cannot Implemented') ||
            (ideaData.IdeaWorkFlowStatus && ideaData.IdeaWorkFlowStatus === 'Implementation Rejected')) {
            if (globalSelectedList.find(list => list.key === 'Delete')) {
                globalSelectedList.filter(list => list.key !== 'Delete');
            }
        }
        else if (!ideaData.isPopularIdeas && !ideaData.isPromotionalFeed && (ideaData.IdeaWorkFlowStatus === 'Draft' ||
            ideaData.IdeaStatus === 'Draft' ||
            ideaData.IdeaWorkFlowStatus === 'Submitted' ||
            ideaData.IdeaWorkFlowStatus === 'Awaiting Mentor Approval' ||
            ideaData.IdeaWorkFlowStatus == 'Need More Information') && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId))) &&
            ideaData.IdeaWorkFlowStatus != 'Under Implementation' && ideaData.IdeaWorkFlowStatus != 'Cannot Implemented' &&
            ideaData.IdeaWorkFlowStatus != 'Implemented' && ideaData.IdeaWorkFlowStatus != 'Signed Off' && ideaData.IdeaWorkFlowStatus != 'Deferred' &&
            ideaData.IdeaWorkFlowStatus != 'Ready for Implementation' && ideaData.IdeaWorkFlowStatus != 'Benefits Signed off' &&
            ideaData.IdeaWorkFlowStatus != 'Benefits Sign Off Initiated' && ideaData.IdeaWorkFlowStatus != 'Closed' && ideaData.IdeaWorkFlowStatus != 'Implementation Rejected') {
            if (!(globalSelectedList.find(list => list.key === 'Delete'))) {
                globalSelectedList.push({ key: "Delete", content: 'Delete', media: (<DeleteFilled />) });
            }
        } else if ((ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Shared with Me') || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Shared with Me') || ideaData.isPopularIdeas || ideaData.isPromotionalFeed) {
            if ((globalSelectedList.find(list => list.key === 'Delete'))) {
                globalSelectedList.pop();
            }
        }
        else if ((ideaData.PrivateIdeaId && ideaData['IdeaStatus'] !== 'Shared with Me') && ideaData['CreatedBy'] === enterpriseId) {
            if (!(globalSelectedList.find(list => list.key === 'Delete'))) {
                globalSelectedList.push({ key: "Delete", content: 'Delete', media: (<DeleteFilled />) });
            }
        }
        else if (!ideaData.isPopularIdeas && !ideaData.isPromotionalFeed && ideaData.IdeaId && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId))) &&
            ideaData.IdeaWorkFlowStatus != 'Under Implementation' && ideaData.IdeaWorkFlowStatus != 'Cannot Implemented' &&
            ideaData.IdeaWorkFlowStatus != 'Implemented' && ideaData.IdeaWorkFlowStatus != 'Signed Off' && ideaData.IdeaWorkFlowStatus != 'Deferred' &&
            ideaData.IdeaWorkFlowStatus != 'Ready for Implementation' && ideaData.IdeaWorkFlowStatus != 'Benefits Signed off' &&
            ideaData.IdeaWorkFlowStatus != 'Benefits Sign Off Initiated' && ideaData.IdeaWorkFlowStatus != 'Closed' && ideaData.IdeaWorkFlowStatus != 'Implementation Rejected') {
            if (!(globalSelectedList.find(list => list.key === 'Delete'))) {
                globalSelectedList.push({ key: "Delete", content: 'Delete', media: (<DeleteFilled />) });
            }
        }
        else if ((ideaData.isPopularIdeas && (ideaData['CreatedBy'] === enterpriseId || ideaData.CoInnovators?.includes(enterpriseId)))) {
            if (!(globalSelectedList.find(list => list.key === 'Delete'))) {
                globalSelectedList.push({ key: "Delete", content: 'Delete', media: (<DeleteFilled />) });
            }
        }
        setSelectedList(globalSelectedList);
    }

    const editIconVisible = () => {
        if (fromSerialInnovator) {
            if (globalSelectedList.find(list => list.key === 'Edit')) {
                globalSelectedList.filter(list => list.key !== 'Edit');
            }
        }
        else if (ideaData.IsDynamicIdea && ideaData.ContestNm.toLowerCase().includes("global tech innovation contest season 6")) {
            if((ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Submitted') || 
            (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Submitted')){
                if (globalSelectedList.find(list => list.key === 'Edit')) {
                    globalSelectedList.filter(list => list.key !== 'Edit');
                }
            }
            else{
                if (!(globalSelectedList.find(list => list.key === 'Edit'))) {
                    globalSelectedList.push({ key: "Edit", content: 'Edit', media: (<EditFilled />) });
                }
            }
        }
        else if (ideaData.IsDynamicIdea && ideaData.ContestNm.toLowerCase().includes("v360") && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId)))) {

            let ideaSubmissionClosed = contestsState?.filter(contest => contest.ContestId === ideaData.ContestId)?.length ? contestsState.filter(contest => contest.ContestId === ideaData.ContestId)[0]["IsIdeaSubmissionClosed"] : true;
            if ((!ideaSubmissionClosed && ideaData?.IsEditEnableDynamic !== 'disable') || ideaData?.IsEditEnableDynamic === 'enable') {
                if (!(globalSelectedList.find(list => list.key === 'Edit'))) {
                    globalSelectedList.push({ key: "Edit", content: 'Edit', media: (<EditFilled />) });
                }
            }
        }
        else if (ideaData.IsDynamicIdea && !ideaData.ContestNm.toLowerCase().includes("v360") && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId)))) {
            let ideaSubmissionClosed = contestsState?.filter(contest => contest.ContestId === ideaData.ContestId)?.length ? contestsState.filter(contest => contest.ContestId === ideaData.ContestId)[0]["IsIdeaSubmissionClosed"] : true;
            if (!ideaSubmissionClosed) {
                if ((ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Rejected')
                    || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Rejected')
                    || (ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Cannot Implemented')
                    || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Cannot Implemented')
                    || (ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Closed') ||
                    (ideaData.IdeaWorkFlowStatus && ideaData.IdeaWorkFlowStatus === 'Implementation Rejected')) {
                    if (globalSelectedList.find(list => list.key === 'Edit')) {
                        globalSelectedList.filter(list => list.key !== 'Edit');
                    }
                }
                else {
                    if (!(globalSelectedList.find(list => list.key === 'Edit'))) {
                        globalSelectedList.push({ key: "Edit", content: 'Edit', media: (<EditFilled />) });
                    }
                }
            }
            else {
                if (ideaSubmissionClosed && ideaData?.IsEditEnableDynamic === 'enable') {
                    if (!(globalSelectedList.find(list => list.key === 'Edit'))) {
                        globalSelectedList.push({ key: "Edit", content: 'Edit', media: (<EditFilled />) });
                    }
                }
                else {
                    if (globalSelectedList.find(list => list.key === 'Edit')) {
                        globalSelectedList.filter(list => list.key !== 'Edit');
                    }
                }
            }
            //setSelectedList(globalSelectedList);
            //return;
        }
        else if ((ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Rejected')
            || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Rejected')
            || (ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Cannot Implemented')
            || (ideaData['IdeaStatus'] && ideaData['IdeaStatus'] === 'Cannot Implemented')
            || (ideaData['IdeaWorkFlowStatus'] && ideaData['IdeaWorkFlowStatus'] === 'Closed') ||
            (ideaData.IdeaWorkFlowStatus && ideaData.IdeaWorkFlowStatus === 'Implementation Rejected')) {
            if (globalSelectedList.find(list => list.key === 'Edit')) {
                globalSelectedList.filter(list => list.key !== 'Edit');
            }
        }
        else if (!(ideaData.isPopularIdeas || ideaData.isPromotionalFeed || ideaData['IdeaStatus'] === 'Shared with Me' || ideaData['IdeaWorkFlowStatus'] === 'Shared with Me') && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId)))) {
            if (!(globalSelectedList.find(list => list.key === 'Edit'))) {
                globalSelectedList.push({ key: "Edit", content: 'Edit', media: (<EditFilled />) });
            }
        }
        else if (!ideaData.isPopularIdeas && !ideaData.isPromotionalFeed && ideaData.IdeaId && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId)))) {
            if (!(globalSelectedList.find(list => list.key === 'Edit'))) {
                globalSelectedList.push({ key: "Edit", content: 'Edit', media: (<EditFilled />) });
            }
        }
        else if ((ideaData.isPopularIdeas && (ideaData['CreatedBy'] === enterpriseId || ideaData.CoInnovators?.includes(enterpriseId)))) {
            if (!(globalSelectedList.find(list => list.key === 'Edit'))) {
                globalSelectedList.push({ key: "Edit", content: 'Edit', media: (<EditFilled />) });
            }
        }
        else if ((ideaData.PrivateIdeaId && ideaData['IdeaStatus'] !== 'Shared with Me' && ideaData['CreatedBy'] === enterpriseId)) {
            if (!(globalSelectedList.find(list => list.key === 'Edit'))) {
                globalSelectedList.push({ key: "Edit", content: 'Edit', media: (<EditFilled />) });
            }
        }
        if (ideaData.IsImplementerIdea && globalSelectedList.find(list => list.key === 'Edit')) {
            globalSelectedList.pop();
        }
        setSelectedList(globalSelectedList);
    }

    const promoteToIdeaIconVisible = () => {
        if (ideaData.PrivateIdeaId && !(ideaData['IdeaStatus'] === 'Shared with Me' || ideaData['IdeaWorkFlowStatus'] === 'Shared with Me') && (ideaData.CreatedBy === enterpriseId || (ideaData.CoInnovators && ideaData.CoInnovators.includes(enterpriseId)))) {
            if (!(globalSelectedList.find(list => list.key === 'Promote'))) {
                globalSelectedList.push({ key: "Promote", content: 'Promote', media: (<ArrowRightRegular />) });
            }
        }
    }


    const getCommentsForIdea = (ideaId: any, pCommentPage: number, sCommentPage: number, IdeaCoOwnerFlag: any, flag?) => {

        if (IdeaCoOwnerFlag) {
            getIdeaCommentsByIdeaId(ideaId, pCommentPage, true).then(resp => {
                if (resp['value'] && resp['value'].length > 0) {
                    let displayedCommentCount = participantComments.length;
                    if (pCommentPage == 1) {
                        setParticipantComments((comment) => resp['value']);
                    }
                    else {
                        setParticipantComments((comment) => [...participantComments, ...resp['value']]);
                    }
                    // resp['value'].length < 5 ? setLoadParticipantMoreShow(false) : null;

                    //to calculate the total comments count
                    let newCommentsLength = pCommentPage === 1 ? resp['value'].length : displayedCommentCount + resp['value'].length;
                    newCommentsLength < resp['value'][0].ParticipantCommentCount ? setLoadParticipantMoreShow(true) : setLoadParticipantMoreShow(false);
                    setNewCommentLoader(false);
                }
                else {
                    setLoadParticipantMoreShow(false);
                    setNewCommentLoader(false);
                }
            });
        }
        else {
            getIdeaCommentsByIdeaId(ideaId, sCommentPage, false).then(resp => {
                if (resp['value'] && resp['value'].length > 0) {
                    let displayedCommentCount = socialComments.length;
                    if (sCommentPage == 1) {
                        setSocialComments((comment) => resp['value']);
                    }
                    else {
                        setSocialComments((comment) => [...socialComments, ...resp['value']]);
                    }
                    // resp['value'].length < 5 ? setLoadSocialMoreShow(false) : null;

                    //to calculate the total comments count
                    let newCommentsLength = sCommentPage === 1 ? resp['value'].length : displayedCommentCount + resp['value'].length;
                    newCommentsLength < resp['value'][0].SocialCommentCount ? setLoadSocialMoreShow(true) : setLoadSocialMoreShow(false);
                    setNewCommentLoader(false);
                }
                else {
                    setLoadSocialMoreShow(false);
                    setNewCommentLoader(false);
                }
            });
            //if (flag) {
            //    getAllIdeaDataRefresh();
            //}
        }
    }

    const getCommentsForSharedIdea = async (ideaId: any, pageIndex: number, flag?): Promise<any> => {
        //let sharedComments = await GetShareComments(ideaId, pageIndex);
        //setPrivateIdeaSharedComments(sharedComments["value"]);
        // to refesh comments count
        //setNewCommentLoader(false);
        //if(flag){
        //    getAllIdeaDataRefresh();
        //}

        GetShareComments(ideaId, pageIndex).then(sharedComments => {
            if (sharedComments['value'] && sharedComments['value'].length > 0) {
                let displayedCommentCount = privateIdeaSharedComments.length;
                if (pageIndex == 1) {
                    setPrivateIdeaSharedComments((comment) => sharedComments['value']);
                }
                else {
                    setPrivateIdeaSharedComments((comment) => [...privateIdeaSharedComments, ...sharedComments['value']]);
                }
                // sharedComments['value'].length < 5 ? setLoadPrivateMoreShow(false) : null;

                //to calculate the total comments count
                let newCommentsLength = pageIndex === 1 ? sharedComments['value'].length : displayedCommentCount + sharedComments['value'].length;
                newCommentsLength < sharedComments['value'][0].IdeaItemCount ? setLoadPrivateMoreShow(true) : setLoadPrivateMoreShow(false);
                setNewCommentLoader(false);
            }
            else {
                setLoadPrivateMoreShow(false);
                setNewCommentLoader(false);
            }
        })
    }

    const seeMore = (s) => {
        s.target.parentElement.parentElement.getElementsByClassName("collapse-text")[0].classList.toggle('expand-text');

        if (s.target.innerHTML == "see more") {
            s.target.innerHTML = "see less";
        }
        else {
            s.target.innerHTML = "see more";
        }
    }

    const onLikeClick = (event, ideaId: string) => {
        event.preventDefault();
        likeIdea(!isIdeaLiked, ideaId);
    }

    const likeIdea = (isLike, ideaId) => {
        setIsIdeaLiked(isLike);
        if (likeCount === undefined) {
            setLikeCount(0);
        }
        let lastLikeCount = likeCount;

        if (isLike) {
            setLikeCount(likeCount + 1);
        } else {
            if (isIdeaLiked) {
                setLikeCount(likeCount - 1);
            } else {
                setLikeCount(likeCount);
            }

        }
        if (ideaData.ChallengeId && ideaData.ChallengeNm && type === 'public') {
            likeService(ideaData.ChallengeId, ideaData.ChallengeNm, peopleHierarchy, ideaId, isLike)
                .then((response) => {
                    if (isLike && response['ResponseCode'] === '104') {

                    } else {
                        setIsIdeaLiked(isLike);
                    }
                });
        } else if (type === 'private') {
            LikeUnlikePrivateIdea(ideaId, isLike, peopleHierarchy).then(response => {
                if (response && response['ResponseCode'] === '104' && response['ResponseCode'] === '106') {
                    if (isLike && response && response['ResponseCode'] === '104') {

                    } else {
                        setIsIdeaLiked(isLike);
                    }
                } else if (response && response['ResponseCode'] === '500') {
                    setIsIdeaLiked(!isLike);
                    setLikeCount(lastLikeCount);
                }
            })
        }
    }

    const onCommentClick = (event, ideaId: any, ideaData: any) => {
        event.preventDefault();
        setNewCommentLoader(true);
        if (fromSerialInnovator || isExploreIdea) {
            setShowMessages(false);
        }
        else if (!showMessages) {
            if (ideaData.PrivateIdeaId) {
                getCommentsForSharedIdea(ideaId, privateCommentsPage);
            }
            else {
                getCommentsForIdea(ideaId, participantCommentsPage, socialCommentsPage, true);
                getCommentsForIdea(ideaId, participantCommentsPage, socialCommentsPage, false);
            }
            setShowMessages(true);
        }
        else {
            setShowMessages(false);
        }
    }

    const onShareClick = (event, ideaId: any) => {
        event.preventDefault();
        setshowSharedPopup(true);
    }

    const onEditClick = (event, ideaId: any, checkPrivateIdea?) => {
        event.preventDefault();

        if (checkPrivateIdea) {
            history.push({
                pathname: '/editPrivateIdea',
                state: { ideaId: ideaId, enterprise: enterprise }
            });
        }
        else if (ideaData.IsDynamicIdea) {
            if (ideaData.ContestNm.toLowerCase().includes("v360")) {
                history.push({
                    pathname: `/v360-idea-submission-form/${ideaData.ContestId}`,
                    state: { ideaId: ideaId }
                });
            }
            else if (ideaData.ContestId === IOTYContestID) {
                history.push({
                    pathname: `/dynamic-form-ioty/${ideaData.ContestId}`,
                    state: { ideaId: ideaId }
                });
            }
            else {
                history.push({
                    pathname: `/dynamic-form/${ideaData.ContestId}`,
                    state: { ideaId: ideaId }
                });
            }
        }
        else {
            history.push({
                pathname: '/submit-idea',
                state: { detail: ideaId, ideaDetails: ideaData }
            });
        }
    }

    const onViewClick = (event, ideaId: any, PrivateIdeaId) => {
        event.preventDefault();
        if (PrivateIdeaId) {
            history.push({
                pathname: '/viewprivateidea',
                state: { ideaId, landingPage }
            });
        }
        else if (ideaData.IsDynamicIdea) {
            if (ideaData?.ContestNm?.toLowerCase().includes("v360")) {
                history.push({
                    pathname: '/view-dynamic-idea',
                    state: { detail: ideaId, contestNm: ideaData.ContestNm, contestId: ideaData.ContestId, landingPage }
                });
            }
            else {
                let role = '', isFromImplementerCard = false;

                if (ideaData?.IsImplementer && ideaData?.IsImplementerIdea) {
                    role = 'implementer';
                }
                else if (ideaData.CreatedBy === enterpriseId) {
                    role = 'innovator';
                }

                if (ideaData?.IsImplementerIdea) {
                    isFromImplementerCard = true;
                }
                console.log("dynamic-idea-details-view from idea feed", ideaData);
                history.push({
                    pathname: '/dynamic-idea-details-view',
                    state: { detail: ideaId, role: role, isFromImplementerCard: isFromImplementerCard, contestNm: ideaData.ContestNm, contestId: ideaData.ContestId, landingPage }
                });
            }
        }
        else {
            let role = '', isFromImplementerCard = false;

            if (ideaData?.IsImplementer && ideaData?.IsImplementerIdea) {
                role = 'implementer';
            }
            else if (ideaData.CreatedBy === enterpriseId) {
                role = 'innovator';
            }

            if (ideaData?.IsImplementerIdea) {
                isFromImplementerCard = true;
            }

            history.push({
                pathname: '/view-idea',
                state: { ideaId, enterprise, role: role, isFromImplementerCard: isFromImplementerCard, landingPage }
            });
        }
    }

    const onPinClick = (event, id: string, type: string) => {
        event.preventDefault();
        if (ideaData['isPromotionalFeed']) {
            addPinUnpinDetailsPromotionalFeed(ideaData['promotionalfeedid'], !isPinned).then(response => {
                if (response && response['ResponseCode'] === '105') {
                    if (isPinned) {
                        setPinned('icon Icon--Pinned');
                        setIsPinned(false);
                    } else {
                        setPinned('icon Icon--PinnedSolid');
                        setIsPinned(true);
                    }
                } else {
                    setPinned('ms-Icon ms-Icon--Pinned');
                }
            })
        } else {
            savePinUnPinDetails(id, (type === 'public' ? false : true), !isPinned).then(response => {
                if (response && response['ResponseCode'] === '105') {
                    if (isPinned) {
                        setPinned('ms-Icon ms-Icon--Pinned');
                        setIsPinned(false);
                    } else {
                        setPinned('ms-Icon ms-Icon--PinnedSolid');
                        setIsPinned(true);
                    }
                } else {
                    setPinned('ms-Icon ms-Icon--Pinned');
                }
            })
        }
        //if (isExploreIdea) {
        //    setTimeout(() => { dispatch(exploreIdeaRefresh()) }, 1000);
        //}

    }

    const onTeamClick = (event, ideaId: any) => {
        event.preventDefault();
    }

    const addCommentToIdea = (e, ideaId, isParticipant) => {
        e.preventDefault();
        if (addComment !== '' && addComment.trim() !== '') {
            setNewCommentLoader(true);
            const commentDetails = {
                "IdeaId": ideaId,
                "IsClientComment": false,
                "CommentBy": enterpriseId,
                "Comments": addComment,
                "IdeaCommentorHierarchy": peopleHierarchy
            }

            AddCommentForIdea(commentDetails, isParticipant)
                .then(data => {
                    if (data['value']) {
                        isParticipant ? setParticipantCommentsPage(1) : setSocialCommentsPage(1);
                        ideaData.IdeaCoOwnerFlag ?
                            setideaDetails(data => ({
                                ...data,
                                ["IdeaCommentCount"]: data.IdeaCommentCount + 1
                            }))
                            :
                            setideaDetails(data => ({
                                ...data,
                                ["SocialCommentCount"]: data.SocialCommentCount + 1
                            }))
                        getCommentsForIdea(ideaId, 1, 1, isParticipant, true);
                        commentDetails['ideaTitle'] = "this is idea title";
                        setShowMessages(true);
                    }
                });
            setAddComment('');
        }
    }

    const addCommentToSharedIdea = async (e, ideaId): Promise<any> => {
        e.preventDefault();
        if (addComment !== '' && addComment.trim() !== '' && addComment !== undefined && addComment !== null) {
            setNewCommentLoader(true);

            let obj = {
                ReferenceId: ideaId,
                ReferenceType: "PrivateIdea",
                Comments: addComment,
                IsForNudge: false,
                IdeaCommentorHierarchy: peopleHierarchy
            }

            let p2 = await AddShareIdeaComments(obj);

            if (p2["Values"]) {
                const refreshIdeaData = true;
                setideaDetails(data => ({
                    ...data,
                    ["IdeaCommentCount"]: data.IdeaCommentCount + 1
                }))
                getCommentsForSharedIdea(ideaId, 1, refreshIdeaData);
                //setShowMessages(false);
            }
            setAddComment('');
        }
    }

    const onAttachClick = (event) => {
        event.preventDefault();
        // setSpinnerData(true);
        // setIdeaIdAttachment(id);
        // getPrivateIdeaDetailsByIdeaId(id).then((res) => {
        //     if (res !== undefined || res !== null) {
        //         setAttachmentData(res['MediaFiles']);
        //         setSpinnerData(false);
        //     }
        // })
    }

    const convertArrayInString = (data: string) => {
        if (data) {
            let newString: any = data.toString();
            let SharedPeopleList: Array<any> = [];
            newString = newString.split(',');
            SharedPeopleList = newString.map(item => {
                return ({
                    key: item,
                    name: item.split('.').join(" ").toUpperCase(),
                    header: item.split('.').join(" ")
                })
            });

            return SharedPeopleList;
        }

    }
    const downloadFileToLocal = (file, fileType) => {
        downloadFile(token, file.FileNm, fileType, ideaIdAttachment, enterpriseId).then(response => {
            const responseFile = new Blob([response], { type: 'octet/stream' });
            var URL = (window.webkitURL || window.URL);
            var csvURL = URL.createObjectURL(responseFile);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', file.FileNm);
            tempLink.click();
            URL.revokeObjectURL(csvURL);
        })
    }

    const closePopUp = (peopleArray?: any) => {
        setshowSharedPopup(false);
        //getAllIdeaDataRefresh();
        if (peopleArray) {
            let sharedUserDetails = peopleArray.map(people => ({ EnterprisId: people }));
            setideaDetails(data => ({
                ...data,
                ["SharedUserDetails"]: [...data.SharedUserDetails, ...sharedUserDetails],
                ["ShareduserCount"]: data.ShareduserCount + sharedUserDetails.length
            }))
        }
    }

    const closeDeletePopUp = () => {
        setShowDelete(false);
    }

    const onShareCountClick = (event) => {
        event.preventDefault();
    }

    const handleOnClick = (e, item) => {
        e.stopPropagation();
        if (item.key === 'Edit') {
            onEditClick(e, ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId, ideaData.PrivateIdeaId ? true : false);
        } else if (item.key === 'Delete') {
            setShowDelete(true);
        } else if (item.key === 'View') {
            onViewClick(e, ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId, ideaData.PrivateIdeaId);
        } else if (item.key === 'Promote') {
            onPromoteToIdeaClick(e, ideaData.PrivateIdeaId);
        }
    }

    const onMoreClick = (event) => {
        event.preventDefault();
        // showList = !showList;
        setShowMoreList(oldData => !oldData);
    }

    const onDeleteEvent = () => {
        if (ideaData.isPrivateIdea) {
            setSpinnerData(true);
            setShowDelete(false);
            deletePrivateIdea(ideaData.PrivateIdeaId).then(response => {
                showList = !showList;
                setShowMoreList(showList);
                setSpinnerData(false);
                if (response && response['ResponseCode'] === '106') {
                    getAllIdeaDataRefresh();
                    showError(response['Values'][0]['ResponseMessage'], 'success');
                    //console.log("show error delete",response['Values'][0]['ResponseMessage']);
                    //setErrorMobile(response['Values'][0]['ResponseMessage'], 'success');
                } else {
                    showError(response['ResponseMessage'], 'error');
                    //setErrorMobile(response['ResponseMessage'], 'error');
                }

            })
        } else {
            setSpinnerData(true);
            setShowDelete(false);

            deleteIdea(ideaData.IdeaId).then(response => {
                showList = !showList;
                setShowMoreList(showList);
                setSpinnerData(false);
                if (response && response['ResponseCode'] === '106') {
                    setSpinnerData(true);
                    setTimeout(() => {
                        getAllIdeaDataRefresh();
                    }, 2000)

                    { showError ? showError(response['Values'][0]['ResponseMessage'], 'success') : null }
                    //setErrorMobile(response['Values'][0]['ResponseMessage'], 'success');

                } else {
                    showError(response['ResponseMessage'], 'error');
                    //setErrorMobile(response['ResponseMessage'], 'error');

                }
            })
        }
    }

    const setIdeaStatus = () => {
        if (ideaData.IdeaWorkFlowStatus) {
            return ideaData['IdeaWorkFlowStatus']
        } else if (ideaData['IdeaStatus']) {
            return ideaData['IdeaStatus']
        } else if (ideaData['PrivateIdeaId']) {
            return 'Private';
        } else if (ideaData['promotionalfeedid']) {
            return 'Promotional';
        }
    }

    const setIdeaStatusClass = () => {
        let status = "";
        if (ideaData.IdeaWorkFlowStatus) {
            status = ideaData['IdeaWorkFlowStatus']
        } else if (ideaData['IdeaStatus']) {
            status = ideaData['IdeaStatus']
        } else if (ideaData['PrivateIdeaId']) {
            status = 'Private';
        } else if (ideaData['promotionalfeedid']) {
            status = 'Promotional';
        }

        return "idea-status " + status.split(" ").join("").toLowerCase();
    }


    const openAcceptedPopup = (event) => {
        event.preventDefault();
        setSpinnerData(true);
        updateImplementerAction(ideaData.IdeaId, 'Accept').then((response) => {
            if (response) {
                // console.log('Status update successful - Accept');
                setShowAcceptedPopup(true);
            }
            else {
                console.log('Status update failed - Accept');
            }
        });
        setSpinnerData(false);
    };

    const openRejectedPopup = (event) => {
        event.preventDefault();
        setShowRejectedPopup(true);
    };

    const closeAcceptedPopUp = () => {
        setSpinnerData(true);
        setShowAcceptedPopup(false);
        getAllIdeaDataRefresh();
        setSpinnerData(false);
    };

    const closeRejectPopup = (isDiscarded) => {
        setShowRejectedPopup(false);

        if (!isDiscarded) {
            setShowConfirmationPopup(true);
        }
    };

    const closeConfirmationPopup = () => {
        setSpinnerData(true);
        setShowConfirmationPopup(false);
        getAllIdeaDataRefresh();
        setSpinnerData(false);
    };

    const getCreatedDateOfFeed = (ideaType) => {
        if (ideaType === "popular") {
            let date = new Date(ideaData.CreatedDtm).toISOString();
            return new Date(date.slice(0, 10)).toDateString().slice(4);
        }
        if (ideaType === "private") {
            //return new Date(ideaData.CreatedDtm.slice(0, 10)).toDateString().slice(4);
            let date = new Date(ideaData.CreatedDtm).toISOString();
            return new Date(date.slice(0, 10)).toDateString().slice(4);
        }
        if (ideaType === "shared") {
            let sharedUserDetails = ideaData.SharedUserDetails.filter(user => user.EnterprisId === enterpriseId);

            if (sharedUserDetails.length > 0 && sharedUserDetails[0]?.ShareResourceDtm !== null) {
                //return new Date(sharedUserDetails[0].ShareResourceDtm.slice(0, 10)).toDateString().slice(4);
                let date = new Date(sharedUserDetails[0].ShareResourceDtm).toISOString();
                return new Date(date.slice(0, 10)).toDateString().slice(4);
            }
            else {
                //return new Date(ideaData.CreatedDtm?.slice(0, 10)).toDateString().slice(4);
                let date = new Date(ideaData.CreatedDtm).toISOString();
                return new Date(date.slice(0, 10)).toDateString().slice(4);
            }
        }
        if (ideaType === "myidea") {
            //return new Date(ideaData.CreatedDtm.slice(0, 10)).toDateString().slice(4);
            let date = new Date(ideaData.CreatedDtm).toISOString();
            return new Date(date.slice(0, 10)).toDateString().slice(4);
        }
    }

    const onPromoteToIdeaClick = (event, ideaId) => {
        if (!isMobile) {
            setShowPromoteToIdeaPopup(true);
        }
        else {
            setShowPromoteToIdeaPopup(true);
            history.push({
                pathname: '/submit-idea-popup-page',
                state: { title: 'Promote this idea to contest', ideaDetails }
            });
        }

    }

    const setMergedTags = () => {

        let MergedTags = [];

        if (ideaData.IndustryAlignment && ideaData.IndustryAlignment.length > 0) {
            ideaData.IndustryAlignment.map((tag) => {
                MergedTags.push(tag);
            })
        }

        if (ideaData.IsCategoryTechnology && ideaData.CategoryNm && ideaData.CategoryNm.split(",").length > 0) {
            ideaData.CategoryNm.split(",").map((tag) => {
                MergedTags.push(tag);
            })
        }

        setAllTags(MergedTags);

    }

    const onPromote = () => {
        setShowPromoteToIdeaPopup(false);
    }
    const onSendCopyIdea = () => {
        setShowCopyIdeaPopup(false);
    }
    const socialCommentsShowMore = () => {
        if (loadSocialMoreShow) {
            setSocialCommentsPage(currentPage => currentPage + 1);
            getCommentsForIdea(ideaData.IdeaId
                ? ideaData.IdeaId : ideaData.PrivateIdeaId, participantCommentsPage, socialCommentsPage + 1, false);
        }
        else {
            if (socialComments.length > 5) {
                setSocialCommentsPage(1);
                setLoadSocialMoreShow(true);
                setSocialComments(socialComments.filter((comment, index) => index < 5));
            }
        }

    }
    const participantCommentsShowMore = () => {
        if (loadParticipantMoreShow) {
            setParticipantCommentsPage(currentPage => currentPage + 1);
            getCommentsForIdea(ideaData.IdeaId
                ? ideaData.IdeaId : ideaData.PrivateIdeaId, participantCommentsPage + 1, socialCommentsPage, true);
        }
        else {
            if (participantComments.length > 5) {
                setParticipantCommentsPage(1);
                setLoadParticipantMoreShow(true);
                setParticipantComments(participantComments.filter((comment, index) => index < 5));
            }
        }

    }
    const privateCommentsShowMore = () => {
        if (loadPrivateMoreShow) {
            setPrivateCommentsPage(currentPage => currentPage + 1);
            getCommentsForSharedIdea(ideaData.IdeaId
                ? ideaData.IdeaId : ideaData.PrivateIdeaId, privateCommentsPage + 1);
        }
        else {
            if (privateIdeaSharedComments.length > 5) {
                setPrivateCommentsPage(1);
                setLoadPrivateMoreShow(true);
                setPrivateIdeaSharedComments(privateIdeaSharedComments.filter((comment, index) => index < 5));
            }
        }

    }
    const onCopyIdeaClick = (event, ideaId) => {
        event.preventDefault();
        if (!isMobile) {
            setShowCopyIdeaPopup(true);
        }
        else {
            setShowCopyIdeaPopup(true);
            history.push({
                pathname: '/submit-idea-popup-page',
                state: { title: 'Copy this idea to another contest', isCopy: true, ideaDetails }
            });
        }

    }
    //const videoFeedSection = useMemo(() =>
    //    (<VideoFeed promotionalfeedid={ideaData.promotionalfeedid} mediaFiles={ideaData['mediafiles']} />)
    //    , [ideaData.promotionalfeedid]);

    const ideaFeedClick = (e) => {
        e.preventDefault();
        //console.log('The link was clicked.', e.target.id, e.target );  
        //console.log('tagName', e.target.tagName, e.target.className);

        // if (ideaData.isPromotionalFeed === undefined || ideaData.promotionalfeedid === undefined) {
        //     return;
        // }

        if (e.target.id === "share" || e.target.id === "pin" || e.target.id === "comment" ||
            e.target.id === "team" || e.target.id === "like" || e.target.id === "more" ||
            e.target.id === "attachment" || e.target.id === "accept" || e.target.id === "reject" || e.target.id === "seemore") {
            return;
        }
        else if (e.target.tagName == "svg" || e.target.tagName == "LI" || e.target.tagName == "path" || e.target.tagName == "UL") {
            return;
        }
        else if (e.target.className.includes("ui-list__itemcontent") || e.target.className.includes("ui-list__itemheader") ||
            e.target.className.includes("fui-Label") || e.target.className.includes("ui-button__content")) {
            return;
        } else if (ideaData.isPromotionalFeed || ideaData.promotionalfeedid) {
            if (ideaData.title === 'Got a big idea? Gear up for GTIC Season 4') {
                if (Object.keys(promotionalContest).length > 0) {
                    history.push({
                        pathname: `/submit-idea/${promotionalContest["ContestId"]}`,
                        state: {
                            contestDetails: {
                                ...promotionalContest,
                            }
                        },
                    });
                } else {
                    return;
                }
            }
            else if (ideaData.Description && ideaData.Description?.toLowerCase().includes("<a")) {
                let url = document.getElementById("html-description").getElementsByTagName('a')[0].getAttribute("href");
                window.open(url, "_blank");
            }
            else {
                return;
            }
        }
        else {
            onViewClick(e, ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId, ideaData.PrivateIdeaId);
        }
    }

    const renderTeamList = () => {
        return ideaData.CoInnovators && convertArrayInString(ideaData.CoInnovators).map((item) => (
            <div key={uniqid()} style={{ padding: '8px 8px 8px 3px'}}>
                <Avatar name={item.name} />
                <span style={{ paddingLeft: '10px'}}>{item.header}</span>
            </div>
        ))
    }

    const renderSharedUserList = () => {
        return ideaDetails.SharedUserDetails && convertArrayInString(ideaDetails.SharedUserDetails?.map(user => user.EnterprisId)).map(item => (
            <div key={uniqid()} style={{ padding: '8px 8px 8px 3px'}}>
                <Avatar name={item.name} />
                <span style={{ paddingLeft: '10px'}}>{item.header}</span>
            </div>
        ))
    }

    const renderFilesList = () => {
        return ideaData.MediaFiles && ideaData.MediaFiles.map((file) => (
            <div key={uniqid()}>
                <span>{file.FileNm}</span>
            </div>
        ))
    }

    return (
        <>
            {
                showAcceptedPopup ? (
                    <StartImplementationPopup
                        openModal={showAcceptedPopup}
                        closeModal={closeAcceptedPopUp}
                        text1="Thanks for accepting the implementer's role."
                        text2="Please work with idea innovator to take this forward."
                        imageClassName="implementation-icon"
                    />
                ) : (
                    ""
                )}
            {
                showRejectedPopup ? (
                    <RejectPopup
                        openModal={showRejectedPopup}
                        closeModal={closeRejectPopup}
                        ideaId={ideaData.IdeaId}
                        enterprise={enterpriseId}
                        isFromViewIdea={false}
                    />
                ) : (
                    ""
                )}
            {
                showConfirmationPopup ?
                <StartImplementationPopup openModal={showConfirmationPopup}
                    closeModal={closeConfirmationPopup} text1="Response recorded. Innovator has been notified."
                    text2='' imageClassName="implementation-icon" /> 
                    : ''
            }
            {showSpinner ? <PageLoader hidden={false} /> : ""}
            {showCopyIdeaPopup && !isMobile ? <CopyIdeaPopup ideaDetails={ideaData} closeModal={onSendCopyIdea} /> : null}
            {showSharedPopup ? 
                <ShareIdeaPopup openModal={showSharedPopup} closeModal={closePopUp} ideaType={type} ideaId={ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId} ideaRegData={ideaDetails} enterprise={enterpriseId} /> 
            : ''}
            {showDelete ? 
            <DeletePopUp closeModal={closeDeletePopUp} deleteIdea={onDeleteEvent} openModal={showDelete} contestName={ideaData?.ContestNm?.toLowerCase().includes("v360") ? "v360" : ''} /> 
            : ''}
            {showPromoteToIdeaPopup && !isMobile ?
            <PromoteToIdea ideaDetails={ideaData} closeModal={onPromote} /> 
            : null}
            {isMobile ?
                <IdeaFeedMobile ideaData={ideaData} ideaFeedClick={ideaFeedClick} isExploreIdea={isExploreIdea} enterpriseId={enterpriseId}
                    onShareClick={onShareClick} onPinClick={onPinClick} pinned={pinned}
                    handleOnClick={handleOnClick} selectedList={selectedList} allTags={allTags} isIdeaLiked={isIdeaLiked}
                    onLikeClick={onLikeClick} likeCount={likeCount} onCommentClick={onCommentClick} ideaDetails={ideaDetails}
                    onShareCountClick={onShareCountClick} fromSerialInnovator={fromSerialInnovator} openAcceptedPopup={openAcceptedPopup}
                    openRejectedPopup={openRejectedPopup} showMessages={showMessages} isDisabled={isDisabled}
                    enterprise={enterprise} setAddComment={setAddComment} addComment={addComment} addCommentToIdea={addCommentToIdea}
                    newCommentLoader={newCommentLoader} socialComments={socialComments} loadSocialMoreShow={loadSocialMoreShow}
                    socialCommentsShowMore={socialCommentsShowMore} socialCommentsPage={socialCommentsPage} addCommentToSharedIdea={addCommentToSharedIdea}
                    privateIdeaSharedComments={privateIdeaSharedComments} loadPrivateMoreShow={loadPrivateMoreShow}
                    privateCommentsShowMore={privateCommentsShowMore} privateCommentsPage={privateCommentsPage} showCommentBox={showCommentBox}
                    participantComments={participantComments} loadParticipantMoreShow={loadParticipantMoreShow} participantCommentsShowMore={participantCommentsShowMore}
                    participantCommentsPage={participantCommentsPage} showCopyIdea={showCopyIdea} onCopyIdeaClick={onCopyIdeaClick} commentBasedFilter={commentBasedFilter} fromMyStories={fromMyStories} />
                :
                <>
                    <div className="idea-feed box-feed-entry-wrapper" onClick={ideaFeedClick}>
                        {ideaData.isPopularIdeas || ideaData.IsPopularIdea ?
                            <Tooltip withArrow content="Popular" relationship='label'
                                 appearance='inverted'> 
                                 <i className="ms-Icon ms-Icon--SingleBookmarkSolid" aria-hidden="true"></i>
                            </Tooltip>
                            : ""}
                        <h3 data-ideaid={ideaData.IdeaId} data-promotionalfeedid={ideaData.promotionalfeedid}
                            data-privateideaid={ideaData.PrivateIdeaId} data-getideatilldate={ideaData.GetIdeaTillDate}
                            data-ideastatus={ideaData.IdeaStatus} data-ideaworkflowstatus={ideaData.IdeaWorkFlowStatus}
                            data-ispopularideas={ideaData.isPopularIdeas} data-ispromotionalfeed={ideaData.isPromotionalFeed} >
                            {ideaData.Title ? ideaData.Title : (ideaData.title ? ideaData.title : ideaData.IdeaTitle)}
                        </h3>
                        <h6>{ideaData.ContestNm ? ideaData.ContestNm : ""}</h6>

                        <div className="idea-top-action">
                            <ul>
                                {(!isExploreIdea || fromMyStories) ?
                                    <li><span className={setIdeaStatusClass()}>{setIdeaStatus()}</span></li> : null}

                                {(ideaData.isPromotionalFeed
                                    || (ideaData.IsPopularIdea && !(ideaData['CreatedBy'] === enterpriseId || ideaData.CoInnovators?.includes(enterpriseId)))
                                    || (['Shared with Me', 'Draft'].includes(ideaData['IdeaWorkFlowStatus']) || ['Shared with Me', 'Draft'].includes(ideaData['IdeaStatus'])) || !(ideaData['CreatedBy'] === enterpriseId || ideaData.CoInnovators?.includes(enterpriseId)))
                                    ? null :
                                    <li key={uniqid()}>
                                        <Tooltip withArrow relationship='label' content="Share" appearance='inverted'>
                                            <a href="" onClick={(event) => onShareClick(event, ideaData.IdeaId)}>
                                                <span className="icon Icon--Share">
                                                    <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" 
                                                    viewBox="0 0 20 20">
                                                        <g fill="none">
                                                            <path d="M12 5.502a2.502 2.502 0 1 1 .531 1.541L7.915 9.351a2.504 2.504 0 0 1 0 1.297l4.618 2.31a2.5 2.5 0 1 1-.448.894l-4.618-2.31a2.5 2.5 0 1 1 0-3.086l4.617-2.307A2.505 2.505 0 0 1 12 5.502zM14.502 4a1.501 1.501 0 1 0 0 3.003a1.501 1.501 0 0 0 0-3.003zm-.002 9a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM4 10a1.5 1.5 0 1 0 3 0a1.5 1.5 0 0 0-3 0z" fill="#626262" /></g><rect id="share" x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </Tooltip>
                                    </li>
                                }
                                <li key={uniqid()}>
                                    <Tooltip withArrow relationship='label' content="Pin" appearance='inverted'>
                                        <a href="" 
                                        onClick={(event) => onPinClick(event, ideaData.IdeaId ? ideaData.IdeaId 
                                        : (ideaData.PrivateIdeaId ? ideaData.PrivateIdeaId : ideaData.promotionalfeedid), 
                                        ideaData.PrivateIdeaId ? 'private' : 'public')}>
                                            <span className={pinned}>
                                                <svg className="pin-outline" width="20" height="20" preserveAspectRatio="xMidYMid meet" 
                                                viewBox="0 0 20 20" >
                                                    <g fill="none">
                                                        <path d="M10.122 3.138a2 2 0 0 1 3.203-.52l4.057 4.057a2 2 0 0 1-.52 3.203l-3.458 1.73a1.5 1.5 0 0 0-.722.784l-1.436 3.59a1 1 0 0 1-1.636.335L7 13.707L3.707 17H3v-.707L6.293 13l-2.61-2.61a1 1 0 0 1 .335-1.636l3.59-1.436a1.5 1.5 0 0 0 .785-.722l1.73-3.458zm2.496.187a1 1 0 0 0-1.601.26l-1.73 3.458A2.5 2.5 0 0 1 7.98 8.247L4.39 9.683l5.927 5.927l1.436-3.59a2.5 2.5 0 0 1 1.204-1.307l3.458-1.73a1 1 0 0 0 .26-1.601l-4.057-4.057z" fill="#626262" /></g><rect id="pin" x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                </svg>

                                                <svg className="pin-filled" width="20" height="20" preserveAspectRatio="xMidYMid meet" 
                                                viewBox="0 0 16 16">
                                                    <g fill="none">
                                                        <path d="M10.059 2.445a1.5 1.5 0 0 0-2.386.354l-2.02 3.79l-2.811.937a.5.5 0 0 0-.196.828L4.793 10.5l-2.647 2.647L2 14l.853-.146L5.5 11.207l2.146 2.147a.5.5 0 0 0 .828-.196l.937-2.81l3.779-2.024a1.5 1.5 0 0 0 .354-2.38L10.06 2.444z" fill="#626262" /></g><rect id="pin" x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
                                                </svg>
                                            </span>
                                        </a>
                                    </Tooltip>

                                </li>
                                {ideaData.isPromotionalFeed ? null : <li ref={moreItemsBox}>
                                    <Menu>
                                        <MenuTrigger disableButtonEnhancement>
                                            <Tooltip withArrow content="More" relationship='label'
                                                appearance='inverted'>
                                                <Button appearance='transparent' size='small' icon={<MoreVerticalRegular fontSize={16} color='#a341ed' onClick={(event) => onMoreClick(event)}/>}></Button>
                                            </Tooltip>
                                        </MenuTrigger>

                                        <MenuPopover>
                                            <MenuList>
                                                {selectedList.map((item) => (
                                                    <MenuItem key={item.key} icon={item.media} onClick={(event) => handleOnClick(event, item)}>{item.content}</MenuItem>
                                                ))
                                                }
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>
                                </li>}

                            </ul>
                        </div>

                        {
                            ideaData.isPromotionalFeed || ideaData.promotionalfeedid ?
                                <p className="collapse-text margin-bottom-5" id="html-description">{parse(ideaData?.Description ? ideaData.Description : '', options)}</p> :
                                <p className="collapse-text margin-bottom-5">{ideaData.Description ? ideaData.Description : ideaData.CurrentSituation}</p>
                        }
                        {
                            ideaData.isPromotionalFeed || ideaData.promotionalfeedid ?
                                ideaData.Description != null && parse(ideaData?.Description ? ideaData.Description : '', options)?.toString()?.length > 200 ?
                                    <p className="margin-bottom-5"><a id="seemore" className="see-more" onClick={seeMore}>see more</a></p> : ""
                                : ideaData.Description != null && ideaData.Description.length > 200 ?
                                <p className="margin-bottom-5"><a id="seemore" className="see-more" onClick={seeMore}>see more</a></p> :
                                ideaData != null && ideaData.CurrentSituation != null && ideaData.CurrentSituation.length > 200 ?
                                    <p className="margin-bottom-5"><a id="seemore" className="see-more" onClick={seeMore}>see more</a></p> :
                                    ""
                        }

                        {
                            ideaData.isPromotionalFeed || ideaData.promotionalfeedid ? 
                            <VideoFeed promotionalfeedid={ideaData.promotionalfeedid} mediaFiles={ideaData['mediafiles']} /> 
                            : ''
                        }

                        <div className="preference-tag">
                            {allTags.filter((tag, i) => i <= 1).map((tag) => {
                                return <div className="skill-tags fs-13" key={uniqid()}>{tag}</div>;
                            })}
                            {allTags?.length > 2 ?
                                <Tooltip
                                    appearance='inverted'
                                    content={allTags.slice(
                                        2,
                                        allTags.length
                                    ).map((tag) => (
                                        <div className="skill-tags fs-13" key={uniqid()}>
                                            {tag}
                                        </div>
                                    ))}
                                    positioning="below"
                                    relationship='label'>
                                        <span><Text size={100}>{`+${allTags.length - 2} more`}</Text></span>
                                    </Tooltip>
                                : null}
                        </div>
                        <div>
                            <ul className="feedback-btns">
                                <li key={uniqid()}>
                                    {!ideaData.isPromotionalFeed ?
                                        <Tooltip withArrow 
                                        content={ ideaData.CreatedBy } appearance='inverted' relationship='label'>
                                            <Avatar size={28} name={ideaData.CreatedBy.split('.').join(" ").toUpperCase()} 
                                            className='fui-Avatar'
                                            onClick={(event) => event.stopPropagation()}  />
                                        </Tooltip>
                                        : null}
                                </li>

                                {(ideaData.isPromotionalFeed || ideaData.IdeaStatus?.toLowerCase() === 'draft' || ideaData.IdeaWorkFlowStatus === 'draft') ? null :
                                    <li key={uniqid()}>

                                        {
                                            isIdeaLiked ?
                                                <a href="" onClick={(event) => onLikeClick(event, ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId)}>
                                                    <Tooltip withArrow content="Like"  
                                                    appearance='inverted' relationship='label' 
                                                    // style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
                                                    >
                                                        <i id="like" className="ms-Icon ms-Icon--LikeSolid" aria-hidden="true"></i>
                                                    </Tooltip>
                                                    <span id="like">{likeCount ? likeCount : 0}</span>
                                                </a> :
                                                <a href="" onClick={(event) => onLikeClick(event, ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId)}>

                                                    <Tooltip withArrow relationship='label' content="Like" appearance='inverted'>
                                                        
                                                    <span className="icon Icon--Like">
                                                            <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                                <g fill="none"><path d="M10.052 2.294c.34-.977 1.632-1.427 2.43-.59c.164.17.326.354.436.519c.32.48.455 1.113.504 1.73c.05.627.016 1.301-.048 1.912c-.065.612-.161 1.174-.241 1.583l-.01.052h.883a3 3 0 0 1 2.952 3.537l-.684 3.761a4.5 4.5 0 0 1-5.612 3.537l-5.6-1.527a2.5 2.5 0 0 1-1.793-1.922l-.353-1.765c-.278-1.389.784-2.559 1.913-3.005a2.84 2.84 0 0 0 .84-.49c1.707-1.514 2.325-2.723 3.385-4.85c.354-.71.718-1.676.998-2.482zm1.965 5.585v-.002l.002-.007l.007-.031a13.699 13.699 0 0 0 .126-.584c.076-.388.167-.92.227-1.495c.061-.577.09-1.184.046-1.728c-.044-.556-.16-.985-.34-1.255a3.258 3.258 0 0 0-.326-.383c-.199-.208-.628-.16-.762.228c-.283.814-.664 1.83-1.048 2.6c-1.067 2.141-1.756 3.502-3.616 5.152c-.34.302-.744.517-1.136.672c-.88.348-1.447 1.148-1.3 1.879l.352 1.765a1.5 1.5 0 0 0 1.077 1.153l5.6 1.527a3.5 3.5 0 0 0 4.364-2.75l.684-3.762A2 2 0 0 0 14.006 8.5h-1.505a.5.5 0 0 1-.484-.621z" fill="#626262" /></g><rect id="like" x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                    <span id="like">{likeCount ? likeCount : 0}</span>
                                                </a>
                                        }
                                    </li>}
                                {(ideaData.isPromotionalFeed || ideaData.IdeaStatus?.toLowerCase() === 'draft' || ideaData.IdeaWorkFlowStatus === 'draft') ? null :
                                    <li key={uniqid()}>
                                        <a href="" onClick={(event) => onCommentClick(event, ideaData.IdeaId
                                            ? ideaData.IdeaId : ideaData.PrivateIdeaId, ideaData)}>
                                            <Tooltip withArrow relationship='label' appearance='inverted'
                                            content="Comment" positioning="above" 
                                            // style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
                                            >
                                                <span className="icon Icon--CannedChat">
                                                    <svg width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16" >
                                                        <g fill="none"><path d="M1 4.5A2.5 2.5 0 0 1 3.5 2h9A2.5 2.5 0 0 1 15 4.5v5a2.5 2.5 0 0 1-2.5 2.5H8.688l-3.063 2.68A.98.98 0 0 1 4 13.942V12h-.5A2.5 2.5 0 0 1 1 9.5v-5zM3.5 3A1.5 1.5 0 0 0 2 4.5v5A1.5 1.5 0 0 0 3.5 11H5v2.898L8.312 11H12.5A1.5 1.5 0 0 0 14 9.5v-5A1.5 1.5 0 0 0 12.5 3h-9z" fill="#626262" /></g><rect id="comment" x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                            <span id="comment">{!commentBasedFilter ? ((ideaData.IdeaCoOwnerFlag || ideaData.IdeaCoOwnerFlag === undefined) ? ideaDetails.IdeaCommentCount ? ideaDetails.IdeaCommentCount : 0 : ideaDetails.SocialCommentCount ? ideaDetails.SocialCommentCount : 0) : commentBasedFilter && ideaDetails.IdeaCommentCount ? ideaDetails.IdeaCommentCount : 0}</span>
                                        </a>
                                    </li>}

                                {(ideaData.PrivateIdeaId || ideaData.isPromotionalFeed) ? null :
                                    <li key={uniqid()}>
                                        <a href="" onClick={(event) => onTeamClick(event, ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId)}>
                                            <Tooltip withArrow relationship='label' appearance='inverted'
                                            content="Key Members" positioning="above">
                                                <span className="icon Icon--People">
                                                    <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                        <g fill="none"><path d="M11 10a2 2 0 0 1 2 2v1.5c0 2.054-2.088 3.5-5.5 3.5S2 15.554 2 13.5V12a2 2 0 0 1 2-2h7zm0 1H4a1 1 0 0 0-1 1v1.5C3 14.907 4.579 16 7.5 16c2.921 0 4.5-1.093 4.5-2.5V12a1 1 0 0 0-1-1zm5-1a2 2 0 0 1 2 2v.5c0 2.089-1.568 3.5-4.5 3.5c-.141 0-.28-.003-.414-.01c.208-.242.382-.502.522-.781l.097-.213h.039C15.976 14.931 17 13.96 17 12.5V12a1 1 0 0 0-1-1h-2.171a3 3 0 0 0-.594-1H16zM7.5 2a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm7 2a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm-7-1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zm7 2a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z" fill="#626262" /></g><rect id="team" x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                            {ideaData.CoInnovators == 0 ? <span id="team">0</span> :
                                              (<Tooltip
                                                content={renderTeamList()}
                                                relationship='label'
                                                positioning='below'
                                                appearance='inverted'
                                                withArrow
                                              >
                                                <span id="team" className='tooltiphost'>{ideaData.CoInnovators ? ideaData.CoInnovators.length : 0}</span>
                                              </Tooltip>)
                                            }
                                        </a>
                                    </li>
                                }
                                {(ideaData.PrivateIdeaId || ideaData.isPromotionalFeed || ideaData.IdeaStatus?.toLowerCase() === 'draft' || ideaData.IdeaWorkFlowStatus === 'draft' || ideaData.IsDynamicIdea || ideaData.ContestNm.toLowerCase().includes("v360")) ? null :
                                    (showCopyIdea ?
                                        <li key={uniqid()}>
                                            <a href="" onClick={(event) => onCopyIdeaClick(event, ideaData.IdeaId ? ideaData.IdeaId : null)}>
                                                <Tooltip withArrow content="Copy Idea" appearance='inverted'
                                                positioning="above" relationship='label'>
                                                    <span className="icon Icon--Copy">
                                                        <svg aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path d="M29.5 7h-19A1.5 1.5 0 0 0 9 8.5v24a1.5 1.5 0 0 0 1.5 1.5h19a1.5 1.5 0 0 0 1.5-1.5v-24A1.5 1.5 0 0 0 29.5 7zM29 32H11V9h18z" fill="currentColor" /><path d="M26 3.5A1.5 1.5 0 0 0 24.5 2h-19A1.5 1.5 0 0 0 4 3.5v24A1.5 1.5 0 0 0 5.5 29H6V4h20z" fill="currentColor" /></svg>
                                                    </span>
                                                </Tooltip>

                                            </a>
                                        </li> : null)
                                }

                                {ideaData.PrivateIdeaId ? <li key={uniqid()}>
                                    <a href="" onClick={(event) => onShareCountClick(event)}>
                                        <Tooltip withArrow content="Share Count" appearance='inverted'
                                        relationship='label' positioning="above">
                                            <span className="icon Icon--People">
                                                <svg id="team" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                                                    <g fill="none">
                                                        <path d="M8.75 3.75a2.75 2.75 0 1 0-5.5 0a2.75 2.75 0 0 0 5.5 0zm-4.5 0a1.75 1.75 0 1 1 3.5 0a1.75 1.75 0 0 1-3.5 0z" fill="#626262" /><path d="M2.5 7.5h4.183c-.164.31-.286.646-.358 1H2.5A.5.5 0 0 0 2 9v.5c0 1.26 1.099 2.614 3.096 2.93c-.322.22-.59.513-.781.854C2.205 12.713 1 11.087 1 9.5V9a1.5 1.5 0 0 1 1.5-1.5z" fill="#626262" /><path d="M7.879 7.5c.504-.61 1.267-1 2.121-1a2.744 2.744 0 0 1 2.646 2a2.753 2.753 0 0 1-3.893 3.202A2.75 2.75 0 0 1 7.88 7.5zm.54 1a1.75 1.75 0 1 0 3.164 1.5a1.75 1.75 0 0 0-3.165-1.5z" fill="#626262" /><path d="M15.685 13.284a2.513 2.513 0 0 0-.781-.853C16.9 12.114 18 10.759 18 9.5V9a.5.5 0 0 0-.5-.5h-3.825a3.726 3.726 0 0 0-.357-1H17.5A1.5 1.5 0 0 1 19 9v.5c0 1.587-1.206 3.212-3.315 3.784z" fill="#626262" /><path d="M14.487 13.37c-.264-.23-.609-.37-.987-.37h-7A1.496 1.496 0 0 0 5 14.5v.5c0 1.971 1.86 4 5 4c3.14 0 5-2.029 5-4v-.5c0-.45-.198-.854-.513-1.13zM6 14.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v.5c0 1.438-1.432 3-4 3s-4-1.562-4-3v-.5z" fill="#626262" /><path d="M14 1a2.75 2.75 0 1 1 0 5.5A2.75 2.75 0 0 1 14 1zm0 1a1.75 1.75 0 1 0 0 3.5A1.75 1.75 0 0 0 14 2z" fill="#212121" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </Tooltip>
                                        {ideaData.ShareduserCount === 0 ? (<span id="team">0</span>) :
                                            (<Tooltip 
                                            appearance='inverted'
                                            content={renderSharedUserList()}
                                            withArrow
                                            relationship='label'
                                            positioning='below'
                                            // styles={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
                                          >
                                            <span id="team">
                                                {ideaDetails.ShareduserCount ? ideaDetails.ShareduserCount : 0}
                                            </span>
                                            </Tooltip>)
                                          }
                                    </a>

                                </li> : null
                                }

                                {ideaData.PrivateIdeaId && ideaData.MediaFiles && ideaData.MediaFiles.length ?
                                    <li key={uniqid()}>
                                        <a href="" onClick={(event) => onAttachClick(event)}>
                                            <Tooltip withArrow  appearance='inverted'
                                                content={renderFilesList()}
                                                positioning="above" relationship='label'
                                            >
                                                <span className="icon scribble-attach">
                                                    <svg xmlns="" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                                                        <g fill="none"><path d="M3.257 8.707l4.597-4.596a2.5 2.5 0 0 1 3.535 3.536L6.086 12.95a1 1 0 0 1-1.414-1.414l5.303-5.304a.5.5 0 0 0-.707-.707L3.964 10.83a2 2 0 1 0 2.829 2.828l5.303-5.303a3.5 3.5 0 0 0-4.95-4.95L2.55 8a.5.5 0 1 0 .707.707z" fill="#626262"></path></g><rect id="attachment" x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)"></rect>
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                            <span id="attachment">{ideaData.MediaFiles?.length}</span>

                                        </a>
                                    </li> : ''}
                            </ul>
                            {!fromMyStories ?
                                <div className="idea-feed-date">
                                    {fromSerialInnovator || isExploreIdea ? <h6>Submitted on {new Date((new Date(ideaData.CreatedDtm).toISOString()).slice(0, 10)).toDateString().slice(4)}</h6> :
                                        <>
                                            {ideaData.IsPopularIdea && ideaData.ShareduserCount == 0 ? <h6>Submitted on {getCreatedDateOfFeed("popular")}</h6> : null}
                                            {ideaData.PrivateIdeaId && ideaData.ShareduserCount == 0 ? <h6>Created on {getCreatedDateOfFeed("private")}</h6> : null}
                                            {ideaData.IdeaId && !ideaData.PrivateIdeaId && !ideaData.IsPopularIdea && ideaData.ShareduserCount == 0 ? <h6>Submitted on {getCreatedDateOfFeed("myidea")}</h6> : null}
                                            {ideaData.ShareduserCount > 0 ? <h6>Shared on {getCreatedDateOfFeed("shared")}</h6> : null}
                                        </>
                                    }
                                </div> : null}
                        </div>
                        {
                            ideaData.IsImplementerIdea && ideaData.IsImplementer && (ideaData?.IdeaWorkFlowStatus?.toUpperCase() === "UNDER IMPLEMENTATION" ||
                                ideaData?.IdeaWorkFlowStatus?.toUpperCase() === "READY FOR IMPLEMENTATION") ?
                                <div className="implementer-actions">
                                    <div className="implementer-content">
                                        <span>Collaborate to become implementer</span>
                                    </div>
                                    <div className="action-wrapper">
                                        <Button
                                            appearance='primary'
                                            className="btn-primary accept-btn"
                                            onClick={(event) =>
                                                openAcceptedPopup(event)
                                            }
                                            id="accept"
                                        >Accept</Button>
                                        <Button
                                            className="btn-primary reject-btn"
                                            onClick={(event) =>
                                                openRejectedPopup(event)
                                            }
                                            id="reject"
                                        >Reject</Button>
                                    </div>
                                </div> : ""
                        }
                        {
                            ideaData.IsImplementerIdea && ideaData?.Implementers &&
                                ideaData?.Implementers?.find(x => x.ImplementerId === enterpriseId) &&
                                ideaData?.Implementers?.find(x => x.ImplementerId === enterpriseId)?.Status != "UnderAcceptance" ?
                                <div className="implementer-actions">
                                    {
                                        ideaData?.Implementers?.find(x => x.ImplementerId === enterpriseId)?.Status === "Accept" ?
                                            <div className="accepted-status">
                                                <span>ACCEPTED</span>
                                            </div> : <div className="rejected-status">
                                                <span>REJECTED</span>
                                            </div>
                                    }
                                </div> : ""
                        }
                    </div>
                    {
                        showMessages ?
                            <div className="idea-feed comment-entry-wrapper">
                                {ideaData.IsPopularIdea ?
                                    <Tabs defaultActiveKey="social" id="uncontrolled-tab-example" class="comments-tab" >
                                        <Tab eventKey="social" title="Social Comments">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>
                                                    <div className="comment-avatar">
                                                        <Avatar name={enterprise} size={32} />
                                                    </div>
                                                    <div className="add-comment margin-bottom-10">
                                                        <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                                            name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                                            className="" maxLength={500} disabled={isDisabled} />
                                                        <a className="send-comment" href="" onClick={(event) => addCommentToIdea(event, ideaData.IdeaId, false)}>
                                                            <span className="icon Icon--Send">
                                                                <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                                    <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    {newCommentLoader ? 
                                                    <Spinner label="Loading" labelPosition="after" size="extra-small" /> 
                                                    : null}
                                                    {socialComments && socialComments.length > 0 ?
                                                        (<>
                                                            {socialComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                                            {loadSocialMoreShow ?
                                                                <div className='load-more-comments'>
                                                                    <Text onClick={socialCommentsShowMore}>
                                                                        Load More Comments
                                                                    </Text>
                                                                </div>
                                                                :
                                                                socialCommentsPage !== 1 ?
                                                                    <div className='load-more-comments'>
                                                                        <Text onClick={socialCommentsShowMore}>
                                                                        Load Less Comments
                                                                        </Text>
                                                                    </div>

                                                                    // </Button> 
                                                                    : null}
                                                        </>)
                                                        : <Text
                                                        style={{ fontSize: '1vw !important', marginLeft: '16vw' }}
                                                        >No Comments Available</Text>}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs> : ""}

                                {ideaData.PrivateIdeaId ?
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <div className="comment-avatar">
                                                    <Avatar name={enterprise} size={32} />
                                                </div>
                                                <div className="add-comment margin-bottom-10">
                                                    <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                                        name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                                        className="" maxLength={500} disabled={isDisabled} />
                                                    <a className="send-comment" href="" onClick={(event) => addCommentToSharedIdea(event, ideaData.PrivateIdeaId)}>

                                                        <span className="icon Icon--Send">
                                                            <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                                <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div>
                                                {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                                {privateIdeaSharedComments && privateIdeaSharedComments.length > 0 ?
                                                    (<>
                                                        {privateIdeaSharedComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} key={uniqid()} />)}
                                                        {loadPrivateMoreShow ?
                                                            <div className='load-more-comments'>
                                                                <Text onClick={privateCommentsShowMore}>
                                                                Load More Comments
                                                                </Text>
                                                            </div>
                                                            :
                                                            privateCommentsPage !== 1 ?
                                                                <div className='load-more-comments'>
                                                                    <Text onClick={privateCommentsShowMore}>
                                                                    Load Less Comments
                                                                    </Text>
                                                                </div>
                                                                 : null}
                                                    </>)
                                                    : <Text style={{ fontSize: '1vw !important', marginLeft: '16vw' }}>
                                                    No Comments Available</Text>}
                                            </div>
                                        </div>
                                    </> : ""}

                                {ideaData.IsPopularIdea == false && ideaData.isPrivateIdea == undefined && ideaData.IdeaWorkFlowStatus === "Shared with Me" ?
                                    <Tabs defaultActiveKey="social" id="uncontrolled-tab-example" class="comments-tab" >
                                        <Tab eventKey="social" title="Social Comments">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>
                                                    <div className="comment-avatar">
                                                        <Avatar name={enterprise} size={32} />
                                                    </div>
                                                    <div className="add-comment margin-bottom-10">
                                                        <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                                            name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                                            className="" maxLength={500} disabled={isDisabled} />
                                                        <a className="send-comment" href="" onClick={(event) => addCommentToIdea(event, ideaData.IdeaId, false)}>

                                                            <span className="icon Icon--Send">
                                                                <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                                    <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                                    {socialComments && socialComments.length > 0 ?
                                                        (<>
                                                            {socialComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                                            {loadSocialMoreShow ?
                                                                // <Button onClick={socialCommentsShowMore} style={{ margin: '0vw !important', marginLeft: '17vw !important' }}>
                                                                    <div className='load-more-comments'>
                                                                         <Text onClick={socialCommentsShowMore}>
                                                                        Load More Comments
                                                                        </Text>
                                                                    </div>
                                                                   
                                                                // </Button>
                                                                :
                                                                socialCommentsPage !== 1 ?
                                                                    <div className='load-more-comments'>
                                                                            <Text onClick={socialCommentsShowMore}>
                                                                            Load Less Comments
                                                                            </Text>
                                                                    </div>
                                                                    
                                                                    // </Button> 
                                                                    : null}
                                                        </>)
                                                        : <Text style={{ fontSize: '1vw !important', marginLeft: '16vw' }}>
                                                            No Comments Available</Text>}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs> : ""}
                                {ideaData.IsPopularIdea == false && ideaData.isPrivateIdea == undefined && ideaData.IdeaId && ideaData.IdeaWorkFlowStatus !== "Shared with Me" ?
                                    <Tabs defaultActiveKey="social" id="uncontrolled-tab-example" class="comments-tab" >
                                        <Tab eventKey="social" title="Social Comments">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {showCommentBox && showCommentBox.SocialCommentBox ?
                                                    <div>
                                                        <div className="comment-avatar">
                                                            <Avatar name={enterprise} size={32} />
                                                        </div>
                                                        <div className="add-comment margin-bottom-10">
                                                            <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                                                name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                                                className="" maxLength={500} disabled={isDisabled} />
                                                            <a className="send-comment" href="" onClick={(event) => addCommentToIdea(event, ideaData.IdeaId, false)}>

                                                                <span className="icon Icon--Send">
                                                                    <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                                        <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div> : null}
                                                <div>
                                                    {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                                    {socialComments && socialComments.length > 0 ?
                                                        (<>
                                                            {socialComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                                            {loadSocialMoreShow ?
                                                                <div className='load-more-comments'>
                                                                    <Text onClick={socialCommentsShowMore}>
                                                                    Load More Comments
                                                                    </Text>
                                                                </div>
                                                                :
                                                                socialCommentsPage !== 1 ?
                                                                    <div className='load-more-comments'>
                                                                        <Text onClick={socialCommentsShowMore}>
                                                                            Load Less Comments
                                                                        </Text>
                                                                    </div>
                                                                    : null}
                                                        </>)
                                                        : (<Text style={{ fontSize: '1vw !important', marginLeft: '16vw' }}>
                                                            No Comments Available
                                                            </Text>)
                                                        }
                                                </div>
                                            </div>
                                        </Tab>
                                        {showCommentBox && showCommentBox.ParticipantCommentBox ?
                                            <Tab eventKey="participant" title="Participant Comments">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div>
                                                        <div className="comment-avatar">
                                                            <Avatar name={enterprise} size={32} />
                                                        </div>
                                                        <div className="add-comment margin-bottom-10">
                                                            <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                                                name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                                                className="" maxLength={500} disabled={isDisabled} />
                                                            <a className="send-comment" href="" onClick={(event) => addCommentToIdea(event, ideaData.IdeaId, true)}>

                                                                <span className="icon Icon--Send">
                                                                    <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                                        <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                                        {participantComments && participantComments.length > 0 ?
                                                            (<>
                                                                {participantComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                                                {loadParticipantMoreShow ?
                                                                    <div className='load-more-comments'>
                                                                        <Text onClick={participantCommentsShowMore}>
                                                                        Load More Comments
                                                                        </Text>
                                                                    </div>
                                                                    :
                                                                    participantCommentsPage !== 1 ?
                                                                        <div className='load-more-comments'>
                                                                            <Text onClick={participantCommentsShowMore}>
                                                                            Load Less Comments
                                                                            </Text>
                                                                        </div>
                                                                         : null}
                                                            </>)
                                                            : <Text style={{ fontSize: '1vw !important', marginLeft: '16vw' }}>
                                                                No Comments Available
                                                                </Text>}
                                                    </div>
                                                </div>
                                            </Tab>
                                            : null}
                                    </Tabs> : ""}
                            </div> : ""
                    }
                </>
            }
        </>
    );
});

export default IdeaFeed;