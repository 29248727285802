import { types } from '../actions/types';

const initialState = {
    setActiveTab: 'Pending',
    setActiveCategory: 'Ideas',
    setBackPIB: false,
}

const fetchActiveTabCatReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ACTIVE_TAB_CAT_PARENT:
            return {
                ...state,
                setActiveTab: action.payload.tabName,
                setActiveCategory: action.payload.categoryName
            }
      
        case types.SET_NAVIGATION_FROM_PIB:
            return {
                ...state,
                navigationFromPIB: action.payload
               
            }
        default:
            return {
                ...state
            }
    }


}
export default fetchActiveTabCatReducer;