import axios from 'axios';
import { serviceMethod } from './config-reader';
import { getMethod, postMethod} from './http-interceptor-service';
let cancelToken;


export const getData = (EnterpriseID) => {
    let method = serviceMethod.peopleDetailApis.peopleDetails;
    method = method.replace('#enterpriseId', EnterpriseID);
    const URL = process.env.REACT_APP_MRDRSERVICE + method;
    return getMethod(URL);
}

export const getPeopleHierarchyUpload = (token, data) => {
    let arr = [];
    if (data) {
        data.forEach((item) => {
            arr.push(item.OrgUnitId)
        })
    }
    let obj = {
        "data" : arr
    }
    const URL = process.env.REACT_APP_MRDRSERVICE + serviceMethod.peopleDetailApis.GetPeopleHierarchyUnfolded;
    return postMethod(URL, obj);
}

export const getContestAndChallengeByDemographic = (token, peoplekey, orgUnits) => {
    const URL = process.env.REACT_APP_CONTESTSERVICE + serviceMethod.demographicApi.GetChallengeNContestByDemographic;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    const obj = {
        LocationHierarchy: [],
        PeopleHierarchy: orgUnits,
        PeopleKey: peoplekey
    }

    return postMethod(URL, obj, config);
}

export const getChallengeNContestByDemographicFromTeams = (token, peoplekey, userCountryName, orgUnits, IsActiveContestOnly = false, IsChallengeRequired = false) => {
    let method = serviceMethod.demographicApi.GetChallengeNContestByDemographicFromTeams;
    method = method.replace('#countryName', userCountryName);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method + "&IsActiveContestOnly=" + IsActiveContestOnly + "&IsChallengeNotRequired=" + IsChallengeRequired;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    const obj = {
        LocationHierarchy: [],
        PeopleHierarchy: orgUnits,
        PeopleKey: peoplekey
    }

    return postMethod(URL, obj, config);
}

export const getChallengeNContestByDemographicFromTeamsContractor = (token, peoplekey, userCountryName, orgUnits, IsActiveContestOnly = false, IsChallengeRequired = false, IsCallFromContractor) => {
    let method = serviceMethod.demographicApi.GetChallengeNContestByDemographicFromTeamsContractor;
    method = method.replace('#countryName', userCountryName);
    method = method.replace ("#IsCallFromContractor",IsCallFromContractor);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method + "&IsActiveContestOnly=" + IsActiveContestOnly + "&IsChallengeNotRequired=" + IsChallengeRequired;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    const obj = {
        LocationHierarchy: [],
        PeopleHierarchy: orgUnits,
        PeopleKey: peoplekey
    }

    return postMethod(URL, obj, config);
}


export const getConfigurationList = (token) => {
    const URL = process.env.REACT_APP_CONFIGURATIONSERVICE + serviceMethod.configurationApis.GetConfigurationList;
    return getMethod(URL);
}

export const GetLocationDetailsTree = (locationId,isMarket=false ) => {
    let method = serviceMethod.GetLocationDetails.GetLocationDetails;;
    method = method.replace('#locationId', locationId);
    method = method.replace('#isMarket', isMarket);
    const URL = process.env.REACT_APP_ADMINSERVICE + method
    return getMethod(URL);
}

export const getUNSDGAlignment = (token) => {
    const URL = process.env.REACT_APP_UNSDGALIGNSERVICE + serviceMethod.idea.GetUNSDGAlignment;
    return getMethod(URL);
}

export const uploadFile = (file, ideaId) => {
   
    let addFileURL = process.env.REACT_APP_REPORTVIEWERSERVICE + `AddFile?ideaId=${ideaId}`;
    const formData = new FormData();
    formData.append('body', file);

    const config = {
        headers: {
            IsBatchProcess: true,
            'content-type': 'multipart/form-data',
        }
    };

    return postMethod(addFileURL, formData, config);
}

export const deleteFile = (data) => {
    let deleteFileURL = `DeleteFile?mode=`;
    const URL = process.env.REACT_APP_IDEASERVICE + deleteFileURL;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);

}

export const getPeopleName = (data) => {
    const url = process.env.REACT_APP_MRDRSERVICE + serviceMethod.peopleDetailApis.GetpeopleSuggestion.replace('#keywordData', data);
    return getMethod(url);
}

export const getPeoplePickerData = (data) => {

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    const url = process.env.REACT_APP_MRDRSERVICE + serviceMethod.peopleDetailApis.GetpeopleSuggestion.replace('#keywordData', data);
    // const res =  axios.get(url,
    //     { headers: { Authorization: `Bearer ${token}` }, 
    //     cancelToken: cancelToken.token })
     return getMethod(url, {cancelToken: cancelToken.token});
}

export const getReviewerData = (reviewerPayload, challengeId, subCatId) => {

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    let method = serviceMethod.idea.GetReviewer;
        method = method.replace('#ChallengeId', challengeId);
        method = method.replace('#subCategoryId', subCatId);

    const URL = process.env.REACT_APP_IDEASERVICE + method;
    return postMethod(URL, reviewerPayload, config);
}

export const getValidCoInnovator = (data) => {

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    const method = serviceMethod.idea.IsValidCoInnovator;
    const URL = process.env.REACT_APP_CHALLENGESERVICE + method;

    return postMethod(URL, data, config);
}

export const getChallengesByContestId = (pageIndex, challengeStatus) => {

    const my_app = axios.create({ baseURL: process.env.REACT_APP_CHALLENGESERVICE });
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    let method = serviceMethod.contest.getChallengeByContestID;
    method =  method.replace('#pageIndex',pageIndex);
    const URL = process.env.REACT_APP_CHALLENGESERVICE + method;
    return postMethod(URL, challengeStatus, config);
   
}

export const isValidCoInnovatorByRole = (coInnovatorRequestBody, idearole) => {

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    let method = serviceMethod.search.isValidCoinnovatorWithIdearole;
    method = method.replace('#idearole',idearole);
    const URL = process.env.REACT_APP_CHALLENGESERVICE + method;
    return postMethod(URL, coInnovatorRequestBody, config);
    
}

export const getUserDetail = (enterpriseId="karishma.b.gupta") => {
        let method = serviceMethod.peopleDetailApis.userTypeDetails;
        method = method.replace('#enterpriseId', enterpriseId)
        const URL = process.env.REACT_APP_USERSERVICE + method;
        const config = {
            headers: {
                IsBatchProcess: true
            }
        };
        return getMethod(URL, config);
}

export const hierarchyFilters =(parentOrgUnit="51546513")=>{
    let method = serviceMethod.exploreAll.hierarchyFilter;
        method = method.replace('#parentOrgUnit', parentOrgUnit)
        const URL = process.env.REACT_APP_MRDRSERVICE + method;
        return getMethod(URL);
}


export const getMarketUnitDetail = (countryKey, countryName, metroCityCode) => {
    let method = serviceMethod.dynamicFormRender.GetMarketUnitDetail;
    method = method.replace('#countryKey', countryKey);
    method = method.replace('#countryName', countryName);
    method = method.replace('#metroCityCode', metroCityCode);
    const URL = process.env.REACT_APP_MARKETSERVICE + method;
    return getMethod(URL);
}

export const getMarketWithMarketUnits = () => {
    let method = serviceMethod.dynamicFormRender.GetMarketWithMarketUnits;
    const URL = process.env.REACT_APP_MARKETSERVICE + method;
    return getMethod(URL);
}


