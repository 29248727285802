import React,{useState} from "react";
import PropTypes from "prop-types";
import { Stack, } from "@fluentui/react";
import { Text, Combobox, Option } from '@fluentui/react-components'
import "./test.scss";

const SelectInput = ({
    inputItems,
    onChangeSelect,
    content,
    size,
    placeholder,
    name,
    className,
    selectValue,
    disabled
}) => {
    //console.log("item-->",selectValue)

    const [placeholderReset, setPlaceholderReset] = useState(false);
    const onDropdownChange = (e, item) => {
        setPlaceholderReset(true);
        onChangeSelect(item);
    };
   
    return (
        <div>
            <Text size={size} style={{ display: 'block' }}>
                {content.slice(-1) === "*" ? 
                <div style={{ display: 'flex' , color: '#000'}}>
                    {content.slice(0, content.length - 1)}
                    <div style={{ color: 'red' }}>*</div>
                </div> : content }</Text>

            <Combobox
                key={name}
                id={name}
                placeholder={placeholderReset || (selectValue == undefined || selectValue == "") ? placeholder : selectValue}
                onOptionSelect={onDropdownChange}
                className={className + " dropdown-height"}
                clearable
                appearance="filled-lighter"
                disabled={disabled}
                defaultValue={selectValue!=undefined? selectValue: placeholder}
                >
                {inputItems.length > 0 && inputItems.map((item, index) => (
                    <Option key={index}>{item}</Option>
                ))}
            </Combobox>
        </div>
    );
};

SelectInput.propTypes = {
    inputItems: PropTypes.array,
    content: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    value:PropTypes.string,
    placeholder: PropTypes.string,
    onChangeSelect: PropTypes.func
};

export default SelectInput;
