import {types} from '../actions/types';

const initialState={
    filterData: {
        allFilters: [],
        ideaStatus: [],
        contestFilters: []
    },
    contestData:false,
    allIdeas: true,
    innovatorFilterData: {
        filters: {},
        contestFilters: []
    },
    selectAllFilter: {
        AllIdeaStatus: false,
        AllContestFilters: false
    }
}

const fetchFilterDataReducerPIB=(state=initialState, action)=>{
    // console.log("action.type",action.type);
    switch(action.type){
        case types.SEND_FILTER_REQUEST_PIB:
            // console.log("inside filter reducer", state);

            if ((action.payload.filter === "ideaStatus")){
                return ({
                    ...state,
                    contestData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        ideaStatus: [...state.filterData.ideaStatus, action.payload.item],
                        allFilters: [...state.filterData.allFilters, action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIdeaStatus: false
                    }
                })
            }
            else {
                // console.log("contestreducer", action.payload);
                return ({
                    ...state,
                    contestData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        contestFilters: [...state.filterData.contestFilters, action.payload.item],
                        allFilters: [...state.filterData.allFilters, action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllContestFilters: false
                    }
                })
            }

            //return ({
            //    ...state,
            //    filterData: [...state.filterData,action.payload],
            //})

        case types.REMOVE_FILTER_ITEM_PIB:
            // console.log("action.payload", action.payload);
           if (action.payload.filter === "ideaStatus"){
                return ({
                    ...state,
                    contestData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        ideaStatus: state.filterData.ideaStatus.filter(Arrayitem => Arrayitem.key !== action.payload.item.key),
                        allFilters: state.filterData.allFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key)
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIdeaStatus: false
                    }
                })
            } else {
                return ({
                    ...state,
                    contestData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        contestFilters: state.filterData.contestFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key),
                        allFilters: state.filterData.allFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key)
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllContestFilters: false
                    }
                })
            }  

        case types.SELECT_ALL_FILTER_ITEMS_PIB:
            // console.log("inside filter reducer", state);

            if ((action.payload.filter === "ideaStatus")) {
                return ({
                    ...state,
                    contestData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        ideaStatus: action.payload.item,
                        allFilters: [...state.filterData.allFilters, ...action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIdeaStatus: true
                    }
                })
            }
            else {
                // console.log("contestreducer", action.payload);
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        contestFilters: action.payload.item,
                        allFilters: [...state.filterData.allFilters, ...action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllContestFilters: true
                    }
                })
            }

        case types.UN_SELECT_ALL_FILTER_ITEMS_PIB:
            // console.log("inside filter reducer", action.payload.item);

            if ((action.payload.filter === "ideaStatus")) {
                return ({
                    ...state,
                    contestData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        ideaStatus: [],
                        allFilters: state.filterData.allFilters.filter(Arrayitem => !action.payload.item.includes(Arrayitem.key))
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIdeaStatus: false
                    }
                })
            }
            else {
                // console.log("contestreducer", action.payload);
                return ({
                    ...state,
                    contestData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        contestFilters: [],
                        allFilters: state.filterData.allFilters.filter(Arrayitem => !action.payload.item.includes(Arrayitem.key))
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllContestFilters: false
                    }
                })
            }

        case types.RESET_FILTER_ITEM_PIB:
            return ({
                ...state,
                filterData: {
                    allFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                contestData: false,
                allIdeas: false,
                selectAllFilter: {
                    AllIdeaStatus: false,
                    AllContestFilters: false
                }
            })
       
      
        case types.SEND_CONTEST_IDEA_REQUEST_PIB:
            return({
                ...state,
                filterData: {
                    allFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                contestData:!state.contestData,
                allIdeas: false,
            })

        
        case types.SEND_ALL_IDEA_REQUEST_PIB:
            return ({
                ...state,
                filterData: {
                    allFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                allIdeas: !state.allIdeas,
                contestData: false,
            })

        case types.INNOVATOR_FILTER_DATA_PIB:
            if (action.payload.filters) {
                return ({
                    ...state,
                    innovatorFilterData: {
                        filters: action.payload.filters,
                        contestFilters: state.innovatorFilterData.contestFilters
                    }
                })
            } else {
                return ({
                    ...state,
                    innovatorFilterData: {
                        filters: state.innovatorFilterData.filters,
                        contestFilters: action.payload.contestFilters
                    }
                })
            }
        default:
            return{
                ...state
            }
    }
    

}
export default fetchFilterDataReducerPIB;