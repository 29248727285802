import {types} from '../actions/types';

const initialState={
    filteredResponseData:[],
    filteredObject:{},
    defaultStartDate:"",
    defaultEndDate: "",
    defaultNodes:[],
    defaultCheckedKeys:[],
    defaultExpandedKeys:[],
    originalNodes:[],
    originalCheckedKeysData:[],
    originalExpandedKeysData:[],
    selectDeselectData: true,
    selectedLabelValueDropDownData:null,
    challengeName: "",
    challengeIdData: "",
    sortedTabData: "",
    selectedOrgUnitArray: [],
    orgUnitSelect: true,

}

const fetchAdvancedFilteredData=(state=initialState, action)=>{
    switch(action.type){
        case types.SET_ADVANCE_FILTERED_DATA:
            return {
                ...state,
                filteredResponseData:action.payload,
                filteredObject: action.advanceObject
            }
        case types.SET_DEFAULT_START_DATE:{
            return{
                ...state,
                defaultStartDate: action.startDate,

            }
        }
        case types.SET_DEFAULT_END_DATE:{
            return{
                ...state,
                defaultEndDate: action.endDate,

            }
        }
        case types.SET_DEFAULT_NODES_DATA:{
            return{
                ...state,
                defaultNodes: action.nodesData,
                defaultCheckedKeys:action.checkedKeysData,
                defaultExpandedKeys: action.expandedKeysData

            }
        }
        case types.SET_ORIGINAL_NODES_DATA:{
            return{
                ...state,
                originalNodes: action.originalNodesData,
                originalCheckedKeysData:action.originalCheckedKeysData,
                originalExpandedKeysData:action.originalExpandedKeysData
            }
        }
        case types.SET_SELECT_DESELECT_DATA:{
            return {
                ...state,
                selectDeselectData: action.selectDeselectData
            }
        }
        case types.SET_CONTESTS_VALUE_LABEL_DATA:{
            return {
                ...state,
                selectedLabelValueDropDownData: action.selectedLabelValueDropDownData
            }
        }

        case types.SET_CHALLANGE_DATA:{
            return {
                ...state,
                challengeName:action.challengeName,
                challengeIdData:action.challengeIdData
            }

        }
        case types.SET_SORTED_MENU:{
            return {
                ...state,
                sortedTabData:action.sortedMenu
            }
        }
        case types.SET_ORGUNIT_ARRAY: {
            return {
                ...state,
                selectedOrgUnitArray: action.selectedOrgUnit
            }
        } 
        case types.IS_ALL_ORG_ID_SELECTED:{
            return {
                ...state,
                orgUnitSelect: action.orgUnitSelect
            }
        }       
        default:
            return{
                ...state
            }
    }
    

}
export default fetchAdvancedFilteredData;