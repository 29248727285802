import { types } from '../actions/types';

const initialState = {
    loginNameState: '',
    userCountryNameState: '',
    profilePicState: '',
    orgUnitsState: '',
    peopleKeyState: '',
    filterDetailsState: '',
    trendingCategoriesState: [],
    industryState: [],
    technologyState: [],
    contestsState: [],
    challengesState: [],
    allIdeaFeedState: '',
    userPreferenceState: '',
    userExpertiseState: '',
    ideaCreationData:'',
    promotionalContest: [],
    tourStatus: true,
    pendingIdeaCount:0,
    pendingPatentIdeaCount: 0,
    ideaCountAsPerRole:{},
    contestIdeasData:[],
    privateIdeaData:[],
    collaborationIdeaCount: 0,
    isContractorLoggedIn: false,
    activeContestDetailsArray: [],
    v360ContestId: '',
    Fy23V360ContestId: '',
    Fy24V360ContestId: '',
    peopleDetails:'',
}

const fetchDemographyReducer = (state = initialState, action) => {
    //console.log("action",action)
    switch (action.type) {
        case types.SET_LOGIN_NAME:
            return {
                ...state,
                loginNameState: action.payload
            }
        case types.SET_COUNTRY_NAME:
            return {
                ...state,
                userCountryNameState: action.payload
            }
        case types.SET_PROFILE_PIC:
            return {
                ...state,
                profilePicState: action.payload
            }
        case types.SET_ORGUNITS_DATA:
            return {
                ...state,
                orgUnitsState: action.payload
            }
        case types.SET_IDEA_CREATION_DATA:
            return {
                ...state,
                ideaCreationData: action.payload
            }            
        case types.SET_PEOPLEKEY_DATA:
            return {
                ...state,
                peopleKeyState: action.payload
            }
        case types.SET_FILTER_DATA:
            return {
                ...state,
                filterDetailsState: action.payload
            }
        case types.SET_TRENDING_CATEGORIES_DATA:
            return {
                ...state,
                trendingCategoriesState: action.payload
            }
        case types.SET_INDUSTRY_DATA:
            return {
                ...state,
                industryState: action.payload
            }
        case types.SET_TECHNOLOGY_DATA:
            return {
                ...state,
                technologyState: action.payload
            }
        case types.SET_IDEA_FEED_DATA:
            return {
                ...state,
                allIdeaFeedState: action.payload
            }
        case types.SET_USER_EXPERTISE_DATA:
            return {
                ...state,
                userExpertiseState: action.payload   
            }
        case types.SET_USER_PREFERENCE_DATA:
            return {
                ...state,
                userPreferenceState: action.payload
            }
        case types.SET_CONTEST_CHALLENGE_DATA:
            return {
                ...state,
                contestsState: action.payload.responseContests,
                challengesState: action.payload.responseChallenges
            }
        case types.SET_PROMOTIONAL_CONTEST_DETAILS:
            return {
                ...state,
                promotionalContest: action.payload
            }
        case types.SET_PEOPLE_DETAILS:
            return {
                ...state,
                peopleDetails: action.payload
            }
        case types.USER_TOUR_DATA:
            return {
                ...state,
                tourStatus: action.payload
            }
        case types.SET_USER_PREFERENCE_COMPLETE_DATA:
            return {
                ...state,
                userPreferenceCompleteState: action.payload
            }
        case types.SET_USER_EXPERTISE_COMPLETE_DATA:
            return {
                ...state,
                userExpertiseCompleteState: action.payload
            }
        case types.SET_PRIVATE_IDEA_DETAILS:
            return {
                ...state,
                privateIdeaData: action.payload
            }
            
        case types.SET_INNOVATOR_IDEA_DATA:
            return {
                ...state,
                contestIdeasData: action.payload
            }
        case types.SET_IDEA_COUNT_AS_PER_ROLE:
            return {
                ...state,
                ideaCountAsPerRole: action.payload
            }
        case types.SET_PENDING_PATENT_IDEA_COUNT:
            return {
                ...state,
                pendingPatentIdeaCount: action.payload
            }
        case types.SET_PENDING_IDEA_COUNT:
            return {
                ...state,
                pendingIdeaCount: action.payload
            }
        case types.SET_COLLABORATION_PENDING_IDEA_COUNT:
            return {
                ...state,
                collaborationIdeaCount: action.payload
            }
        case types.SET_CONTRACTOR_LOGGED_IN:
            return {
                ...state,
                isContractorLoggedIn: action.payload
            }
        case types.SET_ACTIVE_CONTEST_DETAILS:
            return {
                ...state,
                activeContestDetailsArray: action.payload
            }
        case types.SET_V360_CONTEST_ID:
            return {
                ...state,
                v360ContestId: action.payload
            }  
        case types.SET_V360_CONTEST_ID_FY23:
        return {
            ...state,
            Fy23V360ContestId: action.payload
        }  
        case types.SET_V360_CONTEST_ID_FY24:
        return {
            ...state,
            Fy24V360ContestId: action.payload
        }            
        default:
            return state;
    }
}
export default fetchDemographyReducer;