import React, { useEffect, useState } from 'react';
import AdaptiveCard from './AdaptiveCard';
import { GenericMessageBar } from "./../../common/MessageBar";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import * as microsoftTeams from "@microsoft/teams-js";
import { getNudgeDetails } from "./../../api/nudgesApi";
import { PageLoader } from '../../common/PageLoader';
import "../../../src/Nudges.scss";
import InfiniteScrollNudges from './InfiniteScrollNudges';
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import { useTeams } from "@microsoft/teamsfx-react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from "./../appInsight/AppInsight";
import NudgesMobile from "../mobile/nudges/NudgesMobile";

const Nudges = () => {
    const token = useSelector((state: RootStateOrAny) => state.fetchTokenReducer.data);
    const enterpriseId = useSelector((state: RootStateOrAny) => state.entityReducer.entityId);
    const [nudgesData, setNudgesData] = useState([]);
    const [choice, setChoice] = useState("");
    const [msg, setMsg] = useState("");
    const [showSpinner, setSpinnerData] = useState(true);
    const [{ inTeams, theme, context }] = useTeams();
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    useEffect(() => {
        if (Object.keys(token).length > 0) {
            setSpinnerData(true);
            getNudgeDetailsData();
        }
    }, [token])

    const getNudgeDetailsData = () => {
        getNudgeDetails(enterpriseId).then(response => {
            setSpinnerData(false);
            if (response) {
                if (response['ResponseCode'] === '107') {
                    setMessageBar(response['ResponseMessage'], 'error');
                } else {
                    // console.log('Nudge details', response["value"]);
                    setNudgesData(response["value"]);
                }
            }
        });
    }

    const setMessageBar = (errorMsg: string, type: string) => {
        setMsg("There are no notifications available!")
        setChoice(type);
    }

    const resetchoice = () => {
        setChoice(undefined);
    }

    return (
        <React.Fragment>
            <FluentProvider theme={teamsLightTheme}>
                <GenericMessageBar choice={choice} msg={msg} resetMsg={resetchoice} />
                {showSpinner ? <PageLoader hidden={false} /> : ""}
                <div>
                    {
                        isMobile ?
                            <div className="mobile-nudges-wrapper">
                                <NudgesMobile nudgesData={nudgesData} />
                                <></>
                            </div>
                            :
                            <div>
                                <InfiniteScrollNudges nudges={nudgesData} />
                            </div>
                    }
                </div>
            </FluentProvider>
        </React.Fragment>
    )
}

export default (withAITracking(reactPlugin, Nudges, "Nudges page", "Nudges page"));