import React, { useEffect, useState} from 'react';
import { Stack, Text } from "@fluentui/react";
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import './Resets.scss';
var uniqid = require('uniqid');
import GetStartedMobile from '../mobile/get-started/GetStartedMobile';
import { RootStateOrAny, useSelector } from "react-redux";
import { GetDownloadPreSignedUrl } from "../../api/stagesApi";
import { useTeams } from '@microsoft/teamsfx-react';
import { PageLoader } from '../../common/PageLoader';

export const VideoFeed = ({source, title}) => {
    return (
        <Stack className="video-wrapper">
        <video key={source} width="100%" height="200" controls className='video-link'>
            <source src={source} type="video/mp4"/>
            <source src={source} type="video/ogg"/>
        </video>
        <Text className="video-header">{title}</Text>
    </Stack>
    )
}

export default function Resets() {
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);
    const videoList = [
        "Main-Intro_1280x720_4500.mp4",
        "Idea-Submission-to-GTIC_1280x576_4500.mp4",
        "Submit-Private-Idea_1280x580_4500.mp4",
        "Describing-Landing-Page_1280x668_4500.mp4",
        "Mentor-Flow-and-Actions_1280x576_4500.mp4",
        "Submit-Contest-Idea_1280x576_4500.mp4",
        "Messaging-Extension-Feature_1280x580_4500.mp4",
        "Describing-Nudges_1280x576_4500.mp4"
    ]
    const [videoLinks, setVideoLinks] = useState([]);
    const [{ theme }] = useTeams();
    const [showSpinner, setShowSpinner] = useState<Boolean>(false);


    useEffect(() => {
        const videoArray = async(videos) => {
            const resultLink = await Promise.all(videos.map(getVideoLink));
            setVideoLinks(resultLink)
        }
        videoArray(videoList)
    }, []);

    const getVideoLink = async (filename) => {
        setShowSpinner(true);
        const response = await GetDownloadPreSignedUrl(filename, null, "LIC", "VideoFile");
        if (response) {
            setShowSpinner(false);
            if (response.byteLength < 300) {
                return;
            } else {
                console.log(response, 'response')
                return response;
            }
        } else {
            setShowSpinner(false);
            return;
        }
    };

    const videoTitleMap = {
        "Main-Intro" : "Introduction to Living Innovation Channel",
        "Idea-Submission-to-GTIC": "Submit Idea to Global Tech Innovation Contest",
        "Describing-Landing-Page": "Describing Landing Page",
        "Submit-Private-Idea": "Submit Private Idea",
        "Submit-Contest-Idea": "Submit Contest Idea",
        "Mentor-Flow-and-Actions": "Mentor Flow & Actions",
        "Describing-Nudges": "Describing Nudges",
        "Messaging-Extension-Feature": "Messaging Extension Feature"
    }

    const videos = videoLinks.map(videoLink => {
        const videoKey = Object.keys(videoTitleMap).find(key => videoLink.includes(key));
        if (videoKey) {
            return (
                <VideoFeed
                    source={videoLink}
                    title={videoTitleMap[videoKey]}
                />
            );
        }
        return null;
    })

    return (
        <>
            <FluentProvider theme={teamsLightTheme}>
                {isMobile? 
                <GetStartedMobile/>
                 :
                <div>
                    { showSpinner ? <PageLoader hidden={false} /> : null }
                    <h3 className="header">Introduction of Living Innovation Channel</h3>
                    <Stack horizontalAlign='center' verticalAlign='center' className="grid">
                        {videos}
                    </Stack>
                </div>
                
                }
            </FluentProvider>
        </>
    )

   }
