import React, { PropsWithChildren } from "react";
import { appInsights } from "./../components/appInsight/AppInsight";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
export default class ErrorBoundary extends React.Component<PropsWithChildren<{}>> {
  constructor(props: PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

    static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        let jsonCompStack = JSON.stringify(errorInfo, Object.getOwnPropertyNames(errorInfo));
        let jsonError = JSON.stringify(error, Object.getOwnPropertyNames(error));
        jsonError = jsonError + " Component Stack- " + jsonCompStack;
        // You can also log the error to an error reporting service
        appInsights.trackException({ exception: new Error("LIC-Component stack "), severityLevel: SeverityLevel.Error, properties: { component: "EmpExp", method: "Client side errors", jsonError   } });
    }

  render() {
    if (this.state['hasError']) {
      // You can render any custom fallback UI
        return (
            <>
                <div className="p-2 text-center">
                    <h4>Due to some technical issues, this application is not available at the moment. It will be back soon!</h4>
                    <h4>Meanwhile please use <a href="https://myinnovation.accenture.com/">myinnovation portal</a> or reach out to <a href="mailto:myinnovation.support@accenture.com">support team</a></h4>
                    <h4>or call at the Toll-Free Number - 18002703421 (India only), 800 0825 0849 (International).</h4>
                </div>
            </>
        );
    }

    return this.props.children; 
  }
}
