export const types = {
    SEND_REQUEST: 'SEND_REQUEST',
    SEND_REQUEST_SUCCESS: 'SEND_REQUEST_SUCCESS',
    SEND_REQUEST_FAILURE: 'SEND_REQUEST_FAILURE',

    // token requests
    SEND_TOKEN_REQUEST: 'SEND_TOKEN_REQUEST',
    SEND_TOKEN_REQUEST_SUCCESS: 'SEND_TOKEN_REQUEST_SUCCESS',
    SEND_TOKEN_REQUEST_FAILURE: 'SEND_TOKEN_REQUEST_FAILURE',


    // People Detail
    SEND_PEOPLE_DETAIL_REQUEST: ' SEND_PEOPLE_DETAIL_REQUEST',
    SEND_PEOPLE_DETAIL_REQUEST_SUCCESS: ' SEND_PEOPLE_DETAIL_REQUEST_SUCCESS',
    SEND_PEOPLE_DETAIL_REQUEST_FAILURE: ' SEND_PEOPLE_DETAIL_REQUEST_FAILURE',

    // People Heirarchy 
    SEND_PEOPLE_HIERARCHY_REQUEST: 'SEND_PEOPLE_HIERARCHY_REQUEST',
    SEND_PEOPLE_HIERARCHY_REQUEST_SUCCESS: 'SEND_PEOPLE_HIERARCHY_REQUEST_SUCCESS',
    SEND_PEOPLE_HIERARCHY_REQUEST_FAILURE: 'SEND_PEOPLE_HIERARCHY_REQUEST_FAILURE',

    //Set Entity Id
    SET_ENITITY_ID: 'SET_ENITITY_ID',

    //Set IsMobile
    SET_IS_MOBILE: "SET_IS_MOBILE",

    //Filter data
    SEND_FILTER_REQUEST: "SEND_FILTER_REQUEST",
    REMOVE_FILTER_ITEM: "REMOVE_FILTER_ITEM",
    RESET_FILTER_ITEM: "RESET_FILTER_ITEM",
    SELECT_ALL_FILTER_ITEMS: "SELECT_ALL_FILTER_ITEMS",
    UN_SELECT_ALL_FILTER_ITEMS: "UN_SELECT_ALL_FILTER_ITEMS",

    //Popular ideas data
    SEND_POPULAR_IDEAS_REQUEST: "SEND_POPULAR_IDEAS_REQUEST",

    // filter private Idea

    SEND_PRIVATE_IDEA_REQUEST: "SEND_PRIVATE_IDEA_REQUEST",
    SEND_PINNED_IDEA_REQUEST: "SEND_PINNED_IDEA_REQUEST",
    SEND_CONTEST_IDEA_REQUEST: " SEND_CONTEST_IDEA_REQUEST",
    SEND_SHARED_IDEA_REQUEST: "SEND_SHARED_IDEA_REQUEST",
    SEND_ALL_IDEA_REQUEST: "SEND_ALL_IDEA_REQUEST",

    // fetch my expertise
    SEND_EXPERTISE_DATA_REQUEST: "SEND_EXPERTISE_DATA_REQUEST",
    SET_EXPERTISE_DATA_REQUEST: "SET_EXPERTISE_DATA_REQUEST",

    // fetch my preference
    SEND_PREFERENCE_DATA_REQUEST: "SEND_PREFERENCE_DATA_REQUEST",
    SET_PREFERENCE_DATA_REQUEST: "SET_PREFERENCE_DATA_REQUEST",

    //filter promotional idea

    SEND_PROMOTIONAL_IDEAS_REQUEST: "SEND_PROMOTIONAL_IDEAS_REQUEST",

    //innovator filter data
    INNOVATOR_FILTER_DATA: "INNOVATOR_FILTER_DATA",

    //new idea submit
    NEW_CONTEST_IDEA_SUBMIT: "NEW_CONTEST_IDEA_SUBMIT",
    SET_NAVIGATED_FROM_ACTIVITY: "SET_NAVIGATED_FROM_ACTIVITY",


    // fetch demography api response
    SET_ORGUNITS_DATA: "SET_ORGUNITS_DATA",
    SET_PEOPLEKEY_DATA: "SET_PEOPLEKEY_DATA",
    SET_CONTEST_CHALLENGE_DATA: "SET_CONTEST_CHALLENGE_DATA",
    SET_LOGIN_NAME: "SET_LOGIN_NAME",
    SET_COUNTRY_NAME: "SET_COUNTRY_NAME",
    SET_SERIAL_INNOVATOR_DATA: "SET_SERIAL_INNOVATOR_DATA",
    SET_SERIALINNO_DEMOGRAPHY_TYPE: "SET_SERIALINNO_DEMOGRAPHY_TYPE",
    SET_PROFILE_PIC: "SET_PROFILE_PIC",
    SET_FILTER_DATA: "SET_FILTER_DATA",
    SET_TRENDING_CATEGORIES_DATA: "SET_TRENDING_CATEGORIES_DATA",
    SET_TECHNOLOGY_DATA: "SET_TECHNOLOGY_DATA",
    SET_INDUSTRY_DATA: "SET_INDUSTRY_DATA",
    SET_IDEA_FEED_DATA: "SET_IDEA_FEED_DATA",
    SET_USER_EXPERTISE_DATA: "SET_USER_EXPERTISE_DATA",
    SET_USER_PREFERENCE_DATA: "SET_USER_PREFERENCE_DATA",
    SET_IDEA_CREATION_DATA: "SET_IDEA_CREATION_DATA",
    USER_TOUR_DATA: "USER_TOUR_DATA",
    SET_USER_PREFERENCE_COMPLETE_DATA: "SET_USER_PREFERENCE_COMPLETE_DATA",
    SET_USER_EXPERTISE_COMPLETE_DATA: "SET_USER_EXPERTISE_COMPLETE_DATA",
    //fetch last gettilldate
    LAST_GETIDEATILLDATES: "LAST_GETIDEATILLDATES",
    SET_PROMOTIONAL_MEDIAFILES_REQUEST: "SET_PROMOTIONAL_MEDIAFILES_REQUEST",

    SET_EXPLORE_INDUSTRY_FILTERS: "SET_EXPLORE_INDUSTRY_FILTERS",
    SET_EXPLORE_TECHNOLOGY_FILTERS: "SET_EXPLORE_TECHNOLOGY_FILTERS",
    RESET_EXPLORE_FILTERS: "RESET_EXPLORE_FILTERS",
    EXPLORE_IDEA_REFRESH: "EXPLORE_IDEA_REFRESH",
    CATEGORIES_OPTIONS: "CATEGORIES_OPTIONS",
    MARKETUNIT_OPTIONS: "MARKETUNIT_OPTIONS",
    MARKET_OPTIONS: "MARKET_OPTIONS",
    SET_ACTIVE_CONTEST_DETAILS: "SET_ACTIVE_CONTEST_DETAILS",
    SET_V360_CONTEST_ID: 'SET_V360_CONTEST_ID',
    SET_V360_CONTEST_ID_FY23: 'SET_V360_CONTEST_ID_FY23',
    SET_V360_CONTEST_ID_FY24: 'SET_V360_CONTEST_ID_FY24',


    // fetch active tab
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_ACTIVE_TAB_PARENT: "SET_ACTIVE_TAB_PARENT",

    // fetch active tab cat mentor flow
    SET_ACTIVE_TAB_CAT: "SET_ACTIVE_TAB_CAT",
    SET_ACTIVE_TAB_CAT_PARENT: "SET_ACTIVE_TAB_CAT_PARENT",

    SET_PROMOTIONAL_CONTEST_DETAILS: "SET_PROMOTIONAL_CONTEST_DETAILS",

    //fetch all the people details
    SET_PEOPLE_DETAILS: "SET_PEOPLE_DETAILS",

    SET_SERIAL_INNOVATOR_LOCATION_TOGGLE: "SET_SERIAL_INNOVATOR_LOCATION_FILTER",
    SET_SERIAL_INNOVATOR_IDEA_FILTER: "SET_SERIAL_INNOVATOR_IDEA_FILTER",


    // Advance filter data 
    SET_ADVANCE_FILTERED_DATA: 'SET_ADVANCE_FILTERED_DATA',
    SET_DEFAULT_START_DATE: 'SET_DEFAULT_START_DATE',
    SET_DEFAULT_END_DATE: 'SET_DEFAULT_END_DATE',
    SET_DEFAULT_NODES_DATA: 'SET_DEFAULT_NODES_DATA',
    SET_ORIGINAL_NODES_DATA: 'SET_ORIGINAL_NODES_DATA',
    SET_SELECT_DESELECT_DATA: 'SET_SELECT_DESELECT_DATA',
    SET_ACTIVE_CONTESTS: 'SET_ACTIVE_CONTESTS',
    SET_CONTESTS_VALUE_LABEL_DATA: 'SET_CONTESTS_VALUE_LABEL_DATA',
    SET_CHALLANGE_DATA: 'SET_CHALLANGE_DATA',
    SET_SORTED_MENU: 'SET_SORTED_MENU',
    SET_ORGUNIT_ARRAY: 'SET_ORGUNIT_ARRAY',
    IS_ALL_ORG_ID_SELECTED: 'IS_ALL_ORG_ID_SELECTED',
    //private idea
    SET_PRIVATE_IDEA_DETAILS: 'SET_PRIVATE_IDEA_DETAILS',
    SET_INNOVATOR_IDEA_DATA: 'SET_INNOVATOR_IDEA_DATA',

    //idea count
    SET_PENDING_PATENT_IDEA_COUNT:'SET_PENDING_PATENT_IDEA_COUNT',
    SET_PENDING_IDEA_COUNT:'SET_PENDING_IDEA_COUNT',
    SET_IDEA_COUNT_AS_PER_ROLE: 'SET_IDEA_COUNT_AS_PER_ROLE',
    SET_COLLABORATION_PENDING_IDEA_COUNT: 'SET_COLLABORATION_PENDING_IDEA_COUNT',

    //Filter data PIB
    SEND_FILTER_REQUEST_PIB: "SEND_FILTER_REQUEST_PIB",
    REMOVE_FILTER_ITEM_PIB: "REMOVE_FILTER_ITEM_PIB",
    RESET_FILTER_ITEM_PIB: "RESET_FILTER_ITEM_PIB",
    SELECT_ALL_FILTER_ITEMS_PIB: "SELECT_ALL_FILTER_ITEMS_PIB",
    UN_SELECT_ALL_FILTER_ITEMS_PIB: "UN_SELECT_ALL_FILTER_ITEMS_PIB",
    SEND_CONTEST_IDEA_REQUEST_PIB: " SEND_CONTEST_IDEA_REQUEST_PIB",
    SEND_ALL_IDEA_REQUEST_PIB: "SEND_ALL_IDEA_REQUEST_PIB",
    //innovator filter data PIB
    INNOVATOR_FILTER_DATA_PIB: "INNOVATOR_FILTER_DATA_PIB",

    // back to PIB from other page
    SET_NAVIGATION_FROM_PIB: 'SET_NAVIGATION_FROM_PIB',

    // graph api token
    SET_GRAPH_API_AUTH_TOKEN_REQUEST: 'SET_GRAPH_API_AUTH_TOKEN_REQUEST',

    //CONTRACTOR LOGED IN
    SET_CONTRACTOR_LOGGED_IN: 'SET_CONTRACTOR_LOGGED_IN',

    //IDEAVOTESEARCHINPUT
    IDEA_SEARCH_INPUT: 'IDEA_SEARCH_INPUT',

}