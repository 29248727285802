import React from 'react';
import { Avatar } from '@fluentui/react-components';
import moment from 'moment';

const IdeaComment = ({ comment, enterprise }) => {

    return (
        <>
            <div className="float-left width-100 margin-bottom-10 comment-wrapper">                
                <div className="comment-avatar">
                    <Avatar name={comment.CommentBy.split(".").join(" ").toUpperCase()} size={28} />
                </div>
                <div className="comment-details">
                    <div>
                        <span className="comment-creator">{comment.CommentBy}</span>
                        <span className="comment-date">{moment(comment.CommentDt).format("Do MMM,YYYY, h:mm a")}</span>
                    </div>
                    <div>
                        <span className="comment-text">{comment.Comments}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IdeaComment;
