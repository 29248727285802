import React, { useEffect, useState, useRef  } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import ReactModal from "react-modal";
import { GenericMessageBar } from '../../../../common/MessageBar';
import { PageLoader } from "../../../../common/PageLoader";
var uniqid = require('uniqid');
import { FontIcon } from "@fluentui/react/lib/Icon";

const MyExpertiseMobile = ({ showModal, showLoader, showMsg, choice, resetchoice, handleCloseModal, clickedInd, clickedIndSelectAll, industryOptionsArray, industrySelectHandler, industryRemoveHandler, userIndustryData, indBox, clickedTech, clickedTechSelectAll, techBox, technologyOptionsArray, technologySelectHandler, technologyRemoveHandler, userTechnologyData, saveHandler, handleOpenModal, userExpertiseData, classNames}) => {
    
    const [itemsToShowIndustry, setItemsToShowIndustry] = useState(2);
    const [expandedIndustry, setIndustryExpanded] = useState(false);

    const [itemsToShowTechnology, setItemsToShowTechnology] = useState(2);
    const [expandedTechnology, setTechnologyExpanded] = useState(false);

    const showMoreIndustry = () => {
        itemsToShowIndustry === 2 ? (
            setItemsToShowIndustry(userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.length ),
            setIndustryExpanded(true)
        ) : (
                setItemsToShowIndustry(2),
                setIndustryExpanded(false)
            )
    }

    const showMoreTechnology = () => {
        itemsToShowTechnology === 2 ? (
            setItemsToShowTechnology(userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.length ),
            setTechnologyExpanded(true)
        ) : (
            setItemsToShowTechnology(2),
            setTechnologyExpanded(false)
            )
    }
    
    
    return (
        <div className="left-side">
            <>
                <ReactModal isOpen={showModal} className={"ReactModal__Content_Mobilepopup"}>
                    {showLoader ? <PageLoader hidden={false} /> : <PageLoader hidden={true} />}
                    {showMsg ? <div className="message-bar">
                        <GenericMessageBar choice={choice} msg={"Record added successfully"} resetMsg={resetchoice} />
                    </div> : ""}

                    <div className="preference-modal-popup">
                        <div className="pop-my-preference">My Expertise</div>
                        <div className="cross-button" onClick={handleCloseModal}>
                            X
                        </div>
                    </div>

                    <div className="pop-up-select-industries">Select Industry</div>
                    <div className={`${clickedInd ? "multiSelectIndustryClicked" : "multiSelectIndustry"} ${clickedIndSelectAll ? "multiSelectIndustrySelectAllClicked" : ""}`} ref={indBox} >
                        <Multiselect
                            showArrow
                            options={[{ CategoryName: "Select All" }, ...industryOptionsArray]}
                            displayValue="CategoryName"
                            showCheckbox={true}
                            onSelect={industrySelectHandler}
                            onRemove={industryRemoveHandler}
                            selectedValues={userIndustryData.length === industryOptionsArray.length ? [{ CategoryName: "Select All" }, ...userIndustryData] : userIndustryData}
                            closeOnSelect={false}
                            style={{
                                option: { display: 'flex', alignItems: 'center' },
                                searchBox: { maxHeight: '4.396vw', overflowY: 'auto' }
                            }}
                        />
                    </div>
                    <div className="pop-up-select-technology">Select Technology</div>
                    <div className={` ${clickedTech ? "multiSelectTechnologyClicked" : "multiSelectTechnology"} ${clickedTechSelectAll ? "multiSelectTechnologySelectAllClicked" : ""}`} ref={techBox}>
                        <Multiselect
                            showArrow
                            options={[{ CategoryId: "Technology", CategoryName: "Select All" }, ...technologyOptionsArray]}
                            displayValue="CategoryName"
                            showCheckbox={true}
                            onSelect={technologySelectHandler}
                            onRemove={technologyRemoveHandler}
                            selectedValues={userTechnologyData.length === technologyOptionsArray.length ? [{ CategoryId: "Technology", CategoryName: "Select All" }, ...userTechnologyData] : userTechnologyData}
                            closeOnSelect={false}
                            style={{
                                option: { display: 'flex', alignItems: 'center' },
                                searchBox: { maxHeight: '4.396vw', overflowY: 'auto' }
                            }}
                        />
                    </div>
                    <div className="save-cancel-button">
                        <button className="cancel-button" onClick={handleCloseModal}>
                            cancel
                        </button>
                        <button className="save-button" onClick={saveHandler}>save</button>
                    </div>
                </ReactModal>
                <div className="header-div">
                    <p className="patent-header">My Expertise</p>
                </div>
                <div className="edit-icon" onClick={handleOpenModal}>
                    <span className="icon Icon--EditNote">
                        <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                            <g fill="none"><path d="M13.245 2.817a2.783 2.783 0 0 1 4.066 3.796l-.13.14l-9.606 9.606a2.001 2.001 0 0 1-.723.462l-.165.053l-4.055 1.106a.5.5 0 0 1-.63-.535l.016-.08l1.106-4.054c.076-.28.212-.54.398-.76l.117-.128l9.606-9.606zm-.86 2.275L4.346 13.13a1 1 0 0 0-.215.321l-.042.123l-.877 3.21l3.212-.875a1 1 0 0 0 .239-.1l.107-.072l.098-.085l8.038-8.04l-2.521-2.52zm4.089-1.568a1.783 1.783 0 0 0-2.402-.11l-.12.11l-.86.86l2.52 2.522l.862-.86a1.783 1.783 0 0 0 .11-2.402l-.11-.12z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                        </svg>
                    </span>
                </div>
                <div className="contents">
                    {
                        userExpertiseData && ((userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry") &&
                            userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue &&
                            userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue.length > 0) ||
                            (userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology") &&
                                userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue &&
                                userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue.length > 0))
                            ?
                            <>
                                {
                                    (userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry") &&
                                        userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue &&
                                        userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue.length > 0) ?
                                        <>
                                            <div className="content-header">
                                                <FontIcon iconName="CityNext2" className={classNames.deepSkyBlue} />
                                                <span>{userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseKey}</span>
                                            </div>
                                            <div className="content" key={uniqid()}>
                                                <div className="preference-tag">
                                                    {
                                                        userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue
                                                            .filter((tag, i) => i <= 1).map((tag) => {
                                                                return <div className="skill-tags fs-13" key={uniqid()}>{tag.CategoryName}</div>;
                                                            })
                                                    }
                                                    {/* {userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.length > 2 ?
                                                        <Tooltip
                                                            content={userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.slice(
                                                                2,
                                                                userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.length
                                                            ).map((tag) => (
                                                                <div className="skill-tags fs-13" key={uniqid()}>
                                                                    {tag.CategoryName}
                                                                </div>
                                                            ))}
                                                            position="below"
                                                            trigger={
                                                                <span className="more">
                                                                    {`+${userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.length - 2} more`}
                                                                </span>
                                                            }
                                                        />
                                                        : null} */}
                                    {/* changes here */}

                                           {userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.length > 2 ? (
                                            <a onClick={showMoreIndustry} className="show-more-link-mobile">
                                                {!expandedIndustry ? <span>+{userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.length - 2} more</span> : null}
                                            </a>
                                            ) : null}

                                            {expandedIndustry
                                            ? userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.slice(
                                                                        2,
                                                                        userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "industry")?.ExpertiseValue?.length
                                                                    ).map((tag) => (
                                                                        <div className="skill-tags fs-13" key={uniqid()}>
                                                                            {tag.CategoryName}
                                                                        </div>
                                                                    ))
                                            : null}

                                            <a onClick={showMoreIndustry} className="show-more-link-mobile show-less-link-mobile">
                                            {expandedIndustry ? <span>Show less</span> : null}
                                            </a>

                             {/* changes end */}
                                                        
                                                </div>
                                            </div>
                                        </> : ""
                                }
                                {
                                    (userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology") &&
                                        userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue &&
                                        userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue.length > 0) ?
                                        <>
                                            <div className="content-header">
                                                <span className="icon Icon--Settings">
                                                    <svg width="26.624" height="26.624" viewBox="0 0 26.624 26.624">
                                                        <path id="Path_44875" data-name="Path 44875" d="M19.638,2a1,1,0,0,1,.989.863L20.637,3v3.1a5,5,0,0,1,3.893,3.894h3.1a1,1,0,0,1,.136,1.988l-.136.009h-3v2.33h3a1,1,0,0,1,.989.863l.009.136a1,1,0,0,1-.863.989l-.136.009h-3v2.33h3a1,1,0,0,1,.989.863l.009.136a1,1,0,0,1-.863.989l-.136.009h-3.1a5,5,0,0,1-3.893,3.893v3.1a1,1,0,0,1-1.988.136l-.009-.136v-3H16.309v3a1,1,0,0,1-.863.989l-.136.009a1,1,0,0,1-.989-.863l-.009-.136v-3H11.984v3a1,1,0,0,1-.863.989l-.135.009A1,1,0,0,1,10,27.761l-.009-.136V24.531a5,5,0,0,1-3.894-3.894H3a1,1,0,0,1-.135-1.988L3,18.64h3v-2.33H3a1,1,0,0,1-.989-.863L2,15.312a1,1,0,0,1,.863-.989L3,14.314h3v-2.33H3a1,1,0,0,1-.989-.863L2,10.986A1,1,0,0,1,2.863,10L3,9.987h3.1A5,5,0,0,1,9.988,6.093V3a1,1,0,0,1,1.988-.135L11.984,3V5.992h2.328V3a1,1,0,0,1,.863-.989L15.312,2a1,1,0,0,1,.989.863L16.31,3V5.992H18.64V3a1,1,0,0,1,.733-.963l.13-.027Zm0,5.99H10.986a3,3,0,0,0-3,3v8.653a3,3,0,0,0,3,3h8.653a3,3,0,0,0,3-3V10.986A3,3,0,0,0,19.638,7.99Zm-4.32,3.335a3.994,3.994,0,1,1-3.994,3.994A3.994,3.994,0,0,1,15.319,11.325Zm0,2a2,2,0,1,0,2,2A2,2,0,0,0,15.319,13.322Z" transform="translate(-2 -2)" fill="#a341ed" />
                                                    </svg>
                                                </span>
                                                <span style={{ paddingLeft: '0.26vw' }}>
                                                    {userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseKey}
                                                </span>
                                            </div>
                                            <div className="content" key={uniqid()}>
                                                <div className="preference-tag">
                                                    {
                                                        userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue
                                                            .filter((tag, i) => i <= 1).map((tag) => {
                                                                return <div className="skill-tags fs-13" key={uniqid()}>{tag.CategoryName}</div>;
                                                            })
                                                    }
                                                    {/* {userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue?.length > 2 ?
                                                        <Tooltip
                                                            content={userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue?.slice(
                                                                2,
                                                                userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue?.length
                                                            ).map((tag) => (
                                                                <div className="skill-tags fs-13" key={uniqid()}>
                                                                    {tag.CategoryName}
                                                                </div>
                                                            ))}
                                                            position="below"
                                                            trigger={
                                                                <span className="more">
                                                                    {`+${userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue?.length - 2} more`}
                                                                </span>
                                                            }
                                                        />
                                                        : null} */}


                                         {/* changes here */}

                                           {userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue?.length > 2 ? (
                                            <a onClick={showMoreTechnology} className="show-more-link-mobile">
                                                {!expandedTechnology ? <span>+{userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue?.length - 2} more</span> : null}
                                            </a>
                                            ) : null}

                                            {expandedTechnology
                                            ? userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue?.slice(
                                                2,
                                                userExpertiseData.find(x => x.ExpertiseKey.toLowerCase() === "technology")?.ExpertiseValue?.length
                                            ).map((tag) => (
                                                <div className="skill-tags fs-13" key={uniqid()}>
                                                    {tag.CategoryName}
                                                </div>
                                            ))
                                            : null}

                                            <a onClick={showMoreTechnology} className="show-more-link-mobile show-less-link-mobile">
                                            {expandedTechnology ? <span>Show less</span> : null}
                                            </a>

                             {/* changes end */}
                                                </div>
                                            </div>
                                        </> : ""}
                            </>
                            : <div className="skill-tags-nodata fs-13">No data available</div>}
                </div>
            </>
        </div>
    );
}

export default MyExpertiseMobile;