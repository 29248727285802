// @ts-nocheck
import { combineReducers } from 'redux';
import fetchDataReducer from './fetch-data-reducer';
import { createBrowserHistory } from "history";
import { BrowserRouter } from 'react-router-dom';
import fetchTokenReducer from './fetch-token-reducer';
import fetchFilterDataReducer from './fetch-filter-data-reducer';
import fetchFilterDataReducerPIB from './fetch-filterPIB-reducer';
import fetchExploreIdeasFiltersReducer from './fetch-explore-idea-filters-reducer';
import fetchActiveTabReducer from './fetch-active-tab-reducer';
import expertiseReducer from './fetch-expertise-reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import entityReducer from './enitity-reducer';
import fetchDemographyReducer from './fetch-demography-reducer';
import fetchActiveTabCatReducer from './fetch-active-tab-cat-mentor-reducer';
import fetchAdvancedFilteredData from './fetch-advanced-filtered-data-reducer';
import msGraphApiReducer from './msGraph-reducer';
import fetchActiveContests from './fetch-active-contest-reducer';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['fetchDemographyReducer'] 
}

const RootReducer = combineReducers({
    fetchDataReducer,
    fetchTokenReducer,
    entityReducer,
    fetchFilterDataReducer,
    fetchFilterDataReducerPIB,
    expertiseReducer,
    fetchDemographyReducer,
    fetchExploreIdeasFiltersReducer,
    fetchActiveTabReducer,
    fetchActiveTabCatReducer,
    fetchAdvancedFilteredData,
    msGraphApiReducer,
    fetchActiveContests,
    // router: BrowserRouter(history),
})

export default persistReducer(persistConfig,RootReducer);
