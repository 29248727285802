import React, { useEffect, useState } from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { hiddenContentStyle, mergeStyles } from "@fluentui/react/lib/Styling";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { ContextualMenu } from "@fluentui/react/lib/ContextualMenu";
import { useHistory } from "react-router-dom";
import { useId, useBoolean } from "@fluentui/react-hooks";
import { useDispatch, useSelector, RootStateOrAny, shallowEqual } from "react-redux";
import Select from 'react-select';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownStyles,
  IDropdownOption,
} from "@fluentui/react/lib/Dropdown";
import { addPinUnpinDetailsPromotionalFeed } from "../../api/IdeaApi";

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};
const screenReaderOnly = mergeStyles(hiddenContentStyle);
const dialogContentProps = {
  type: DialogType.normal,
  title: "Promote this idea to a contest",
  closeButtonAriaLabel: "Close",
  subText: "Submit this idea to an ongoing contest",
};
const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
let contestOptions = [];

export const PromoteToIdea = ({ ideaDetails, closeModal }) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");
  const { contestsState, challengesState } = useSelector((state: RootStateOrAny) => (state.fetchDemographyReducer), shallowEqual);
  const [selectedContest, setSelectedContest] = useState({});

    const history = useHistory();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [error, setError] = useState(false);
    const [contestList, setContestList] = useState<any>(
      contestsState?.length ? contestsState : {}
  );

  const [contestListOptions, setContestListOptions] = useState([]);
  let GLCContestID= process.env.REACT_APP_GLC2022_CONTEST_ID;

    useEffect(() => {
        function updateSize() {
            if (navigator.userAgent.match(/iPhone/i) !== null) {
                setIsMobile(true);
            } else if (
                navigator.userAgent.match(/Android/i) !== null &&
                (parseInt(window.innerWidth.toString()) < 768 ||
                    parseInt(window.innerHeight.toString()) < 768)
            ) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable, labelId, subTextId]
  );

  useEffect(() => {
    contestOptions = [];
    contestList.forEach((contest) => {
      if (
        contest.ContestType &&
        contest.ContestType.toLowerCase() === "public" &&
        contest.ContestNm.trim() !== "" &&
        contest["ChallengeCount"] > 0 &&
        contest["ContestIsActive"] &&
        contest.IsIdeaSubmissionClosed === false &&
       (( contest.ContestId == (GLCContestID ? GLCContestID: "")) || contest.IsDynamicContest == false))
       {
        contestOptions.push({
          value: contest.ContestId,
          label: contest.ContestNm,
        });
      }
    });
    contestOptions = contestOptions.sort((a, b) => -b.label?.toUpperCase().localeCompare(a.label?.toUpperCase()));
    setContestListOptions(contestOptions);

  }, [contestList]);

  const onChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: any
  ): void => {
    //console.log(item);
    //console.log(`idea details ${ideaDetails}`);
    setSelectedContest(event);
    setError(false);
  };

  const onProceedClick = () => {
    //console.log(selectedContest);
    //console.log(`idea details ${ideaDetails}`);
    if(Object.keys(selectedContest).length > 0) {
      let contest = contestsState.find(
        (contest) => contest.ContestId === selectedContest["value"]
        );
        if (contest && contest.IsDynamicContest) {
            if (contest.ContestNm.toLowerCase().includes("v360")) {
                history.push({
                    pathname: `/v360-idea-submission-form/${contest.ContestId}`,
                    state: { ideaDetails: ideaDetails  }
                });
            }
            else {
                history.push({
                    pathname: `/dynamic-form/${contest.ContestId}`,
                    state: { contestNm: contest.ContestNm, contestId: contest.ContestId, contestDetails: contest, ideaDetails: ideaDetails }
                });
            }
        }
        else {
            history.push({
                pathname: `/submit-idea/${selectedContest["value"]}`,
                state: {
                    contestDetails: {
                        ...contest,
                    },
                    ideaDetails: ideaDetails,
                },
            });
        }      
      closeModal();
      toggleHideDialog();
    } else {
      setError(true);
    }
    
  };


  const onCancelClick = () => {
    toggleHideDialog();
    closeModal();
  };

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
  });

  const boxShadow = () => ({
    boxShadow: error ? '#FF0000' : '#9713fa',
      ":hover": {
        borderColor: error ? '#FF0000' : '#9713fa',
        borderWidth: 1
      },
      ":focus": {
        borderColor: error ? '#FF0000' : '#9713fa',
        borderWidth: 1
      },
      ":active": {
        borderColor: error ? '#FF0000' : '#9713fa',
        borderWidth: 1
      },
      ":focus-within": {
        borderColor: error ? '#FF0000' : '#9713fa',
        borderWidth: 1
      }
  })

  let customStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      fontSize: '10px',
      height: '30px',
      minHeight: '30px',
      verticalAlign: 'center',
      borderColor: error ? '#FF0000' : '#808080',
      ...boxShadow()
      //paddingBottom: 9
    }),
    menu: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.selectProps.menuColor,
      maxHeight: 130
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: 120
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderBottom: '1px',
        fontSize: '10px',
        ":hover": {
          background: '#efe4f7'
        },
        backgroundColor: isFocused  ? '#efe4f7' : isSelected ? '#9713fa' : '#fff',
        color: '#000',
      };
    },
    singleValue: (provided, state) => {
      return { ...provided, paddingBottom: 5, ...boxShadow() };
    },
    placeholder: styles => ({ ...styles, ...dot(), paddingBottom: 6, ...boxShadow() }),
    input: styles => ({ ...styles, ...dot(), paddingBottom: 8,
      ...boxShadow()
     }),
    indicatorsContainer: styles => ({ ...styles, ...dot(), height: 30 }),
    container: styles => ({
      ...styles,
     ...boxShadow()
    })
  }

  return (
    <>
      <Dialog
        hidden={hideDialog}
        onDismiss={onCancelClick}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        className={isMobile ? "dialogBox-promoteToIdea-mobile" : "dialogBox-promoteToIdea"}
      >
        {/* <Dropdown
          placeholder="Select contest"
          // eslint-disable-next-line react/jsx-no-bind
          className="filter-criteria-dropdown"
          onChange={onChange}
          options={contestOptions}
          styles={dropdownStyles}
        /> */}

        <Select
          onChange={onChange}
          options={contestListOptions}
          isSearchable={true}
          placeholder="Select Contest"
          className="filter-criteria-dropdown"
          styles={customStyles}
        />
        <DialogFooter>
          <DefaultButton onClick={onCancelClick} text="Cancel" />
          <PrimaryButton onClick={onProceedClick} text="Proceed" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
