import React, { Component,useState,useEffect, createRef } from 'react'
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { fetchFilterData, removeFilterItem, selectAllFilterItems, unSelectAllFilterItems } from '../../../redux-sharedframework/actions/fetch-filter-action';
import { Multiselect } from "multiselect-react-dropdown";
const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 180, fontSize: 10 }, callout: { width: 180, height: 100, color: '#484644' }, dropdownOptionText: { fontSize: 10, color: '#484644' } };

interface IRecipeProps {
    filterDetails?: any;
    classname?: string;
    showFilter?: any;
}
  
const stackTokens: IStackTokens = { childrenGap: 20 };

export const FilterCriteria: React.FunctionComponent<IRecipeProps> = ( props ) => {

    
    const { filterDetails, classname, showFilter } = props;
    
    //const dropdownRef = createRef<IDropdown>();
    //const onSetFocus = () => dropdownRef.current!.focus(true);
    const dropdownRef = createRef<any>();
    const onSetFocus = () => dropdownRef.current.focus();
    const filterDataStore = useSelector((state: RootStateOrAny) => state.fetchFilterDataReducer.filterData);
    const selectAllClicked = useSelector((state: RootStateOrAny) => state.fetchFilterDataReducer.selectAllFilter);
    const [filterData, setFilterData] = useState<any>([]);
    const [selectedFilter, setSelectedFilter] = useState<any>([]);
    const [dropdownOptions, setDropdownOptions] = useState<any>([]);
    const dispatch = useDispatch();
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

    //console.log("filterDatanew", selectAllClicked);

    useEffect(() => {
        if (showFilter.industry) {
            setFilterData(filterDetails.industryFilters);
            setSelectedFilter("industry");
        }
        else if (showFilter.category) {
            setFilterData(filterDetails.categoryFilters);
            setSelectedFilter("category");
        }
        else if (showFilter.technology) {
            setFilterData(filterDetails.technologyFilters);
            setSelectedFilter("technology");
        }
        else if (showFilter.ideaStatus) {
            setFilterData(filterDetails.ideaStatus);
            setSelectedFilter("ideaStatus");
        }
        else if (showFilter.contest) {
            setFilterData(filterDetails.contestFilters);
            setSelectedFilter("contest");
        }
        else {
            setFilterData([]);
            setSelectedFilter([]);
        }
        
    }, [showFilter]);

    useEffect(() => {
        //console.log("logged", filterDataStore, showFilter);
        if (showFilter.industry) {
            setSelectedKeys(filterDataStore.industryFilters)
        }
        else if (showFilter.category) {
            setSelectedKeys(filterDataStore.categoryFilters)
        }
        else if (showFilter.technology) {
            setSelectedKeys(filterDataStore.technologyFilters)
        }
        else if (showFilter.ideaStatus) {
            setSelectedKeys(filterDataStore.ideaStatus)
        }
        else if (showFilter.contest) {
            setSelectedKeys(filterDataStore.contestFilters)
        }
        else {
            setSelectedKeys([]);
            setDropdownOptions([]);
        }
    }, [showFilter, filterDataStore])

    useEffect(() => {
        
        if (filterData.length > 0) {
            if (selectedFilter === "contest") {
                setDropdownOptions(filterData.map(filter => ({ 'key': filter.ContestId, 'text': filter.ContestNm })));
            }
            else if (selectedFilter === "technology") {
                setDropdownOptions(filterData.map(filter => ({ 'key': filter.CategoryId, 'text': filter.CategoryName })));
            }
            else { setDropdownOptions(filterData.map(filter => ({ 'key': filter, 'text': filter }))); }

            //setDropdownOptions(filterData.map(filter => ({ 'key': filter, 'text': filter })));
        }
        

    }, [filterData]);

    //const DropdownControlledMultiExampleOptions: IDropdownOption[] = filterData && filterData.map(filter => ({ 'key': filter, 'text': filter }));
    //console.log("dropdownOptions", selectedKeys);
    //    [
    //    { key: 'Energy', text: 'Energy' },
    //    { key: 'Health', text: 'Health' },
    //    { key: 'Chemicals', text: 'Chemicals' },
    //    { key: 'High Tech', text: 'High Tech' }
    //];

    

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        if (item) {
            //setSelectedKeys(
            //    item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter(key => key !== item.key),
            //);
            //console.log("filterDatanew", item);
            if (item.key === "Select All") {
                item.selected ? dispatch(selectAllFilterItems({ item : dropdownOptions.map(option => option.key as string), filter: selectedFilter })) : dispatch(unSelectAllFilterItems(selectedFilter))
            }
            else if (selectedFilter === "contest" || selectedFilter === "technology") {
                //console.log("item:::", item);
                item.selected ? dispatch(fetchFilterData({ item: item.key as string, filter: selectedFilter })) : dispatch(removeFilterItem({ item: item.key as string, filter: selectedFilter }))
            }
            else {
                item.selected ? dispatch(fetchFilterData({ item: item.key as string, filter: selectedFilter })) : dispatch(removeFilterItem({ item: item.key as string, filter: selectedFilter }))
            }


            //item.selected ? dispatch(fetchFilterData({ item: item.key as string, filter: selectedFilter })) : dispatch(removeFilterItem({ item: item.key as string, filter: selectedFilter }))
    }    
    };
    //useEffect(() => {
    //  dispatch(fetchFilterData(selectedKeys));
    //}, [selectedKeys])

    const optionSelectHandler = (selectedList, selectedItem) => {
        if (selectedItem) {
            if (selectedItem.text === "Select All") {
                dispatch(selectAllFilterItems({ item: dropdownOptions, filter: selectedFilter }))
            }
            else if ((selectedFilter === "contest" || selectedFilter === "technology") && selectedItem.key !== "Select All") {
                
                dispatch(fetchFilterData({ item: { 'key': selectedItem.key, 'text': selectedItem.text }, filter: selectedFilter }))
            }
            else {
                dispatch(fetchFilterData({ item: { 'key': selectedItem.key, 'text': selectedItem.text }, filter: selectedFilter })) 
            }
        }  

    }
    const optionRemoveHandler = (selectedList, selectedItem) => {
        if (selectedItem) {
            if (selectedItem.text === "Select All") {
                dispatch(unSelectAllFilterItems({ item: dropdownOptions.map(option => option.key as string), filter: selectedFilter }))
            }
            else if (selectedFilter === "contest" || selectedFilter === "technology") {
                dispatch(removeFilterItem({ item: { 'key': selectedItem.key, 'text': selectedItem.text }, filter: selectedFilter }))
            }
            else {
                dispatch(removeFilterItem({ item: { 'key': selectedItem.key, 'text': selectedItem.text }, filter: selectedFilter }))
            }
        }
    }


    return (
        //<Dropdown
        //    componentRef={dropdownRef}
        //    placeholder="Select options"
        //    selectedKeys={selectedKeys}
        //    // eslint-disable-next-line react/jsx-no-bind
        //    className={"filter-criteria-dropdown"}
        //    onChange={onChange}
        //    multiSelect
        //    options={dropdownOptions}
        //    styles={dropdownStyles}
        ///>
        <>
        {
            dropdownOptions && dropdownOptions.length > 0 ? 
                <Multiselect
                    showArrow
                    ref={dropdownRef}
                    options={selectedFilter === "industry" ? [{ 'key': 'AllIndustry', 'text': 'Select All' }, ...dropdownOptions]
                            : selectedFilter === "technology" ? [{ 'key': 'AllTechnology', 'text': 'Select All' }, ...dropdownOptions] 
                            : selectedFilter === "ideaStatus" ? [{ 'key': 'AllIdeaStatus', 'text': 'Select All' }, ...dropdownOptions]
                            : [{ 'key': 'AllContest', 'text': 'Select All' }, ...dropdownOptions] }
                    displayValue="text"
                    showCheckbox={true}
                    onSelect={optionSelectHandler}
                    onRemove={optionRemoveHandler}
                    selectedValues={selectedFilter === "industry" && selectAllClicked.AllIndustryFilters ? [{ 'key': 'AllIndustry', 'text': 'Select All' }, ...selectedKeys]
                        : selectedFilter === "technology" && selectAllClicked.AllTechnologyFilters ? [{ 'key': 'AllTechnology', 'text': 'Select All' }, ...selectedKeys]
                        : selectedFilter === "ideaStatus" && selectAllClicked.AllIdeaStatus ? [{ 'key': 'AllIdeaStatus', 'text': 'Select All' }, ...selectedKeys]
                        : selectedFilter === "contest" && selectAllClicked.AllContestFilters ? [{ 'key': 'AllContest', 'text': 'Select All' }, ...selectedKeys]
                        : selectedKeys}
                    placeholder="Search"
                    closeOnSelect={false}
                    style={{
                        multiselectContainer: { width: '180px', height: '20px', background: 'white' },
                        inputField: { height: '15px' },
                        option: { display: 'flex', alignItems: 'center' },
                        searchBox: { background: 'white' }
                    }}
                /> : <div style={{ width: '180px' , minWidth: '180px'}}></div>
        }
        </>
        

    );
};