// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TextInput from '../../common/TextInput';
import TextAreaInput from '../../common/TextAreaInput';
import { DialogService } from '../../common/DialogueService';
import { Button, CloseIcon, Tooltip, Label } from '@fluentui/react-components';
import { savePrivateIdea, uploadFile, uploadPreSignedUrl,UpdatePrivateIdeaDetailsAfterFileUpload, deleteFile, deleteFilePrivate, updatePrivateIdea, deletePrivateIdea } from '../../api/IdeaApi';
import { PageLoader } from '../../common/PageLoader';
import ValidationPopUp from './ValidationPopup';
import * as moment from 'moment';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from "./../appInsight/AppInsight";
import ScribbleIdeaMobile from "../mobile/landing/middle-panel/ScribbleIdeaMobile";
import Attachment from '../../common/Attachment';
import { RootStateOrAny, useSelector } from "react-redux";
import {uploadToAWSS3}  from '../../api/S3BucketApi';
import { HttpClient, HttpBackend, HttpRequest, HttpContext, HttpContextToken, HttpHeaders, HttpParams } from '@ngify/http';

const ScribbleIdea = ({ enterpriseId, onSavePrivateIdea, editPrivateIdeaCheck, ideaData, showError, showSuccess }) => {
    let httpHandler:HttpBackend;
    const http = new HttpClient(httpHandler);

    const ideaCreationData = useSelector(
        (state: RootStateOrAny) => state.fetchDemographyReducer.ideaCreationData
    );
    
    const isDisabled = false;
    const [privateIdeaData, setPrivateIdeaData] = useState({});
    const [privateIdeaId, setPrivateIdeaId] = useState<any>('');
    const [uploadFilesList, setUploadFileList] = useState<any>([]);
    const [mediaLinksData, setMediaLinksData] = useState<any>([]);
    const [ideaId, setIdeaId] = useState<any>('');
    const [fileName, setFileName] = useState<any>('');
    const [showSpinner, setSpinnerData] = useState(false);
    const [errorClassIdeaTitle, seterrorClassIdeaTitle] = useState("");
    const [filesToShow, setFilesToShow] = useState<any>(3);
    const [expanded, setExpanded] = useState<any>(false);
    const [showValidationPopup, setShowValidationPopup] = useState(false);
    const [popupText, setPopupText] = useState("");
    const [focus, setFocus] = useState(false);
    const [focusDesc, setFocusDesc] = useState(false);
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    const handleChangeField = (item) => {
        //setDiscardValue(false);
        const { name, value } = item;
        setPrivateIdeaData(privateIdeaData => ({
            ...privateIdeaData,
            [name]: value
        }))
        errorClassIdeaTitle !== "" ? seterrorClassIdeaTitle("") : null;
    }
    useEffect(() => {
        if (editPrivateIdeaCheck && Object.keys(ideaData).length > 0) {
            const obj = {
                privateIdeaTitle: ideaData.Title ? ideaData.Title : '',
                privateIdeaDescription: ideaData.Description ? ideaData.Description : ''
            }
            setPrivateIdeaData(obj);
            const id = ideaData.PrivateIdeaId ? ideaData.PrivateIdeaId : '';
            setPrivateIdeaId(id);
            if (ideaData.MediaFiles && ideaData.MediaFiles.length > 0) {
                const uploadListEdit = getUploadedFilesList(ideaData.MediaFiles);
                setUploadFileList(uploadListEdit);
            }
        }

    }, [editPrivateIdeaCheck, ideaData])

    const getUploadedFilesList = (mediaFiles) => {
        let uploadedFiles = [];
        let mediaFile = []
        mediaFiles.forEach(files => {
            const obj = {
                name: files.FileNm,
                sizeIn: bytesToSize(files.FileSize),
                checkUpload: true,
                fileType: 'edit',
                fileCreatedOn: files.FileCreatedOn,
                size: files.FileSize
            }
            uploadedFiles.push(obj);
            mediaFile.push(files);
        });
        setMediaLinksData(mediaFile);
        return uploadedFiles;
    }

    const uploadDocuments = {
        accept: [
            'application/zip',
            'application/octet-stream',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/x-zip-compressed',
            'application/pdf',
            'text/plain',
            'audio/mpeg3',
            'audio/x-mpeg-3',
            'video/mpeg',
            'video/x-mpeg',
            'video/mp4',
            'audio/mpeg',
            'image/png',
            'image/jpeg',
        ],
        maxsize: 10240
    }

    //file
    const handleOnSelectFile = (event) => {
        setFilesToShow(3);
        setExpanded(false);
        const file = event.target.files[0];
        let uploadedFiles = [...uploadFilesList];

        if (file) {
            if (isValidFileExtension(file)) {
                if ((uploadedFiles.findIndex(x => x.name === file.name)) === -1) {
                    file['sizeIn'] = bytesToSize(file.size);
                    if (file.size > 0) {
                        addFiles(file);
                    }
                }
                else {
                    showError("File already uploaded", "error");
                }
            } else {
                showError("Selected file type is not supported.", "error");
            }
        }

    }

    const isValidFileExtension = (file) => {
        const extensions = uploadDocuments.accept;
        if (file && file.type === '') {
            const fileType = file.name.substr(file.name.lastIndexOf('.'), file.name.length - 1);
            if (extensions.indexOf(fileType) === -1) {
                return false;
            } else {
                return true;
            }
        } else if (file && extensions.indexOf(file.type) === -1) {
            return false;
        }
        else {
            return true;
        }
    }

    const bytesToSize = (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) { return '0'; }
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        if (i === 0) { return bytes + ' ' + sizes[i]; }
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    }

    const addFiles = (file) => {
        if (!isInValidFileSizeArray(uploadFilesList, file)) {
            let uploadedFiles = [...uploadFilesList];
            file.fileType = 'new';
            file.checkUpload = false;
            file.fileCreatedOn = '';
            uploadedFiles.push(file);
            setUploadFileList(uploadedFiles);
        } else {
            setSpinnerData(false);
            showError("You have reached the maximum uploading limit of 10 MB.", "error");
        }
    }

    useEffect(() => {
        if (uploadFilesList.length > 0) {
            const filesToUpload = uploadFilesList.filter(x => !x.checkUpload);

            if (filesToUpload != null && filesToUpload.length > 0) {
                startUpload();
            }
        }
    }, [uploadFilesList]);


    const startUpload = () => {
        if (uploadFilesList && uploadFilesList.length && !editPrivateIdeaCheck) {
            if (mediaLinksData && (uploadFilesList.length === mediaLinksData.length)) {
                showError('Please select the file to upload.', "error");
            } else {
                if (privateIdeaId != '') {
                    uploadFiles(privateIdeaId, uploadFilesList, mediaLinksData);
                } else {
                    handleSavePrivateIdea(true);
                }
            }
        } else if (uploadFilesList && uploadFilesList.length && editPrivateIdeaCheck) {
            uploadFiles(privateIdeaId, uploadFilesList, mediaLinksData);
        } else {
            showError('Please select the file to upload.', 'error');
        }
    }


    const uploadFiles = (ideaId, files, mediaLinksData) => {
        if (ideaId && files && files.length) {
            if (isInValidFileSize(files)) {
                setSpinnerData(false);
                showError("You have reached the maximum uploading limit of 10 MB.", "error");
            } else {
                files.forEach(file => {
                    const index = mediaLinksData.findIndex(x => x.FileNm === file.name);
                    if (index === -1) {
                        setSpinnerData(true);

                        if (!file.checkUpload) {
                            console.log("file",file)
                            uploadPreSignedUrl(file,ideaId).then((fileurl)=>{
                                if(fileurl){
                                    uploadToAWSS3(fileurl,file);

                                    // console.log("s3 upload data",s3uploadData);

                                      setTimeout(()=>{
                                        UpdatePrivateIdeaDetailsAfterFileUpload(file,ideaId,fileurl).then((fileUploadRes)=>{
                                            if (fileUploadRes && fileUploadRes['ResponseCode'] == "105" && fileUploadRes?.Values) {
                                                setFileName(file.name);

                                                const innerIndex = uploadFilesList.findIndex(x => x.name === file.name);
                                                uploadFilesList[innerIndex].checkUpload = true;
                                                uploadFilesList[innerIndex].fileType = 'edit';
                                                // uploadFilesList[innerIndex].fileCreatedOn = file['FileCreatedOn'];

                                                mediaLinksData.push({
                                                    FileUrl: fileurl,
                                                    FileNm: uploadFilesList[innerIndex].name,
                                                    FileSize: uploadFilesList[innerIndex].size,
                                                    FileCreatedOn: new Date(),
                                                    FileType: file['fileType']
                                                });

                                                setFileName('');
                                                updateUploadFilesList(uploadFilesList, mediaLinksData);
                                                setSpinnerData(false);
                                            }
                                            else {
                                                showError("Some error occured", 'error');
                                                setSpinnerData(false);
                                            }
                                        });
                                      },5000)
                                   
                                }
                            });

                            // uploadFile(file, ideaId).then((response) => {
                            //     console.log("file",file);
                            //     if (response['ResponseCode']) {
                            //         showError("Some error occured", 'error');
                            //         setSpinnerData(false);
                            //     } else if (response && response['FileUrl']) {
                            //         setFileName(file.name);

                            //         const innerIndex = uploadFilesList.findIndex(x => x.name === file.name);
                            //         uploadFilesList[innerIndex].checkUpload = true;
                            //         uploadFilesList[innerIndex].fileType = 'edit';
                            //         uploadFilesList[innerIndex].fileCreatedOn = response['FileCreatedOn'];

                            //         mediaLinksData.push({
                            //             FileUrl: response['FileUrl'],
                            //             FileNm: uploadFilesList[innerIndex].name,
                            //             FileSize: uploadFilesList[innerIndex].size,
                            //             FileCreatedOn: response['FileCreatedOn'],
                            //             FileType: response['FileType']
                            //         });

                            //         setFileName('');
                            //         updateUploadFilesList(uploadFilesList, mediaLinksData);
                            //         setSpinnerData(false);
                            //     }
                            // }, error => {
                            //     setSpinnerData(false);
                            // });

                        }
                    } else if (index === 0 && files.length === mediaLinksData.length) {
                        setSpinnerData(false);
                        showError('Please select the file to upload.', 'error');
                    }

                });
            }
        } else {
            setSpinnerData(false);
        }
    }

    const updateUploadFilesList = (files, mediaLinksData) => {

        if (files.length === mediaLinksData.length) {
            // after all files upload this would be called
            onUpload({ uploadFilesList: files, mediaLinksData: mediaLinksData });
        }
    }

    const onUpload = (event) => {
        setUploadFileList(event.uploadFilesList);
        setMediaLinksData(event.mediaLinksData);
    }

    const isInValidFileSize = (files) => {
        let totalSize = 0;
        const maxsize = uploadDocuments.maxsize;
        if (files && files.length) {
            files.forEach(file => {
                totalSize += file.size;
            });
            if ((totalSize / 1024) > maxsize) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    const isInValidFileSizeArray = (files, newFile) => {
        let totalSize = 0;
        const maxsize = uploadDocuments.maxsize;
        if (files && files.length) {
            files.forEach(file => {
                totalSize += file.size;
            });
        }
        totalSize += newFile.size;
        if ((totalSize / 1024) > maxsize) {
            return true;
        } else {
            return false;
        }

    }

    const handleRemoveFile = (fileName) => {
        const obj = {
            IdeaId: privateIdeaId,
            FileName: fileName
        }
        setSpinnerData(true);
        deleteFilePrivate(obj).then((res) => {
            //console.log(res);
            if (res && res !== undefined && res !== null && res) {
                if (res) {
                    setSpinnerData(false);
                    //console.log('deleted');
                    mediaLinksData.filter(x => x.FileNm !== fileName);
                    uploadFilesList.filter(x => x.name !== fileName);
                    setMediaLinksData([...mediaLinksData.filter(x => x.FileNm !== fileName)]);
                    setUploadFileList([...uploadFilesList.filter(x => x.name !== fileName)]);
                    showSuccess('File(s) deleted successfully.');
                } else if (res && res['ResponseCode'] === '500') {
                    setSpinnerData(false);
                    showError(res['ResponseMessage'], 'error');
                }
            }else{
                setSpinnerData(false);
                showError(res['ResponseMessage'], 'error');
            }
        });

    }

    //file end
    const handleSavePrivateIdea = (fileUpload?) => {

        //console.log(privateIdeaData, "private");
        if (!fileUpload && (!('privateIdeaTitle' in privateIdeaData) || privateIdeaData['privateIdeaTitle'] == '')) {
            seterrorClassIdeaTitle("errorClassIdeaTitle");
            showError('please add title', 'error');
        } else {
            seterrorClassIdeaTitle("");
            setSpinnerData(true);


            if (privateIdeaData['privateIdeaTitle']?.toLowerCase()?.includes("<script")) {
                showError('please provide valid title', 'error');
                seterrorClassIdeaTitle("errorClassIdeaTitle");
                setSpinnerData(false);
                return;
            }
            if (privateIdeaData['privateIdeaDescription']?.toLowerCase()?.includes("<script")) {
                showError('please provide valid description', 'error');
                setSpinnerData(false);
                return;
            }



            if (editPrivateIdeaCheck || privateIdeaId) {
                const obj = {
                    "PrivateIdeaId": privateIdeaId,
                    "Title": privateIdeaData['privateIdeaTitle'] ? privateIdeaData['privateIdeaTitle'] : '',
                    "Description": privateIdeaData['privateIdeaDescription'] ? privateIdeaData['privateIdeaDescription'] : '',
                    "SharedUserDetails": [],
                    "MediaFiles": mediaLinksData.length > 0 ? mediaLinksData : [],
                    "CreatedBy": ideaData ? ideaData.CreatedBy : enterpriseId,
                    "IdeaCreatorHierarchy": ideaCreationData
                }
                updatePrivateIdea(obj).then((res) => {
                    if (res !== undefined && res !== null) {
                        if (res['ResponseCode'] === '105') {
                            fileUpload ? setFocus(true) : setFocus(false);
                            setPrivateIdeaId(res['Values'][0].Id);
                            if (fileUpload) {
                                uploadFiles(res['Values'][0].Id, uploadFilesList, mediaLinksData);
                            } else {
                                const obj = {
                                    privateIdeaTitle: '',
                                    privateIdeaDescription: ''
                                }
                                setPrivateIdeaData(obj);
                                setUploadFileList([]);
                                setMediaLinksData([]);
                                setPrivateIdeaId('');
                                setSpinnerData(false);
                                showSuccess(res['ResponseMessage']);
                                onSavePrivateIdea(false);
                            }
                        }
                    }
                })
            } else {
                const obj = {
                    "Title": privateIdeaData['privateIdeaTitle'] ? privateIdeaData['privateIdeaTitle'] : '',
                    "Description": privateIdeaData['privateIdeaDescription'] ? privateIdeaData['privateIdeaDescription'] : '',
                    "SharedUserDetails": [],
                    "MediaFiles": mediaLinksData.length > 0 ? mediaLinksData : [],
                    "CreatedBy": enterpriseId,
                    "IdeaCreatorHierarchy": ideaCreationData
                }
                savePrivateIdea(obj).then((res) => {
                    if (res !== undefined && res !== null) {
                        if (res['ResponseCode'] === '104') {
                            fileUpload ? setFocus(true) : setFocus(false);
                            setPrivateIdeaId(res['Values'][0].Id);
                            if (fileUpload) {
                                uploadFiles(res['Values'][0].Id, uploadFilesList, mediaLinksData);
                            } else {
                                const obj = {
                                    privateIdeaTitle: '',
                                    privateIdeaDescription: ''
                                }
                                setPrivateIdeaData(obj);
                                setUploadFileList([]);
                                setMediaLinksData([]);
                                setPrivateIdeaId('');
                                setSpinnerData(false);
                                showSuccess('Idea submitted successfully');
                                onSavePrivateIdea(false);
                            }
                        }
                    }
                })
            }
        }
    }

    const showAllFiles = () => {
        setFilesToShow(uploadFilesList.length);
        setExpanded(true);
    }

    const showFileDesc = (FileDate, bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) { return '0'; }
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        if (i === 0) { return (FileDate + " | " + bytes + ' ' + sizes[i]); }
        //return (FileDate + " | " + (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]);
        return (moment(FileDate).format('DD MMM, YYYY') + " | " + (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]);
    }
    const [discardValue, setDiscardValue] = useState(false);
    const cancel = () => {
        deleteIdea();
        const obj = {
            privateIdeaTitle: '',
            privateIdeaDescription: ''
        }
        setPrivateIdeaData(obj)
        setUploadFileList([]);
        setMediaLinksData([]);
        setPrivateIdeaId('');
        setPopupText('');
        setShowValidationPopup(false);
        seterrorClassIdeaTitle("");
        //setDiscardValue(true);

    }

    const deleteIdea = () => {
        deletePrivateIdea(privateIdeaId).then(response => {
            if (response && response['ResponseCode'] === '106') {
                console.log(response['Values'][0]['ResponseMessage'], 'success');
            } else {
                console.log(response['ResponseMessage'], 'error');
            }

        })
    }

    const closePopup = () => {
        setPopupText('');
        setShowValidationPopup(false);
    }

    const handleInputFocus = (value) => {
        setFocus(value);
    };


    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setFocus(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <>           
            {
                isMobile ?
                    <>
                        {showSpinner ? <PageLoader hidden={false} /> : ""}
                        <ScribbleIdeaMobile privateIdeaData={privateIdeaData} errorClassIdeaTitle={errorClassIdeaTitle}
                            handleOnSelectFile={handleOnSelectFile} uploadFilesList={uploadFilesList}
                            handleRemoveFile={handleRemoveFile} showFileDesc={showFileDesc}
                            handleSavePrivateIdea={handleSavePrivateIdea} cancel={cancel} handleChangeField={handleChangeField}
                            filesToShow={filesToShow} expanded={expanded} showAllFiles={showAllFiles} discardValue={discardValue} />
                    </>
                    :
                    <div className="scribble-idea" ref={wrapperRef} onFocus={() => { handleInputFocus(true) }} >
                        {showSpinner ? <PageLoader hidden={false} /> : ""}
                        {showValidationPopup ? <ValidationPopUp closeModal={closePopup} openModal={showValidationPopup} subText={popupText} /> : ""}
                        <div className="header">
                            <div className="icon">
                                <span className="icon scribble-idea-bulb">
                                    <svg xmlns="" id="Objects_01_Light_lightbulb-on" data-name="Objects/01 Light/lightbulb-on" width="32" height="24" viewBox="0 0 32 24">
                                        <path id="Objects_01_Light_lightbulb-on-2" data-name="Objects/01 Light/lightbulb-on" d="M17.971,24H14.028a.812.812,0,0,1-.665-.335l-1.225-1.727A.708.708,0,0,1,12,21.525L12,18a12.452,12.452,0,0,0-2.622-4.323A7.909,7.909,0,0,1,7.2,8.261,8.1,8.1,0,0,1,9.792,2.383,9.008,9.008,0,0,1,16,0h.006a8.863,8.863,0,0,1,8.009,4.838,7.861,7.861,0,0,1-1.392,8.84A12.52,12.52,0,0,0,20,18l0,3.523a.729.729,0,0,1-.135.414l-1.225,1.728A.814.814,0,0,1,17.971,24ZM13.6,21v.3l.853,1.2h3.086l.855-1.2V21ZM13.6,18l0,1.5h4.8V18ZM16,1.5a7.388,7.388,0,0,0-5.1,1.952,6.6,6.6,0,0,0-2.1,4.8,6.484,6.484,0,0,0,1.778,4.438,15.777,15.777,0,0,1,2.53,3.81h5.781a15.953,15.953,0,0,1,2.529-3.81A6.5,6.5,0,0,0,23.2,8.25,6.994,6.994,0,0,0,16,1.5Zm13.6,15a.835.835,0,0,1-.357-.08l-3.2-1.5a.762.762,0,0,1-.4-.434.7.7,0,0,1,.044-.573A.8.8,0,0,1,26.4,13.5a.834.834,0,0,1,.357.08l3.2,1.5a.73.73,0,0,1,.358,1.006A.8.8,0,0,1,29.6,16.5Zm-27.2,0a.838.838,0,0,1-.441-.124.738.738,0,0,1-.358-.671.753.753,0,0,1,.441-.626l3.2-1.5A.859.859,0,0,1,5.6,13.5a.79.79,0,0,1,.78.578.734.734,0,0,1-.422.844l-3.2,1.5A.849.849,0,0,1,2.4,16.5ZM31.2,9H28a.752.752,0,1,1,0-1.5h3.2a.752.752,0,1,1,0,1.5Zm-20,0a.777.777,0,0,1-.8-.75A5.45,5.45,0,0,1,16,3a.777.777,0,0,1,.8.75.777.777,0,0,1-.8.75,3.891,3.891,0,0,0-4,3.75A.777.777,0,0,1,11.2,9ZM4,9H.8a.752.752,0,1,1,0-1.5H4A.752.752,0,1,1,4,9ZM26.4,3a.789.789,0,0,1-.779-.578.733.733,0,0,1,.42-.843l3.2-1.5A.836.836,0,0,1,29.6,0a.8.8,0,0,1,.716.414.7.7,0,0,1,.043.573.761.761,0,0,1-.4.434l-3.2,1.5A.865.865,0,0,1,26.4,3ZM5.6,3a.859.859,0,0,1-.358-.08l-3.2-1.5a.76.76,0,0,1-.4-.433A.7.7,0,0,1,1.685.414.8.8,0,0,1,2.4,0a.836.836,0,0,1,.358.08l3.2,1.5a.732.732,0,0,1,.42.843A.788.788,0,0,1,5.6,3Z" fill="#a100ff" />
                                    </svg>
                                </span>
                            </div>
                            <h5 className="title">Capture your private idea</h5>
                        </div>
                        <div className={focus == false ? "input-idea-title-char" + " input-fields" : "input-fields"}>
                            <TextInput
                                keyVal="Idea Title"
                                label=""
                                onChangeField={handleChangeField}
                                name='privateIdeaTitle'
                                valueTextArea={privateIdeaData['privateIdeaTitle'] ? privateIdeaData['privateIdeaTitle'] : ''}
                                size='medium'
                                placeholder='Title'
                                className={errorClassIdeaTitle}
                                maxLength={100}
                                disabled={isDisabled}
                                discardValue={discardValue}
                            />
                            <hr className="margin-0" />
                            {focus ?
                                <>
                                    <TextAreaInput
                                        onChangeField={handleChangeField}
                                        content=""
                                        placeholder='Description'
                                        size='medium'
                                        name='privateIdeaDescription'
                                        valueTextArea={privateIdeaData['privateIdeaDescription'] ? privateIdeaData['privateIdeaDescription'] : ''}
                                        maxLength={2000}
                                        className="textarea"
                                        disabled={isDisabled}
                                        discardValue={discardValue}
                                    />
                                    {/* <hr className="margin-0" /> */}
                                </> : null
                            }
                        </div>
                        {focus ?
                            <div className="action-btn-wrapper">
                                <div className="float-left">
                                    <div>
                                        <label htmlFor="file" style={{ cursor: 'pointer', color: '#a100fe' }}>
                                            {/*<i className="ms-Icon ms-Icon--Attach scribble-attach float-left" aria-hidden="true"></i>*/}
                                            <span className="icon scribble-attach float-left">
                                                <svg xmlns="" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="none">
                                                    <path d="M3.257 8.707l4.597-4.596a2.5 2.5 0 0 1 3.535 3.536L6.086 12.95a1 1 0 0 1-1.414-1.414l5.303-5.304a.5.5 0 0 0-.707-.707L3.964 10.83a2 2 0 1 0 2.829 2.828l5.303-5.303a3.5 3.5 0 0 0-4.95-4.95L2.55 8a.5.5 0 1 0 .707.707z" fill="#626262" /></g><rect x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
                                                </svg>
                                            </span>
                                        </label>
                                        <span className="label-attachments" >Attachments</span>
                                        {/* <hr className="margin-0" /> */}
                                        <Label className="scribble-attachment">File size: Max 10 MB</Label>
                                        <input id="file" type="file" style={{ display: 'none' }} value="" onChange={handleOnSelectFile} />
                                    </div>

                                    <div className="attached-file">
                                    {uploadFilesList && uploadFilesList.slice(0, filesToShow).map((file, index) => {
                                            return (
                                                <div className="wrapper">
                                                    <Attachment
                                                        header={file.name}
                                                        description={showFileDesc(file.fileCreatedOn?file.fileCreatedOn: new Date() , file.size)}
                                                        action='remove'
                                                        clickAction={() => handleRemoveFile(file.name)}
                                                    />
                                                    </div>
                                            )})
                                        }
                                    </div>
                                    {(uploadFilesList && uploadFilesList.length > 3 && !expanded) ?
                                        <div className='additional-files' onClick={showAllFiles}>+{uploadFilesList.length - 3}</div> : ''
                                    }

                                </div>

                                <div className="full-width divider">
                                    <hr className="margin-0" />
                                </div>

                                <div className="float-right">
                                    <span className="scribbleidea-message">*This idea will be visible only to you.</span>
                                    <Button type="button" primary className="btn-primary primary-btn-content" onClick={() => handleSavePrivateIdea(false)}>Submit</Button>
                                    {((privateIdeaData && (privateIdeaData['privateIdeaDescription'] !== '' && privateIdeaData['privateIdeaDescription'] !== undefined &&
                                        privateIdeaData['privateIdeaDescription'] !== null) || (privateIdeaData['privateIdeaTitle'] !== '' && privateIdeaData['privateIdeaTitle'] !== undefined &&
                                            privateIdeaData['privateIdeaTitle'] !== null)) || (uploadFilesList && uploadFilesList.length > 0)) ?
                                        <Button content="Discard" type="button" secondary className="btn-secondary secondary-btn-content" onClick={() => cancel()}>Discard</Button>
                                        : null}
                                </div>
                                
                            </div> : null}
                    </div >
            }
        </>
    )
}

export default React.memo((withAITracking(reactPlugin, ScribbleIdea, "Private idea Submit page", "EPrivate idea Submit page")));