import {types} from '../actions/types';

const initialState={
   activeContests:[]
}

const fetchActiveContests=(state=initialState, action)=>{
    switch(action.type){
        case types.SET_ACTIVE_CONTESTS:
            return {
                ...state,
                activeContests:action.activeContests
              
            }
            default:
                return{
                    ...state
                }
    }
    

}
export default fetchActiveContests;