import { types } from './types';

export const setEntityId = (id: string) => ({
    type: types.SET_ENITITY_ID,
    payload: id
});

export const setNavigatedFromActivity = (navigatedFromActivity: boolean) => ({
    type: types.SET_NAVIGATED_FROM_ACTIVITY,
    payload: navigatedFromActivity
});

export const setIsMobile = (isMobile: boolean) => ({
    type: types.SET_IS_MOBILE,
    payload: isMobile
});
