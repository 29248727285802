import {types} from './types';

export const fetchMyExpertise = () => {
    return {
        type: types.SEND_EXPERTISE_DATA_REQUEST
    }
}
export const setMyExpertise = () => {
    return {
        type: types.SET_EXPERTISE_DATA_REQUEST
    }
}
export const fetchMyPreference = () => {
    return {
        type: types.SEND_PREFERENCE_DATA_REQUEST
    }
}
export const setMyPreference= () => {
    return {
        type: types.SET_PREFERENCE_DATA_REQUEST
    }
}