import { types } from '../actions/types';

const initialState = {
    industryFilters: [],
    technologyFilters: [],
    refeshExploreIdea: 0,
    marketUnitOptionsValue:[],
    categoriesOptionsValue: [],
    marketUnitSelectedValue: [],
    categorySelectedValue: [],
    ideaSearchInput: '',
    marketsOptionsValue:[],
    marketsSelectedValue:[]

}

const fetchExploreIdeasFiltersReducer = (state = initialState, action) => {
    // console.log('reducer action Type', action);
    switch (action.type) {
        case types.SET_EXPLORE_INDUSTRY_FILTERS:
            return {
                ...state,
                industryFilters: action.payload
            }
        case types.SET_EXPLORE_TECHNOLOGY_FILTERS:
             return {
                ...state,
                 technologyFilters: action.payload
            }
        case types.RESET_EXPLORE_FILTERS:
            return {
                ...state,
                industryFilters: [],
                technologyFilters: [],
                refeshExploreIdea: 0
            }
        case types.EXPLORE_IDEA_REFRESH:
            return {
                ...state,
                refeshExploreIdea: state.refeshExploreIdea + 1
            }
        case types.CATEGORIES_OPTIONS:
            return{
                ...state,
                categoriesOptionsValue: action.categoryOptions,
                categorySelectedValue: action.selectedCategory

            }
        case types.MARKETUNIT_OPTIONS:
                return{
                    ...state,
                    marketUnitOptionsValue: action.marketUnitOptions,
                    marketUnitSelectedValue: action.selectedMarketUnit
    
            }
        case types.IDEA_SEARCH_INPUT:
                    return{
                        ...state,
                        ideaSearchInput: action.ideaSearchInput,
                    }
        case types.MARKET_OPTIONS:
                    return{
                        ...state,
                        marketsOptionsValue: action.marketsOptions,
                        marketsSelectedValue: action.marketsSelectedValue        
                    }
        default:
            return {
                ...state
            }
    }
}

export default fetchExploreIdeasFiltersReducer;