import React, { useEffect, useState } from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { hiddenContentStyle, mergeStyles } from '@fluentui/react/lib/Styling';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useHistory } from 'react-router-dom';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useSelector, RootStateOrAny } from "react-redux";
import { IDropdownStyles, IDropdownOption } from '@fluentui/react';
import Select from 'react-select';
import "./CommonPopup.scss";

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
};
const screenReaderOnly = mergeStyles(hiddenContentStyle);
const dialogContentProps = {
    type: DialogType.normal,
    title: 'Select Contest',
    closeButtonAriaLabel: 'Close',
    subText: ' ',
};
const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
interface SubmitIdeaPopupPropsMobile{
    optionsData?:any;
    closeModal:any;
    contractorCancel?:any;
  }

export const CommonPopupMobile = ({ closeModal ,optionsData ,contractorCancel}:SubmitIdeaPopupPropsMobile) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const { contestsState, challengesState, v360ContestId } = useSelector((state: RootStateOrAny) => state.fetchDemographyReducer);
    const [selectedContest, setSelectedContest] = useState({});
    const [contestOptions, setContestOptions] = useState(optionsData);
    const [defaultSelectedContest, setDefaultSelectedContest] = useState<any>();
    const history = useHistory();
    const [error, setError] = useState(false);
    let sustainabilityContestID = process.env.REACT_APP_SUSTAINABILITY2023_CONTEST_ID;

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: dialogStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable, labelId, subTextId],
    );

    useEffect(()=>{
        if(optionsData){
            setContestOptions(optionsData)

        }
        },[optionsData])


    const onChange = (event: React.FormEvent<HTMLDivElement>, item: any): void => {
        setSelectedContest(event);
        setError(false);
    };

    const onProceedClick = () => {
      if(Object.keys(selectedContest).length > 0) {
        let contest =  contestOptions.find(contest => contest.value === selectedContest['value']);
        let fy23v360Id = contestOptions.find(contest=>contest.value == process.env.REACT_APP_V360_FY23)
        let fy24v360Id = contestOptions.find(contest=>contest.value == process.env.REACT_APP_V360_FY24)
        if (selectedContest['value'] === v360ContestId) {
        history.push(`/view-contest-homepage-v360/${v360ContestId}`);
        }
        else if (selectedContest['value'] === sustainabilityContestID) {
            history.push(`/view-contest-homepage-sustainability/${sustainabilityContestID}`);
        }
        else if(fy23v360Id && selectedContest['value'] === fy23v360Id?.value){
          history.push(`/view-contest-homepage-v360/${fy23v360Id.value}`);
        }
        else if(fy24v360Id && selectedContest['value'] === fy24v360Id?.value){
          history.push(`/view-contest-homepage-v360/${fy24v360Id.value}`);
        }
        else {
            history.push({
                pathname: `/view-contest-homepage-gtic/${selectedContest['value']}`,
                state: {
                    contestDetails: {
                        ...contest
                    }
                }
            });
        }          
        closeModal();
        toggleHideDialog(); 
    } else {
      setError(true);
    }

  }

    const onCancelClick = () => {
        toggleHideDialog();
        closeModal();
        if(contractorCancel) {
            history.push("/unautherised-user")
          }
      }

      let customStyles = {
        control: styles => ({
          ...styles,
          backgroundColor: 'white',
          fontSize: '10px',
          height: '30px',
          minHeight: '30px',
          verticalAlign: 'center',
          borderColor: error ? '#FF0000' : '#808080',
          ...boxShadow()
          //paddingBottom: 9
        }),
        menu: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted pink',
          color: state.selectProps.menuColor,
          maxHeight: 130
        }),
        menuList: (provided, state) => ({
          ...provided,
          maxHeight: 120
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            borderBottom: '1px',
            fontSize: '10px',
            ":hover": {
              background: '#efe4f7'
            },
            backgroundColor: isFocused  ? '#efe4f7' : isSelected ? '#9713fa' : '#fff',
            color: '#000',
          };
        },
        singleValue: (provided, state) => {
          return { ...provided, paddingBottom: 5, ...boxShadow() };
        },
        placeholder: styles => ({ ...styles, ...dot(), paddingBottom: 6, ...boxShadow() }),
        input: styles => ({ ...styles, ...dot(), paddingBottom: 8,
          ...boxShadow()
         }),
        indicatorsContainer: styles => ({ ...styles, ...dot(), height: 30 }),
        container: styles => ({
          ...styles,
         ...boxShadow()
        })
      }

      const dot = (color = '#ccc') => ({
        alignItems: 'center',
        display: 'flex',
      });
    
      const boxShadow = () => ({
        boxShadow: '#9713fa',
          ":hover": {
            borderColor: '#9713fa',
            borderWidth: 1
          },
          ":focus": {
            borderColor: '#9713fa',
            borderWidth: 1
          },
          ":active": {
            borderColor: '#9713fa',
            borderWidth: 1
          },
          ":focus-within": {
            borderColor: '#9713fa',
            borderWidth: 1
          }
      })

    return (
        <>
            <Dialog
                hidden={hideDialog}
                onDismiss={onCancelClick}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                className="dialogBox-promoteToIdea-mobile"
            >

                <Select
                    onChange={onChange}
                    options={contestOptions}
                    isSearchable={true}
                    placeholder="Select Contest"
                    className="filter-criteria-dropdown"
                    styles={customStyles}
                    defaultValue={defaultSelectedContest}
                />
                <DialogFooter>
                    <DefaultButton onClick={onCancelClick} text="Cancel" />
                    <PrimaryButton onClick={onProceedClick} text="Proceed" />                    
                </DialogFooter>
            </Dialog>
        </>
    );
};
