import React from 'react';
import { Text } from '@fluentui/react-components';
import { Link, useHistory } from 'react-router-dom';

function PatentabilityCheck({trendingTopics, contestIds, challengeIds}) {
    const history = useHistory();
    const categoryIds = trendingTopics.map((topic) => { return topic.CategoryIds });
    localStorage.setItem("checkboxDisabled", 'true');
    localStorage.setItem("checkboxChecked", 'false');
    const navigateToPatentIdeas = () => {
        history.push({
            pathname: '/patentideas',
            state: { categoryIds: categoryIds, contestIds: contestIds, challengeIds: challengeIds }
        });
    }
    return (
        <div className="patent-div">
            <p className="patent-header">Patentability Check</p>
            <div className="patent-check">
                <div>
                    <Text>Elevate your innovations to the next level, Initiate Patent Mentoring now!</Text>
                </div>
                <div>
                    <button onClick={navigateToPatentIdeas}>Initiate</button>
                </div>

            </div>
        </div>
    )
}

export default React.memo(PatentabilityCheck);