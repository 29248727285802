import axios from 'axios';
import { serviceMethod } from './config-reader';
import { deleteMethod, getMethod, postMethod, putMethod, patchMethod } from './http-interceptor-service';
let cancelToken;

export const getContestApplicableEntityDetail = (contestId) => {
    let method = serviceMethod.contest.GetContestApplicableEntityDetail;
    method = method.replace('#contestId', contestId);

    const URL = process.env.REACT_APP_CONTESTSERVICE + method;

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const getContestByContestId = (contestId) => {
    let method = serviceMethod.contest.getContestByContestId;
    method = method.replace('#contestId', contestId);
    method = method.replace('#isvaliduser', 'true');
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const getUserReportAccessDetail =(contestId)=>{
    let method= serviceMethod.contest.getUserReportAccessDetail
    method = method.replace('#contestId', contestId);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const downloadS3File = (token, fileName, id, EnterpriseId) => {
    //const my_app = axios.create({ baseURL: process.env.REACT_APP_CHALLENGESERVICE, responseType: 'blob' });
    //let method = serviceMethod.download.downloadDoc;
    //method = method.replace("#FileName", encodeURIComponent(fileName));
    //method = method.replace("#Id", id);

    //const config = {
    //    headers: {
    //        Authorization: `Bearer ${token}`,
    //        EnterpriseId: EnterpriseId,
    //        IsBatchProcess: true
    //    }
    //};

    //return new Promise((resolve, reject) => {
    //    my_app.get(`${method}`, config)
    //        .then(response => { resolve(response.data); })
    //        .catch(error => { reject(error); });
    //})

    let method = serviceMethod.download.downloadDoc;
    method = method.replace("#FileName", encodeURIComponent(fileName));
    method = method.replace("#Id", id);

    const URL = process.env.REACT_APP_CHALLENGESERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
}
export const deleteDocument = (fileName, contestId) => {
    const method = serviceMethod.contest.deleteFileContest;
    const URL = process.env.REACT_APP_CHALLENGESERVICE + method;
    const data = {
        ContestId: contestId,
        FileName: fileName
    };
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const getMyContests = (ContestStatus, ContestType, pageIndex) => {
    let method = serviceMethod.adminPanel.getMyContests;
    method = method.replace('#pageIndex', pageIndex);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;

    const data = {
        ContestStatus,
        ContestType
    };
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const validContest = (contestValidRequest) => {
    let method = serviceMethod.adminPanel.isValidContest;
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, contestValidRequest, config);
}

export const updateContest=(contest) =>{
    let method = serviceMethod.adminPanel.editContest;
    method = method.replace('#contestID', contest.ContestId);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
      const config = {
          headers: {
              IsBatchProcess: true
          }
      };
      return putMethod(URL, contest, config);
  }

  export const downloadContestFile=(fileName, id)=> {
      let method = serviceMethod.adminPanel.downloadFileContest;
      method = method.replace('#fileName', encodeURIComponent(fileName));
      method = method.replace('#uniqueID', id);
      const URL = process.env.REACT_APP_CHALLENGESERVICE + method;
      const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
  }
export const getContestDetailsById=(contestId) =>{
    let method = serviceMethod.adminPanel.GetContestByContestId;
    method = method.replace('#contestId', contestId);
    method = method.replace('#isvaliduser', 'true');
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
  }

  export  const checkIdeaExistForContest=(contestId)=> {
    let method = serviceMethod.adminPanel.CheckIdeaExistForContest;
    method = method.replace('#contestId', contestId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
  }
  export const  getInviteByChallengeInviteeMappingId =(inviteeMappingId)=> {
    let method = serviceMethod.adminPanel.getInviteeByChallengeInviteeMappingId;
    method = method.replace('#ChallengeInviteeMappingId', inviteeMappingId);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
  }
  export const saveContest = (request) => {
    let method = serviceMethod.contest.addNewContest;
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, request, config);
}
export const upload = (challengeInviteeMappingId, challengeId, file) => {
    let method = serviceMethod.contest.AddFileInviteCreate;
    method = method.replace("#ChallengeInviteeMappingId", challengeInviteeMappingId);
    method = method.replace("#ChallengeId", challengeId);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const formData = new FormData();
    formData.append('fileName', file);
    const config = {
        headers: {
            IsBatchProcess: true,
            'content-type': 'multipart/form-data',
        }
    };
    return postMethod(URL, formData, config);
}

export const getUploadPreSignedUrl =(fileName,id, type)=>{
    let method = serviceMethod.contest.getUploadPreSignedUrl;
    method = method.replace('#fileName', fileName);
    method = method.replace('#id', id);
    method = method.replace('#type', type);
    const URL = process.env.REACT_APP_DOCUMENTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);

}

export const uploadToAWSS3=(fileurl,file)=>{
    axios.put(fileurl, file)
    .then(response =>  response)
    .catch(error => {
        element.parentElement.innerHTML = `Error: ${error.message}`;
        // console.error('There was an error!', error);
    });
}


export const uploadFile = (file, contestId) => {
    let method = serviceMethod.contest.addFileContest;
    const formData = new FormData();
    formData.append('fileName', file);
    method = method.replace('#contestID', contestId);
    const URL = process.env.REACT_APP_CHALLENGESERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true,
            'content-type': 'multipart/form-data',
        }
    };
    return postMethod(URL, formData, config);
}

export const getBannerImageByteData = (id, fileName) => {
    let method = serviceMethod.contest.getBannerImageByteData;
    method = method.replace('#id', id);
    method = method.replace('#fileName', fileName);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
}

export const SaveContestView = (ContestId, EnterpriseId, MarketId, MarketName, MarketUnitId, MarketUnitName, IsCallFromTeams, PageViewedDetail) => {
    let method = serviceMethod.challenge.SaveContestView;
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const data = {
        "ContestId": ContestId,
        "EnterpriseId": EnterpriseId,
        "MarketId": MarketId,
        "MarketName": MarketName,
        "MarketUnitId": MarketUnitId,
        "MarketUnitName": MarketUnitName,
        "IsCallFromTeams": IsCallFromTeams,
        "PageViewedDetail": PageViewedDetail
    };

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const getStageDetailForJudge = (ContestId) => {
    let method = serviceMethod.evaluate.GetStageDetailForJudge;
    const URL = process.env.REACT_APP_IDEA_ALLOCATIONSERVICE + method;
    const data = {
        "ContestId": ContestId,
    };

    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const getAllocatedIdeaForJudgeReviewDynamic = (ContestId, StageId, IsReviewed, ChallengeIds, pageIndex) => {
    let method = serviceMethod.evaluate.GetAllocatedIdeaForJudgeReviewDynamic;
    method = method.replace('#PageIndex', pageIndex);
    const URL = process.env.REACT_APP_JUDGESERVICE + method;
    const data = {
        "ContestId": ContestId,
        "StageId": StageId,
        "IsReviewed": IsReviewed,
        "ChallengeIds": ChallengeIds
    };
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const submitJudgeRatingsDynamic = (object, status) => {
    let method = serviceMethod.evaluate.SubmitJudgeRatingsDynamic;
    method = method.replace('#status', status);
    if(status=="submit"){
        method = method+ "&isDynamic=true";
    }
    const URL = process.env.REACT_APP_JUDGESERVICE + method;
    const data = object;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return patchMethod(URL, data, config);
}

export const getPatentDetailsByIdfNumber = (IdfNum) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let method = serviceMethod.ioty.getPatentDetailsByIdfNumber;
    const data = {
        "IDFNumber": IdfNum
    };
    const URL = process.env.REACT_APP_ADMINSERVICE + method;
    return postMethod(URL, data,{ cancelToken: cancelToken.token });
}
