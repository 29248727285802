// import { configureStore } from '@reduxjs/toolkit';
import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import RootReducer from './reducers/root-reducer';
import RootSaga from './sagas/root-saga';

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(RootReducer, applyMiddleware(sagaMiddleware));

// export const store = configureStore({
//     reducer: RootReducer, 
//     middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware({
//             // immutableCheck: false,
//             serializableCheck: false,
//         }).concat(sagaMiddleware)
// })

export const persistor = persistStore(store);

sagaMiddleware.run(RootSaga);

export default { store, persistor };