import {types} from './types';
export const fetchData=(data)=>{
    return {
        type: types.SEND_REQUEST,
        payload:data
    }
}


export const fetchDataSuccess=(data)=>{
    return {
        type: types.SEND_REQUEST_SUCCESS,
        payload:data
    }
}

export const fetchDataFailure=(error)=>{
 //   console.log("inside error",error)
    return {
        type: types.SEND_REQUEST_FAILURE,
        payload:error
    }
}


export const fetchIdeaFeedLastGetIdeaTillDates = (data) => {
    //   console.log("inside error",error)
    return {
        type: types.LAST_GETIDEATILLDATES,
        payload: data
    }
}

export const setPromotionalMediaFiles = (data) => {
    return {
        type: types.SET_PROMOTIONAL_MEDIAFILES_REQUEST,
        payload: data
    }
}

export const setSerialInnovatorData = (data) =>{
    return {
        type: types.SET_SERIAL_INNOVATOR_DATA,
        payload: data
    }
}
export const setSerialInnoDemograpghyType = (data) =>{
    return {
        type: types.SET_SERIALINNO_DEMOGRAPHY_TYPE,
        payload: data
    }
}

export const setSerialInnovatorIdeaFilter = (data) => {
    return {
        type: types.SET_SERIAL_INNOVATOR_IDEA_FILTER,
        payload: data
    }
}