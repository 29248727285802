import {types} from '../actions/types';

const initialState={
    filterData: {
        allFilters: [],
        industryFilters: [],
        categoryFilters: [],
        technologyFilters: [],
        ideaStatus: [],
        contestFilters: []
    },
    popularData: false,
    hallofFame: false,
    privateData:false,
    pinnedData:false,
    contestData:false,
    sharedData: false,
    allIdeas: true,
    innovatorFilterData: {
        filters: {},
        contestFilters: []
    },
    selectAllFilter: {
        AllIndustryFilters: false,
        AllTechnologyFilters: false,
        AllIdeaStatus: false,
        AllContestFilters: false
    }
}

const fetchFilterDataReducer=(state=initialState, action)=>{
    // console.log("action.type",action.type);
    switch(action.type){
        case types.SEND_FILTER_REQUEST:
            // console.log("inside filter reducer", state);

            if (action.payload.filter === "industry") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        industryFilters: [...state.filterData.industryFilters, action.payload.item],
                        allFilters: [...state.filterData.allFilters, action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIndustryFilters: false
                    }
                })
            } else if (action.payload.filter === "category") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        categoryFilters: [...state.filterData.categoryFilters, action.payload.item],
                        allFilters: [...state.filterData.allFilters, action.payload.item]
                    }
                })
            } else if (action.payload.filter === "technology") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        technologyFilters: [...state.filterData.technologyFilters, action.payload.item],
                        allFilters: [...state.filterData.allFilters, action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllTechnologyFilters: false
                    }
                })
            } else if ((action.payload.filter === "ideaStatus")){
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        ideaStatus: [...state.filterData.ideaStatus, action.payload.item],
                        allFilters: [...state.filterData.allFilters, action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIdeaStatus: false
                    }
                })
            }
            else {
                // console.log("contestreducer", action.payload);
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        contestFilters: [...state.filterData.contestFilters, action.payload.item],
                        allFilters: [...state.filterData.allFilters, action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllContestFilters: false
                    }
                })
            }

            //return ({
            //    ...state,
            //    filterData: [...state.filterData,action.payload],
            //})

        case types.REMOVE_FILTER_ITEM:
            // console.log("action.payload", action.payload);
            if (action.payload.filter === "industry") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        industryFilters: state.filterData.industryFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key),
                        allFilters: state.filterData.allFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key)
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIndustryFilters: false
                    }
                })
            } else if (action.payload.filter === "category") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        categoryFilters: state.filterData.categoryFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key),
                        allFilters: state.filterData.allFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key)
                    }
                })
            } else if (action.payload.filter === "technology") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        technologyFilters: state.filterData.technologyFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key),
                        allFilters: state.filterData.allFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key)
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllTechnologyFilters: false
                    }
                })
            } else if (action.payload.filter === "ideaStatus"){
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        ideaStatus: state.filterData.ideaStatus.filter(Arrayitem => Arrayitem.key !== action.payload.item.key),
                        allFilters: state.filterData.allFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key)
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIdeaStatus: false
                    }
                })
            } else {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        contestFilters: state.filterData.contestFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key),
                        allFilters: state.filterData.allFilters.filter(Arrayitem => Arrayitem.key !== action.payload.item.key)
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllContestFilters: false
                    }
                })
            }  

        case types.SELECT_ALL_FILTER_ITEMS:
            // console.log("inside filter reducer", state);

            if (action.payload.filter === "industry") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        industryFilters: action.payload.item,
                        allFilters: [...state.filterData.allFilters, ...action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIndustryFilters : true
                    }
                })
            } else if (action.payload.filter === "technology") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        technologyFilters: action.payload.item,
                        allFilters: [...state.filterData.allFilters, ...action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllTechnologyFilters: true
                    }
                })
            } else if ((action.payload.filter === "ideaStatus")) {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        ideaStatus: action.payload.item,
                        allFilters: [...state.filterData.allFilters, ...action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIdeaStatus: true
                    }
                })
            }
            else {
                // console.log("contestreducer", action.payload);
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        contestFilters: action.payload.item,
                        allFilters: [...state.filterData.allFilters, ...action.payload.item]
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllContestFilters: true
                    }
                })
            }

        case types.UN_SELECT_ALL_FILTER_ITEMS:
            // console.log("inside filter reducer", action.payload.item);

            if (action.payload.filter === "industry") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        industryFilters: [],
                        allFilters: state.filterData.allFilters.filter(Arrayitem => !action.payload.item.includes(Arrayitem.key))
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIndustryFilters: false
                    }
                })
            } else if (action.payload.filter === "technology") {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        technologyFilters: [],
                        allFilters: state.filterData.allFilters.filter(Arrayitem => !action.payload.item.includes(Arrayitem.key))
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllTechnologyFilters: false
                    }
                })
            } else if ((action.payload.filter === "ideaStatus")) {
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        ideaStatus: [],
                        allFilters: state.filterData.allFilters.filter(Arrayitem => !action.payload.item.includes(Arrayitem.key))
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllIdeaStatus: false
                    }
                })
            }
            else {
                // console.log("contestreducer", action.payload);
                return ({
                    ...state,
                    popularData: false,
                    hallofFame: false,
                    privateData: false,
                    pinnedData: false,
                    contestData: false,
                    sharedData: false,
                    allIdeas: false,
                    filterData: {
                        ...state.filterData,
                        contestFilters: [],
                        allFilters: state.filterData.allFilters.filter(Arrayitem => !action.payload.item.includes(Arrayitem.key))
                    },
                    selectAllFilter: {
                        ...state.selectAllFilter,
                        AllContestFilters: false
                    }
                })
            }

        case types.RESET_FILTER_ITEM:
            return ({
                ...state,
                filterData: {
                    allFilters: [],
                    industryFilters: [],
                    categoryFilters: [],
                    technologyFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                popularData: false,
                hallofFame: false,
                privateData: false,
                pinnedData: false,
                contestData: false,
                sharedData: false,
                allIdeas: false,
                selectAllFilter: {
                    AllIndustryFilters: false,
                    AllTechnologyFilters: false,
                    AllIdeaStatus: false,
                    AllContestFilters: false
                }
            })
        case types.SEND_POPULAR_IDEAS_REQUEST:
            return({
                ...state,
                filterData: {
                    allFilters: [],
                    industryFilters: [],
                    categoryFilters: [],
                    technologyFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                popularData: !state.popularData,
                hallofFame: false,
                privateData:false,
                pinnedData:false,
                contestData: false,
                allIdeas: false,
                sharedData:false
            })
        case types.SEND_PROMOTIONAL_IDEAS_REQUEST:
            return ({
                ...state,   
                filterData: {
                    allFilters: [],
                    industryFilters: [],
                    categoryFilters: [],
                    technologyFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                hallofFame: !state.hallofFame,
                privateData: false,
                popularData: false,
                pinnedData: false,
                contestData: false,
                allIdeas: false,
                sharedData: false
            })
        case types.SEND_PRIVATE_IDEA_REQUEST:
            return({
                ...state,
                filterData: {
                    allFilters: [],
                    industryFilters: [],
                    categoryFilters: [],
                    technologyFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                privateData:!state.privateData,
                popularData:false,
                pinnedData:false,
                contestData:false,
                sharedData: false,
                allIdeas: false,
                hallofFame: false

            })
        case types.SEND_PINNED_IDEA_REQUEST:
            return({
                ...state,
                filterData: {
                    allFilters: [],
                    industryFilters: [],
                    categoryFilters: [],
                    technologyFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                pinnedData:!state.pinnedData,
                popularData:false,
                privateData:false,
                contestData:false,
                sharedData: false,
                allIdeas: false,
                hallofFame: false
            })
        case types.SEND_CONTEST_IDEA_REQUEST:
            return({
                ...state,
                filterData: {
                    allFilters: [],
                    industryFilters: [],
                    categoryFilters: [],
                    technologyFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                contestData:!state.contestData,
                popularData:false,
                privateData:false,
                pinnedData:false,
                sharedData: false,
                allIdeas: false,
                hallofFame: false
            })

        case types.SEND_SHARED_IDEA_REQUEST:
                return({
                    ...state,
                    filterData: {
                        allFilters: [],
                        industryFilters: [],
                        categoryFilters: [],
                        technologyFilters: [],
                        ideaStatus: [],
                        contestFilters: []
                    },
                    sharedData:!state.sharedData,
                    popularData:false,
                    privateData:false,
                    pinnedData: false,
                    allIdeas: false,
                    contestData: false,
                    hallofFame: false
            })

        case types.SEND_ALL_IDEA_REQUEST:
            return ({
                ...state,
                filterData: {
                    allFilters: [],
                    industryFilters: [],
                    categoryFilters: [],
                    technologyFilters: [],
                    ideaStatus: [],
                    contestFilters: []
                },
                allIdeas: !state.allIdeas,
                sharedData: false,
                popularData: false,
                privateData: false,
                pinnedData: false,
                contestData: false,
                hallofFame: false
            })

        case types.INNOVATOR_FILTER_DATA:
            if (action.payload.filters) {
                return ({
                    ...state,
                    innovatorFilterData: {
                        filters: action.payload.filters,
                        contestFilters: state.innovatorFilterData.contestFilters
                    }
                })
            } else {
                return ({
                    ...state,
                    innovatorFilterData: {
                        filters: state.innovatorFilterData.filters,
                        contestFilters: action.payload.contestFilters
                    }
                })
            }
        default:
            return{
                ...state
            }
    }
    

}
export default fetchFilterDataReducer;