import React, { useState } from 'react';
import { MenuList, MenuItem, Tooltip } from "@fluentui/react-components";
import FeedbackPopup from '../../../my-space/FeedbackPopup';
import RaiseIncidentPopup from '../../../my-space/RaiseIncidentPopup';
import "./RightPanel.scss";

const HelpComponentMobile = ({ enterpriseId, setSuccess }) => {
    const [showModal, setShowModal] = useState(false);
    const [showMoreList, setShowMoreList] = useState(false);
    const [showRaiseTicketModal, setShowRaiseTicketModal] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = (isSuccess) => {
        setShowModal(false);

        if (isSuccess) {
            setSuccess("Feedback submitted successfully");
        }
    };

    const openRaiseTicketModal = () => {
        setShowRaiseTicketModal(true);
    };

    const closeRaiseTicketModal = (isSuccess) => {
        setShowRaiseTicketModal(false);
    };

    return (
        <>
            {
                showModal ?
                    <FeedbackPopup showModal={showModal} handleCloseModal={handleCloseModal} enterpriseId={enterpriseId} /> :
                    ""
            }
            {
                showRaiseTicketModal ?
                    <RaiseIncidentPopup showModal={showRaiseTicketModal} handleCloseModal={closeRaiseTicketModal} enterpriseId={enterpriseId} /> :
                    ""
            }
            <div>
            {
                !showCloseButton ?
                    <Tooltip withArrow relationship='label' appearance='inverted' content="Help" positioning="above">
                        <button className="bottom-right-feedback-button bottom-right-feedback-button-mobile" 
                            onClick={(event) => { event.preventDefault(); setShowMoreList(true); setShowCloseButton(true);}} title="">
                                <svg aria-hidden="true" focusable="false" width="1.455vw" height="1.455vw" style={{ msTransform: 'rotate(360deg)', WebkitTransform: 'rotate(360deg)', transform: 'rotate(360deg)' }} preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                                    <g fill="none">
                                        <path d="M2 10a8 8 0 1 1 4.262 7.075l-3.64.91a.5.5 0 0 1-.607-.606l.91-3.641A7.968 7.968 0 0 1 2 10zm7.25-2.693C9.403 7.134 9.64 7 10 7s.597.134.75.307c.161.184.25.438.25.693c0 .317-.071.523-.166.685c-.101.174-.236.314-.423.51l-.022.024c-.189.197-.421.444-.598.781c-.18.346-.291.761-.291 1.293a.5.5 0 1 0 1 0c0-.384.077-.638.177-.829c.105-.2.248-.359.434-.554l.042-.043c.17-.177.382-.398.545-.678c.186-.32.302-.7.302-1.189c0-.473-.161-.97-.5-1.354C11.153 6.252 10.64 6 10 6s-1.153.252-1.5.646C8.16 7.031 8 7.526 8 8a.5.5 0 0 0 1 0c0-.255.089-.51.25-.693zm1.45 6.206a.7.7 0 1 0-1.4 0a.7.7 0 0 0 1.4 0z" fill="#fff" />
                                    </g>
                                </svg>
                        </button>
                    </Tooltip>
                    :
                        <button className="bottom-right-feedback-button bottom-right-feedback-button-mobile"
                            onClick={(event) => { event.preventDefault(); setShowMoreList(false); setShowCloseButton(false); }} title="">
                            <svg aria-hidden="true" focusable="false" width="1.455vw" height="1.455vw" style={{ msTransform: 'rotate(360deg)', WebkitTransform: 'rotate(360deg)', transform: 'rotate(360deg)' }} preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                                <g fill="none">
                                    <path d="M4.089 4.216l.057-.07a.5.5 0 0 1 .638-.057l.07.057L10 9.293l5.146-5.147a.5.5 0 0 1 .638-.057l.07.057a.5.5 0 0 1 .057.638l-.057.07L10.707 10l5.147 5.146a.5.5 0 0 1 .057.638l-.057.07a.5.5 0 0 1-.638.057l-.07-.057L10 10.707l-5.146 5.147a.5.5 0 0 1-.638.057l-.07-.057a.5.5 0 0 1-.057-.638l.057-.07L9.293 10L4.146 4.854a.5.5 0 0 1-.057-.638l.057-.07l-.057.07z" fill="#fff" />
                                </g></svg>
                        </button>
            }
            </div>
            {
                showMoreList ?
                    <MenuList className="help-list">
                        <MenuItem
                            content={<Tooltip withArrow relationship='label' appearance='inverted' content="Provide Feedback" positioning="before">
                                <button className="help-buttons"
                                    onClick={handleOpenModal} title="">
                                    <svg aria-hidden="true" focusable="false" width="1.455vw" height="1.455vw" style={{ msTransform: 'rotate(360deg)', WebkitTransform: 'rotate(360deg)', transform: 'rotate(360deg)' }} preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                                        <g fill="none">
                                            <path d="M10.5 12a1.5 1.5 0 0 1 1.5 1.5v.5c0 1.971-1.86 4-5 4c-3.14 0-5-2.029-5-4v-.5A1.5 1.5 0 0 1 3.5 12h7zm0 1h-7a.5.5 0 0 0-.5.5v.5c0 1.438 1.432 3 4 3s4-1.562 4-3v-.5a.5.5 0 0 0-.5-.5zM7 5.5A2.75 2.75 0 1 1 7 11a2.75 2.75 0 0 1 0-5.5zM16 2a2 2 0 0 1 1.994 1.85L18 4v2a2 2 0 0 1-1.85 1.995L16 8h-1.501l-1.198 1.6c-.53.706-1.604.42-1.777-.376l-.017-.111L11.5 9L11.5 7.935l-.078-.02a2.003 2.003 0 0 1-1.397-1.6l-.02-.166L10 6V4a2 2 0 0 1 1.85-1.995L12 2h4zM7 6.5A1.75 1.75 0 1 0 7 10a1.75 1.75 0 0 0 0-3.5zM16 3h-4a1 1 0 0 0-.993.883L11 4v2a1 1 0 0 0 .883.993L12 7h.5v2L14 7h2a1 1 0 0 0 .993-.883L17 6V4a1 1 0 0 0-.883-.993L16 3z" fill="#fff" />
                                        </g>
                                    </svg>
                                </button>
                            </Tooltip>} />
                        <MenuItem
                            content={<Tooltip withArrow relationship='label' appearance='inverted' content="Raise Ticket"  positioning="before">
                                <button className="help-buttons" onClick={openRaiseTicketModal} title="">
                                    <svg aria-hidden="true" focusable="false" width="1.455vw" height="1.455vw" style={{ msTransform: 'rotate(360deg)', WebkitTransform: 'rotate(360deg)', transform: 'rotate(360deg)' }} preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                                        <g fill="none">
                                            <path d="M9.976 1.352a1.4 1.4 0 0 0-1.936.042L1.424 8.01a1.4 1.4 0 0 0 0 1.98l.926.926c.347.347.846.264 1.14.067a1.1 1.1 0 0 1 1.525 1.525c-.195.295-.279.794.068 1.141l.905.905a1.4 1.4 0 0 0 2.01-.03l6.598-7.012a1.4 1.4 0 0 0-.073-1.992l-.886-.812a.842.842 0 0 0-.727-.198c-.227.04-.442.161-.598.331a1.1 1.1 0 1 1-1.622-1.487c.157-.169.258-.394.279-.623a.841.841 0 0 0-.26-.707l-.733-.672z" fill="#fff" />
                                        </g>
                                    </svg>
                                </button>
                            </Tooltip>} />
                    </MenuList>
                    :
                    null
            }
        </>
    )
}

export default HelpComponentMobile;