import React, { useEffect, useState, useCallback} from "react";
import ScribbleIdea from "../../../my-space/scribble-idea";
import FilterIdeas from "../../../my-space/filters/FilterIdeas";
import { Skeleton } from "@fluentui/react-components";
import InfiniteScrollFeed from "../../../infinite-scroll/InfiniteScroll";
import "./MiddlePanel.scss";
import { GenericMessageBar } from "../../../../common/MessageBar";

interface MiddlePanelProps {
    name: any;
    ideas: any;
    enterpriseId: string;
    handleEditPrivateIdea?: any;
    setError?: any;
    contestIds?: any;
    challengeIds?: any;
    filterData: any;
    popularData: any;
    privateIdeas: any;
    pinnedData?: any;
    allContestData?: any;
    contestPopularIdea?: any;
    allSharedData?: any;
    allpromotionalData?: any;
    getAllIdeaDataRefresIdeaFeed?: any;
    firstFeedData?: any;
    filters?: any;
    allContestFilters?: any;
    dataLoader?: boolean;
    onSavePrivateIdea?: any;
    editPrivateIdeaCheck?: any;
    ideaData?: any;
    showError?: any;
    showSuccess?: any;
}

const MiddlePanel = ({ enterpriseId, onSavePrivateIdea, editPrivateIdeaCheck, ideaData, showError, showSuccess,
    filters, allContestFilters, dataLoader, ideas, firstFeedData, name, handleEditPrivateIdea, setError,
    getAllIdeaDataRefresIdeaFeed, contestIds, challengeIds, filterData, popularData, privateIdeas, pinnedData,
    allContestData, allSharedData, allpromotionalData }: MiddlePanelProps) => {
        
    const [msgMobile, setMsgMobile] = useState<string>("");
    const [choiceMobile, setChoiceMobile] = useState<string>("");

    const setErrorMobile = useCallback(
        (errorMsg, type) => {
        setMsgMobile(errorMsg);
        setChoiceMobile(type);
        },[]
    );
    const resetchoiceMobile = () => {
        setChoiceMobile(undefined);
    };
    return (
        <div className="middle-area">
            {/*<GenericMessageBar choice={choiceMobile} msg={msgMobile} resetMsg={resetchoiceMobile} />*/}
            <div className="child padding-top-5">
                <ScribbleIdea enterpriseId={enterpriseId} onSavePrivateIdea={onSavePrivateIdea}
                    editPrivateIdeaCheck={editPrivateIdeaCheck} ideaData={ideaData}
                    showError={showError} showSuccess={showSuccess} />
            </div>
            <div className="filter-idea-wrapper">
                {Object.keys(filters).length > 0 ? (
                    <FilterIdeas
                        filters={filters}
                        allContestFilters={allContestFilters}
                    />
                ) : null}
            </div>
            {dataLoader ? (
                <Skeleton animation="wave">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Skeleton>
            ) : (
                    <InfiniteScrollFeed
                        ideas={ideas}
                        name={name}
                        getAllIdeaDataRefresIdeaFeed={getAllIdeaDataRefresIdeaFeed}
                        enterpriseId={enterpriseId}
                        handleEditPrivateIdea={handleEditPrivateIdea}
                        setError={setError}
                        contestIds={contestIds}
                        challengeIds={challengeIds}
                        filterData={filterData}
                        popularData={popularData}
                        privateIdeas={privateIdeas}
                        pinnedData={pinnedData}
                        allContestData={allContestData}
                        allSharedData={allSharedData}
                        allpromotionalData={allpromotionalData}
                        firstFeedData={firstFeedData}
                        setErrorMobile={setErrorMobile}
                    />
                )}
        </div>
    )
}

export default MiddlePanel;