import React from 'react';

const UnautherisedUser = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '2.0vw'}}>
            <span className="iconify" data-icon="fluent:warning-20-regular" data-inline="false" style={{ fontSize: '7.0vw', color: 'orange' }}></span>
            <div>
                <span>Access Denied. Request you to reach out to </span>
                <span style={{ fontWeight: "bold"}}><a href="mailto:myinnovation.support@accenture.com"> myinnovation.support@accenture.com</a></span>
                <span> for access.</span>
            </div>
        </div>
        )
}

export default UnautherisedUser;