import axios from 'axios';
import { serviceMethod } from './config-reader';
import { getMethod, postMethod, putMethod } from './http-interceptor-service';

export const getSerialInnovator = (IndustryAlignment, TechnologyIds, CategoryIds, enterpriseId,demographyVal,userCountryNameState) => {
    let method = serviceMethod.serialInnovators.getSerialInnovator;
    method = method.replace('#EnterpriseId', enterpriseId);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    
    let data = {
        "IndustryAlignment": IndustryAlignment,
        "TechnologyIds": TechnologyIds,
        "CategoryIds": CategoryIds,
        "DemographyType":demographyVal,
        "CountryName":[userCountryNameState]
    
    }
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}


export const getSerialInnovatorForMaxIdeaCount = (IndustryAlignment, TechnologyIds, CategoryIds, enterpriseId,DemographyType,CountryName) => {
    let method = serviceMethod.serialInnovators.getSerialInnovatorForMaxIdeaCount;
    method = method.replace('#EnterpriseId', enterpriseId);
    const URL = process.env.REACT_APP_REPORTVIEWERSERVICE + method;
    
    let data = {
        "IndustryAlignment": IndustryAlignment,
        "TechnologyIds": TechnologyIds,
        "CategoryIds": CategoryIds,
        "DemographyType": DemographyType,
        "CountryName" : [CountryName]
    }
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const searchInnovatorIdeas = (InnovatorId, pageIndex) => {
    let method = serviceMethod.serialInnovators.searchInnovatorIdeas;
    method = method.replace("#pageIndex", pageIndex);
    const URL = process.env.REACT_APP_SEARCHSERVICELAMBDA + method;
    let data = {
        "AllChallengeIds": [],
        "Idea": {
            "Title": "",
            "IdeaStatus": [],
            "InnovatorEntId": InnovatorId,
            "keywords": [],
            "FromDte": "",
            "ToDte": "",
            "FromIdeaNumber": null,
            "challengeIds": [],
            "ContestId": [],
            "ToIdeaNumber": null
        },
        "OrgUnitIds": []
    }


    return postMethod(URL, data);
}
