import {types} from '../actions/types';

const initialState={
    loading:false,
    data:{},
    error:{}
}

const fetchTokenReducer=(state=initialState, action)=>{
    switch(action.type){
        case types.SEND_TOKEN_REQUEST:
            return {
                ...state,
                loading:true
            }
        case types.SEND_TOKEN_REQUEST_SUCCESS:
            return {
                ...state,
                loading:false,
                data:action.payload,
                error: {}
            }

         case types.SEND_TOKEN_REQUEST_FAILURE:
                return {
                    ...state,
                    loading:false,
                    error:action.error,
                    data:{},
                
                }
            default:
                return{
                    ...state
                }
    }
    

}
export default fetchTokenReducer;