import React, { useEffect } from "react";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useId, useBoolean } from '@fluentui/react-hooks';

const dialogStyles = { main: { maxWidth: 100 } };

const ValidationPopUp = ({ closeModal, openModal, subText }) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Error',
        closeButtonAriaLabel: 'Close',
        subText: subText,
    };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId],
    );

    useEffect(() => {
        toggleHideDialog();
    }, [openModal])


    const onCancel = () => {
        toggleHideDialog();
        closeModal();
    }

    return (
        <>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <DefaultButton onClick={onCancel} text="OK" />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default ValidationPopUp;