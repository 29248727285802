import {types} from '../actions/types';

const initialState={
    loading:false,
    data:{},
    error: {},
    lastDates: {
        privateIdea: '',
        innovatorIdea: '',
        popularIdea: '',
        promotionalIdea: '',
        SharedPrivateIdea: '',
        implementerIdeas:''
    },
    promotionalMediaFiles: [],
    serialInnovatorData: [],
    serialInnovDemographyType: '',
    serialInnovatorIdeaFilter: 'max_idea_submitted'
}

const fetchDataReducer=(state=initialState, action)=>{
    //console.log("action", action);
    switch(action.type){
        case types.SEND_REQUEST:
            return {
                ...state,
                loading:true
            }
        case types.SEND_REQUEST_SUCCESS:
            return {
                ...state,
                loading:false,
                data:action.payload,
                error: {}
            }

         case types.SEND_REQUEST_FAILURE:
                return {
                    ...state,
                    loading:false,
                    error:action.error,
                    data:{},
                
            }

        case types.SET_PROMOTIONAL_MEDIAFILES_REQUEST:
            return {
                ...state,
                promotionalMediaFiles: action.payload
            }
        case types.SET_SERIAL_INNOVATOR_DATA:
            return {
                ...state,
                serialInnovatorData: action.payload
            }
        case types.SET_SERIALINNO_DEMOGRAPHY_TYPE:
            return {
                ...state,
                serialInnovDemographyType: action.payload
            }
        case types.LAST_GETIDEATILLDATES:
            return {
                ...state,
                lastDates: {
                    ...state.lastDates,
                    privateIdea: action.payload.privateIdea,
                    innovatorIdea: action.payload.innovatorIdea,
                    popularIdea: action.payload.popularIdea,
                    promotionalIdea: action.payload.promotionalIdea,
                    SharedPrivateIdea: action.payload.SharedPrivateIdea,
                    implementerIdeas: action.payload.implementerIdeas                   
                }
            }
        case types.SET_SERIAL_INNOVATOR_IDEA_FILTER:
            return {
                ...state,
                serialInnovatorIdeaFilter: action.payload
            }

        default:
            return{
                ...state
            }
    }

}
export default fetchDataReducer;