import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import { useSelector, RootStateOrAny, shallowEqual} from "react-redux";
import { getBannerImageByteData, SaveContestView } from "../../api/contestApi";
import stepsIcon from "../../assets/images/steps-icon.svg";
import bulbIcon from "../../assets/images/bulb-icon.svg";
import eyeIcon from '../../assets/images/eye-show-icon.svg';
import featureicon from "../../assets/images/feature-image.svg";
import backgroundImage from '../../assets/images/background_contest_img.jpg';

import MaskGroup73 from '../../assets/images/Mask_Group_73.png';
import MaskGroup74 from '../../assets/images/Mask_Group_74.png';
import MaskGroup75 from '../../assets/images/Mask_Group_75.png';
import MaskGroup76 from '../../assets/images/Mask_Group_76.png';
import MaskGroup77 from '../../assets/images/Mask_Group_77.png';
import MaskGroup78 from '../../assets/images/Mask_Group_78.png';
import MaskGroup79 from '../../assets/images/Mask_Group_79.png';

import defaultContestThumbnail1 from "../../assets/images/default-contest-thumbnail-1.png";
import defaultContestThumbnail2 from "../../assets/images/default-contest-thumbnail-2.png";
import defaultContestThumbnail3 from "../../assets/images/default-contest-thumbnail-3.png";
import defaultContestThumbnail4 from "../../assets/images/default-contest-thumbnail-4.png";
import defaultContestThumbnail5 from "../../assets/images/default-contest-thumbnail-5.png";
import defaultContestThumbnail6 from "../../assets/images/default-contest-thumbnail-6.png";
import defaultContestThumbnail4Dup from "../../assets/images/default-contest-thumbnail-4-2.png";

import { Tooltip } from '@fluentui/react-components';

import "./style.css";

interface ActiveContest {
    contest?: any;
    key?: any;
}


const ActiveContest = React.memo(({ contest }: ActiveContest) => {
    //console.log('active contest', contest);
    const [activeContestDetails, setActiveContestDetails] = React.useState<any>(contest);
    const [bannerImage, setBannerImage] = React.useState<any>('');
    const history = useHistory();
    const { filterDetailsState } = useSelector(
        (state: RootStateOrAny) => state.fetchDemographyReducer
    );

    const enterpriseId = useSelector(
        (state: RootStateOrAny) => state.entityReducer.entityId, shallowEqual
    );
    const [cardImage, setCardImage] = React.useState<any>(undefined);


    React.useEffect(() => {
        if (contest) {
            getBannerImage(contest);
            //setActiveContestDetails(contest);
        }
    }, [contest])

    //React.useEffect(() => {
    //    if (bannerImage != '') {
    //        setActiveContestDetails({ ...activeContestDetails, bannerImagesrc: bannerImage })
    //    }
    //}, [bannerImage])
    

    const navigateToSubmitIdea = () => {
        history.push({
            pathname: `/submit-idea/${activeContestDetails.ContestId}`,
            state: { contestDetails: contest}
        });
    }

    const navigateToExploreIdea = () => {
            const categoryIds = filterDetailsState["Category"].map(
                (categorydetail) => categorydetail.CategoryId
            );  
        //console.log('on click', categoryIds, contestIds, challengeIds);
        history.push({
            pathname: '/exploreideas',
            state: { categoryIds: categoryIds, contestIds: [activeContestDetails.ContestId], challengeIds: [], fromActiveContest: true, contestName: activeContestDetails.ContestNm }
        });
    }

    const navigateToViewContestDetails = () => {
        //if (enterpriseId) {
        //    SaveContestView(activeContestDetails.ContestId, enterpriseId).then((response) => {
        //        if (response && response["Values"]) {
        //            console.log(response["Values"]);
        //        }
        //    });
        //}
        if(!(activeContestDetails?.IsDynamicContest)){

            const categoryIds = filterDetailsState["Category"].map(
                (categorydetail) => categorydetail.CategoryId
            );
            history.push({
                pathname: '/exploreideas',
                state: { categoryIds: categoryIds, contestIds: [activeContestDetails.ContestId], challengeIds: [], fromActiveContest: true, fromStaticContest: true, landingPage: true, contestName: activeContestDetails.ContestNm}
            });
        }
        else{
            if(activeContestDetails.ContestNm.toLowerCase().includes("v360")){
                history.push({
                    pathname: `/view-contest-homepage-v360/${activeContestDetails.ContestId}`,
                    state: { landingPage: true }
                });
            }
            else{
                history.push({
                    pathname: `/view-contest-homepage/${activeContestDetails.ContestId}`,
                    state: { contestDetails: activeContestDetails, landingPage: true }
                });
            }           
        }        
    }

    const getBannerImage = (contest:any) => {
        if (contest.BannerImage && contest.BannerImage.length) {
            let BannerImages = contest.BannerImage.filter(item => (item.FileType.toLowerCase().includes("png") || item.FileType.toLowerCase().includes("jpg") || item.FileType.toLowerCase().includes("svg")) && item.IsThumnail == true);
            if (BannerImages?.length) {
                BannerImages.forEach(BannerImage => {
                    if (BannerImage && BannerImage.FileURL != null) {
                        getBannerImageByteData(contest.ContestId, BannerImage.FileName).then(response => {
                            if (response && response["BannerImageData"]) {
                                //console.log(response);
                                const binaryData = 'data:image/jpeg;base64,' + response["BannerImageData"];
                                setBannerImage(binaryData);
                            }
                        })
                    }
                    else if (BannerImage && BannerImage.FileName != null && BannerImage.FileURL == null) {
                        let fileName = BannerImage.FileName.split(".")[0];
                        switch (fileName) {
                            case 'Mask Group 73':
                                setCardImage(MaskGroup73);
                                break;
                            case 'Mask Group 74':
                                setCardImage(MaskGroup74);
                                break;
                            case 'Mask Group 75':
                                setCardImage(MaskGroup75);
                                break;
                            case 'Mask Group 76':
                                setCardImage(MaskGroup76);
                                break;
                            case 'Mask Group 77':
                                setCardImage(MaskGroup77);
                                break;
                            case 'Mask Group 78':
                                setCardImage(MaskGroup78);
                                break;
                            case 'Mask Group 79':
                                setCardImage(MaskGroup79);
                                break;

                            case 'default-contest-thumbnail-1':
                                setCardImage(defaultContestThumbnail1);
                                break;
                            case 'default-contest-thumbnail-2':
                                setCardImage(defaultContestThumbnail2);
                                break;
                            case 'default-contest-thumbnail-3':
                                setCardImage(defaultContestThumbnail3);
                                break;
                            case 'default-contest-thumbnail-4':
                                setCardImage(defaultContestThumbnail4);
                                break;
                            case 'default-contest-thumbnail-5':
                                setCardImage(defaultContestThumbnail5);
                                break;
                            case 'default-contest-thumbnail-6':
                                setCardImage(defaultContestThumbnail6);
                                break;
                            case 'default-contest-thumbnail-4 (2)':
                                setCardImage(defaultContestThumbnail4Dup);
                                break;
                        }

                    }
                })
            }
        }
    }

    const numFormatter = (numString) => {        
        let num = parseInt(numString);
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
        } else if (num <= 999) {            
            return num; // if value < 1000, nothing to do
        }
    }

    return (
        //<div className="active-contest" style={{ backgroundImage: 'url(' + cardImage + ')', backgroundSize: 'cover', height: '45vh'}}>
        //    <Label styles={{ opacity: '0.5', fontSize: '0.8vw'}} color="black" content="FEATURED CAMPAIGN" />
        //    <h6 style={{ fontSize: '1.5vw', color: '#fff', marginTop: '1vw' }}>{contest.ContestNm}</h6>
        //    <Stack styles={{ color: "#fff" }} gap="gap.medium">
        //        <Stack vAlign="center">
        //            <MessageSeenIcon size="large" styles={{ color: "#fff"}}/>
        //            <Text content="100" size="smaller" />
        //        </Stack>
        //        <Stack vAlign="center">
        //            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.7em" height="1.7em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><g fill="none"><path d="M11 3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v4h-1V3h-3v3a1 1 0 0 1-1 1H8v3a1 1 0 0 1-1 1H4v3h4v1H4a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h3V7a1 1 0 0 1 1-1h3V3zm3 5a1 1 0 0 0-1 1v3h-3a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5.5a2.5 2.5 0 0 0 2.5-2.5V9a1 1 0 0 0-1-1h-3zm0 1h3v5.5a1.5 1.5 0 0 1-1.5 1.5H10v-3h3a1 1 0 0 0 1-1V9z" fill="#fff" /></g></svg>
        //            <Text content={contest.ChallengeCount} size="smaller" />
        //        </Stack>
        //        <Stack vAlign="center">
        //            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.7em" height="1.7em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 1 1 7.072 0l-.548.547A3.374 3.374 0 0 0 14 18.469V19a2 2 0 1 1-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></g></svg>
        //            <Text content="100" size="smaller" />
        //        </Stack>
        //    </Stack>
        //    <div>
        //        {/* <p>{contest.ContestDesc}</p> */}
        //        <a style={{fontSize:'11px', color:'#FFF', cursor:'pointer', opacity: '0.7' }} onClick={navigateToExploreIdea}>Click here to explore ideas</a> 
        //        {/*<span style={{color:'#777678'}}>Last Date for Idea Submission </span>*/}
        //        {/*<div className="action-btn">     */}
        //            {/*<span data-enddate={contest.EndDate} style={{ fontSize: '13px', fontWeight: 'bold' }} className="date">*/}
        //            {/*{contest.CloseDate}*/}
        //            {/*    {Moment(contest.EndDate, 'DD/MM/YYYY').format('Do MMMM')}*/}
        //            {/*</span>              */}
        //            {/* <button content="Submit Idea" type="button" primary className="btn-primary" /> */}
        //            {/*<button onClick={navigateToSubmitIdea}>Submit Idea</button>*/}
        //            {/* <button className="active-contest-explore" onClick={navigateToExploreIdea}>Explore</button> */}
        //            {/* <a onClick={navigateToExploreIdea} className="active-contest-explore"><span>Explore</span></a> */}
        //        {/*</div>*/}

        //    </div>
        //</div>
        <div className="card-placeholder" onClick={navigateToViewContestDetails}>
            <div className="row">
                <div className="col-lg-12">
                    <div className="cards-wrapper h-100">
                        <div className="card text-white h-100">
                            <div className="card-clickable-section">
                                {activeContestDetails?.IsFeatureCampain ?
                                    <div className="square-box-container">
                                        <div className="tags-pills borderradius-0 feature-img-section" >
                                         <img src={featureicon} alt="" />
                                        </div>
                                    </div>
                                : null}

                                <div className="image-gradient">
                                    {bannerImage !== '' ? 
                                        <img src={bannerImage? bannerImage: cardImage?cardImage: backgroundImage} className="card-img" alt={backgroundImage} /> :
                                        <img src={bannerImage? bannerImage: cardImage?cardImage: backgroundImage} className="card-img" alt={backgroundImage} />
                                    }
                                </div>


                                    <div className="card-img-overlay">

                                        <div className="card-heading-section">
                                            <div className="card-heading-inner">
                                            <h2 className="card-title">{activeContestDetails.ContestNm}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="description">
                                    {activeContestDetails.ContestDesc}
                                    </div>

                                    {/*<div className="selected-entity-section">*/}
                                    {/*    <div className="tags-pills">*/}
                                    {/*        <a href="#"><span className="tag-text">Selected Entity</span></a>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="tags-pills">*/}
                                    {/*        <a href="#"><span className="tag-text">Entity 2</span></a>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                <div className="card-icon-section">
                                    <ul>
                                        {/* <li>
                                            <a href="#">
                                                <img src={badgeIcon} />
                                                <span className="icon-text">32</span>
                                            </a>
                                        </li> */}
                                        <li>
                                            <Tooltip relationship='label' withArrow content='Challenges' appearance='inverted'>
                                                <span>
                                                    <img src={stepsIcon} />
                                                    <span className="icon-text">{activeContestDetails.ChallengeCount}</span>
                                                </span>
                                            </Tooltip>    
                                        </li>
                                        <li>
                                            <Tooltip relationship='label' withArrow content="Ideas Submitted" appearance='inverted'>
                                                <span>
                                                    <img src={bulbIcon} />
                                                    <span className="icon-text">{numFormatter(activeContestDetails.ContestIdeaSubmittedCount)}</span>
                                                </span>
                                            </Tooltip>
                                        </li>
                                        {activeContestDetails?.IsDynamicContest &&
                                            <li>
                                                <Tooltip relationship='label' withArrow content="Contest View" appearance='inverted'>
                                                    <span>
                                                        <img src={eyeIcon} />
                                                        <span className="icon-text">{numFormatter(activeContestDetails.ContestViewCount)}</span>
                                                    </span>
                                                </Tooltip>
                                            </li>
                                        }
                                    </ul>
                                    <div className="ending-date-bottom">
                                    <span>Ending on {Moment(activeContestDetails.CloseDate, 'DD/MM/YYYY').format('Do MMMM')}</span>
                                        {/*<a onClick={navigateToExploreIdea} className="active-contest-explore" ><span>Explore Ideas</span></a>*/}
                                    </div>
                                </div>
                            </div>
                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
    )
})

export default ActiveContest; 