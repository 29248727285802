import { serviceMethod } from './config-reader';
import { postMethod } from './http-interceptor-service';

export const saveUserFeedback = (data) => {
    let method = serviceMethod.user.saveUserFeedback;
    const URL = process.env.REACT_APP_FEEDBACKSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}

export const SaveUniqueUser = (data) => {
    let method = serviceMethod.user.saveUniqueUser;
    const URL = process.env.REACT_APP_USERSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, data, config);
}