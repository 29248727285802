import React, { Component } from 'react'
import { ChevronRightRegular } from '@fluentui/react-icons';
import { Button } from '@fluentui/react-components';
import {FilterCriteria} from './FilterCriteria';
import { connect } from "react-redux";

interface FilterPanelState{
    activeClassIndustry:string;
    activeClassTechnology:string;
    activeClassContest:string;
    activeClassIdeaStatus:string;
    activeClassCategory:string;
    activeClassPopularIdeas: string;
    activeClassHallofFame: string;
    showFilter: any;
    filterDetails: any;
}
interface FilterPanelProps{
    fetchPopularData?(): any;
    filters?: any;
    fetchPromotionalData?(): any;
    resetFilterItems?(): any;
    allContestFilters? : any;
}
import { fetchPopularData, fetchPromotionalData, resetFilterItems} from '../../../redux-sharedframework/actions/fetch-filter-action';

class FilterPanel extends Component<FilterPanelProps,FilterPanelState> {
    constructor(props){
        super(props);
        this.filterListHovered= this.filterListHovered.bind(this);
        this.filterListUnHovered= this.filterListUnHovered.bind(this);
        this.state={
            activeClassIndustry:'filter-list-active',
            activeClassTechnology: 'filter-list',
            activeClassContest: 'filter-list',
            activeClassIdeaStatus:'filter-list',
            activeClassCategory:'filter-list',
            activeClassPopularIdeas: 'filter-list',
            activeClassHallofFame: 'filter-list',
            showFilter: {
                industry: true, 
                category: false,
                technology: false,
                ideaStatus: false,
                contest: false
            },
            filterDetails: {
                industryFilters: [],
                categoryFilters: [],
                technologyFilters: [],
                ideaStatus: [],
                contestFilters: []
            },
        }
    }



    componentDidMount() {

        //this.props.resetFilterItems();
       // console.log("fiter panel filters", this.props.filters);
        const industry = this.props.filters['IndustryDetails'].map(industrydetail => industrydetail.CategoryName);
        const category = this.props.filters['Category'].map(categorydetail => categorydetail.CategoryName);
        //const technology = this.props.filters['Technology'].map(technologydetail => technologydetail.CategoryName);
        const technology = this.props.filters['Technology']
        const ideaStatus = this.props.filters['IdeaStatus'].filter(status => status !== 'UnderAcceptance' && status !== 'Approved' && status !== 'Signed Off');
        const contestfilters = this.props.allContestFilters;

        this.setState({
            filterDetails: {
                industryFilters: industry,
                categoryFilters: category,
                technologyFilters: technology,
                ideaStatus: ideaStatus,
                contestFilters: contestfilters
            }
        });

    }

    filterListHovered(e){
        // e.preventDefault();
        //console.log("target",e.target)
        //console.log("list clicked",e.target.id)
        if (e.target.id !== "Industry" && this.state.activeClassIndustry === 'filter-list-active') { this.setState({ activeClassIndustry: 'filter-list' });}
        if(e.target.id=="Industry")
            this.setState({
                activeClassIndustry: 'filter-list-active',
                activeClassTechnology: 'filter-list',
                activeClassContest: 'filter-list',
                activeClassIdeaStatus: 'filter-list',
                activeClassPopularIdeas: 'filter-list',
                activeClassHallofFame: 'filter-list',
                showFilter: { industry: true, category: false, technology: false, ideaStatus: false, contest: false }
            });
        if(e.target.id=="Technology")
            this.setState({
                activeClassTechnology: 'filter-list-active',
                activeClassIndustry: 'filter-list',
                activeClassContest: 'filter-list',
                activeClassIdeaStatus: 'filter-list',
                activeClassPopularIdeas: 'filter-list',
                activeClassHallofFame: 'filter-list',
                showFilter: { industry: false, category: false, technology: true, ideaStatus: false, contest: false }
            });
        if(e.target.id=="Contest")
            this.setState({
                activeClassContest: 'filter-list-active',
                activeClassIndustry: 'filter-list',
                activeClassTechnology: 'filter-list',
                activeClassIdeaStatus: 'filter-list',
                activeClassPopularIdeas: 'filter-list',
                activeClassHallofFame: 'filter-list',
                showFilter: { industry: false, category: false, technology: false, ideaStatus: false, contest: true }
            });
        if(e.target.id=="IdeaStatus")
            this.setState({
                activeClassIdeaStatus: 'filter-list-active',
                activeClassIndustry: 'filter-list',
                activeClassTechnology: 'filter-list',
                activeClassContest: 'filter-list',
                activeClassPopularIdeas: 'filter-list',
                activeClassHallofFame: 'filter-list',
                showFilter: { industry: false, category: false, technology: false, ideaStatus: true, contest: false }
            });
        if(e.target.id=="Category")
            this.setState({ activeClassCategory: 'filter-list-active', showFilter: { industry: false, category: true, technology: false, ideaStatus: false, contest: false }});
        if(e.target.id=="PopularIdeas")
            this.setState({
                activeClassPopularIdeas: 'filter-list-active',
                activeClassIndustry: 'filter-list',
                activeClassTechnology: 'filter-list',
                activeClassContest: 'filter-list',
                activeClassIdeaStatus: 'filter-list',
                activeClassHallofFame: 'filter-list',
                showFilter: { industry: false, category: false, technology: false, ideaStatus: false, contest: false }
            });
        if (e.target.id == "HallofFame")
            this.setState({
                activeClassHallofFame: 'filter-list-active',
                activeClassIndustry: 'filter-list',
                activeClassTechnology: 'filter-list',
                activeClassContest: 'filter-list',
                activeClassIdeaStatus: 'filter-list',
                activeClassPopularIdeas: 'filter-list',
                showFilter: { industry: false, category: false, technology: false, ideaStatus: false, contest: false }
            });

    }
    filterListUnHovered(e){
        if(e.target.id=="Industry")
        this.setState({ activeClassIndustry: 'filter-list' });
        if(e.target.id=="Technology")
        this.setState({activeClassTechnology:'filter-list'});
        if(e.target.id=="Contest")
        this.setState({activeClassContest:'filter-list'});
        if(e.target.id=="IdeaStatus")
        this.setState({activeClassIdeaStatus:'filter-list'});
        if(e.target.id=="Category")
        this.setState({activeClassCategory:'filter-list'});
        if(e.target.id=="PopularIdeas")
        this.setState({ activeClassPopularIdeas: 'filter-list' });
        if (e.target.id == "HallofFame")
        this.setState({ activeClassHallofFame: 'filter-list' });
    }
    // filterPopularDataVal(){
    //     this.props.fetchPopularData();
    // }

    render() {
        const { activeClassIndustry, activeClassTechnology, activeClassContest, activeClassIdeaStatus, activeClassCategory, showFilter, activeClassPopularIdeas, activeClassHallofFame, filterDetails} = this.state;
        //console.log("Show industry filter", filterDetails.industryFilters.length);
        return (
            <div className={(!showFilter.industry && !showFilter.category && !showFilter.technology && !showFilter.ideaStatus && !showFilter.contest) ? "filter-container" : "filter-container filter-container-background"} >
                <div className="filter-panel" >
                    <div className={activeClassContest} onMouseLeave={this.filterListUnHovered} onMouseEnter={this.filterListHovered} onClick={this.filterListHovered} id="Contest">Contest <ChevronRightRegular id="Contest" className="chevron-right" /></div>
                    <div className={activeClassPopularIdeas} onMouseLeave={this.filterListUnHovered} onMouseEnter={this.filterListHovered} id="PopularIdeas" onClick={() => { this.props.fetchPopularData() }}>Popular Ideas</div>
                    <div className={activeClassHallofFame} onMouseLeave={this.filterListUnHovered} onMouseEnter={this.filterListHovered} id="HallofFame" onClick={() => { this.props.fetchPromotionalData() }}>Promotional Feeds</div>
                    <div className={activeClassIndustry} onMouseLeave={this.filterListUnHovered} onMouseEnter={this.filterListHovered} onClick={this.filterListHovered} id="Industry" >Industry <ChevronRightRegular id="Industry" className="chevron-right" /></div>
                    <div className={activeClassTechnology} onMouseLeave={this.filterListUnHovered} onMouseEnter={this.filterListHovered} onClick={this.filterListHovered} id="Technology">Technology <ChevronRightRegular id="Technology" className="chevron-right" /></div>
                    <div className={activeClassIdeaStatus} onMouseLeave={this.filterListUnHovered} onMouseEnter={this.filterListHovered} onClick={this.filterListHovered} id="IdeaStatus">Idea Status <ChevronRightRegular id="IdeaStatus" className="chevron-right"/></div>
                    {/*<div className={activeClassCategory} onMouseLeave={this.filterListUnHovered} onMouseEnter={this.filterListHovered} id="Category">Category <ChevronRightRegular className="chevron-right" /></div>*/}
                    {/*<button className="clear-filter-button-panel" onClick={() => { this.props.resetFilterItems(); }}>Clear Filter</button>*/}
                    <div className="filter-list"> <Button className="clear-filter-button-panel" onClick={() => { this.props.resetFilterItems(); }}>Clear Filter</Button> </div>
            </div>
                {(showFilter && filterDetails.industryFilters.length > 0) ? <FilterCriteria showFilter={showFilter} filterDetails={filterDetails}/> : "" }
                {/*{(showFilter.category) ? <FilterCriteria filterData={filterDetails.categoryFilters} classname="category"/> : "" }*/}
                {/*{(showFilter.technology) ? <FilterCriteria filterData={filterDetails.technologyFilters} classname="technology"/> : "" }*/}
                {/*{(showFilter.ideaStatus) ? <FilterCriteria filterData={filterDetails.ideaStatus} classname="ideaStatus"/> : "" }*/}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        fetchPopularData: () => dispatch(fetchPopularData()),
        resetFilterItems: () => dispatch(resetFilterItems()),
        fetchPromotionalData: () => dispatch(fetchPromotionalData())
    })
}
export default connect(null, mapDispatchToProps)(FilterPanel);
