import React, { useEffect ,useState} from "react";
import PropTypes from "prop-types";
import { Stack } from "@fluentui/react";
import { Input, Label, Text, Dropdown,  Button,  } from '@fluentui/react-components';
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

interface TextInputProps{
  keyVal:any, 
  label:any, 
  onChangeField:any,
  size:any, 
  name:any, 
  placeholder:any, 
  disabled:any, 
  valueTextArea:any,
  className:any, 
  maxLength:any
  discardValue?:any
}

const TextInput = ({ keyVal, label, onChangeField, size, name, placeholder, disabled, valueTextArea='', className, maxLength, discardValue}:TextInputProps) => {
//const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);
    //console.log("item", (typeof valueTextArea));

  const handleChangeField = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const item = { name, value}
    if (value.length <= maxLength) {
      onChangeField(item);
    } else if (value.length > maxLength) {
      item.value = value.substr(0, maxLength);
      onChangeField(item);
    } else if (maxLength === undefined) {
      onChangeField(item);
    }
  }
  
  return (
    <div>
        {/* <Text fluid="True" content={label} size={size} style={{ display: 'block' }} /> */}
        {label === "" ? null : 
        <Label>
          {label.slice(-1) === "*" ? <div style={{ display: 'flex' }} >{label.slice(0, label.length - 1)}<div style={{ color: 'red' }}>*</div></div> : label}
        </Label>
        }
        <Input 
          appearance="filled-lighter"
          key={keyVal}
          disabled={disabled} name={name}
          type="text" value={valueTextArea} 
          onChange={handleChangeField}
          placeholder={placeholder} id="inside-label" className={className}
          style= {{ width: '100%' }} 
        />

      {/*{isMobile ? <hr className="margin-0"  /> : null}*/}
      {(typeof valueTextArea === 'string' || maxLength > 0) ? 
        <Text style={{ fontSize: '8px', display: 'block'}} align="end">{valueTextArea ? ((valueTextArea.length).toString() + "/100 Characters") : "0/100 Characters"}</Text>
        : null}
    </div>
  );
};

TextInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default TextInput;