import { types } from '../actions/types';

const initialState = {
    authAccessTokenFromReducer: null
}

const msGraphApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_GRAPH_API_AUTH_TOKEN_REQUEST:
            return {
                ...state,
                authAccessTokenFromReducer: action.payload
            }
        default:
            return {
                ...state
            }
    }


}
export default msGraphApiReducer;