import React, { useEffect, useState } from 'react';
import { Spinner, Skeleton, SkeletonItem } from "@fluentui/react-components";
import InfiniteScroll from "react-infinite-scroll-component";
import AdaptiveCard from './AdaptiveCard';
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { getNudgeDetails, updateNudgeReadFlag } from "./../../api/nudgesApi";
import AdaptiveCardMobile from './../mobile/nudges/AdaptiveCardMobile';

const InfiniteScrollNudges = ({ nudges }) => {
    const enterpriseId = useSelector((state: RootStateOrAny) => state.entityReducer.entityId);
    const [filteredNudges, setFilteredNudges] = useState<any>([]);
    const [filteredDataMore, setFilteredDataMore] = useState<boolean>(true);
    const [filteredDataPage, setFilteredDataPage] = useState<number>(1);
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    useEffect(() => {
        setFilteredNudges(nudges);
        setFilteredDataPage(1);
        setFilteredDataMore(true);
    }, [nudges]);

    useEffect(() => {
        if (filteredDataPage != 1) {
            fetchFilteredData(filteredDataPage);
        }
    }, [filteredDataPage]);

    const fetchFilteredData = (pageIndex) => {
        getNudgeDetails(enterpriseId, pageIndex, 'desc').then(response => {
            if (response) {
                if (response['ResponseCode'] === '107') {
                    // console.log('error', response['ResponseMessage']);
                    setFilteredDataMore(false);
                } else {
                    if (response["value"].length > 0) {
                        // console.log('Infinite Scroll Nudge details', response["value"]);
                        setFilteredNudges([...filteredNudges, ...response["value"]]);
                    }
                    else {
                        setFilteredDataMore(false);
                    }
                }
            }
        });
    }

    const fetchMoreNudges = () => {
        if (filteredNudges.length > 0) {
            setFilteredDataPage(oldPage => oldPage + 1);
        }
    }

    const nudgeRefresh = () => {
        setFilteredNudges([]);
        setFilteredDataPage(1);
        fetchFilteredData(1);
    }

    return (
        <>
            {
                filteredNudges != null && filteredNudges != undefined && filteredNudges.length > 0 ?
                    <InfiniteScroll
                        dataLength={filteredNudges.length}
                        next={fetchMoreNudges}
                        hasMore={filteredDataMore}
                        loader={<Spinner label="" labelPosition="after" size="medium" />}
                        refreshFunction={nudgeRefresh}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={50}
                        releaseToRefreshContent={
                            <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                        }
                    >
                        {
                            filteredNudges != null && filteredNudges != undefined && filteredNudges.length > 0 ?
                                filteredNudges.map(nudge => {
                                    if (nudge == "") {
                                        return <div>No Notifications available</div>
                                    }
                                    else if (isMobile) {
                                        return <AdaptiveCardMobile payload={nudge} />
                                    }
                                    else {
                                        return <AdaptiveCard payload={nudge}  />
                                    }
                                }) : ""
                        }
                    </InfiniteScroll> : isMobile ?
                        <Skeleton animation="wave">
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        </Skeleton>
                        : null
            }
        </>
    )
}

export default InfiniteScrollNudges;