import { types } from './types';

export const setLoginName = (data) => {
    return {
        type: types.SET_LOGIN_NAME,
        payload: data
    }
}
export const setUserCountryName = (data) => {
    return {
        type: types.SET_COUNTRY_NAME,
        payload: data
    }
}
export const setProfilePic = (data) => {
    return {
        type: types.SET_PROFILE_PIC,
        payload: data
    }
}
export const setOrgUnits = (data) => {
    return {
        type: types.SET_ORGUNITS_DATA,
        payload: data
    }
}

export const IdeaCreationData = (data) => {
    return {
        type: types.SET_IDEA_CREATION_DATA,
        payload: data
    }
}

export const setPeopleKey = (data) => {
    return {
        type: types.SET_PEOPLEKEY_DATA,
        payload: data
    }
}

export const setFilterData = (data) => {
    return {
        type: types.SET_FILTER_DATA,
        payload: data
    }
}

export const setTrendingCategoriesData = (data) => {
    return {
        type: types.SET_TRENDING_CATEGORIES_DATA,
        payload: data
    }
}

export const setIndustryData = (data) => {
    return {
        type: types.SET_INDUSTRY_DATA,
        payload: data
    }
}

export const setTechnologyData = (data) => {
    return {
        type: types.SET_TECHNOLOGY_DATA,
        payload: data
    }
}

export const setContestChallengeData = (data) => {
    return {
        type: types.SET_CONTEST_CHALLENGE_DATA,
        payload: data
    }
}


export const setAllIdeaFeedData = (data) => {
    return {
        type: types.SET_IDEA_FEED_DATA,
        payload: data
    }
}

export const storeUserExpertiseData = (data) => {
    return {
        type: types.SET_USER_EXPERTISE_DATA,
        payload: data
    }
}

export const storeUserPreferenceData = (data) => {
    return {
        type: types.SET_USER_PREFERENCE_DATA,
        payload: data
    }
}

export const promotionalContestData = (data) => {
    return {
        type: types.SET_PROMOTIONAL_CONTEST_DETAILS,
        payload: data
    }
}

export const setPeopleDetails = (data) => {
    return {
        type: types.SET_PEOPLE_DETAILS,
        payload: data
    }
}

export const userTourData = (data) => {
    return {
        type: types.USER_TOUR_DATA,
        payload: data
    }
}

export const storeUserPreferenceCompleteData = (data) => {
    return {
        type: types.SET_USER_PREFERENCE_COMPLETE_DATA,
        payload: data
    }
}
export const storeUserExpertiseCompleteData = (data) => {
    return {
        type: types.SET_USER_EXPERTISE_COMPLETE_DATA,
        payload: data
    }
}

export const storePrivateIdeaData = (data) => {
    return {
        type: types.SET_PRIVATE_IDEA_DETAILS,
        payload: data
    }
}

export const setInnovatorsData = (data) => {
    return {
        type: types.SET_INNOVATOR_IDEA_DATA,
        payload: data
    }
}

export const setIdeaCountAsPerRoleState = (data) => {
    return {
        type: types.SET_IDEA_COUNT_AS_PER_ROLE,
        payload: data
    }
}


export const setPendingPatentIdeaCount = (data) => {
    return {
        type: types.SET_PENDING_PATENT_IDEA_COUNT,
        payload: data
    }
} 

export const setPendingIdeaCount = (data) => {
    return {
        type: types.SET_PENDING_IDEA_COUNT,
        payload: data
    }
}

export const setPendingCollaborationCount = (data) => {
    return {
        type: types.SET_COLLABORATION_PENDING_IDEA_COUNT,
        payload: data
    }
}

export const setIsContractorLoggedin = (isContractorLoggedIn) => {
    return {
        type: types.SET_CONTRACTOR_LOGGED_IN,
        payload: isContractorLoggedIn
    }
}

export const setActiveContestDetails = (activeContestDetails) => {
    return {
        type: types.SET_ACTIVE_CONTEST_DETAILS,
        payload: activeContestDetails
    }
}

export const setV360ContestId = (contestId) => {
    return {
        type: types.SET_V360_CONTEST_ID,
        payload: contestId
    }
}

export const setV360ContestIdFy23 = (contestId) => {
    return {
        type: types.SET_V360_CONTEST_ID_FY23,
        payload: contestId
    }
}

export const setV360ContestIdFy24 = (contestId) => {
    return {
        type: types.SET_V360_CONTEST_ID_FY24,
        payload: contestId
    }
}



