import {types} from './types';

export const fetchToken=()=>{
    return {
        type: types.SEND_TOKEN_REQUEST,
    }
}


export const fetchTokenSuccess=(data)=>{
    
    return {
        type: types.SEND_TOKEN_REQUEST_SUCCESS,
        payload:data
    }
}

export const fetchTokenFailure=(error)=>{
    // console.log("inside token error",error)
    return {
        type: types.SEND_TOKEN_REQUEST_FAILURE,
        payload:error
    }
}

