import React, { useEffect, useState, useRef } from "react";
import { Avatar, Tooltip, Spinner, Button, Text,
    Menu, MenuTrigger, MenuPopover, MenuList, MenuItem 
 } from '@fluentui/react-components';
import { EditRegular, DeleteRegular, EyeRegular, ArrowRightRegular, MoreVerticalRegular } from "@fluentui/react-icons";
import { List } from "@fluentui/react";
import "./MiddlePanel.scss";
// import VideoFeed from "../../../PromotionalFeed/VideoFeed";
var uniqid = require('uniqid');
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import IdeaComment from '../../../my-space/idea-comment';
import TextAreaInput from '../../../../common/TextAreaInput';

interface IdeaFeedMobileProps{
    ideaData?:any,
    showCopyIdea?:any,
    onCopyIdeaClick?:any,
     ideaFeedClick?:any, 
     isExploreIdea?:any, 
     enterpriseId?:any,
     onShareClick?:any, 
     onPinClick?:any, 
     pinned?:any,
    handleOnClick?:any, 
    selectedList?:any, 
    allTags?:any, 
    isIdeaLiked?:any, 
    onLikeClick?:any, 
    likeCount?:any, 
    onCommentClick?:any, 
    ideaDetails?:any,
    onShareCountClick?:any,
    fromSerialInnovator?: any,
    openAcceptedPopup?: any,
    openRejectedPopup?: any,
    showMessages?: any,
    isDisabled?: any,
    enterprise?: any,
    setAddComment?: any,
    addComment?: any,
    addCommentToIdea?: any,
    newCommentLoader?: any,
    socialComments?: any,
    loadSocialMoreShow?: any,
    socialCommentsShowMore?: any,
    socialCommentsPage?: any,
    addCommentToSharedIdea?: any,
    privateIdeaSharedComments?: any,
    loadPrivateMoreShow?: any,
    privateCommentsShowMore?: any,
    privateCommentsPage?: any,
    showCommentBox?: any,
    participantComments?: any,
    loadParticipantMoreShow?: any,
    participantCommentsShowMore?: any,
    participantCommentsPage?: any,
    commentBasedFilter?:any,
    fromMyStories?:any

}
const IdeaFeedMobile = ({ ideaData,showCopyIdea,onCopyIdeaClick, ideaFeedClick, isExploreIdea, enterpriseId, onShareClick, onPinClick, pinned,
    handleOnClick, selectedList, allTags, isIdeaLiked, onLikeClick, likeCount, onCommentClick, ideaDetails,
    onShareCountClick, fromSerialInnovator, openAcceptedPopup, openRejectedPopup, showMessages, isDisabled,
    enterprise, setAddComment, addComment, addCommentToIdea, newCommentLoader, socialComments, loadSocialMoreShow,
    socialCommentsShowMore, socialCommentsPage, addCommentToSharedIdea, privateIdeaSharedComments, loadPrivateMoreShow,
    privateCommentsShowMore, privateCommentsPage, showCommentBox, participantComments, loadParticipantMoreShow, participantCommentsShowMore, participantCommentsPage,commentBasedFilter,fromMyStories }:IdeaFeedMobileProps) => {
    const moreItemsBox = useRef(null);
    const [showMoreList, setShowMoreList] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<any>(-1);


    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, false);
        return () => {
            document.removeEventListener("click", handleOutsideClick, false);
        };
    }, [])

    const onMoreClick = (event) => {
        event.preventDefault();
        setShowMoreList(oldData => !oldData);
    }

    const handleOutsideClick = (event) => {
        if (moreItemsBox.current && moreItemsBox.current?.contains(event.target)) {
            // console.log("event.target", event.target);
            //clickedInd ? setClickedInd(prevClicked => !prevClicked) : clickedTech ? setClickedTech(prevClicked => !prevClicked) : null;
            //setShowMoreList(true);
        }
        else {
            setShowMoreList(false);
        }
    }

    const setIdeaStatusClass = () => {
        let status = "";
        if (ideaData.IdeaWorkFlowStatus) {
            status = ideaData['IdeaWorkFlowStatus']
        } else if (ideaData['IdeaStatus']) {
            status = ideaData['IdeaStatus']
        } else if (ideaData['PrivateIdeaId']) {
            status = 'Private';
        } else if (ideaData['promotionalfeedid']) {
            status = 'Promotional';
        }

        return "idea-status " + status.split(" ").join("").toLowerCase();
    }

    const setIdeaStatus = () => {
        if (ideaData.IdeaWorkFlowStatus) {
            return ideaData['IdeaWorkFlowStatus']
        } else if (ideaData['IdeaStatus']) {
            return ideaData['IdeaStatus']
        } else if (ideaData['PrivateIdeaId']) {
            return 'Private';
        } else if (ideaData['promotionalfeedid']) {
            return 'Promotional';
        }
    }

    const seeMore = (s) => {
        s.target.parentElement.parentElement.getElementsByClassName("collapse-text")[0].classList.toggle('expand-text');

        if (s.target.innerHTML == "see more") {
            s.target.innerHTML = "see less";
        }
        else {
            s.target.innerHTML = "see more";
        }
    }

    const handleInitials = (CreatedBy) => {
        if (CreatedBy) {
            var res = CreatedBy.split(".");
            let nameInitials = (res[res.length - 1].charAt(0) + res[0].charAt(0)).toUpperCase();
            return nameInitials;
        }
        else {
            return "";
        }
    }

    const convertArrayInString = (data: string) => {
        if (data) {
            let newString: any = data.toString();
            let SharedPeopleList: Array<any> = [];
            newString = newString.split(',');
            SharedPeopleList = newString.map(item => {
                return ({
                    key: item,
                    media: (
                        <Avatar name={item.split('.').join(" ").toUpperCase()} />
                    ),
                    header: item.split('.').join(" ")
                })
            });

            return SharedPeopleList;
        }

    }

    const getCreatedDateOfFeed = (ideaType) => {
        if (ideaType === "popular") {
            return new Date(ideaData.CreatedDtm.slice(0, 10)).toDateString().slice(4);
        }
        if (ideaType === "private") {
            return new Date(ideaData.CreatedDtm.slice(0, 10)).toDateString().slice(4);
        }
        if (ideaType === "shared") {
            let sharedUserDetails = ideaData.SharedUserDetails.filter(user => user.EnterprisId === enterpriseId);
            if (sharedUserDetails.length > 0 && sharedUserDetails[0]?.ShareResourceDtm !== null) {
                return new Date(sharedUserDetails[0].ShareResourceDtm.slice(0, 10)).toDateString().slice(4);
            }
            else {
                return new Date(ideaData.CreatedDtm.slice(0, 10)).toDateString().slice(4);
            }
        }
        if (ideaType === "myidea") {
            return new Date(ideaData.CreatedDtm.slice(0, 10)).toDateString().slice(4);
        }
    }
    const [itemsToShow, setItemsToShow] = useState(2);
    const [expanded, setExpanded] = useState(false);

    const showMore = () => {
        itemsToShow === 2 ? (
            setItemsToShow(allTags.length),
            setExpanded(true)
        ) : (
                setItemsToShow(2),
                setExpanded(false)
            )
    }

    return (
        <>
        <div className="idea-feed box-feed-entry-wrapper" onClick={ideaFeedClick}>
                {ideaData.isPopularIdeas || ideaData.IsPopularIdea ?
                    <i className="ms-Icon ms-Icon--SingleBookmarkSolid" aria-hidden="true"></i>
                //<Tooltip pointing={true} content="Popular" trigger={
                //    } position="above" />
                : ""}
            <div className="idea-top-action">
                <ul>
                        {(!isExploreIdea || fromMyStories) ?
                        <li><span className={setIdeaStatusClass()}>{setIdeaStatus()}</span></li> : null}

                    {(ideaData.isPromotionalFeed
                            || (ideaData.IsPopularIdea && !(ideaData['CreatedBy'] === enterpriseId || ideaData.CoInnovators?.includes(enterpriseId)))
                        || (['Shared with Me', 'Draft'].includes(ideaData['IdeaWorkFlowStatus']) || ['Shared with Me', 'Draft'].includes(ideaData['IdeaStatus'])) || !(ideaData['CreatedBy'] === enterpriseId || ideaData.CoInnovators?.includes(enterpriseId)))
                        ? null :
                        <li>
                                <a href="" onClick={(event) => onShareClick(event, ideaData.IdeaId)}>
                                    <span className="icon Icon--Share">
                                        <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                                            <g fill="none"><path d="M12 5.502a2.502 2.502 0 1 1 .531 1.541L7.915 9.351a2.504 2.504 0 0 1 0 1.297l4.618 2.31a2.5 2.5 0 1 1-.448.894l-4.618-2.31a2.5 2.5 0 1 1 0-3.086l4.617-2.307A2.505 2.505 0 0 1 12 5.502zM14.502 4a1.501 1.501 0 1 0 0 3.003a1.501 1.501 0 0 0 0-3.003zm-.002 9a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM4 10a1.5 1.5 0 1 0 3 0a1.5 1.5 0 0 0-3 0z" fill="#626262" /></g><rect id="share" x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                        </svg>
                                    </span>
                                </a>
                            {/*<Tooltip pointing={true} content="Share" trigger={*/}
                            {/*    } position="above" />*/}
                        </li>
                    }
                    <li>
                            <a href="" onClick={(event) => onPinClick(event, ideaData.IdeaId ? ideaData.IdeaId : (ideaData.PrivateIdeaId ? ideaData.PrivateIdeaId : ideaData.promotionalfeedid), ideaData.PrivateIdeaId ? 'private' : 'public')}>

                                <span className={pinned}>
                                    <svg className="pin-outline" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                        <g fill="none"><path d="M10.122 3.138a2 2 0 0 1 3.203-.52l4.057 4.057a2 2 0 0 1-.52 3.203l-3.458 1.73a1.5 1.5 0 0 0-.722.784l-1.436 3.59a1 1 0 0 1-1.636.335L7 13.707L3.707 17H3v-.707L6.293 13l-2.61-2.61a1 1 0 0 1 .335-1.636l3.59-1.436a1.5 1.5 0 0 0 .785-.722l1.73-3.458zm2.496.187a1 1 0 0 0-1.601.26l-1.73 3.458A2.5 2.5 0 0 1 7.98 8.247L4.39 9.683l5.927 5.927l1.436-3.59a2.5 2.5 0 0 1 1.204-1.307l3.458-1.73a1 1 0 0 0 .26-1.601l-4.057-4.057z" fill="#626262" /></g><rect id="pin" x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                    </svg>

                                    <svg className="pin-filled" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                                        <g fill="none"><path d="M10.059 2.445a1.5 1.5 0 0 0-2.386.354l-2.02 3.79l-2.811.937a.5.5 0 0 0-.196.828L4.793 10.5l-2.647 2.647L2 14l.853-.146L5.5 11.207l2.146 2.147a.5.5 0 0 0 .828-.196l.937-2.81l3.779-2.024a1.5 1.5 0 0 0 .354-2.38L10.06 2.444z" fill="#626262" /></g><rect id="pin" x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
                                    </svg>
                                </span>

                            </a>
                        {/*<Tooltip pointing={true} content="Pin" trigger={*/}
                        {/*    } position="above" />*/}
                    </li>
                    {ideaData.isPromotionalFeed ? null : <li ref={moreItemsBox} style={{ margin: "0px"}}>
                        <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    {/* <Tooltip withArrow content="More" relationship='label'
                                        appearance='inverted'> */}
                                        <Button appearance='transparent' size='small' style={{ padding: '0px' }}
                                        icon={<MoreVerticalRegular fontSize={16} color='#a341ed' onClick={(event) => onMoreClick(event)}/>}></Button>
                                    {/* </Tooltip> */}
                                </MenuTrigger>

                                <MenuPopover style={{ position: 'absolute', top: '100%', left: '0' }}>
                                    <MenuList>
                                        {selectedList.map((item) => (
                                            <MenuItem key={item.key} icon={item.media} onClick={(event) => handleOnClick(event, item)}>{item.content}</MenuItem>
                                        ))
                                        }
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                    </li>}
                </ul>
            </div>
            <h3 data-ideaid={ideaData.IdeaId} data-promotionalfeedid={ideaData.promotionalfeedid}
                data-privateideaid={ideaData.PrivateIdeaId} data-getideatilldate={ideaData.GetIdeaTillDate}
                data-ideastatus={ideaData.IdeaStatus} data-ideaworkflowstatus={ideaData.IdeaWorkFlowStatus}
                data-ispopularideas={ideaData.isPopularIdeas} data-ispromotionalfeed={ideaData.isPromotionalFeed} >
                {ideaData.Title ? ideaData.Title : (ideaData.title ? ideaData.title : ideaData.IdeaTitle)}
            </h3>
            <h6>{ideaData.ContestNm ? ideaData.ContestNm : ""}</h6>
            <p className="collapse-text margin-bottom-5">{ideaData.Description ? ideaData.Description : ideaData.CurrentSituation}</p>
            {
                ideaData.Description != null && ideaData.Description.length > 200 ?
                    <p className="margin-bottom-5"><a id="seemore" className="see-more" onClick={seeMore}>see more</a></p> :
                    ideaData != null && ideaData.CurrentSituation != null && ideaData.CurrentSituation.length > 200 ?
                        <p className="margin-bottom-5"><a id="seemore" className="see-more" onClick={seeMore}>see more</a></p> :
                        ""
            }

            {
                ideaData.isPromotionalFeed || ideaData.promotionalfeedid ? 
                <></>
                // <VideoFeed promotionalfeedid={ideaData.promotionalfeedid} mediaFiles={ideaData['mediafiles']} /> 
                : ''
            }

            <div className="preference-tag">
                {allTags.filter((tag, i) => i <= 1).map((tag) => {
                    return <div className="skill-tags fs-13" key={uniqid()}>{tag}</div>;
                })}

                {allTags?.length > 2 ? (
                        <a onClick={(e) => { e.stopPropagation(); showMore()}} className="show-more-link-mobile">
                            {!expanded ? <span>+{allTags.length - 2} more</span> : null}
                        </a>
                        ) : null}
                        {expanded
                        ? allTags
                            .slice(2, allTags.length)
                            .map((tag) => (
                                <div className="skill-tags fs-13">{tag}</div>
                            ))
                        : null}

                        <a onClick={(e) => { e.stopPropagation(); showMore()}} className="show-more-link-mobile show-less-link-mobile">
                        {expanded ? <span>Show less</span> : null}
                        </a>

                {/* {allTags?.length > 2 ?
                    <Tooltip
                        content={allTags.slice(
                            2,
                            allTags.length
                        ).map((tag) => (
                            <div className="skill-tags fs-13" key={uniqid()}>
                                {tag}
                            </div>
                        ))}
                        position="below"
                        trigger={
                            <span className="more">{`+${allTags.length - 2} more`}</span>
                        }
                    />
                    : null} */}
            </div>
            {
                ideaData.isPromotionalFeed || ideaData.promotionalfeedid ? null :
                    <div>
                        {!fromMyStories ?
                        <div className="idea-feed-date">
                            {fromSerialInnovator || isExploreIdea ? <h6>Submitted on {new Date(ideaData.CreatedDtm.slice(0, 10)).toDateString().slice(4)}</h6> :
                                <>
                                    {ideaData.IsPopularIdea && ideaData.ShareduserCount == 0 ? <h6>Submitted on {getCreatedDateOfFeed("popular")}</h6> : null}
                                    {ideaData.PrivateIdeaId && ideaData.ShareduserCount == 0 ? <h6>Created on {getCreatedDateOfFeed("private")}</h6> : null}
                                    {ideaData.IdeaId && !ideaData.PrivateIdeaId && !ideaData.IsPopularIdea && ideaData.ShareduserCount == 0 ? <h6>Submitted on {getCreatedDateOfFeed("myidea")}</h6> : null}
                                    {ideaData.ShareduserCount > 0 ? <h6>Shared on {getCreatedDateOfFeed("shared")}</h6> : null}
                                </>
                            }
                        </div> : null}
                        <ul className="feedback-btns">
                            <li>
                                {!ideaData.isPromotionalFeed ?
                                    <Avatar size={28} 
                                    name={ideaData.CreatedBy.split('.').join(" ").toUpperCase()} />
                                : null}
                            </li>

                            {(ideaData.isPromotionalFeed || ideaData.IdeaStatus?.toLowerCase() === 'draft' || ideaData.IdeaWorkFlowStatus === 'draft') ? null :
                                <li>

                                    {
                                        isIdeaLiked ?
                                            <a href="" onClick={(event) => onLikeClick(event, ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId)}>
                                                <i id="like" className="ms-Icon ms-Icon--LikeSolid" aria-hidden="true"></i>
                                            {/*<Tooltip pointing={true} content="Like" trigger={} position="above" styles={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} />*/}
                                            <span id="like">{likeCount ? likeCount : 0}</span>
                                            </a> :
                                            <a href="" onClick={(event) => onLikeClick(event, ideaData.IdeaId ? ideaData.IdeaId : ideaData.PrivateIdeaId)}>
                                                <span className="icon Icon--Like">
                                                    <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                        <g fill="none"><path d="M10.052 2.294c.34-.977 1.632-1.427 2.43-.59c.164.17.326.354.436.519c.32.48.455 1.113.504 1.73c.05.627.016 1.301-.048 1.912c-.065.612-.161 1.174-.241 1.583l-.01.052h.883a3 3 0 0 1 2.952 3.537l-.684 3.761a4.5 4.5 0 0 1-5.612 3.537l-5.6-1.527a2.5 2.5 0 0 1-1.793-1.922l-.353-1.765c-.278-1.389.784-2.559 1.913-3.005a2.84 2.84 0 0 0 .84-.49c1.707-1.514 2.325-2.723 3.385-4.85c.354-.71.718-1.676.998-2.482zm1.965 5.585v-.002l.002-.007l.007-.031a13.699 13.699 0 0 0 .126-.584c.076-.388.167-.92.227-1.495c.061-.577.09-1.184.046-1.728c-.044-.556-.16-.985-.34-1.255a3.258 3.258 0 0 0-.326-.383c-.199-.208-.628-.16-.762.228c-.283.814-.664 1.83-1.048 2.6c-1.067 2.141-1.756 3.502-3.616 5.152c-.34.302-.744.517-1.136.672c-.88.348-1.447 1.148-1.3 1.879l.352 1.765a1.5 1.5 0 0 0 1.077 1.153l5.6 1.527a3.5 3.5 0 0 0 4.364-2.75l.684-3.762A2 2 0 0 0 14.006 8.5h-1.505a.5.5 0 0 1-.484-.621z" fill="#626262" /></g><rect id="like" x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                    </svg>
                                                </span>
                                                {/*<Tooltip pointing={true} content="Like" trigger={*/}
                                                {/*   } position="above" />*/}
                                                <span id="like">{likeCount ? likeCount : 0}</span>
                                            </a>
                                    }
                                </li>}
                            {(ideaData.isPromotionalFeed || ideaData.IdeaStatus?.toLowerCase() === 'draft' || ideaData.IdeaWorkFlowStatus === 'draft') ? null :
                                <li>
                                    <a href="" onClick={(event) => onCommentClick(event, ideaData.IdeaId
                                        ? ideaData.IdeaId : ideaData.PrivateIdeaId, ideaData)}>
                                        <span className="icon Icon--CannedChat">
                                            <svg width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16" >
                                                <g fill="none"><path d="M1 4.5A2.5 2.5 0 0 1 3.5 2h9A2.5 2.5 0 0 1 15 4.5v5a2.5 2.5 0 0 1-2.5 2.5H8.688l-3.063 2.68A.98.98 0 0 1 4 13.942V12h-.5A2.5 2.5 0 0 1 1 9.5v-5zM3.5 3A1.5 1.5 0 0 0 2 4.5v5A1.5 1.5 0 0 0 3.5 11H5v2.898L8.312 11H12.5A1.5 1.5 0 0 0 14 9.5v-5A1.5 1.5 0 0 0 12.5 3h-9z" fill="#626262" /></g><rect id="comment" x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
                                            </svg>
                                        </span>
                                        {/*<Tooltip pointing={true} content="Comment" trigger={*/}
                                        {/*    } position="above" styles={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} />*/}
                                        <span id="comment">{!commentBasedFilter? ((ideaData.IdeaCoOwnerFlag || ideaData.IdeaCoOwnerFlag === undefined) ? ideaDetails.IdeaCommentCount ? ideaDetails.IdeaCommentCount : 0 : ideaDetails.SocialCommentCount ? ideaDetails.SocialCommentCount : 0): commentBasedFilter && ideaDetails.IdeaCommentCount ? ideaDetails.IdeaCommentCount :0}</span>
                                    </a>
                                </li>}

                            {(ideaData.PrivateIdeaId || ideaData.isPromotionalFeed) ? null :
                                <li>

                                    <a href="" onClick={(event) => { event.preventDefault(); }}>
                                        <span className="icon Icon--People">
                                            <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                <g fill="none"><path d="M11 10a2 2 0 0 1 2 2v1.5c0 2.054-2.088 3.5-5.5 3.5S2 15.554 2 13.5V12a2 2 0 0 1 2-2h7zm0 1H4a1 1 0 0 0-1 1v1.5C3 14.907 4.579 16 7.5 16c2.921 0 4.5-1.093 4.5-2.5V12a1 1 0 0 0-1-1zm5-1a2 2 0 0 1 2 2v.5c0 2.089-1.568 3.5-4.5 3.5c-.141 0-.28-.003-.414-.01c.208-.242.382-.502.522-.781l.097-.213h.039C15.976 14.931 17 13.96 17 12.5V12a1 1 0 0 0-1-1h-2.171a3 3 0 0 0-.594-1H16zM7.5 2a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm7 2a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm-7-1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zm7 2a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z" fill="#626262" /></g><rect id="team" x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                            </svg>
                                        </span>
                                        {/*<Tooltip pointing={true} content="Key Members" trigger={*/}
                                        {/*    } position="above" />*/}
                                        {ideaData.CoInnovators == 0 ? <span id="team">0</span> :
                                            <span id="team">{ideaData.CoInnovators ? ideaData.CoInnovators.length : 0}</span>
                                            //<Tooltip pointing={true} content={<List id="team" items={convertArrayInString(ideaData.CoInnovators)} className="member-list" />} trigger={} position="below" styles={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} />
                                        }
                                    </a>
                                </li>
                            }

                                {(ideaData.PrivateIdeaId || ideaData.isPromotionalFeed || ideaData.IdeaStatus?.toLowerCase() === 'draft' || ideaData.IdeaWorkFlowStatus === 'draft' || ideaData.IsDynamicIdea || ideaData.ContestNm.toLowerCase().includes("v360"))? null :
                                    (showCopyIdea ? 
                                        <li>
                                         <a href="" onClick={(event) => onCopyIdeaClick(event, ideaData.IdeaId ? ideaData.IdeaId : null)}>
                                            <Tooltip withArrow content="Copy Idea" relationship="label"
                                            appearance="inverted" positioning="above">
                                            <span className = "icon Icon--Copy">
                                               <svg  aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path d="M29.5 7h-19A1.5 1.5 0 0 0 9 8.5v24a1.5 1.5 0 0 0 1.5 1.5h19a1.5 1.5 0 0 0 1.5-1.5v-24A1.5 1.5 0 0 0 29.5 7zM29 32H11V9h18z"  fill="currentColor"/><path d="M26 3.5A1.5 1.5 0 0 0 24.5 2h-19A1.5 1.5 0 0 0 4 3.5v24A1.5 1.5 0 0 0 5.5 29H6V4h20z" fill="currentColor"/></svg>
                                               </span>
                                            </Tooltip>
                                          
                                        </a>
                                    </li> :null )
                                }
                            {ideaData.PrivateIdeaId ? <li>
                                <a href="" onClick={(event) => onShareCountClick(event)}>
                                    <span className="icon Icon--People">
                                        <svg id="team" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                                            <g fill="none">
                                                <path d="M8.75 3.75a2.75 2.75 0 1 0-5.5 0a2.75 2.75 0 0 0 5.5 0zm-4.5 0a1.75 1.75 0 1 1 3.5 0a1.75 1.75 0 0 1-3.5 0z" fill="#626262" /><path d="M2.5 7.5h4.183c-.164.31-.286.646-.358 1H2.5A.5.5 0 0 0 2 9v.5c0 1.26 1.099 2.614 3.096 2.93c-.322.22-.59.513-.781.854C2.205 12.713 1 11.087 1 9.5V9a1.5 1.5 0 0 1 1.5-1.5z" fill="#626262" /><path d="M7.879 7.5c.504-.61 1.267-1 2.121-1a2.744 2.744 0 0 1 2.646 2a2.753 2.753 0 0 1-3.893 3.202A2.75 2.75 0 0 1 7.88 7.5zm.54 1a1.75 1.75 0 1 0 3.164 1.5a1.75 1.75 0 0 0-3.165-1.5z" fill="#626262" /><path d="M15.685 13.284a2.513 2.513 0 0 0-.781-.853C16.9 12.114 18 10.759 18 9.5V9a.5.5 0 0 0-.5-.5h-3.825a3.726 3.726 0 0 0-.357-1H17.5A1.5 1.5 0 0 1 19 9v.5c0 1.587-1.206 3.212-3.315 3.784z" fill="#626262" /><path d="M14.487 13.37c-.264-.23-.609-.37-.987-.37h-7A1.496 1.496 0 0 0 5 14.5v.5c0 1.971 1.86 4 5 4c3.14 0 5-2.029 5-4v-.5c0-.45-.198-.854-.513-1.13zM6 14.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v.5c0 1.438-1.432 3-4 3s-4-1.562-4-3v-.5z" fill="#626262" /><path d="M14 1a2.75 2.75 0 1 1 0 5.5A2.75 2.75 0 0 1 14 1zm0 1a1.75 1.75 0 1 0 0 3.5A1.75 1.75 0 0 0 14 2z" fill="#212121" />
                                            </g>
                                        </svg>
                                    </span>
                                    {/*<Tooltip pointing={true} content="Share Count" trigger={*/}
                                    {/*} position="above" />*/}
                                    {ideaDetails.ShareduserCount == 0 ? <span id="team">0</span> :
                                        <span id="team">{ideaDetails.ShareduserCount ? ideaDetails.ShareduserCount : 0}</span>
                                        //<Tooltip pointing={true} content={<List items={convertArrayInString(ideaDetails.SharedUserDetails?.map(user => user.EnterprisId))} className="member-list" />} trigger={} position="below" styles={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} />
                                    }
                                </a>

                            </li> : null
                            }

                            {ideaData.PrivateIdeaId && ideaData.MediaFiles && ideaData.MediaFiles.length ?
                                <li>
                                    <a href="" onClick={(event) => { event.preventDefault(); }}>
                                        {/*<Tooltip pointing={true} content={<List items={ideaData.MediaFiles.map(file => file.FileNm)} className="private-idea-attachment-list" />}*/}
                                        {/*    trigger={} position="above" />*/}
                                        <span className="icon scribble-attach">
                                            <svg xmlns="" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                                                <g fill="none"><path d="M3.257 8.707l4.597-4.596a2.5 2.5 0 0 1 3.535 3.536L6.086 12.95a1 1 0 0 1-1.414-1.414l5.303-5.304a.5.5 0 0 0-.707-.707L3.964 10.83a2 2 0 1 0 2.829 2.828l5.303-5.303a3.5 3.5 0 0 0-4.95-4.95L2.55 8a.5.5 0 1 0 .707.707z" fill="#626262"></path></g><rect id="attachment" x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)"></rect>
                                            </svg>
                                        </span>
                                        <span id="attachment">{ideaData.MediaFiles?.length}</span>
                                    </a>
                                </li> : ''}
                        </ul>
                    </div>
            }
            {
                ideaData.IsImplementerIdea && ideaData.IsImplementer && (ideaData?.IdeaWorkFlowStatus?.toUpperCase() === "UNDER IMPLEMENTATION" ||
                    ideaData?.IdeaWorkFlowStatus?.toUpperCase() === "READY FOR IMPLEMENTATION") ?
                    <div className="implementer-actions">
                        <div className="implementer-content">
                            <span>Collaborate to become implementer</span>
                        </div>
                        <div className="action-wrapper">
                            
                            <Button
                                className="btn-primary reject-btn"
                                onClick={(event) =>
                                    openRejectedPopup(event)
                                }
                                id="reject"
                            >Reject</Button>
                            <Button
                                appearance="primary"
                                className="btn-primary accept-btn"
                                onClick={(event) =>
                                    openAcceptedPopup(event)
                                }
                                id="accept"
                            >Accept</Button>
                        </div>
                    </div> : ""
            }
            {
                ideaData.IsImplementerIdea && ideaData?.Implementers &&
                    ideaData?.Implementers?.find(x => x.ImplementerId === enterpriseId) &&
                    ideaData?.Implementers?.find(x => x.ImplementerId === enterpriseId)?.Status != "UnderAcceptance" ?
                    <div className="implementer-actions">
                        {
                            ideaData?.Implementers?.find(x => x.ImplementerId === enterpriseId)?.Status === "Accept" ?
                                <div className="accepted-status">
                                    <span>ACCEPTED</span>
                                </div> : <div className="rejected-status">
                                    <span>REJECTED</span>
                                </div>
                        }
                    </div> : ""
            }
        </div>
        {
        showMessages ?
            <div className="idea-feed comment-entry-wrapper">
                {ideaData.IsPopularIdea ?
                    <Tabs defaultActiveKey="social" id="uncontrolled-tab-example" class="comments-tab" >
                        <Tab eventKey="social" title="Social Comments">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <div className="comment-avatar">
                                                <Avatar name={enterprise} size={32} />  
                                    </div>
                                    <div className="add-comment margin-bottom-10">
                                        <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                            name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                            className="" maxLength={500} disabled={isDisabled} />
                                        <a className="send-comment" href="" onClick={(event) => addCommentToIdea(event, ideaData.IdeaId, false)}>
                                            <span className="icon Icon--Send">
                                                <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                    <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div> 
                                    {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                    {socialComments && socialComments.length > 0 ?
                                        (<>
                                            {socialComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                            {loadSocialMoreShow ?
                                                <div className="loadbtn-Section">
                                                    <Button onClick={socialCommentsShowMore} className="load-more">
                                                        <Text className="load-btn">Load More Comments</Text>
                                                    </Button>
                                                </div>
                                                :
                                                socialCommentsPage !== 1 ?
                                                    <div className="loadbtn-Section">
                                                        <Button onClick={socialCommentsShowMore} className="load-more">
                                                            <Text className="load-btn">Load Less Comments</Text>
                                                        </Button>
                                                    </div>: null}
                                        </>)
                                        : <div className="loadbtn-Section">
                                                    <Text style={{ fontSize: '12px !important'}}>No Comments Available</Text>
                                        </div>}
                                </div>
                            </div>
                        </Tab>
                    </Tabs> : ""}

                {ideaData.PrivateIdeaId ?
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <div className="comment-avatar">
                                    <Avatar name={enterprise} size={32} />
                                </div>
                                <div className="add-comment margin-bottom-10">
                                    <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                        name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                        className="" maxLength={500} disabled={isDisabled} />
                                    <a className="send-comment" href="" onClick={(event) => addCommentToSharedIdea(event, ideaData.PrivateIdeaId)}>

                                        <span className="icon Icon--Send">
                                            <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                                </div> 
                            {/*<div style={{ maxHeight: '150px', overflowY: 'auto' }}>*/}
                            {/*    {newCommentLoader ? <Loader label="Loading" labelPosition="end" size="smaller" /> : null}*/}
                            {/*{privateIdeaSharedComments && privateIdeaSharedComments.length > 0 ? privateIdeaSharedComments.sort(function (o) { return o.CommentDt }).map((comment) => {*/}
                            {/*    return <IdeaComment comment={comment} enterprise={name} />*/}
                            {/*}) : <Text content="No Comments Available" styles={{ fontSize: '1vw !important', marginLeft: '16vw' }} />}*/}
                            {/*</div>*/}
                            <div>
                                {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                {privateIdeaSharedComments && privateIdeaSharedComments.length > 0 ?
                                    (<>
                                        {privateIdeaSharedComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                        {loadPrivateMoreShow ?
                                                    <div className="loadbtn-Section">
                                                        <Button onClick={privateCommentsShowMore} className="load-more">
                                                            <Text>Load More Comments</Text>
                                                        </Button>
                                                    </div>
                                            :
                                                    privateCommentsPage !== 1 ?
                                                        <div className="loadbtn-Section">
                                                            <Button onClick={privateCommentsShowMore} className="load-more">
                                                                <Text>Load Less Comments</Text>
                                                            </Button>
                                                        </div> : null}

                                    </>)
                                    : <div className="loadbtn-Section">
                                                <Text style={{ fontSize: '12px !important'}}>No Comments Available</Text>
                                    </div>}
                            </div>
                        </div>
                    </> : ""}

                {ideaData.IsPopularIdea == false && ideaData.isPrivateIdea == undefined && ideaData.IdeaWorkFlowStatus === "Shared with Me" ?
                    <Tabs defaultActiveKey="social" id="uncontrolled-tab-example" class="comments-tab" >
                        <Tab eventKey="social" title="Social Comments">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <div className="comment-avatar">
                                        <Avatar name={enterprise} size={32} />
                                    </div>
                                    <div className="add-comment margin-bottom-10">
                                        <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                            name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                            className="" maxLength={500} disabled={isDisabled} />
                                        <a className="send-comment" href="" onClick={(event) => addCommentToIdea(event, ideaData.IdeaId, false)}>

                                            <span className="icon Icon--Send">
                                                <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                    <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                    {socialComments && socialComments.length > 0 ?
                                        (<>
                                            {socialComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                             {loadSocialMoreShow ?
                                                <div className="loadbtn-Section">
                                                    <Button onClick={socialCommentsShowMore} className="load-more">
                                                        <Text>Load More Comments</Text>
                                                    </Button>
                                                </div>
                                                :
                                                socialCommentsPage !== 1 ?
                                                    <div className="loadbtn-Section">
                                                        <Button onClick={socialCommentsShowMore} className="load-more">
                                                            <Text>Load Less Comments</Text>
                                                        </Button>
                                                    </div>
                                                    : null}
                                        </>)
                                        :
                                        <div className="loadbtn-Section">
                                                    <Text style={{ fontSize: '12px !important' }}>No Comments Available</Text>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Tab>
                    </Tabs> : ""}
                {ideaData.IsPopularIdea == false && ideaData.isPrivateIdea == undefined && ideaData.IdeaId && ideaData.IdeaWorkFlowStatus !== "Shared with Me" ?
                    <Tabs defaultActiveKey="social" id="uncontrolled-tab-example" class="comments-tab" >
                        <Tab eventKey="social" title="Social Comments">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {showCommentBox && showCommentBox.SocialCommentBox ?
                                    <div> 
                                        <div className="comment-avatar">
                                            <Avatar name={enterprise} size={32} />
                                        </div>
                                        <div className="add-comment margin-bottom-10">
                                            <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                                name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                                className="" maxLength={500} disabled={isDisabled} />
                                            <a className="send-comment" href="" onClick={(event) => addCommentToIdea(event, ideaData.IdeaId, false)}>

                                                <span className="icon Icon--Send">
                                                    <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                        <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div> : null}
                                <div>
                                    {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                    {socialComments && socialComments.length > 0 ?
                                        (<>
                                            {socialComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                            {loadSocialMoreShow ?
                                                <div className="loadbtn-Section">
                                                    <Button onClick={socialCommentsShowMore} className="load-more">
                                                        <Text>Load More Comments</Text>
                                                    </Button>
                                                </div>
                                                :
                                                socialCommentsPage !== 1 ?
                                                    <div className="loadbtn-Section">
                                                        <Button onClick={socialCommentsShowMore} className="load-more">
                                                            <Text>Load Less Comments</Text>
                                                        </Button>
                                                    </div>: null}
                                        </>)
                                        :
                                        <div className="loadbtn-Section">
                                                    <Text style={{ fontSize: '12px !important'}}>No Comments Available</Text>
                                        </div>
                                        }
                                </div>
                            </div>
                        </Tab>
                        {showCommentBox && showCommentBox.ParticipantCommentBox ?
                            <Tab eventKey="participant" title="Participant Comments">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>
                                        <div className="comment-avatar">
                                            <Avatar name={enterprise} size={32} />
                                        </div>
                                        <div className="add-comment margin-bottom-10">
                                            <TextAreaInput key="Idea Comment" content="" onChangeField={(item) => setAddComment(item?.value)}
                                                name='ideaTitle' valueTextArea={addComment} size='medium' placeholder='Add a Comment'
                                                className="" maxLength={500} disabled={isDisabled} />
                                            <a className="send-comment" href="" onClick={(event) => addCommentToIdea(event, ideaData.IdeaId, true)}>

                                                <span className="icon Icon--Send">
                                                    <svg width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" >
                                                        <g fill="none"><path d="M2.721 2.051l15.355 7.566a.5.5 0 0 1 0 .897L2.72 18.08a.5.5 0 0 1-.704-.576l1.969-7.434l-1.97-7.442a.5.5 0 0 1 .705-.577zm.543 1.383l1.61 6.082l.062-.012L5 9.5h7a.5.5 0 0 1 .09.992L12 10.5H5a.506.506 0 0 1-.092-.008l-1.643 6.206l13.458-6.632L3.264 3.434z" fill="#626262" /></g><rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    {/*<div style={{ maxHeight: '150px', overflowY: 'auto' }}>*/}
                                    {/*{newCommentLoader ? <Loader label="Loading" labelPosition="end" size="smaller" /> : null}*/}
                                    {/*{participantComments && participantComments.length > 0 ? participantComments.sort(function (o) { return o.CommentDt }).map((comment) => {*/}
                                    {/*    return <IdeaComment comment={comment} enterprise={name} />*/}
                                    {/*}) : <Text content="No Comments Available" styles={{ fontSize: '1vw !important', marginLeft: '16vw' }} />}*/}
                                    {/*</div>*/}
                                    <div>
                                        {newCommentLoader ? <Spinner label="Loading" labelPosition="after" size="extra-small" /> : null}
                                        {participantComments && participantComments.length > 0 ?
                                            (<> 
                                                {participantComments.sort(function (o) { return o.CommentDt }).map((comment) => <IdeaComment comment={comment} enterprise={name} />)}
                                                {loadParticipantMoreShow ?
                                                    <div className="loadbtn-Section">
                                                        <Button onClick={participantCommentsShowMore} className="load-more">
                                                            <Text>Load More Comments</Text>
                                                        </Button>
                                                    </div>
                                                    :
                                                    participantCommentsPage !== 1 ?
                                                        <div className="loadbtn-Section">
                                                            <Button onClick={participantCommentsShowMore} className="load-more">
                                                                <Text>Load Less Comments</Text>
                                                            </Button> 
                                                        </div>: null}
                                            </>)
                                            :
                                            <div className="loadbtn-Section">
                                                        <Text style={{ fontSize: '12px !important'}}>No Comments Available</Text>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Tab>
                            : null}
                    </Tabs> : ""}
            </div> : ""
            }
            </>
        )
}

export default IdeaFeedMobile;