// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Dialog, DialogType } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import { Button, FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import TextAreaInput from './../../common/TextAreaInput';
import { AddCommentForIdea, updateImplementerAction } from "./../../api/IdeaApi";
import { PageLoader } from '../../common/PageLoader';
import { GenericMessageBar } from './../../common/MessageBar';
import "./../../explore-mentor-idea.scss";
import { useTeams } from "@microsoft/teamsfx-react";
import { StartImplementationPopup } from './StartImplementationPopup';
import { RootStateOrAny, useSelector } from "react-redux";

const modalProps = {
    isBlocking: true,
    styles: { main: { minWidth: '625px !important', maxHeight: '425px !important' } },
};

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: 'Idea Implementation Details',
};

const RejectPopup = ({ openModal, closeModal, ideaId, enterprise, isFromViewIdea }) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [errorCommentsMentor, setErrorCommentsMentor] = useState("");
    const [comments, setComments] = useState<string>('');
    const [showSpinner, setSpinnerData] = useState(false);
    const [choice, setChoice] = useState<string>("");
    const [msg, setMsg] = useState<string>("");
    const [{ inTeams, theme, context }] = useTeams();
    const [showAwaitingMentorPopup, setShowAwaitingMentorPopup] = useState<Boolean>(false);
    const peopleHierarchy = useSelector(
        (state: RootStateOrAny) => state.fetchDemographyReducer.ideaCreationData
    );
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    useEffect(() => {
        if (openModal) {
            toggleHideDialog();
        }
    }, [openModal]);

    const OnDiscardClick = () => {
        toggleHideDialog();
        closeModal(true);
    }

    const onSubmitClick = (event) => {
        event.preventDefault();
        if (comments && comments != null && comments != '') {
            setSpinnerData(true);
            updateImplementerAction(ideaId, 'Reject').then((response) => {
                if (response) {
                    // console.log('status updated - Reject');
                    addComments();
                }
                else {
                    console.log('Reject status update failed');
                }
            });
        }
        else {
            setErrorCommentsMentor("errorCommentsMentor");
            setMessageBar("Please add comment", "error");
        }
    }

    const setMessageBar = (errorMsg: string, type: string) => {
        // console.log(errorMsg);
        setMsg(errorMsg);
        setChoice(type);
    }

    const resetchoice = () => {
        setChoice(undefined);
    }

    const addComments = () => {
        if (comments && comments != null && comments != '') {
            const requestJSON = {
                CommentBy: enterprise,
                Comments: comments,
                IdeaId: ideaId,
                IsClientComment: false,
                IdeaCommentorHierarchy: peopleHierarchy
            };

            AddCommentForIdea(requestJSON, true).then((response) => {
                if (response) {
                    // console.log('Add comments successful!');
                    setSpinnerData(false);
                    setShowAwaitingMentorPopup(true);
                    toggleHideDialog();

                    if (!isFromViewIdea) {
                        closeModal(false);
                    }
                }
                else {
                    console.log('Add comments failed!');
                }
            });
        }
    }

    const onChangeFormField = (item) => {
        const { name, value } = item;
        setComments(value);
    }

    const closeAwaitingMentorPopUp = () => {
        setShowAwaitingMentorPopup(false);
    }

    return (
        <>
            {
                showAwaitingMentorPopup && isFromViewIdea ? <StartImplementationPopup openModal={showAwaitingMentorPopup}
                    closeModal={closeAwaitingMentorPopUp} text1="Response recorded. Innovator has been notified."
                    text2='' imageClassName="implementation-icon" /> : ''
            }

            <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps}
                modalProps={{...modalProps, className: isMobile ? "implementation-popup-mobile" : "implementation-popup"}}>
                {showSpinner ? <PageLoader hidden={false} /> : ""}
                <FluentProvider theme={teamsLightTheme}>
                    <GenericMessageBar choice={choice} msg={msg} resetMsg={resetchoice} />
                    <form className="">
                        <div className="implemetation-comments">
                            <div>
                                <div>
                                    <TextAreaInput onChangeField={onChangeFormField} content="Comments *"
                                        placeholder='Comments' size='medium' name='Message' valueTextArea={comments}
                                        maxLength={500} className={errorCommentsMentor} disabled={false} />
                                </div>
                            </div>
                        </div>
                        <div className="action-btn-wrapper">
                            <Button className="cancel-btn"
                                onClick={() => OnDiscardClick()} appearance="secondary">Cancel</Button>
                            <Button className="btn-primary submit-btn" content="Submit"
                                appearance="primary" onClick={(event) => onSubmitClick(event)}>Submit</Button>
                        </div>
                    </form>
                </FluentProvider>
            </Dialog >
        </>
    );
};

export default RejectPopup;