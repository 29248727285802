import React from "react";
import PropTypes from "prop-types";
import { Text, Textarea, Label } from '@fluentui/react-components';

interface TextAreaInputProps{
    maxLength:any, 
    onChangeField:any, 
    size:any, 
    placeholder:any, 
    content:any, 
    valueTextArea:any, 
    name:any, 
    disabled?:any,
    className:any,
    discardValue?:any;
}

const TextAreaInput = ({ maxLength, onChangeField, size, placeholder, content, valueTextArea='', name, disabled,className, discardValue}:TextAreaInputProps) => {

const handleChangeField = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const item = {name, value}
    if (value.length <= maxLength) {
        onChangeField(item);
    } else if (value.length > maxLength) {
        item.value = value.substr(0, maxLength);
        onChangeField(item);
    } else if (maxLength === undefined) {
        onChangeField(item);
    }
      
  }

    return (
        <div>
            {content === "" ? null : 
                <Text style={{ display: 'block'}}>
                    {content.slice(-1) === "*" ? 
                    <div style={{ display: 'flex' }} >{content.slice(0, content.length - 1)}<div style={{ color: 'red' }}>*</div></div> : content}
                </Text>
            }
           
            <Textarea 
                appearance="filled-lighter" 
                name={name}
                value={valueTextArea}
                disabled={disabled}
                onChange={handleChangeField} 
                placeholder={placeholder} 
                className={className} 
                style= {{ width: '100%' }} />
            {/* {isMobile ? <hr className="margin-0" /> : null} */}
            { name === 'privateIdeaDescription' ? <hr className="margin-0" /> : null }
            <Label className="char-limit" style={{ fontSize: '10px', textAlign: 'right', display: 'block' }}>
                {valueTextArea ? `${(valueTextArea.length).toString()}/${maxLength} Characters` : `0/${maxLength} Characters`}
            </Label>
        </div>
    );
}
TextAreaInput.prototype = {
    placeholder: PropTypes.string.isRequired,
    maxLength: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    valueTextArea: PropTypes.string.isRequired,
    onChangeField: PropTypes.func.isRequired,
}
export default TextAreaInput;