import React, { useEffect, useState} from 'react';
import { FluentProvider, Text, teamsLightTheme } from "@fluentui/react-components";
import { Stack } from '@fluentui/react';
import './GetStartedMobile.scss';
import { GetDownloadPreSignedUrl } from "../../../api/stagesApi";
import { PageLoader } from '../../../common/PageLoader';
var uniqid = require('uniqid');


export const VideoFeed = ({source, title}) => {
    return (
        <Stack className="video-wrapper-mobile">
            <video id="vid1" width="100%" height="200" controls
                className="video-link-mobile">
                <source src={source} type="video/mp4"/>
                <source src={source} type="video/ogg"/>
            </video>
            <Text className="video-header-mobile" weight='bold'>{title}</Text>
        </Stack>
    )
}

const GetStartedMobile=()=> {
    const [videoLinks, setVideoLinks] = useState([]);
    const [showSpinner, setShowSpinner] = useState<Boolean>(false);

    useEffect(() => {
        const videoArray = async(videos) => {
            const resultLink = await Promise.all(videos.map(getVideoLink));
            setVideoLinks(resultLink)
        }
        videoArray(videoList)
    }, []);

    const getVideoLink = async (filename) => {
        setShowSpinner(true);
        const response = await GetDownloadPreSignedUrl(filename, null, "LIC", "VideoFile");
        if (response) {
            setShowSpinner(false);
            if (response.byteLength < 300) {
                return;
            } else {
                console.log(response, 'response')
                return response;
            }
        } else {
            setShowSpinner(false);
            return;
        }
    };

    const videoList = [
        "Main-Intro_1280x720_4500.mp4",
        "Idea-Submission-to-GTIC_1280x576_4500.mp4",
        "Submit-Private-Idea_1280x580_4500.mp4",
        "Describing-Landing-Page_1280x668_4500.mp4",
        "Mentor-Flow-and-Actions_1280x576_4500.mp4",
        "Submit-Contest-Idea_1280x576_4500.mp4",
        "Messaging-Extension-Feature_1280x580_4500.mp4",
        "Describing-Nudges_1280x576_4500.mp4"
    ]

    const videoTitleMap = {
        "Main-Intro" : "Introduction to Living Innovation Channel",
        "Idea-Submission-to-GTIC": "Submit Idea to Global Tech Innovation Contest",
        "Describing-Landing-Page": "Describing Landing Page",
        "Submit-Private-Idea": "Submit Private Idea",
        "Submit-Contest-Idea": "Submit Contest Idea",
        "Mentor-Flow-and-Actions": "Mentor Flow & Actions",
        "Describing-Nudges": "Describing Nudges",
        "Messaging-Extension-Feature": "Messaging Extension Feature"
    }

    const videos = videoLinks.map(videoLink => {
        const videoKey = Object.keys(videoTitleMap).find(key => videoLink.includes(key));
        if (videoKey) {
            return (
                <VideoFeed
                    source={videoLink}
                    title={videoTitleMap[videoKey]}
                />
            );
        }
        return null;
    });

    return (
        <>
        <FluentProvider theme={teamsLightTheme}>
            <div>
                { showSpinner ? <PageLoader hidden={false} /> : null }
                <h5 className="header-mobile">Introduction of Living Innovation Channel</h5>
                <Stack horizontalAlign='center' verticalAlign='center'>
                    {videos}
                </Stack>
            </div>
        </FluentProvider>
    </>
    )
}

export default GetStartedMobile
