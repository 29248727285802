import React, { useEffect, useState } from "react";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';
import { Input, Avatar, Label } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import TextAreaInput from './../../common/TextAreaInput';
import PeoplePickerInput from './../../common/PeoplePickerInput';
import { addPrivateIdeaShareUserDetails, addIdeaShareUserDetails, AddShareIdeaComments, getChallengeByContestId } from "./../../api/IdeaApi";
import { getValidCoInnovator } from "./../../api/PeopleApi";
import { PageLoader } from '../../common/PageLoader';
import { GenericMessageBar } from './../../common/MessageBar';
import { RootStateOrAny, useSelector } from "react-redux";

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: 'Share Idea',
};
let peopleArray = [];
let dontShare = [];

export const ShareIdeaPopup = ({ openModal, closeModal, ideaType, ideaId, ideaRegData, enterprise }) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [errorClassNameSolution, seterrorClassNameSolution] = useState("");
    const [message, setMessage] = useState('');
    const [people, setPeople] = useState<string[]>();
    const [showSpinner, setShowSpinner] = useState(false);
    const [choice, setChoice] = useState("");
    const [msg, setMsg] = useState("");
    const [challengeAll, setChallengeAll] = useState([]);
    const peopleHierarchy = useSelector(
        (state: RootStateOrAny) => state.fetchDemographyReducer.ideaCreationData
    );
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    const popupClass = isMobile ? 'share-idea-popup-mobile' : 'share-idea-popup'

    const modalProps = {
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
        className: popupClass
    };

    useEffect(() => {
        dontShare = [];
        dontShare.push(enterprise);
        if (openModal) {
            toggleHideDialog();
            peopleArray = [];
            setPeople([...peopleArray]);
        }

        if (ideaRegData) {
            if (ideaType !== 'private') {
                getChallengeInfo(ideaRegData.ContestId);
            }

            if (ideaRegData.CoInnovators && ideaRegData.CoInnovators.length) {
                ideaRegData.CoInnovators.forEach(x => {
                    dontShare.push(x)
                });
            }
            if (ideaRegData.SharedUserDetails && ideaRegData.SharedUserDetails.length) {
                ideaRegData.SharedUserDetails.forEach(x => {
                    dontShare.push(x.EnterprisId)
                })
            }

            if (ideaRegData.Implementers && ideaRegData.Implementers.length) {
                ideaRegData.Implementers.forEach(x => {
                    dontShare.push(x.ImplementerId);
                })
            }

            if (ideaRegData.TierOneMentorId) {
                dontShare.push(ideaRegData.TierOneMentorId);
            }
        }
    }, [openModal, ideaRegData])

    const OnDiscardClick = () => {
        toggleHideDialog();
        closeModal();
    }

    const OnSendClick = () => {
        if(message.length>0 && people !== null && people.length > 0){
        if (ideaType === 'private') {
            let obj = {
                ReferenceId: ideaId,
                ReferenceType: "PrivateIdea",
                Comments: message,
                IsForNudge: true,
                IdeaCommentorHierarchy: peopleHierarchy
            }
            let p1 = addPrivateIdeaShareUserDetails(ideaId, people, message);
            let p2 = AddShareIdeaComments(obj);
            setShowSpinner(true);
            Promise.all([p1, p2]).then((response) => {

                if (response[0]["Values"] && response[1]["Values"]) {
                    setShowSpinner(false);
                    //console.log(response);
                    toggleHideDialog();
                    closeModal(people);
                    peopleArray = [];
                    setPeople([...peopleArray]);
                }                
            })
        } else {
            let obj = {
                ReferenceId: ideaId,
                ReferenceType: "Idea",
                Comments: message,
                IsForNudge: true,
                IdeaCommentorHierarchy: peopleHierarchy
            }
            let p1 = addIdeaShareUserDetails(ideaId, people, message);
            let p2 = AddShareIdeaComments(obj);
            setShowSpinner(true);
            Promise.all([p1, p2]).then((response) => {
                if (response[0]["Values"] && response[1]["Values"]) {
                    setShowSpinner(false);
                    toggleHideDialog();
                    closeModal(people);
                    peopleArray = [];
                    setPeople([...peopleArray]);
                }
            })
        }
    }
    else{
        setChoice('error');
        setMsg("Please fill the mandatory fields");
    }
}

    const onChangeFormField = (item) => {
        const { name, value } = item;
        setMessage(value);
    }

    const getPerson = (enterpriseId) => {
        let index = dontShare.indexOf(enterpriseId.key);
        if (index === -1) {
            if (ideaType === 'private') {
                const isExist = peopleArray.findIndex(x => x === enterpriseId.key);
                if (isExist === -1) {
                    peopleArray.push(enterpriseId.key);
                    setPeople([...peopleArray]);
                }
            } else {
                handleIsValidCoInnovator(enterpriseId.key, ideaRegData.ChallengeNm);
            }
        } else {
            setMessageBar("Employee Id is already associate with this idea", "error");
        }
    }

    const handleRemoveVal = (item) => {
        const index = peopleArray.indexOf(item);
        if (index > -1) {
            peopleArray.splice(index, 1);
        }
        setPeople([...peopleArray]);
    }

    const resetchoice = () => {
        setChoice(undefined);
    }

    const setMessageBar = (errorMsg: string, type: string) => {
        setMsg(errorMsg);
        setChoice(type);
    }

    const getChallengeInfo = (contestId) => {
        getChallengeByContestId(contestId).then((response) => {
            setChallengeAll(response['value']);
        });
    }

    const handleIsValidCoInnovator = (val, challenge) => {
        const coInnovatorRequestBody = generateRequestforCoInnovator(val, challenge);
        getValidCoInnovator(coInnovatorRequestBody).then((res) => {
            if (res['value']) {
                const entId = val;
                const isExist = peopleArray.findIndex(x => x === val);
                if (isExist === -1) {
                    peopleArray.push(entId);
                    setPeople([...peopleArray]);
                }
            } else {
                setMessageBar('Please add member from the same organisation unit.', 'error');
            }
        });
    }

    const generateRequestforCoInnovator = (coInnovEntIdList, challenge) => {
        const challengeIndex = challengeAll.findIndex(item => item.ChallengeNm === challenge);
        const challengeId = challengeIndex >= 0 ? challengeAll.filter(item => item.ChallengeNm === challenge)[0].ChallengeId : '';
        const request = {
            'OrgUnitIds': challengeAll[challengeIndex].OrgUnits,
            'LocationIds': challengeAll[challengeIndex].LocationHierarchy,
            'CoInnovatorId': coInnovEntIdList,
            'IsPrivate': challengeAll[challengeIndex].ChallengeType === 'Private' ? true : false,
            'ChallengeId': challengeId,
            'IsAvailableForNonTC': challengeAll[challengeIndex].IsAvailableForNonTC ? true : false
        };

        return request;
    }

    const handleInitials = (CreatedBy) => {
        var res = CreatedBy.split(".");
        let nameInitials = (res[res.length - 1].charAt(0) + res[0].charAt(0)).toUpperCase();
        return nameInitials;
    }

    return (
        <>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}              
                className={isMobile ? "share-idea-popup-mobile" : "share-idea-popup"}
            >
                <GenericMessageBar choice={choice} msg={msg} resetMsg={resetchoice} />
                {showSpinner ? <PageLoader hidden={false} /> : ""}
                <PeoplePickerInput labelName='Share with *' selectedPerson={getPerson} />
                {people && people.map((p, i) => {
                    return (<div>
                        <div className="comment-avatar">
                            <Label key={i}>
                                <Avatar name={p} size={28} className="shared-idea-initials" initials={`${p.split('.').pop().charAt(0).toUpperCase()}${p.charAt(0).toUpperCase()}`}/>{p}<DismissRegular onClick={() => handleRemoveVal(p)}/>
                            </Label>
                        </div>
                    </div>)
                })}
                <div className="message-div">
                    <TextAreaInput
                        onChangeField={onChangeFormField}
                        content="Message *"
                        placeholder='Message'
                        size='medium'
                        name='Message'
                        valueTextArea={message}
                        maxLength={500}
                        className={errorClassNameSolution}
                    />
                </div>
                <DialogFooter>
                    <DefaultButton onClick={OnDiscardClick} text="Discard" />
                    <PrimaryButton onClick={OnSendClick} text="Send" />
                </DialogFooter>
            </Dialog>
        </>
    );
};