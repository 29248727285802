import React, { useEffect, useState } from 'react';
import AdaptiveCard from './../components/Nudges/AdaptiveCard';
import { GenericMessageBar } from "./../common/MessageBar";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { getNudgeDetails } from "./../api/nudgesApi";
import { PageLoader } from '../common/PageLoader';

export default function Nudges() {

    const token = useSelector((state: RootStateOrAny) => state.fetchTokenReducer.data);
    const enterpriseId = useSelector((state: RootStateOrAny) => state.entityReducer.entityId);
    const [nudgesData, setNudgesData] = useState([]);
    const [choice, setChoice] = useState("");
    const [msg, setMsg] = useState("");
    const [showSpinner, setSpinnerData] = useState(true);

    useEffect(() => {        
        if (Object.keys(token).length > 0) {
            setSpinnerData(true);
            getNudgeDetailsData();
        }
    }, [token])

    const getNudgeDetailsData = () => {
        getNudgeDetails(enterpriseId).then(response => {
            setSpinnerData(false);
            // console.log('response', response);
            if (response) {
                if (response['ResponseCode'] === '107') {
                    setMessageBar(response['ResponseMessage'], 'error');
                } else {
                   // console.log(response.value);
                    setNudgesData(response["value"]);
                }
            }
        })
    }

    const setMessageBar = (errorMsg: string, type: string) => {
        // console.log(errorMsg);
        setMsg(errorMsg);
        setChoice(type);
    }

    const resetchoice = () => {
        setChoice(undefined);
    }

    return (
        <React.Fragment>            
            <div>
                <div>
                    <GenericMessageBar choice={choice} msg={msg} resetMsg={resetchoice} />
                    {showSpinner ? <PageLoader hidden={false} /> : ""}
                    {nudgesData && nudgesData.length > 0 ?
                        nudgesData.map((forecast, index) => {
                            if (index === 0) {
                                return <AdaptiveCard payload={forecast} />
                            } else {
                                return <AdaptiveCard payload={forecast} />
                            }
                        }) : "No data available"
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
