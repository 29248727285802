import React from 'react';
import { useHistory } from 'react-router-dom';

export default function AnalyticsDashboardView({}) {
    const history = useHistory();
   
    const navigateToAnalytics = () => {
        history.push({
            pathname: '/analytics-dashboard'
        });
    }
    return (
        <div className="left-side" style={{ cursor: "pointer" }} onClick={navigateToAnalytics}>

            <div className="header-div">
                <p className="patent-header">Analytics Dashboard</p>
            </div>
            <div className="count-div">
                <span className="text-span">
                Click to view Analytics Dashboard
                </span>
            </div>

        </div>
    )
}
