import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from 'react-router-dom';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ArrowLeftRegular } from '@fluentui/react-icons';
import './ExploreIdeasMobile.scss'
const SubmitIdeaPopupPage=({location})=> {

    const [contestOptions,setContestOptions] = useState([]);
    const { contestsState, challengesState } = useSelector((state: RootStateOrAny) => state.fetchDemographyReducer);
    const [selectedContest, setSelectedContest] = useState({});
    const [error, setError] = useState(false);
    const [defaultSelectedContest,setDefaultSelectedContest] = useState<any>();
    const history = useHistory();
    let title = location.state && location.state.title ? location.state.title : null;
    let ideaDetails = location.state && location.state.ideaDetails ? location.state.ideaDetails : null;
    let navigateBackToExploreIdeas = location.state && location.state.navigateBackToExploreIdeas ? location.state.navigateBackToExploreIdeas : null;
    let fromStaticContest = location.state && location.state.fromStaticContest ? location.state.fromStaticContest : null;
    let landingPage = location.state && location.state.landingPage ? location.state.landingPage : null;
    let fromActiveContest = location.state && location.state.fromActiveContest ? location.state.fromActiveContest : null;
    let isCopy = location.state && location.state.isCopy? location.state.isCopy : null ;
    const { categoryIds, contestIds, challengeIds } = location.state;

    useEffect(() => {
        let arr = [];
        let GLCContestID= process.env.REACT_APP_GLC2022_CONTEST_ID;

        contestsState.forEach(contest => {
            if (contest.ContestType 
            && contest.ContestType.toLowerCase() === 'public' 
            && contest.ContestNm.trim() !== ''
            && contest['ChallengeCount'] > 0
            && contest['ContestIsActive']
            && contest.IsIdeaSubmissionClosed === false &&
            (( contest.ContestId == (GLCContestID ? GLCContestID: "")) || contest.IsDynamicContest == false))
            {
                arr.push({
                    value: contest.ContestId,
                    label: contest.ContestNm
                });
            }
        });
        //copy idea
        if(isCopy) {
          arr = arr.filter(item=> item.value != ideaDetails.ContestId);
          arr = arr.sort((a, b) => -b.label?.toUpperCase().localeCompare(a.label?.toUpperCase()));
        } else {
          arr = arr.sort((a, b) => -b.label?.toUpperCase()?.localeCompare(a.label?.toUpperCase()));
        }
        setContestOptions(arr);
        
        let defaultSelected;
        if(contestIds && arr.length){
           arr.map((options) => {
            if (options.value == contestIds[0]) {
              defaultSelected= options;
              return options;
            } else {
              return;
            }
          }) 
        }
        if(defaultSelected){
          setDefaultSelectedContest(defaultSelected);
          //console.log("defaultSelected-->",defaultSelected);
          setSelectedContest(defaultSelected);
        }


        
    }, [contestsState])

    

    const onChange = (event: React.FormEvent<HTMLDivElement>): void => {
        setSelectedContest(event);
        setError(false);
    };
    let customStyles = {
        control: styles => ({
          ...styles,
          backgroundColor: 'white',
          fontSize: '10px',
          height: '30px',
          minHeight: '30px',
          verticalAlign: 'center',
          borderColor: error ? '#FF0000' : '#808080',
          ...boxShadow()
          //paddingBottom: 9
        }),
        menu: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted pink',
          color: state.selectProps.menuColor,
          maxHeight: 130
        }),
        menuList: (provided, state) => ({
          ...provided,
          maxHeight: 120
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            borderBottom: '1px',
            fontSize: '10px',
            ":hover": {
              background: '#efe4f7'
            },
            backgroundColor: isFocused  ? '#efe4f7' : isSelected ? '#9713fa' : '#fff',
            color: '#000',
          };
        },
        singleValue: (provided, state) => {
          return { ...provided, paddingBottom: 5, ...boxShadow() };
        },
        placeholder: styles => ({ ...styles, ...dot(), paddingBottom: 6, ...boxShadow() }),
        input: styles => ({ ...styles, ...dot(), paddingBottom: 8,
          ...boxShadow()
         }),
        indicatorsContainer: styles => ({ ...styles, ...dot(), height: 30 }),
        container: styles => ({
          ...styles,
         ...boxShadow()
        })
      }

      const dot = (color = '#ccc') => ({
        alignItems: 'center',
        display: 'flex',
      });
    
      const boxShadow = () => ({
        boxShadow: '#9713fa',
          ":hover": {
            borderColor: '#9713fa',
            borderWidth: 1
          },
          ":focus": {
            borderColor: '#9713fa',
            borderWidth: 1
          },
          ":active": {
            borderColor: '#9713fa',
            borderWidth: 1
          },
          ":focus-within": {
            borderColor: '#9713fa',
            borderWidth: 1
          }
      })
      const onProceedClick = () => {
        if(Object.keys(selectedContest).length > 0) {
            let contest =  contestsState.find(contest => contest.ContestId === selectedContest['value']);            
            history.push({
                pathname: `/submit-idea/${selectedContest['value']}`,
                state: {
                    contestDetails: {
                        ...contest
                    }
                }
            });
      } else {
        setError(true);
          }

    }

    
  const onProceedClickWithDetails = () => {
    if(Object.keys(selectedContest).length > 0) {
      let contest = contestsState.find(
        (contest) => contest.ContestId === selectedContest["value"]
      );
      if(isCopy){
        history.push({
          pathname: `/submit-idea/${selectedContest["value"]}`,
          state: {
            contestDetails: {
              ...contest,
            },
            ideaDetails: ideaDetails,
            isCopyIdea: true,
          },
        });
      }
        else if (contest.IsDynamicContest) {
        history.push({
            pathname: `/dynamic-form/${contest.ContestId}`,
            state: { contestNm: contest.ContestNm, contestId: contest.ContestId, contestDetails: contest, ideaDetails: ideaDetails }
        });
        }
      else {
        history.push({
          pathname: `/submit-idea/${selectedContest["value"]}`,
          state: {
            contestDetails: {
              ...contest,
            },
            ideaDetails: ideaDetails,
          },
        });
      }
     
    } else {
      setError(true);
    }
    
  };
  

    const onBackClick = (event) => {
        event.preventDefault();
        if (navigateBackToExploreIdeas) {
            if (fromStaticContest) {
                history.push({
                    pathname: "/exploreideas",
                    state: { refreshNeeded: false, categoryIds: categoryIds, fromStaticContest: true, landingPage: landingPage, fromActiveContest: fromActiveContest, contestIds: contestIds, challengeIds: challengeIds, backFromSubmitIdeaPopup: true },
                });
            }
            else {
                history.push({
                    pathname: "/exploreideas",
                    state: { refreshNeeded: false, categoryIds: categoryIds, contestIds: contestIds, challengeIds: challengeIds, backFromSubmitIdeaPopup: true },
                });
            }          
        }
        else{
          history.push({
            pathname: "/",
            state: { refreshNeeded: false },
          });
        }
      };
    
  
  
    return (
        <div className="explore-idea-mobile-popup-page">
                <div className="back-btn">
                            <div style={{ cursor: "pointer" }} onClick={(event) => onBackClick(event)}>
                                <ArrowLeftRegular />
                            </div>
                <div className="page-title">{title}</div>
                </div>  
            <p className="submit-popup-page-on-going-contest-text">Submit this idea to an ongoing contest</p>
                <Select
                    onChange={onChange}
                    options={contestOptions}
                    isSearchable={true}
                    placeholder="Select Contest"
                    className="filter-criteria-dropdown"
                    styles={customStyles}
                    defaultValue= {defaultSelectedContest}

                />
                    <PrimaryButton onClick={ideaDetails? onProceedClickWithDetails: onProceedClick} text="Proceed" className="proceed-button-popup-page" /> 
        </div>
    )
}

export default SubmitIdeaPopupPage;