import React, { useEffect, useState, useMemo, useCallback } from 'react';
import IdeaFeed from './../my-space/idea-feed';
import InfiniteScroll from "react-infinite-scroll-component";
import {
    getInnovatorIdeas, getPrivateIdeaDetails, popularRequests, getPromotionalFeed, getIdeasForImplementer,
    getPinnedData, getPrivatePinnedData, getPromotionalPinnedFeed, getSharedIdeasFromPrivateIdeas, getPromotionalFeedBinaryData, getInnovatorIdeasWithFilters, popularRequestsWithFilters
} from '../../api/IdeaApi';
import { setAllIdeaFeedData } from '../../redux-sharedframework/actions/fetch-demography-actions';
import { fetchIdeaFeedLastGetIdeaTillDates, setPromotionalMediaFiles } from '../../redux-sharedframework/actions/fetch-data-actions';
import { useDispatch, useSelector, RootStateOrAny, shallowEqual } from "react-redux";
import { Spinner } from '@fluentui/react-components';
var uniqid = require('uniqid');

interface ScrollFeed {
    name: any;
    ideas: any;
    enterpriseId: string;
    handleEditPrivateIdea?: any;
    setError?: any;
    contestIds?: any;
    challengeIds?: any;
    filterData: any;
    popularData: any;
    privateIdeas: any;
    pinnedData?: any;
    allContestData?: any;
    contestPopularIdea?: any;
    allSharedData?: any;
    allpromotionalData?: any;
    getAllIdeaDataRefresIdeaFeed?: any;
    firstFeedData?: any;
    setErrorMobile?: any;
}
const InfiniteScrollFeed = React.memo(({ ideas, firstFeedData, name, enterpriseId, handleEditPrivateIdea, setError, getAllIdeaDataRefresIdeaFeed, contestIds, challengeIds, filterData, popularData, privateIdeas, pinnedData, allContestData, allSharedData, allpromotionalData,setErrorMobile}: ScrollFeed) => {

    const [more, setMore] = React.useState<boolean>(true);
    const filterStateData = useSelector((state: RootStateOrAny) => state.fetchFilterDataReducer.filterData);
    const lastGetIdeaTillDate = useSelector((state: RootStateOrAny) => state.fetchDataReducer.lastDates, shallowEqual);
    const orgUnitsState = useSelector((state: RootStateOrAny) => (state.fetchDemographyReducer.orgUnitsState), shallowEqual);
    const contestsState = useSelector((state: RootStateOrAny) => (state.fetchDemographyReducer.contestsState), shallowEqual);

    const [contestDataArray, setContestDataArray] = React.useState<any>([]);
    const [contestDataMore, setContestDataMore] = React.useState<boolean>(true);

    const [pinnedDataArray, setPinnedDataArray] = React.useState<any>([]);
    const [pinnedDataMore, setPinnedDataMore] = React.useState<boolean>(true);
    const [pinnedDataPage, setPinnedDataPage] = React.useState<number>(1);

    const [popularDataArray, setPopularDataArray] = React.useState<any>([]);
    const [popularDataMore, setPopularDataMore] = React.useState<boolean>(true);

    const [privateDataArray, setPrivateDataArray] = React.useState<any>([]);
    const [privateDataMore, setPrivateDataMore] = React.useState<boolean>(true);

    const [filteredDataArray, setFilteredDataArray] = React.useState<any>([]);
    const [filteredDataMore, setFilteredDataMore] = React.useState<boolean>(true);
    const [filteredDataPage, setFilteredDataPage] = React.useState<number>(1);

    //const [infiniteScrollIdeas, setInfiniteScrollIdeas] = React.useState<any>(allIdeaFeedState.length ? allIdeaFeedState : []);
    const [infiniteScrollIdeas, setInfiniteScrollIdeas] = React.useState<any>([]);

    const [tempPromotionalData, setTempPromotionalData] = useState<any>([]);

    const dispatch = useDispatch();
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    useEffect(() => {
        if (allContestData?.length > 0) {
            setContestDataArray(allContestData);
            setContestDataMore(true);
        }        
    }, [allContestData]);

    useEffect(() => {
        if (privateIdeas?.length > 0) {
            setPrivateDataArray(privateIdeas);
            setPrivateDataMore(true);
        }
    }, [privateIdeas]);

    useEffect(() => {
        if (pinnedData?.length > 0) {
            setPinnedDataArray(pinnedData);
            setPinnedDataPage(1);
            setPinnedDataMore(true);
        }
    }, [pinnedData]);
            
    useEffect(() => {
        if (filterData?.length > 0) {
            setFilteredDataArray(filterData);
            setFilteredDataPage(1);
            setFilteredDataMore(true);
        }
    }, [filterData]);

    useEffect(() => {
        if (popularData?.length > 0) {
            setPopularDataArray(popularData);
            setPopularDataMore(true);
        }
    }, [popularData]);

    useEffect(() => {
        if (pinnedDataPage != 1) {
            fetchPinnedData(pinnedDataPage);
        }
    }, [pinnedDataPage]);

    useEffect(() => {
        if (filteredDataPage != 1) {
            fetchFilteredData(filterStateData, filteredDataPage);
        }
    }, [filteredDataPage]);


    React.useEffect(() => {
        if (ideas.length > 1) {
            let ideasArray = ideas.sort((a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate));
            ideasArray = getUniqueData(ideasArray);
            ideasArray?.length < 8 ? setMore(false) : null;
            dispatch(setAllIdeaFeedData(ideasArray));
            setInfiniteScrollIdeas(ideasArray);
        }
    }, [ideas]);

    useEffect(() => {
        if (tempPromotionalData.length) {
            let promotionalItems = [];
            tempPromotionalData.forEach(element => {
                if (element['mediafiles'][0]['isfileuploaded']) {
                    promotionalItems.push(element['promotionalfeedid']);
                }
            });
            Promise.all(promotionalItems.map(item => getPromotionalFeedBinaryData(item))).then(values => {
                dispatch(setPromotionalMediaFiles(values));
            });
        }
    }, [tempPromotionalData])

    const getLastIdeaDate = (ideas) => {
        const [lastidea] = ideas.slice(-1);
        return lastidea['GetIdeaTillDate'];
    }

    const getFilteredArray = (ideas) => {
        const ideasArray = ideas.sort((a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate));
        let newArray = [...infiniteScrollIdeas, ...ideasArray];
        newArray = getUniqueData(newArray);
        if (newArray.length === infiniteScrollIdeas.length) setMore(false);
        else setInfiniteScrollIdeas(newArray);
    }

    const getAllIdeaData = (contests: any, challenges: any, pageIndex = 1, sortType = "desc")  => {
        const lastIdeaDate = getLastIdeaDate(infiniteScrollIdeas);

        let p1 = getPrivateIdeaDetails(enterpriseId, lastGetIdeaTillDate.privateIdea , pageIndex);
        let p2 = getInnovatorIdeas([], contests, challenges, enterpriseId, lastGetIdeaTillDate.innovatorIdea , sortType, pageIndex);
        let p3 = popularRequests(contestsState.filter(contest => !(contest.ContestNm?.toLowerCase().includes("v360")))
                                .filter(contest =>
                                    (contest.IsExplorDisable && !contest.ExplorerVisibleTo && contest.ExplorerUsersValues.includes(enterpriseId)) ||
                                    (!contest.IsExplorDisable && !contest.ExplorerVisibleTo) ||
                                    (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "selectedusers" && contest.ExplorerUsersValues.includes(enterpriseId)) ||
                                    (contest.ExplorerVisibleTo != null && contest.ExplorerVisibleTo.toLowerCase() == "all")).map((contest) => contest.ContestId),
                                challenges, lastGetIdeaTillDate.popularIdea, pageIndex, sortType, true);
        let p4 = getPromotionalFeed(lastGetIdeaTillDate.promotionalIdea, orgUnitsState);
        let p5 = getSharedIdeasFromPrivateIdeas(enterpriseId, "desc", { GetIdeaTillDate: lastGetIdeaTillDate.SharedPrivateIdea });
        let p6 = getIdeasForImplementer(enterpriseId, contests, challenges, lastGetIdeaTillDate.implementerIdeas, [], pageIndex, sortType);

        Promise.all([p1, p2, p3, p4, p5, p6]).then(values => {
            let tempArr = [];
            if (values[0] && values[0]['value'] && values[0]['value'].length > 0) {
                values[0]['value'].forEach(element => {
                    element['isPrivateIdea'] = true;
                });
                tempArr.push(...values[0]['value']);
            }
            if (values[1] && values[1]['value'] && values[1]['value'].length > 0) {
                values[1]['value'].forEach(element => {
                    element['IsImplementerIdea'] = false;
                    if (element.Implementers && element.Implementers.length > 0) {
                        if (element.Implementers.find(x => x.ImplementerId === enterpriseId) &&
                            element.Implementers.find(x => x.ImplementerId === enterpriseId)?.Status === "UnderAcceptance") {
                            element['IsImplementer'] = true;
                        }
                    }
                });
                tempArr.push(...values[1]['value']);
            }
            if (values[2] && values[2]['value'] && values[2]['value'].length > 0) {
                //values[2]['value'].forEach(element => {
                //    element['isPopularIdeas'] = element['IsPopularIdea'];
                //});
                let popularIdeas = values[2]["value"];
                if (popularIdeas && popularIdeas.length > 0) {
                    popularIdeas.forEach((element) => {
                        element["isPopularIdeas"] = element['IsPopularIdea'];
                    });

                    // start - code for removing popular data  which is already in myidea
                    //let indexArr = [];
                    //tempArr.forEach(myideaelement => {
                    //    values[2]['value'].forEach(popularelement => {
                    //        if (myideaelement["IdeaId"] === popularelement["IdeaId"]) {
                    //            myideaelement['isPopularIdeas'] = true;
                    //            indexArr.push(popularelement["IdeaId"]);
                    //        }
                    //    });
                    //});
                    //values[2]['value'] = values[2]['value'].filter(item => !indexArr.includes(item["IdeaId"]));
                    let indexArr = [];
                    tempArr.forEach(myideaelement => {
                        popularIdeas.forEach(popularelement => {
                            if (myideaelement["IdeaId"] === popularelement["IdeaId"]) {
                                //myideaelement['isPopularIdeas'] = false;
                                myideaelement['isPopularIdeas'] = popularelement['IsPopularIdea'];
                                indexArr.push(popularelement["IdeaId"]);
                            }
                        });
                    });
                    popularIdeas = popularIdeas.filter(item => !indexArr.includes(item["IdeaId"]));
                    // end - code for removing popular data  which is already in myidea

                    //let tempArr1 = values[2]['value']; //.filter(item => item.CreatedBy != enterpriseId);
                    let tempArr1 = popularIdeas.filter(item => item.CreatedBy != enterpriseId);
                    tempArr.push(...tempArr1);
                }
            }
            if (values[3] && values[3]['value'] && values[3]['value'].length > 0) {
                values[3]['value'].forEach(element => {
                    element['isPromotionalFeed'] = true;
                });
                tempArr.push(...values[3]['value']);
                setTempPromotionalData(values[3]['value']);
            }

            if (values[4] && values[4]['value'] && values[4]['value'].length > 0) {
                tempArr.push(...values[4]['value']);
            }

            if (values[5] && values[5]['value'] && values[5]['value'].length > 0) {
                values[5]['value'].forEach(element => {
                    element['IsImplementerIdea'] = true;

                    if (element.Implementers && element.Implementers.length > 0) {
                        if (element.Implementers.find(x => x.ImplementerId === enterpriseId) &&
                            element.Implementers.find(x => x.ImplementerId === enterpriseId)?.Status === "UnderAcceptance") {
                            element['IsImplementer'] = true;
                        }
                    }
                });
                tempArr.push(...values[5]['value']);
            }

            if ((values[0] && values[0]['value'].length === 0) && (values[1] && values[1]['value'].length === 0) && (values[2] && values[2]['value'].length === 0) && (values[3] && values[3]['value'].length === 0) && ((values[4] && values[4]['value']?.length === 0) || (values[4] && values[4]['Values'])) && ((values[5] && values[5]['value']?.length === 0) || (values[5] && values[5]['Values']))) {
                setMore(false);
            }
            else {
                getFilteredArray(tempArr);
                
                dispatch(fetchIdeaFeedLastGetIdeaTillDates({
                    privateIdea: values[0] && values[0]['value'] && values[0]['value'].length > 0 ?  values[0]['value'].sort((a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)).slice(-1)[0]['GetIdeaTillDate'] : lastGetIdeaTillDate.privateIdea,
                    innovatorIdea: values[1] && values[1]['value'] && values[1]['value'].length > 0 ? values[1]['value'].slice(-1)[0]['GetIdeaTillDate'] : lastGetIdeaTillDate.innovatorIdea,
                    popularIdea: values[2] && values[2]['value'] && values[2]['value'].length > 0 ? values[2]['value'].slice(-1)[0]['GetIdeaTillDate'] : lastGetIdeaTillDate.popularIdea,
                    promotionalIdea: values[3] && values[3]['value'] && values[3]['value'].length > 0 ? values[3]['value'].sort((a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)).slice(-1)[0]['GetIdeaTillDate'] : lastGetIdeaTillDate.promotionalIdea,
                    SharedPrivateIdea: values[4] && values[4]['value'] && values[4]['value'].length > 0 ? values[4]['value'].sort((a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)).slice(-1)[0]['GetIdeaTillDate'] : lastGetIdeaTillDate.SharedPrivateIdea,
                    implementerIdeas: values[5] && values[5]['value'] && values[5]['value'].length > 0 ? values[5]['value'].sort((a, b) => -a.GetIdeaTillDate?.localeCompare(b.GetIdeaTillDate)).slice(-1)[0]['GetIdeaTillDate'] : lastGetIdeaTillDate.implementerIdeas
                }));
            }
        }).catch(error => console.log(error));;
    }

    const fetchMoreData = () => {
        if (infiniteScrollIdeas.length > 0) {
            getAllIdeaData(contestIds,[]);
        }
    };

    const fetchMorePrivateData = () => {
        if (privateDataArray.length > 0) {
            fetchPrivateData();
        }
    }


    const fetchPrivateData = () => {
        const lastIdeaDate = getLastIdeaDate(privateDataArray);
        let p1 = getPrivateIdeaDetails(enterpriseId, lastIdeaDate, 1);
        
        Promise.all([p1]).then(values => {
            let tempAllPrivateArray = [];
            let tempFilteredPrivateArray = [];

            if (values[0] && values[0]['value'] && values[0]['value'].length > 0) {
                tempAllPrivateArray.push(...values[0]['value']);
                values[0]['value'].forEach(element => {
                    element['isPrivateIdea'] = true;
                });
            }

            tempFilteredPrivateArray = tempAllPrivateArray.sort((a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate));

            if (tempFilteredPrivateArray.length > 0) {
                setPrivateDataArray([...privateDataArray, ...tempFilteredPrivateArray]);
            }
            else {
                setPrivateDataMore(false);
            }
        });
    }

    const updateInfiniteScrollIdeas = useCallback(
        (ideaId, type) => {
            let tempArr = [...infiniteScrollIdeas];
            tempArr = tempArr.filter(idea => {
                if (type === 'private') {
                    return idea.PrivateIdeaId !== ideaId;
                }
                else if (type === 'public') {
                    return idea.IdeaId !== ideaId;
                }
            });

            tempArr = getUniqueData(tempArr);
            setInfiniteScrollIdeas([...tempArr]);
        }, []
    );

    const checkfilteredData = (idea, filterArray) => {
        if (idea.CreatedBy === enterpriseId || (idea.CoInnovators && idea.CoInnovators.includes(enterpriseId))) {
            if (idea.IndustryAlignment && idea.IndustryAlignment.includes(filterArray)) return idea;
            if (idea.IdeaWorkFlowStatus && idea.IdeaWorkFlowStatus.includes(filterArray)) return idea;
            if (idea.CategoryNm && idea.CategoryNm.includes(filterArray)) return idea;
            if (idea.ContestNm && idea.ContestNm.includes(filterArray)) return idea;
        }
    }

    const getUniqueData = (data) => {
        return data;
    }

    const fetchMorePopularData = () => {
        if (popularDataArray.length > 0) {
            fetchPopularData(contestIds, []);
        }
    }

    const fetchPopularData = (contests: any, challenges: any) => {
        const lastIdeaDate = getLastIdeaDate(popularDataArray);
        popularRequests(contests, challenges, lastIdeaDate, 1).then((response) => {
            
            if (response && response["value"]) {
                response['value'].forEach(element => {
                    element['isPopularIdeas'] = true;
                });
                
                let tempFilteredPopularArray = (response['value']).sort((a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate));
                
                if (tempFilteredPopularArray.length > 0) {
                    setPopularDataArray([...popularDataArray, ...tempFilteredPopularArray]);
                }
                else {
                    setPopularDataMore(false);
                }
            }

        })
    }

    const fetchMoreFilteredData = () => {
        if (filteredDataArray.length > 0) {
            setFilteredDataPage(oldPage => oldPage + 1);
        }
    }

    const fetchFilteredData = (filterData: any, pageIndex) => {
        if (filterData.allFilters.length !== 0) {

            const industryFiltersData = filterData.industryFilters.map(industry => industry.key);
            const contestFiltersData = filterData.contestFilters.map(contest => contest.key);
            const ideaStatusData = filterData.ideaStatus.map(idea => idea.key);
            const technologyFiltersData = filterData.technologyFilters.map(technology => technology.key);
            const lastIdeaDate = getLastIdeaDate(filteredDataArray);
            //let p1 = getInnovatorIdeasWithFilters(contestFiltersData, challengeIds, ideaStatusData, industryFiltersData, technologyFiltersData, lastIdeaDate, enterpriseId, 'desc', pageIndex);
            //let p2 = getIdeasForImplementer(enterpriseId, contestFiltersData, challengeIds, lastIdeaDate, ideaStatusData, pageIndex, 'desc');

            let p1 = getInnovatorIdeasWithFilters(contestFiltersData, [], ideaStatusData, industryFiltersData, technologyFiltersData, lastIdeaDate, enterpriseId, 'desc', pageIndex);
            let p2 = getIdeasForImplementer(enterpriseId, contestFiltersData, [], lastIdeaDate, ideaStatusData, pageIndex, 'desc');

            Promise.all([p1, p2]).then(values => {
                let tempArr = [];

                if (values && values[0]['value'] && values[0]['value'].length > 0) {
                    tempArr.push(...values[0]['value']);
                }

                if (values[1] && values[1]['value'] && values[1]['value'].length > 0) {
                    values[1]['value'].forEach(element => {
                        element['IsImplementerIdea'] = true;

                        if (element.Implementers && element.Implementers.length > 0) {
                            if (element.Implementers.find(x => x.ImplementerId === enterpriseId) &&
                                element.Implementers.find(x => x.ImplementerId === enterpriseId)?.Status === "UnderAcceptance") {
                                element['IsImplementer'] = true;
                            }
                        }
                    });
                    tempArr.push(...values[1]['value']);
                }
                
                let tempFilteredArray = tempArr.filter(idea => {
                    if (idea.CreatedBy === enterpriseId ||
                        (idea.CoInnovators && idea.CoInnovators.includes(enterpriseId)) ||
                        (idea.Implementers && idea.Implementers.find(x => x.ImplementerId === enterpriseId)))
                        return idea
                })
                    .sort((a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate));

                if (tempFilteredArray.length > 0) {
                    setFilteredDataArray([...filteredDataArray, ...tempFilteredArray]);
                }
                else {
                    setFilteredDataMore(false);
                }                
            });
        }
    }

    const fetchMoreContestData = () => {
        if (contestDataArray.length > 0) {
            fetchContestData(contestIds, []);
        }
    }

    const fetchContestData = (contests: any, challenges: any, pageIndex = 1, sortType = "desc") => {
        const lastIdeaDate = getLastIdeaDate(contestDataArray);
        let p1 = getInnovatorIdeas([], contests, challenges, enterpriseId, lastIdeaDate, sortType, pageIndex);
        let p2 = getIdeasForImplementer(enterpriseId, contests, challenges, lastIdeaDate);

        Promise.all([p1, p2]).then(values => {
            let tempAllContestArray = [];
            let tempFilteredContestArray = [];
            
            if (values && values[0]['value'] && values[0]['value'].length > 0) {
                tempAllContestArray.push(...values[0]['value']);                
            }

            if (values[1] && values[1]['value'] && values[1]['value'].length > 0) {
                values[1]['value'].forEach(element => {
                    element['IsImplementerIdea'] = true;

                    if (element.Implementers && element.Implementers.length > 0) {
                        if (element.Implementers.find(x => x.ImplementerId === enterpriseId) &&
                            element.Implementers.find(x => x.ImplementerId === enterpriseId)?.Status === "UnderAcceptance") {
                            element['IsImplementer'] = true;
                        }
                    }
                });
                tempAllContestArray.push(...values[1]['value']);
            }

            if (tempAllContestArray.length > 0) {
                tempFilteredContestArray = tempAllContestArray.filter(idea => {
                    if (idea.CreatedBy === enterpriseId ||
                        (idea.CoInnovators && idea.CoInnovators.includes(enterpriseId)) ||
                        (idea.Implementers && idea.Implementers.find(x => x.ImplementerId === enterpriseId)))
                        return idea
                })
                    .sort((a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate));
                if (tempFilteredContestArray.length > 0) {
                    setContestDataArray([...contestDataArray, ...tempFilteredContestArray]);
                }
                else {
                    setContestDataMore(false);
                }
            }
            else {
                setContestDataMore(false);
            }
        });
    }

    const fetchMorePinnedData = () => {
        if (pinnedDataArray.length > 0) {
            setPinnedDataPage(oldPage => oldPage + 1);
        }
    }

    const fetchPinnedData = (pageIndex) => {
        const lastIdeaDate = getLastIdeaDate(pinnedDataArray);
        let p1 = getPinnedData(lastIdeaDate, contestIds);
        let p2 = getPrivatePinnedData(lastIdeaDate);
        let p3 = getPromotionalPinnedFeed(lastIdeaDate, orgUnitsState);
        //let p4 = getIdeasForImplementer(enterpriseId, contestIds, challengeIds, lastIdeaDate);
        let p4 = getIdeasForImplementer(enterpriseId, contestIds, [], lastIdeaDate);

        Promise.all([p1, p2, p3, p4]).then(values => {
            let tempAllPinnedArray = [];
            let pinnedImplementerIdeas = [];            

            if (values[2]['value']) {
                values[2]['value'].forEach(element => {
                    element['isPromotionalFeed'] = true;
                });
                setTempPromotionalData(values[2]['value']);
            }
            if (values[0]['value']) {
                values[0]['value'].forEach(element => {
                    if (element['IsPopularIdea'] = true) {
                        element['isPopularIdeas'] = true
                    }

                    if (element.Implementers && element.Implementers.length > 0) {
                        if (element.Implementers.find(x => x.ImplementerId === enterpriseId && x.CreatedBy !== enterpriseId)) {
                            element['IsImplementerIdea'] = true;
                        }

                        if (element.Implementers.find(x => x.ImplementerId === enterpriseId) && element.CreatedBy !== enterpriseId &&
                            element.Implementers.find(x => x.ImplementerId === enterpriseId)?.Status === "UnderAcceptance") {
                            element['IsImplementer'] = true;
                            element['IsImplementerIdea'] = true;
                        }
                    }
                });
            }            

            if (values[0] && values[0]['value'] && values[0]['value'].length > 0) {                
                tempAllPinnedArray.push(...values[0]['value']);
            }

            if (values[0]['value'] && values[0]['value'].length > 0 && values[3]['value'] && values[0]['value'].length > 0) {
                pinnedImplementerIdeas = values[3]['value'].filter(x => values[0]['value'].find(y => y.IdeaId === x.IdeaId));
            }

            if (values[1] && values[1]['value'] && values[1]['value'].length > 0) {
                values[1]['value'].forEach(element => {
                    element['isPrivateIdea'] = true;
                });
                tempAllPinnedArray.push(...values[1]['value']);
            }
            if (values[2] && values[2]['value'] && values[2]['value'].length > 0) {

                tempAllPinnedArray.push(...values[2]['value']);
            }

            let filteredImplementerIdeas = [];
            if (pinnedImplementerIdeas && pinnedImplementerIdeas.length > 0) {
                pinnedImplementerIdeas.forEach(element => {
                    element['IsImplementerIdea'] = true;

                    if (element.Implementers && element.Implementers.length > 0) {
                        if (element.Implementers.find(x => x.ImplementerId === enterpriseId) &&
                            element.Implementers.find(x => x.ImplementerId === enterpriseId)?.Status === "UnderAcceptance") {
                            element['IsImplementer'] = true;
                        }
                    }

                    if (element.CreatedBy === enterpriseId && element.Implementers && element.Implementers.find(x => x.ImplementerId === enterpriseId)) {
                        filteredImplementerIdeas.push(element);
                    }
                });

                if (filteredImplementerIdeas && filteredImplementerIdeas.length > 0) {
                    tempAllPinnedArray.push(...filteredImplementerIdeas);
                }
            }
            
            if ((values[0] && values[0]['value'].length === 0)
                && (values[1] && values[1]['value'].length === 0)
                && (values[2] && values[2]['value'].length === 0)
                && (filteredImplementerIdeas && filteredImplementerIdeas.length === 0)) {
                setPinnedDataMore(false);
            }
            else {                
                let tempAllSortedPinnedArray = tempAllPinnedArray.sort((a, b) => -a.GetIdeaTillDate.localeCompare(b.GetIdeaTillDate));
                setPinnedDataArray([...pinnedDataArray, ...tempAllSortedPinnedArray]);
            }
        });
    }

    // to refesh comments count
    const getAllIdeaDataRefresh = useCallback(
        () => {
            getAllIdeaDataRefresIdeaFeed();
        }, []
    );

    const firstFeedDataList = useMemo(() => (
                                            isMobile ? 
                                            <InfiniteScroll
                                                dataLength={firstFeedData.length}
                                                next={fetchMoreData}
                                                hasMore={false}
                                                loader={<Spinner appearance="primary" label="" labelPosition="after" size="medium" />} 
                                                scrollableTarget="scrollableDivMobile"
                                                refreshFunction={getAllIdeaDataRefresh}
                                                pullDownToRefresh
                                                pullDownToRefreshThreshold={50}
                                                releaseToRefreshContent={
                                                    <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                                                }
                                             >
                                                {
                                                    firstFeedData && firstFeedData.length > 0 && firstFeedData.map(idea => {

                                                        return <IdeaFeed ideaData={idea} enterprise={name} enterpriseId={enterpriseId} getAllIdeaDataRefresh={getAllIdeaDataRefresh} setErrorMobile={setErrorMobile}
                                                            onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />


                                                    })
                                                }
                                            </InfiniteScroll>
                                             :           
                                            <InfiniteScroll
                                                dataLength={firstFeedData.length}
                                                next={fetchMoreData}
                                                hasMore={false}
                                                loader={<Spinner appearance="primary"  label="" labelPosition="after" size="medium" />}                                                
                                             >
                                                {
                                                    firstFeedData && firstFeedData.length > 0 && firstFeedData.map(idea => {

                                                        return <IdeaFeed ideaData={idea} enterprise={name} enterpriseId={enterpriseId} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                                            onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />


                                                    })
                                                }
                                                </InfiniteScroll>), [firstFeedData])


    const mainFeedDataList = useMemo(() => (
                                            isMobile ?
                                                <InfiniteScroll
                                                    dataLength={infiniteScrollIdeas.length}
                                                    next={fetchMoreData}
                                                    hasMore={more}
                                                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                                                    scrollableTarget="scrollableDivMobile"
                                                    refreshFunction={getAllIdeaDataRefresh}
                                                    pullDownToRefresh
                                                    pullDownToRefreshThreshold={50}
                                                    releaseToRefreshContent={
                                                        <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                                                    }
                                                >
                                                    {
                                                        infiniteScrollIdeas && infiniteScrollIdeas.length > 0 && infiniteScrollIdeas.map(idea => {

                                                            return <IdeaFeed ideaData={idea} enterprise={name} enterpriseId={enterpriseId} getAllIdeaDataRefresh={getAllIdeaDataRefresh} setErrorMobile={setErrorMobile}
                                                                onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />


                                                        })
                                                    }
                                                </InfiniteScroll>
                                                :
                                                <InfiniteScroll
                                                    dataLength={infiniteScrollIdeas.length}
                                                    next={fetchMoreData}
                                                    hasMore={more}
                                                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                                                >
                                                    {
                                                        infiniteScrollIdeas && infiniteScrollIdeas.length > 0 && infiniteScrollIdeas.map(idea => {

                                                            return <IdeaFeed ideaData={idea} enterprise={name} enterpriseId={enterpriseId} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                                                onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />


                                                        })
                                                    }
                                                </InfiniteScroll>), [infiniteScrollIdeas, lastGetIdeaTillDate])

    
    
    if (isMobile) {

        return (
            <div id="scrollableDivMobile" style={{ height: '90vh',overflowY: 'auto'}}>
            {
                firstFeedData.length && privateDataArray.length == 0 && pinnedDataArray.length == 0 && contestDataArray.length == 0 && allSharedData.length == 0 && allpromotionalData.length == 0 && popularDataArray.length == 0 && filteredDataArray.length == 0 ?
                    firstFeedDataList : null
            }
            {
                privateDataArray.length == 0 && pinnedDataArray.length == 0 && contestDataArray.length == 0 && allSharedData.length == 0 && allpromotionalData.length == 0 && popularDataArray.length == 0 && filteredDataArray.length == 0 ?
                    mainFeedDataList : null
            }
            { (privateDataArray != null && privateDataArray != undefined && privateDataArray.length > 0) ?
                <InfiniteScroll
                    dataLength={privateDataArray.length}
                    next={fetchMorePrivateData}
                    hasMore={privateDataArray[0] !== "no data available" ? privateDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                    scrollableTarget="scrollableDivMobile"
                    refreshFunction={getAllIdeaDataRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    releaseToRefreshContent={
                        <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                    }
                >
                    {
                        (privateDataArray != null && privateDataArray != undefined && privateDataArray.length > 0) ?
                            privateDataArray.map(idea => {
                                if (idea == "no data available") {
                                    return <div className="no-data-available">No private idea available</div>
                                }
                                else {
                                    return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh} setErrorMobile={setErrorMobile}
                                        enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                                }
                            }) : ""
                    }
                </InfiniteScroll> : null
            }
            {contestDataArray != null && contestDataArray != undefined && contestDataArray.length > 0 ?
                <InfiniteScroll
                    dataLength={contestDataArray.length}
                    next={fetchMoreContestData}
                    hasMore={contestDataArray[0] !== "no data available" ? contestDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                    scrollableTarget="scrollableDivMobile"
                    refreshFunction={getAllIdeaDataRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    releaseToRefreshContent={
                        <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                    }
                >
                    {
                        contestDataArray != null && contestDataArray != undefined && contestDataArray.length > 0 ?
                            contestDataArray.map(idea => {
                                if (idea == "no data available") {
                                    return <div className="no-data-available">No contest idea available</div>
                                }
                                else {
                                    return <IdeaFeed ideaData={idea} getAllIdeaDataRefresh={getAllIdeaDataRefresh} setErrorMobile={setErrorMobile}
                                        enterprise={name} enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                                }
                            }) : ""
                    }
                </InfiniteScroll> : null
            }
            {(pinnedDataArray != null && pinnedDataArray != undefined && pinnedDataArray.length > 0) ?
                <InfiniteScroll
                    dataLength={pinnedDataArray.length}
                    next={fetchMorePinnedData}
                    hasMore={pinnedDataArray[0] !== "no data available" ? pinnedDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                    scrollableTarget="scrollableDivMobile"
                    refreshFunction={getAllIdeaDataRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    releaseToRefreshContent={
                        <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                    }
                >
                    {(pinnedDataArray != null && pinnedDataArray != undefined && pinnedDataArray.length > 0) ?
                        pinnedDataArray.map(idea => {
                            if (idea == "no data available") {
                                return <div className="no-data-available">No pinned idea available</div>
                            }
                            else {
                                return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh} setErrorMobile={setErrorMobile}
                                    enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                            }
                        }) : ""

                    }
                </InfiniteScroll> : null
            }
            {(allSharedData != null && allSharedData != undefined && allSharedData.length > 0) ?
                <InfiniteScroll
                    dataLength={allSharedData.length}
                    next={fetchMoreData}
                    hasMore={false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                    scrollableTarget="scrollableDivMobile"
                    refreshFunction={getAllIdeaDataRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    releaseToRefreshContent={
                        <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                    }
                >
                    {
                        (allSharedData != null && allSharedData != undefined && allSharedData.length > 0) ?
                            (allSharedData.map(idea => {
                                if (idea == "no data available") {
                                    return <div className="no-data-available">No shared idea available</div>
                                }
                                else {
                                    return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh} setErrorMobile={setErrorMobile}
                                        enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />

                                }

                            })) : ""

                    }
                </InfiniteScroll> : null
            }

            { (allpromotionalData != null && allpromotionalData != undefined && allpromotionalData.length > 0) ?
                <InfiniteScroll
                    dataLength={allpromotionalData.length}
                    next={fetchMoreData}
                    hasMore={false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                    scrollableTarget="scrollableDivMobile"
                    refreshFunction={getAllIdeaDataRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    releaseToRefreshContent={
                        <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                    }
                >
                    {(allpromotionalData != null && allpromotionalData != undefined && allpromotionalData.length > 0) ?
                        (allpromotionalData.map(idea => {
                            if (idea == "no data available") {
                                return <div className="no-data-available">No promotional feed available</div>
                            }
                            else {
                                return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                    enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} setErrorMobile={setErrorMobile}
                                    isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                            }
                        })) : ""
                    }
                </InfiniteScroll> : null
            }
            {popularDataArray != null && popularDataArray != undefined && popularDataArray.length > 0 ?
                <InfiniteScroll
                    dataLength={popularDataArray.length}
                    next={fetchMorePopularData}
                    hasMore={popularDataArray[0] !== "no data available" ? popularDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                    scrollableTarget="scrollableDivMobile"
                    refreshFunction={getAllIdeaDataRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    releaseToRefreshContent={
                        <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                    }
                >
                    {popularDataArray != null && popularDataArray != undefined && popularDataArray.length > 0 ?
                        popularDataArray.map(idea => {
                            if (idea == "no data available") {
                                return <div className="no-data-available">No popular idea available</div>
                            }
                            else {
                                return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh} setErrorMobile={setErrorMobile}
                                    enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                            }
                        }) : ""
                    }
                </InfiniteScroll> : null
            }
            {filteredDataArray != null && filteredDataArray != undefined && filteredDataArray.length > 0 ?
                <InfiniteScroll
                    dataLength={filteredDataArray.length}
                    next={fetchMoreFilteredData}
                    hasMore={filteredDataArray[0] !== "no data available" ? filteredDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                    scrollableTarget="scrollableDivMobile"
                    refreshFunction={getAllIdeaDataRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    releaseToRefreshContent={
                        <h6 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h6>
                    }
                >
                    {filteredDataArray != null && filteredDataArray != undefined && filteredDataArray.length > 0 ?
                        filteredDataArray.map(idea => {
                            if (idea == "no data available") {
                                return <div className="no-data-available">No idea available</div>
                            }
                            else {
                                return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh} setErrorMobile={setErrorMobile}
                                    enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                            }
                        }) : ""
                    }
                </InfiniteScroll> : null
            }
        </div>)
    }
    else {
        return (<>
            {
                firstFeedData.length && privateDataArray.length == 0 && pinnedDataArray.length == 0 && contestDataArray.length == 0 && allSharedData.length == 0 && allpromotionalData.length == 0 && popularDataArray.length == 0 && filteredDataArray.length == 0 ?
                    firstFeedDataList : null
            }
            {
                privateDataArray.length == 0 && pinnedDataArray.length == 0 && contestDataArray.length == 0 && allSharedData.length == 0 && allpromotionalData.length == 0 && popularDataArray.length == 0 && filteredDataArray.length == 0 ?
                    mainFeedDataList : null
            }
            { (privateDataArray != null && privateDataArray != undefined && privateDataArray.length > 0) ?
                <InfiniteScroll
                    dataLength={privateDataArray.length}
                    next={fetchMorePrivateData}
                    hasMore={privateDataArray[0] !== "no data available" ? privateDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                >
                    {
                        (privateDataArray != null && privateDataArray != undefined && privateDataArray.length > 0) ?
                            privateDataArray.map(idea => {
                                if (idea == "no data available") {
                                    return <div key={uniqid()} className="no-data-available">No private idea available</div>
                                }
                                else {
                                    return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                        enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                                }
                            }) : ""
                    }
                </InfiniteScroll> : null
            }
            {contestDataArray != null && contestDataArray != undefined && contestDataArray.length > 0 ?
                <InfiniteScroll
                    dataLength={contestDataArray.length}
                    next={fetchMoreContestData}
                    hasMore={contestDataArray[0] !== "no data available" ? contestDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                >
                    {
                        contestDataArray != null && contestDataArray != undefined && contestDataArray.length > 0 ?
                            contestDataArray.map(idea => {
                                if (idea == "no data available") {
                                    return <div className="no-data-available">No contest idea available</div>
                                }
                                else {
                                    return <IdeaFeed ideaData={idea} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                        enterprise={name} enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                                }
                            }) : ""
                    }
                </InfiniteScroll> : null
            }
            {(pinnedDataArray != null && pinnedDataArray != undefined && pinnedDataArray.length > 0) ?
                <InfiniteScroll
                    dataLength={pinnedDataArray.length}
                    next={fetchMorePinnedData}
                    hasMore={pinnedDataArray[0] !== "no data available" ? pinnedDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                >
                    {(pinnedDataArray != null && pinnedDataArray != undefined && pinnedDataArray.length > 0) ?
                        pinnedDataArray.map(idea => {
                            if (idea == "no data available") {
                                return <div className="no-data-available">No pinned idea available</div>
                            }
                            else {
                                return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                    enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                            }
                        }) : ""

                    }
                </InfiniteScroll> : null
            }
            {(allSharedData != null && allSharedData != undefined && allSharedData.length > 0) ?
                <InfiniteScroll
                    dataLength={allSharedData.length}
                    next={fetchMoreData}
                    hasMore={false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                >
                    {
                        (allSharedData != null && allSharedData != undefined && allSharedData.length > 0) ?
                            (allSharedData.map(idea => {
                                if (idea == "no data available") {
                                    return <div className="no-data-available">No shared idea available</div>
                                }
                                else {
                                    return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                        enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />

                                }

                            })) : ""

                    }
                </InfiniteScroll> : null
            }

            { (allpromotionalData != null && allpromotionalData != undefined && allpromotionalData.length > 0) ?
                <InfiniteScroll
                    dataLength={allpromotionalData.length}
                    next={fetchMoreData}
                    hasMore={false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                >
                    {(allpromotionalData != null && allpromotionalData != undefined && allpromotionalData.length > 0) ?
                        (allpromotionalData.map(idea => {
                            if (idea == "no data available") {
                                return <div className="no-data-available">No promotional feed available</div>
                            }
                            else {
                                return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                    enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea}
                                    isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                            }
                        })) : ""
                    }
                </InfiniteScroll> : null
            }
            {popularDataArray != null && popularDataArray != undefined && popularDataArray.length > 0 ?
                <InfiniteScroll
                    dataLength={popularDataArray.length}
                    next={fetchMorePopularData}
                    hasMore={popularDataArray[0] !== "no data available" ? popularDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                >
                    {popularDataArray != null && popularDataArray != undefined && popularDataArray.length > 0 ?
                        popularDataArray.map(idea => {
                            if (idea == "no data available") {
                                return <div className="no-data-available">No popular idea available</div>
                            }
                            else {
                                return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                    enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                            }
                        }) : ""
                    }
                </InfiniteScroll> : null
            }
            {filteredDataArray != null && filteredDataArray != undefined && filteredDataArray.length > 0 ?
                <InfiniteScroll
                    dataLength={filteredDataArray.length}
                    next={fetchMoreFilteredData}
                    hasMore={filteredDataArray[0] !== "no data available" ? filteredDataMore : false}
                    loader={<Spinner label="" labelPosition="after" size="medium" />}
                >
                    {filteredDataArray != null && filteredDataArray != undefined && filteredDataArray.length > 0 ?
                        filteredDataArray.map(idea => {
                            if (idea == "no data available") {
                                return <div className="no-data-available">No idea available</div>
                            }
                            else {
                                return <IdeaFeed ideaData={idea} enterprise={name} getAllIdeaDataRefresh={getAllIdeaDataRefresh}
                                    enterpriseId={enterpriseId} onEditPrivateIdea={handleEditPrivateIdea} isExploreIdea={false} showError={setError} updateIdeaFeed={updateInfiniteScrollIdeas} landingPage={true} key={uniqid()} />
                            }
                        }) : ""
                    }
                </InfiniteScroll> : null
            }
        </>)
    }
    
    
}
)

export default InfiniteScrollFeed;