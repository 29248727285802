import React from 'react';
import PropTypes from "prop-types";
import { Card, CardHeader, Text, Button, Caption1 } from '@fluentui/react-components';
import { DismissRegular, ArrowDownloadRegular } from '@fluentui/react-icons';

interface AttachmentProps{
    header:any, 
    description?:any, 
    action?:any, 
    clickAction?:any,
    onCardClick?:any
}

const Attachment = ({ header, description, action, clickAction, onCardClick }: AttachmentProps) => {

    return(
        <Card className='attachment'>
            <CardHeader
                // onClick={onCardClick}
                header={
                <Text onClick={onCardClick} weight="semibold">
                    {header}
                </Text>
                }
                description={
                <Caption1>{description}</Caption1>
                }
                action={
                    action === 'remove' ? 
                    <Button 
                        className='attachment-button'
                        icon={<DismissRegular />}
                        appearance='subtle'
                        onClick={clickAction}
                        aria-label='Remove attachment'
                        style={{margin: 0}}
                    /> : action === 'download' ?
                    <Button 
                        className='attachment-button'
                        icon={<ArrowDownloadRegular />}
                        appearance='subtle'
                        onClick={clickAction}
                        aria-label='Download attachment'
                        style={{margin: 0}}
                    /> : <></>
                }
            />
        </Card>
    )
}

Attachment.prototype = {
    header:PropTypes.string.isRequired
}

export default Attachment;