import axios from "axios";
import React from 'react';
import { connect } from "react-redux";
import { authentication, app } from "@microsoft/teams-js";
import { appInsights} from "./../components/appInsight/AppInsight";
import { SeverityLevel } from "@microsoft/applicationinsights-web";


interface HttpInterceptorProps {
    token: string;
    entityId?: string;
}

let instance = axios.create();

export const getMethod = (getUrl: string, cancelToken?): Promise<any> =>  {
    // console.log("getUrl ", getUrl);
    if (getUrl.includes("DownloadS3File")) {
        instance.defaults.responseType = "blob";
    }
    else {
        instance.defaults.responseType = "json";
    }

    return instance.get(getUrl, cancelToken)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                let errorResult = error.response;
                let err = setError(errorResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "GET Method", err  } });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                let errorResult = error.response;
                let err = setError(errorResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "GET Method", err } });
            }
            //console.log(error.config);
        });
}


export const postMethod = async (postURL: string, data: any, config?: any) => {
    instance.defaults.responseType = "json";
    //console.log(postURL, data, config, instance.post, instance.defaults.responseType);
    return await instance.post(postURL, data, config)
        .catch(function (error) {
            if (error.response) {
                let errResult = error.response;
                let err = setError(errResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "POST Method", err } });
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            }
            else {
                if (axios.isCancel(error)) {
                    console.log('Request cancelled successfully.');
                    return;
                }
                // Something happened in setting up the request that triggered an Error
                let errResult = error.response;
                let err = setError(errResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "POST Method", err } })
                console.log("Error", error.message);
            }
            console.log(error.config);
        });
}


export const putMethod = (putURL: string, data: any, config?: any) => {
    instance.defaults.responseType = "json";
    return instance.put(putURL, data, config)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                let errResult = error.response;
                // let err = JSON.stringify(errResult, Object.getOwnPropertyNames(errResult));
                let err = setError(errResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "PUT Method", err } })
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                let errResult = error.response;
                //let err = JSON.stringify(errResult, Object.getOwnPropertyNames(errResult));
                let err = setError(errResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "PUT Method", err } })
            }
            console.log(error.config);
        });
}

export const patchMethod = (putURL: string, data: any, config?: any) => {
    instance.defaults.responseType = "json";
    return instance.patch(putURL, data, config)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                let errResult = error.response;
                // let err = JSON.stringify(errResult, Object.getOwnPropertyNames(errResult));
                let err = setError(errResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "PATCH Method", err } })
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                let errResult = error.response;
                //let err = JSON.stringify(errResult, Object.getOwnPropertyNames(errResult));
                let err = setError(errResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "PATCH Method", err } })
            }
            console.log(error.config);
        });
}


export const deleteMethod = (deleteURL: string, data: any, config?: any) => {
    instance.defaults.responseType = "json";
    return instance.delete(deleteURL, config)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                let errResult = error.response;
                //let err = JSON.stringify(errResult, Object.getOwnPropertyNames(errResult));
                let err = setError(errResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "DELETE Method", err } })
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                let errResult = error.response;
                //let err = JSON.stringify(errResult, Object.getOwnPropertyNames(errResult));
                let err = setError(errResult);
                appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "DELETE Method", err } })
            }
            console.log(error.config);
        });
}

export async function getTeamstoken() {
    console.log('inside this getTeamsToken method line number 184');
    let loginPromise = new Promise<any>((resolve, reject) => {
        authentication.getAuthToken().then((token) => {
            //console.log('line 187>> on success', token);
            app.notifySuccess();
            resolve(token);
            //console.log("token", token);
        }).catch((message) => {
            //console.log('on catch of getTeamsToken',message);
            reject({ loginToken: null, reason: message });
            app.notifyFailure({
                reason: app.FailedReason.AuthFailed,
                message
            });
        })
            //,
            //resources: [process.env.MYTEAMSAPPTAB_APP_URI as string]
    });

    let result = await loginPromise;
   // console.log('interceptor get teams token method',result);
    return result;
}

function setError(errorResult) {
    let typeOfError = typeof errorResult;
    let err = "";
    if ((typeOfError !== "undefined") && typeOfError !== 'object') {
        err = JSON.stringify(errorResult, Object.getOwnPropertyNames(errorResult));
    } else {
        err = errorResult;
    }
    return err;
}

class HttpService extends React.Component<HttpInterceptorProps> {
   
    accessToken = null;

    // Request interceptor
    requestInterceptor = instance.interceptors.request.use(
         (config) => {
            //TOKEN is hardcoded temporarily
            //const token = this.props.token;
            //console.log(this.props.token);
            const EnterpriseId = this.props?.entityId;
            //console.log(EnterpriseId, this.props?.entityId, config);
            //console.log(">>> line number 226 ", getTeamstoken(), config);
           // return getTeamstoken().then((response) => {
                //console.log(">>>>inside getteamstoaken interceptor<<<<", config);
                if (this.props.token) {
                    const token = this.props.token;
                    //console.log('line number 230',this.props.token, config);
                    if (Object.keys(token).length > 0) {
                        //console.log('>>line number 232', token, config);
                        config.headers["Authorization"] = "Bearer " + token;
                        config.headers["EnterpriseId"] = `${EnterpriseId}`;
                        //console.log('>>request interceptor 237',config);
                        return config;
                    } else if(Object.keys(this.props?.token).length > 0) {
                        //console.log('>>line number 240', token, config);
                        config.headers["Authorization"] = "Bearer " + token;
                        config.headers["EnterpriseId"] = `${EnterpriseId}`;
                        //console.log('>>request interceptor 243',config);
                        return config;
                    } else {
                        return;
                    }
                } else {
                    //console.log('>> line 241', config);
                    return config;
                }
           // }).catch(err => { console.log("token error:", err); return config; });            
            // config.headers['Content-Type'] = 'application/json';      
            //return config;
        },
        error => {
            let err = JSON.stringify(error, Object.getOwnPropertyNames(error));
            appInsights.trackException({ exception: new Error("LIC-API Call"), severityLevel: SeverityLevel.Critical, properties: { component: "EmpExp", method: "interceptors Method", err } })
            Promise.reject(error);
        });

    // Add a response interceptor
    responseInterceptor = instance.interceptors.response.use(
        (response) => {
            return response.data;
        },
        function (error) {
            const originalRequest = error.config;
            // All error code handlings here
            if (error.response.status === 401 && !originalRequest._retry) {
                console.log("ERROR ", error.response);
            }

            // return Error object with Promise
            return Promise.reject(error);
        }
    );

    render() {
       // console.log(this.props);
        // console.log(`EnterpriseId ${this.props.entityId}`);
        return (<> </>);
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.fetchTokenReducer.data,
        error: state.fetchTokenReducer.error,
        entityId: state.entityReducer.entityId
    }
}


export default connect(mapStateToProps)(HttpService);