import {takeEvery,call, put} from 'redux-saga/effects';
import {types} from '../actions/types';
import axios from 'axios';
import {fetchDataFailure, fetchDataSuccess} from '../actions/fetch-data-actions';

function* asyncFetchRequest(action){
    // console.log("which action",action);
    
    try{
        const url= `https://jsonplaceholder.typicode.com/todos/${action.payload}`
        const response = yield call(()=>axios.get(url))
      //  console.log("response",response);
        yield put(fetchDataSuccess(response.data.title));

    }
    catch(error){
        // console.log(error);
        yield put (fetchDataFailure("error"))
        
    }
}

export function* watchFetchDataSaga(){
    yield takeEvery(types.SEND_REQUEST, asyncFetchRequest)
}