import React, { useEffect, useState } from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { hiddenContentStyle, mergeStyles } from "@fluentui/react/lib/Styling";
import { ContextualMenu } from "@fluentui/react/lib/ContextualMenu";
import { useId, useBoolean } from "@fluentui/react-hooks";
import {
    IDropdownStyles,
} from "@fluentui/react/lib/Dropdown";
import { addPinUnpinDetailsPromotionalFeed } from "../../api/IdeaApi";
import {
    getConfigurationList,
} from "../../api/PeopleApi";
import { useHistory } from "react-router-dom";

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu,
    keepInBounds: true,
};
const screenReaderOnly = mergeStyles(hiddenContentStyle);
const dialogContentProps = {
    type: DialogType.normal,
    title: "What's New?",
    closeButtonAriaLabel: "Close"
};

export const Announcements = ({closeModal}) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const labelId: string = useId("dialogLabel");
    const subTextId: string = useId("subTextLabel");
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const history = useHistory();

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: dialogStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable, labelId, subTextId]
    );


    const onCancelClick = () => {
        closeModal();
        toggleHideDialog();
    }

    const getUrl = () => {        
        const URL = process.env.REACT_APP_RELEASENOTES_URL;
        return URL;
    }

    const gotToV360HomePage = () => {
        getConfigurationList('').then((response) => {
            if (response) {
                for (const obj of response["value"]) {
                    for (const Key in obj) {
                        if (obj["Key"] === "v360ContestIdFY2023") {
                            let v360ContestId = obj["Value"];
                            history.push(`/view-contest-homepage-v360/${v360ContestId}`);
                        }
                    }
                }
            }
        });
    }

    return (
        <>
            <Dialog
                hidden={hideDialog}
                onDismiss={onCancelClick}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                className="dialogBox-announcement"
            >
                <div className="release-note-entry">
                    <span>
                        <strong><a href={getUrl()} target="_blank" style={{ color: "#a100ff" }}>Release Notes</a></strong>  
                    </span>
                </div>
                <div className="active-contest">
                    <span className="arrow">&#62; </span>
                    <span>
                        <strong>Launching sustainability contest and invention of the year contest on 16 Jan. Stay tuned !!</strong>
                    </span>
                </div>
                <div className="active-contest">
                    <span className="arrow">&#62; </span>
                    <span>
                        <strong>The V360 Awards 2023 are back on myInnovation!!</strong> The V360s celebrate the people who innovate to create 360 value. The winners will showcase how we embrace change and deliver on the promise of technology and human ingenuity. Submissions will be accepted beginning Tuesday, November 15, through Thursday, December 15. <a onClick={() => gotToV360HomePage()} style={{ color: "#a100ff", cursor: 'pointer' }}>Click here to know more</a>
                    </span>
                </div>
                <div className="active-contest">
                    <span className="arrow">&#62; </span>
                    <span>
                        You can <strong>now select the mentor for the ideas submitted</strong> and take the ideas to next level. <a href="https://kxdocuments.accenture.com/contribution/a5bc9f1a-95a2-4189-8860-cbd1d4c1f4a0" target="_blank" style={{ color: "#a100ff" }}>Click here </a> to view the steps for selecting mentor.
                    </span>
                </div>
                <div className="active-contest">
                    <span className="arrow">&#62; </span>
                    <span>
                        <b>Stay Connected with InnoVerse - Innovation Community.</b> Innoverse a newly launched yammer channel to share updates on innovation, client stories, path-breaking ideas, events, and a lot more. <a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMDU2NTU0NTE2NDgifQ/all" target="_blank" style={{ color: "#a100ff" }}>Click here </a> to navigate
                    </span>
                </div>
                 <div className="active-contest">
                    <span className="arrow">&#62; </span><span>We’re bringing Collaboration &amp; Innovation at your finger tips to Innovate anytime, anywhere. The Living Innovation Channel in Microsoft Teams is <b>now available as messaging extension app in your chat window!</b></span>
                </div>
               {/* <div className="active-contest">
                    <span className="arrow">&#62; </span><span>Global Technology Innovation Contest is live ! Go ahead and submit idea. </span>
                </div> */}
                {/* <div className="active-contest">
                    <span className="arrow">&#62; </span><span>Innovate more, Living Innovation Channel is on teams mobile now. </span>
                </div> */}
               
            </Dialog>
        </>
    );
};
