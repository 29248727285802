import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

export const NudgePreference = ({}) => {
  const history = useHistory();

  const navigateToNudgePreference = () => {
    history.push({
      pathname: "/nudgepreference",
    });
  };
  return (
    <div
      className="left-side"
      style={{ cursor: "pointer" }}
      onClick={navigateToNudgePreference}
    >
      <div className="header-div">
        <p className="patent-header">Nudge Preferences</p>
      </div>
      <div className="edit-icon" onClick={navigateToNudgePreference}>
        {/*<i className="ms-Icon ms-Icon--EditNote" aria-hidden="true"></i>*/}
        <span className="icon Icon--EditNote">
          <svg
            width="20"
            height="20"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 20 20"
          >
            <g fill="none">
              <path
                d="M13.245 2.817a2.783 2.783 0 0 1 4.066 3.796l-.13.14l-9.606 9.606a2.001 2.001 0 0 1-.723.462l-.165.053l-4.055 1.106a.5.5 0 0 1-.63-.535l.016-.08l1.106-4.054c.076-.28.212-.54.398-.76l.117-.128l9.606-9.606zm-.86 2.275L4.346 13.13a1 1 0 0 0-.215.321l-.042.123l-.877 3.21l3.212-.875a1 1 0 0 0 .239-.1l.107-.072l.098-.085l8.038-8.04l-2.521-2.52zm4.089-1.568a1.783 1.783 0 0 0-2.402-.11l-.12.11l-.86.86l2.52 2.522l.862-.86a1.783 1.783 0 0 0 .11-2.402l-.11-.12z"
                fill="#626262"
              />
            </g>
            <rect x="0" y="0" width="20" height="20" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </span>
      </div>
      <div className="count-div">
        <span
            className="iconify nudge-icon"
            data-icon="fluent:tap-double-24-regular"
            data-inline="false"
        ></span>
        <span className="text-span">
                  These settings will reflect in your 'Living Innovation Channel' MS Teams App's activity feed and banners.
        </span>
      </div>
      {/* <div className="header-div">
          <p className="patent-header">Mentor Approval</p>
        </div>
        <div className="count-div">
          <span className="text-span">Ideas pending for your approval </span>
          <span className="count">{mentorPendingCount ? mentorPendingCount : 0}</span>
        </div>
        <div className="count-div">
          <span className="text-span">Patent Requests pending for your approval </span>
          <span className="count">{patentPendingCount ? patentPendingCount : 0}</span>
        </div> */}
    </div>
  );
};

export default connect()(NudgePreference);
