// @ts-nocheck
import React, { useEffect, useState } from "react";
import { List } from '@fluentui/react'
import { Label, Input } from '@fluentui/react-components';
import { getPeoplePickerData } from './../api/PeopleApi';

interface PeoplePickerInputProps {
    labelName: any;
    selectedPerson: any;
    showLabel?: any;
    backgroundColor?: any
    userProfileIcon?: any
    fromMobile?: any
    fromDynamicForm?: any;
}
export default function PeoplePickerInput({ labelName, selectedPerson, showLabel, backgroundColor, userProfileIcon, fromMobile, fromDynamicForm }: PeoplePickerInputProps) {
    const [showPickerList, setShowPickerList] = useState<boolean>(true);
    const [pickerData, setPeoplePickerData] = useState<any>([]);
    const [valueKeyTeamMember, setValKeyMember] = useState('');
    const [selectedIndex, setSelectedIndex] = useState<any>(-1);


    const handleChangePeoplePicker = (e, item) => {

        setPeoplePickerData([]);
        setValKeyMember();
        //console.log(item);
        if (item.value.length > 3) {
            setShowPickerList(true);
            getPeoplePickerData(item.value)
                .then((res) => {
                    try {
                        if (res && res['ResponseCode'] === '107' || res['ResponseCode'] === '109') {

                        } else {
                            if (res && res['length'] > 0) {
                                //console.log(res);
                                const data1 = res.map(item => ({ key: item.EnterpriseId, content: item.EnterpriseId }));
                                setPeoplePickerData(data1);
                            }
                        }
                    } catch (error) {
                        //console.log(error)
                    }
                });
        }
    }


    const handleOnClick = (item) => {
        if (item !== null) {
            const keyMember = item;
            setShowPickerList(false);
            if (fromDynamicForm) {
                selectedPerson({ ...fromDynamicForm, "enterpriseId": keyMember })
            }
            else {
                selectedPerson(keyMember);
            }
            setValKeyMember('');
        }
    }


    return (
        <div className="idea-shared-with">
            {/*<Input label={labelName} placeholder='Search By Enterprise ID' id="inside-label" onChange={handleChangePeoplePicker} value={valueKeyTeamMember} />*/}
            {
                labelName === "" ? null :
                <div>
                    {labelName && <div>
                        { labelName !== "CALname" ? labelName.slice(-1) === "*" ?
                            <Label required>
                                {labelName.slice(0, labelName.length - 1)}
                            </Label> :
                            <Label>
                                {labelName}
                            </Label> : null
                        }
                    </div>}
                    <Input style={{ display: 'flex'}}
                     placeholder={labelName == "CALname" ? 'Enter CAL EID' : 'Search By Enterprise ID'} disabled={showLabel == ""} id="inside-label" onChange={handleChangePeoplePicker} value={valueKeyTeamMember} className={backgroundColor ? "people-picker-whitebackground" : showLabel == "" ? " disabled-field" : ""}/>
                    {showPickerList ?
                        <List
                            items={pickerData}
                            onRenderCell={(item) => (
                                <div
                                    key={item.key}
                                    onClick={() => handleOnClick(item)}
                                    className='peoplePickerList'
                                >
                                    {item.content}
                                </div>
                            )}
                        />  : ''}
                </div>
            }
             
            {userProfileIcon ? <div className={fromMobile ? "profile-status-icon-mobile" : "profile-status-icon"}><span className="iconify" data-icon="gridicons:user-circle" /></div> : ""}
           
        </div>
    )
}
