import React, { useEffect, useState, useMemo } from 'react';
import { Image } from 'react-bootstrap';
// import Gallery from 'react-grid-gallery';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useSelector, RootStateOrAny, shallowEqual } from "react-redux";
// import { AzureMP } from 'react-azure-mp'
var uniqid = require('uniqid');

// export const ImageFeed = (images) => {
//     <div>
//         <Gallery images={images} />
//     </div>
// // }
// const imageCounter = 1;

// export const VideosFeed = (url) => {
//     return (<div className='idea-feed box-feed-entry-wrapper video-feed-wrapper'>
//         {/* <ReactPlayer url="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_lgulrchu/uiConfId/27188242" /> */}
//         {/* <embed id="kmsembed-1_lgulrchu" width="100%" height="300" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_lgulrchu/uiConfId/27188242" className="kmsembed" /> */}
//         <embed id="kmsembed-1_lgulrchu" width="100%" height="300" src={url} className="kmsembed" />
//     </div>)
// }

function VideoFeed({ promotionalfeedid, mediaFiles }) {
    const [image, setImage] = useState([]);
    const [video, setvideo] = useState([]);
    const promotionalMediaFiles = useSelector((state: RootStateOrAny) => state.fetchDataReducer.promotionalMediaFiles, shallowEqual);

    useEffect(() => {
        // console.log(mediaFiles ? mediaFiles : '');
        const images = [];
        promotionalMediaFiles?.filter(media => media.promotionalfeedid == promotionalfeedid).map(media => {
            media['mediafiles'].map(file => {
                const binaryData = 'data:image/jpeg;base64,' + file['mediafilebinarydata'];
                const obj = {
                    src: binaryData,
                    thumbnail: binaryData,
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,
                    caption: file['filenm']
                };
                images.push(obj);
            })
        })
        setImage(images);

    }, [mediaFiles, promotionalMediaFiles])

    useEffect(() => {
       // console.log(mediaFiles ? mediaFiles : '');
        const videos = [];
        mediaFiles?.map(media => {
            if (media['filetype'] === '.mp4') {
                videos.push(media['fileurl']);
            } 
        })
        setvideo(videos);
    }, [mediaFiles])

    //const videoRender = useMemo(() => (<div className="video-feed-wrapper">
    //                                    {video.map(vid => {
    //                                        /*return (<embed id="kmsembed-1_lgulrchu" width="100%" height="300" src={vid} className="kmsembed" key={uniqid()} />)*/

    //                                        /*return (<video width="100%" height="300"
    //                                              src={vid} className="kmsembed" key={uniqid()} controls controlsList="nodownload"></video>)*/

    //                                        //let abc = "https://aesmediaserviceaccname-aase.streaming.media.azure.net/713ab827-6b0d-4264-bcc0-b284df1bd699/Accenture Living Innovation Chan.ism/manifest"
    //                                        return (
    //                                            <AzureMP
    //                                                width="100%" height="300"
    //                                                className="azuremediaplayer amp-default-skin kmsembed"
    //                                                options={{ controls: true, autoplay: false }}
    //                                                src={[{ src: vid, type: "application/vnd.ms-sstr+xml" }]}
    //                                            />
    //                                        )
    //                                    })}
    //                                    </div>), [video])

    return (
        <>
            {video && video.length ? <div className="video-feed-wrapper">
                {video.map((vid,index) => {
                    return (
                        <></>
                        // <AzureMP key={index}
                        //     width="100%" height="300"
                        //     className="azuremediaplayer amp-default-skin kmsembed"
                        //     options={{ controls: true, autoplay: false }}
                        //     src={[{ src: vid, type: "application/vnd.ms-sstr+xml" }]}
                        // />
                    )
                })}
            </div> : ''}
            {image && image.length ? <></>
                // <Carousel showArrows={true} autoPlay dynamicHeight={true} showThumbs={false} showStatus={false} showIndicators={false}>
                //     {image.map(img => {
                //         return (<div key={uniqid()}>
                //             <img alt="" src={img.src} />
                //             {/* <p className="legend">{img.caption}</p> */}
                //         </div>)
                //     })}
                // </Carousel>
                : ''}
        </>

    )
}

export default React.memo(VideoFeed);