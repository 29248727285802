// @ts-nocheck
import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactModal from "react-modal";
import { Icon } from '@fluentui/react/lib/Icon';
import { useTeams } from "@microsoft/teamsfx-react";
import SelectInput from '../../common/SelectInput';
import TextAreaInput from './../../common/TextAreaInput';
import { GenericMessageBar } from "../../common/MessageBar";
import { saveUserFeedback } from '../../api/UserApi';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { RootStateOrAny, useSelector } from "react-redux";
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

// initializeIcons();

const FeedbackPopup = ({ showModal, handleCloseModal, enterpriseId }) => {
    const [{ theme }] = useTeams();
    const [choice, setChoice] = useState<string>("");
    const [msg, setMsg] = useState<string>("");
    const [feedbackType, setFeedbackType] = useState('');
    const [comments, setComments] = useState<string>();
    const feedbackTypes = ['General', 'Idea Feed', 'Nudges', 'Submit Idea', 'My Preferences', 'My Expertise', 'Patentibilty'];
    const isMobile = useSelector((state: RootStateOrAny) => state.entityReducer.isMobile);

    const modelStyles = {
        overlay: {
            backgroundColor: 'rgba(4, 16, 29, 0.7)',
        },
        content: {
            width: '54.26vw',
            maxWidth: '54.26vw',
            border: '1px solid black',
            borderRadius: 'none',
            padding: '1.812vw'
        }
    }

    const resetchoice = () => {
        setChoice(undefined);
    }

    const handleOnChangeSelect = (item) => {
        const { id, optionValue } = item;
        setFeedbackType(optionValue);
    }

    const onChangeComments = (item) => {
        const { name, value } = item;
        setComments(value);
    }

    const setError = (errorMsg, type) => {
        setMsg(errorMsg);
        setChoice(type);
    }

    const onSubmitFeedback = () => {
        if (feedbackType && feedbackType !== '' && feedbackType.length > 0 &&
            comments && comments !== '' && comments.length > 0) {
            const data = { CategoryNm: feedbackType, FeedbackDesc: comments, CreatedBy: enterpriseId };

            saveUserFeedback(data).then((response) => {
                if (response && response['ResponseCode'] === "104") {
                    handleCloseModal(true);
                }
                else {
                    setError('Feedback submission failed', "error");
                }
            });
        }
        else {
            setError('Please enter all fields.', 'error');
        }
    }

    const overlayClassName = {
        base: "animation-modal-overlay feedback-wrapper-mobile-popup",
        afterOpen: "animation-modal-overlay--after-open",
        beforeClose: "animation-modal-overlay--before-close"
    };

    return (
        <FluentProvider theme={teamsLightTheme}>
            <ReactModal isOpen={showModal} style={modelStyles} closeTimeoutMS={500} overlayClassName={overlayClassName}>
                <GenericMessageBar choice={choice} msg={msg} resetMsg={resetchoice} />
                <div className={isMobile? "feedback-wrapper-mobile": "feedback-wrapper"}>
                    <div className="feedback-mail-header">
                        <div className="header">
                            <h6>Provide Feedback</h6>
                        </div>
                        <div className="feedback-mail">
                            <a href={"mailto:" + (process.env.REACT_APP_FEEDBACK_EMAIL)} onClick={() => handleCloseModal(false)}>
                                <Icon iconName="Mail" className="icon-mail" />
                                <span className="email-button">Give Feedback through E-Mail</span>
                            </a>
                        </div>
                    </div>
                    
                    <div className="feedback-dropdown">
                        <div className="section-label">
                            <span>Section</span>
                            <span className="asterisk">*</span>
                        </div>
                        <div className="dropdown-control">
                            <SelectInput inputItems={feedbackTypes} name='feedbackType'
                                onChangeSelect={handleOnChangeSelect} content=""
                                placeholder='Please Select' size='medium' selectValue={feedbackType}
                                className="margin-right-10rem" disabled={false} />
                        </div>
                    </div>
                    <div className="comment-feedback">
                        <TextAreaInput onChangeField={onChangeComments} content="Feedback *"
                            placeholder='Enter Feedback' size='medium' name='Feedback' valueTextArea={comments}
                            maxLength={500} className="" disabled={false} />
                    </div>
                    <div className="footer-section-buttons">
                        <button className="cancel-btn" onClick={() => handleCloseModal(false)}>Cancel</button>
                        <button className="submit-btn" onClick={onSubmitFeedback}>Submit</button>
                    </div>
                </div>
            </ReactModal>
        </FluentProvider>
    )
}

export default connect()(FeedbackPopup);