import axios from 'axios';
import { serviceMethod } from './config-reader';
import { deleteMethod, getMethod, postMethod, putMethod } from './http-interceptor-service';

export const GetStagesByChallengeIdOrContestId = (id, isChallenge, arrStageStatus =[], pageIndex=0) => {
    let method = serviceMethod.stageVoteService.GetStagesByChallengeIdOrContestId;
    const data = {
        "arrStageStatus": arrStageStatus
    };
    method = method.replace('#Id', id);
    method = method.replace('#IsChallenge', isChallenge);
    method = method.replace('#pageIndex', pageIndex);
    const URL = process.env.REACT_APP_IDEA_STAGESERVICE_LAMBDA + method;
    const config = {
        headers: {
            IsBatchProcess: true,
        }
    };
    return postMethod(URL, data, config);
}

export const GetContestAnnouncement=(contestId)=>{
    let method = serviceMethod.stageVoteService.GetContestAnnouncement;
    method = method.replace('#contestId', contestId);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const GetHallOfFameData = (ContestIds, pageIndex = 1) => {
    let method = serviceMethod.stageVoteService.GetHallOfFameData;
    const data = {
        "Contestids": ContestIds,
        "Fiscalyear": "2022",
        "Marketunits": [],
        "ContestType": "Employee Contest",
        "Innovator": ""
    }
    method = method.replace('#pageIndex', pageIndex);
    const URL = process.env.REACT_APP_IDEA_ALLOCATIONSERVICE + method;
    const config = {
        //headers: {
        //    IsBatchProcess: true,
        //}
    };
    return postMethod(URL, data, config);
}

export const GetContestInsight = (contestId, NumberOfDays) => {
    let method = serviceMethod.stageVoteService.GetContestInsight;
    method = method.replace('#ContestId', contestId);
    method = method.replace('#NumberOfDays', NumberOfDays);
    const URL = process.env.REACT_APP_IDEASERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const GetIdeaByAwardType = (contestId,awardType) => {
    let method = serviceMethod.stageVoteService.GetIdeaByAwardType;
    method = method.replace('#ContestId', contestId);
    method = method.replace('#AwardType', awardType);
    const URL = process.env.REACT_APP_IDEA_ALLOCATIONSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const GetContestQuickLinks = (contestId,type) => {
    let method = serviceMethod.stageVoteService.GetContestQuickLinks;
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const data = {
        "ContestId": contestId,
        "QuickLinkType": type
    }
    const config = {
        headers: {
            IsBatchProcess: true,
        }
    };
    return postMethod(URL, data, config);
}

export const GetContestQuickLinkTypes = (contestId, type) => {
    let method = serviceMethod.stageVoteService.GetContestQuickLinkTypes;
    method = method.replace('#ContestId', contestId);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const addStageRequestQueue = (payloadObject) => {
    let method = serviceMethod.stageVoteService.AddStageRequestQueue;
    const URL = process.env.REACT_APP_IDEA_ALLOCATIONSERVICE + method;
    const data = payloadObject;
    const config = {
        headers: {
            IsBatchProcess: true,
        }
    };
    return postMethod(URL, data, config);
}

export const getRequestQueueDetails = (payloadObject) => {
    let method = serviceMethod.stageVoteService.GetRequestQueueDetails;
    const URL = process.env.REACT_APP_IDEA_STAGESERVICE_LAMBDA + method;
    const data = payloadObject
    const config = {
        headers: {
            IsBatchProcess: true,
        }
    };
    return postMethod(URL, data, config);
}

export const uploadFileToQueue = (stageRequestQueueId, file) => {
    let method = serviceMethod.stageVoteService.AddFile;   
    // const formData = new FormData();
    // formData.append('fileName', file);
    method = method.replace('#StageRequestQueueId', stageRequestQueueId);
    const URL = process.env.REACT_APP_IDEA_STAGESERVICE_LAMBDA + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return postMethod(URL, file, config);
}

export const getIdeaAllocationQueueStatus = (stageReqQueueId) => {
    let method = serviceMethod.stageVoteService.GetIdeaAllocationQueueStatus;
    method = method.replace('#StageReqQueueId', stageReqQueueId);
    const URL = process.env.REACT_APP_IDEA_STAGESERVICE_LAMBDA + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };
    return getMethod(URL, config);
}

export const downloadFileStageVoteService = (fileName, id) => {
    
    let method = serviceMethod.download.downloadDoc;
    method = method.replace("#FileName", encodeURIComponent(fileName));
    method = method.replace("#Id", id);

    const URL = process.env.REACT_APP_STAGESERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
}
export const GetDownloadPreSignedUrl =(fileName,propertyName,id, type="mediafile")=>{
    let method = '';
    if(propertyName && propertyName != null){
        method = serviceMethod.presignedUrl.GetDownloadPreSignedUrlWithProp;
    }
    else{
        method = serviceMethod.presignedUrl.GetDownloadPreSignedUrl;
    }
    method = method.replace("#FileName", encodeURIComponent(fileName));
    method = method.replace("#Id", id);
    method = method.replace('#Type', type);
    if(propertyName && propertyName != null){
        let propUpdated = propertyName.split('_').filter((prop,ind) => ind > 0).join('_');
        method = method.replace('#propertyName', propUpdated);
    }

    const URL = process.env.REACT_APP_DOCUMENTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
}


export const getBannerImageByteData = (id, fileName) => {
    let method = serviceMethod.contest.getBannerImageByteData;
    method = method.replace('#id', id);
    method = method.replace('#fileName', fileName);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
}

export const getStageImageByteData = (id, fileName) => {
    let method = serviceMethod.contest.getStageImageByteData;
    method = method.replace('#id', id);
    method = method.replace('#fileName', fileName);
    const URL = process.env.REACT_APP_CONTESTSERVICE + method;
    const config = {
        headers: {
            IsBatchProcess: true
        }
    };

    return getMethod(URL, config);
}

